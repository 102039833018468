import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "5%", className: "text-center", label: "ID Bujt" },
        { width: "5%", className: "text-center", label: "ID Gerbang Masuk" },
        { width: "5%", className: "text-center", label: "ID Gerbang Keluar" },
        { width: "5%", className: "text-center", label: "ID Golongan" },
        { width: "5%", className: "text-center", label: "Tarif" },
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.id_bujt}</td>
            <td className="text-center">{item.id_gerbang_masuk}</td>
            <td className="text-center">{item.id_gerbang_keluar}</td>
            <td className="text-center">{item.golongan_id}</td>
            <td className="text-center">{item.tarif_nilai}</td>
        </tr>
    ));
};

const BujtTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default BujtTable;
