import React from "react";

// komponen
import {
    Input,
    InputRadioInline,
    InputSelectReact,
    InputSelectAsync
} from "../../../components/AdminLTE";

const FieldEvent = props => {
    return (
        <fieldset className="mt-2">
            <legend>Form Event : </legend>
            <div className="form-group row">
                <label
                    htmlFor={"event_name"}
                    className="control-label col-md-3 mt-1"
                >
                    Nama Event
                </label>
                <div className="col-md-4">
                    <Input
                        id={"event_name"}
                        type="text"
                        placeholder="Nama Event"
                        has_error={props.error_field.event_name}
                        defaultValue={props.event_name}
                        onChange={ev =>
                            props.handleChange("event_name", ev.target.value)
                        }
                        error_field={props.error_field.event_name}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"event_date"}
                    className="control-label col-md-3 mt-1"
                >
                    Tanggal Event
                </label>
                <div className="col-md-7">
                    <Input
                            id={"event_date"}
                            type="date"
                            placeholder="Tanggal Event"
                            has_error={props.error_field.event_date}
                            defaultValue={props.event_date}
                            onChange={ev =>
                                props.handleChange("event_date", ev.target.value)
                            }
                            error_field={props.error_field.event_date}
                        />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"event_time"}
                    className="control-label col-md-3 mt-1"
                >
                    Waktu
                </label>
                <div className="col-md-2">
                    <Input
                        id={"event_time"}
                        type="text"
                        placeholder="hh:mm"
                        has_error={props.error_field.event_time}
                        defaultValue={props.event_time}
                        onChange={ev =>
                            props.handleChange("event_time", ev.target.value)
                        }
                        error_field={props.error_field.event_time}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"event_location"}
                    className="control-label col-md-3 mt-1"
                >
                    Lokasi Event
                </label>
                <div className="col-md-7">
                    <Input
                        id={"event_location"}
                        type="text"
                        placeholder="Lokasi Event"
                        has_error={props.error_field.event_location}
                        defaultValue={props.event_location}
                        onChange={ev =>
                            props.handleChange("event_location", ev.target.value)
                        }
                        error_field={props.error_field.event_location}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"event_description"}
                    className="control-label col-md-3 mt-1"
                >
                    Deskripsi
                </label>
                <div className="col-md-7">
                    <textarea
                        id={"event_description"}
                        placeholder="Deskripsi Event"
                        has_error={props.error_field.event_description}
                        defaultValue={props.event_description}
                        onChange={ev =>
                            props.handleChange("event_description", ev.target.value)
                        }
                        error_field={props.error_field.event_description}
                        rows={8} 
                        cols={50} 
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"event_status"}
                    className="control-label col-md-3 mt-1"
                >
                    Status Event
                </label>
                <div className="col-md-4">
                    <InputSelectReact
                        id={"event_status"}
                        has_error={props.error_field.event_status}
                        defaultValue={{
                            label: props.event_status_name,
                            value: props.event_status
                        }}
                        onChange={item => {
                            props.handleChange("event_status", item.value);
                            props.handleChange("event_status_name", item.label);
                        }}
                        error_field={props.error_field.event_status}
                        options={props.list_status}
                    />
                </div>
            </div>
    
    
        </fieldset>
    );
};

export default FieldEvent;
