import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class KecelakaanModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        //console.log(props);
        
        this.state = {
            loading: false,
            tanggal: props.detail ? props.detail.tanggal : "",
            waktu: props.detail ? props.detail.waktu : "",
            id: props.detail ? props.detail.id : "",
            detail_lokasi: props.detail_lokasi ? props.detail.detail_lokasi : "",
            jml_kendaraan: props.detail ? props.detail.jml_kendaraan : "",
            luka_ringan: props.detail ? props.detail.luka_ringan : "",
            luka_berat: props.detail ? props.detail.luka_berat : "",
            meninggal: props.detail ? props.detail.meninggal : "",
            penyebab_id: props.detail ? props.detail.penyebab_id : "",
            penyebab_nama: props.detail ? props.detail.penyebab_nama : "",
            uraian: props.detail ? props.detail.uraian : "",
            patroli_id: props.detail ? props.detail.patroli_id : "",
            patroli_nama: props.detail ? props.detail.patroli_nama : "",
            ambulance_kp_id: props.detail ? props.detail.ambulance_kp_id : "",
            ambulance_kp_nama: props.detail ? props.detail.ambulance_kp_nama : "",
            ambulance_kh_id: props.detail ? props.detail.ambulance_kh_id : "",
            ambulance_kh_nama: props.detail ? props.detail.ambulance_kh_nama : "",
            rescue_id: props.detail ? props.detail.rescue_id : "",
            rescue_nama: props.detail ? props.detail.rescue_nama : "",
            derek_id: props.detail ? props.detail.derek_id : "",
            derek_nama: props.detail ? props.detail.derek_nama : "",
            satgas_id: props.detail ? props.detail.satgas_id : "",
            satgas_nama: props.detail ? props.detail.satgas_nama : "",
            pjr_id: props.detail ? props.detail.pjr_id : "",
            pjr_nama: props.detail ? props.detail.pjr_nama : "",
            damkar_id: props.detail ? props.detail.damkar_id : "",
            damkar_nama: props.detail ? props.detail.damkar_nama : "",
            catatan_lain: props.detail ? props.detail.catatan_lain : "",
            barang_bukti: props.detail ? props.detail.barang_bukti : "",
            foto1: props.detail ? props.detail.foto1 : "",
            foto2: props.detail ? props.detail.foto2 : "",
            foto3: props.detail ? props.detail.foto3 : "",
            waktu_laka: props.detail ? props.detail.waktu_laka : "",
            no_laporan: props.detail ? props.detail.no_laporan : "",
            form_id: props.detail ? props.detail.form_id : "",
            shift_id: props.detail ? props.detail.shift_id : "",
            unit_id: props.detail ? props.detail.unit_id : "",
            unit_nama: props.detail ? props.detail.unit_nama : "",
            event_id: props.detail ? props.detail.event_id : "",
            jenis_laka_id: props.detail ? props.detail.jenis_laka_id : "",
            jenis_laka_nama: props.detail ? props.detail.jenis_laka_nama : "",
            km: props.detail ? props.detail.km : "",
            km_to: props.detail ? props.detail.km_to : "",
            id_lokasi: props.detail ? props.detail.id_lokasi : "",
            nama_lokasi: props.detail ? props.detail.nama_lokasi : "",
            status: props.detail ? props.detail.status : "",
            kendaraan_terlibat:props.detail ? props.detail.kendaraan : [],
            lokasi_kecelakaan:props.lokasi_kecelakaan,
            penyebab_kecelakaan:props.penyebab_kecelakaan,
            unit_patroli:props.unit_patroli,
            unit_ambulance_kp:props.unit_ambulance_kp,
            unit_ambulance_kh:props.unit_ambulance_kh,
            unit_rescue:props.unit_rescue,
            unit_derek:props.unit_derek,
            unit_satgas:props.unit_satgas,
            unit_pjr:props.unit_pjr,
            unit_damkar:props.unit_damkar,
            jenis_kecelakaan:props.jenis_kecelakaan,
            penumpang:props.penumpang,
            combo_kendaraan:props.combo_kendaraan,
            jenis_kelamin:props.jenis_kelamin,
            kondisi:props.kondisi,
            error_field: {
                gardu_nama: null
            }
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);     
        this.handleChangeKendaraan = this.handleChangeKendaraan.bind(this);
        this.handleHapusKendaraan = this.handleHapusKendaraan.bind(this);
        this.handleChangePenumpang = this.handleChangePenumpang.bind(this);
        this.handleHapusPenumpang = this.handleHapusPenumpang.bind(this);
        this.addKendaraan = this.addKendaraan.bind(this);
        this.addPenumpang = this.addPenumpang.bind(this);
        
    }

    
    addKendaraan() {
        let kendaraan = this.state.kendaraan_terlibat;
        
        const new_row = {merk:"",seri:"",plat_nomor:"",jml_penumpang:"1"};
        kendaraan.push(new_row);
                
        this.setState({ kendaraan_terlibat: kendaraan });
    }
    
    addPenumpang() {
        let penumpang = this.state.penumpang;
        
        const new_row = {nik:"",nama:"",alamat:"",jenis_kelamin_id:"",jenis_kelamin:"",usia:"",kondisi_id:"",kondisi:"",kendaraan_id:"",kendaraan_label:""};
        penumpang.push(new_row);
        this.setState({ penumpang: penumpang });
    }
    
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
        
    }
    
    handleChangeKendaraan(field, value, index) {
        // set state disini akan kompleks
        let kendaraan = this.state.kendaraan_terlibat;
        kendaraan[index][field] = value;
        
        let combo_kendaraan = [];
        
        kendaraan.map((row,i) => {
            combo_kendaraan.push({id:row.plat_nomor, label:row.merk+' '+row.seri+' '+row.plat_nomor});
        });
        
        this.setState({ kendaraan_terlibat: kendaraan, combo_kendaraan:combo_kendaraan });
        
        
        
    }
    
    handleHapusKendaraan(index) {
        // set state disini akan kompleks
        let kendaraan = this.state.kendaraan_terlibat;
        kendaraan.splice(index, 1);
        this.setState({ kendaraan_terlibat: kendaraan });
    }
    
    handleChangePenumpang(field, value, index) {
        // set state disini akan kompleks
        let penumpang = this.state.penumpang;
        penumpang[index][field] = value;
        this.setState({ penumpang: penumpang });
    }
    
    handleHapusPenumpang( index) {
        // set state disini akan kompleks
        let penumpang = this.state.penumpang;
        penumpang.splice(index, 1);
        this.setState({ penumpang: penumpang });
    }
    

    onSubmit = evt => {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        
        that.setState({ loading: true }, function() {
            const params = {
                tanggal                 : this.state.tanggal,
                waktu                   : this.state.waktu,
                id                      : this.state.id,
                detail_lokasi           : this.state.detail_lokasi,
                jml_kendaraan           : this.state.jml_kendaraan,
                luka_ringan             : this.state.luka_ringan,
                luka_berat              : this.state.luka_berat,
                meninggal               : this.state.meninggal,
                penyebab_id             : this.state.penyebab_id,
                penyebab_nama           : this.state.penyebab_nama,
                uraian                  : this.state.uraian,
                patroli_id              : this.state.patroli_id,
                patroli_nama            : this.state.patroli_nama,
                ambulance_kp_id         : this.state.ambulance_kp_id,
                ambulance_kp_nama       : this.state.ambulance_kp_nama,
                ambulance_kh_id         : this.state.ambulance_kh_id,
                ambulance_kh_nama       : this.state.ambulance_kh_nama,
                rescue_id               : this.state.rescue_id,
                rescue_nama             : this.state.rescue_nama,
                derek_id                : this.state.derek_id,
                derek_nama              : this.state.derek_nama,
                satgas_id               : this.state.satgas_id,
                satgas_nama             : this.state.satgas_nama,
                pjr_id                  : this.state.pjr_id,
                pjr_nama                : this.state.pjr_nama,
                damkar_id               : this.state.damkar_id,
                damkar_nama             : this.state.damkar_nama,
                catatan_lain            : this.state.catatan_lain,
                barang_bukti            : this.state.barang_bukti,
                waktu_laka              : this.state.waktu_laka,
                jenis_laka_id           : this.state.jenis_laka_id,
                jenis_laka_nama         : this.state.jenis_laka_nama,
                km                      : this.state.km,
                km_to                   : this.state.km_to,
                id_lokasi               : this.state.id_lokasi,
                nama_lokasi             : this.state.nama_lokasi,
                kendaraan_terlibat      : this.state.kendaraan_terlibat,
                penumpang               : this.state.penumpang

            };
            
            
            PostPrivate(that, `transaksi/kecelakaan/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow('');
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });     
        });
    };
    
    render() {
        // return
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ display: !this.state.form_mode ? 'block' : 'none' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">No. Laporan</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.no_laporan}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Tanggal</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="date" 
                                                        className="form-control" 
                                                        value={this.state.tanggal}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "tanggal",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.tanggal
                                                        }
                                                    />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jam</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.waktu}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "waktu",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.waktu
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Lokasi KM</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.km}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "km",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.km
                                                        }
                                                    />
                                                </div>
                                                <label className="col-sm-2 col-form-label">KM Hingga</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.km_to}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "km_to",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.km_to
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Jenis Lokasi</label>
                                                <div className="col-md-8">
                                                    <InputSelectReact
                                                            id={"lokasi_kecelakaan"}
                                                            has_error={this.state.error_field.lokasi_kecelakaan}
                                                            defaultValue={{
                                                                label: this.state.nama_lokasi,
                                                                value: this.state.id_lokasi
                                                            }}
                                                            onChange={item => {
                                                                this.handleChange("id_lokasi", item.id);
                                                                this.handleChange("nama_lokasi", item.label);
                                                            }}
                                                            error_field={this.state.error_field.lokasi_kecelakaan}
                                                            options={this.state.lokasi_kecelakaan}
                                                        />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Kategori Kejadian</label>
                                                <div className="col-md-8">
                                                    <InputSelectReact
                                                            id={"jenis_kecelakaan"}
                                                            has_error={this.state.error_field.jenis_kecelakaan}
                                                            defaultValue={{
                                                                label: this.state.jenis_laka_nama,
                                                                value: this.state.jenis_laka_id
                                                            }}
                                                            onChange={item => {
                                                                this.handleChange("jenis_laka_id", item.id);
                                                                this.handleChange("jenis_laka_nama", item.label);
                                                            }}
                                                            error_field={this.state.error_field.jenis_kecelakaan}
                                                            options={this.state.jenis_kecelakaan}
                                                        />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Deskripsi Singkat</label>
                                                <div className="col-md-8">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.uraian}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "uraian",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.uraian
                                                        }
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Lokasi Lainnya</label>
                                                <div className="col-md-8">
                                                    <textarea 
                                                        className="form-control" 
                                                        value={this.state.detail_lokasi}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "detail_lokasi",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.detail_lokasi
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Gambar Lampiran</label>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <div className="col-sm-4"><img src={this.state.foto1} className="img-fluid" alt="foto1" width="300" /></div>
                                                <div className="col-sm-4"><img src={this.state.foto2} className="img-fluid" alt="foto2" width="300" /></div>
                                                <div className="col-sm-4"><img src={this.state.foto3} className="img-fluid" alt="foto3" width="300" /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Dugaan Penyebab Kecelakaan</label>
                                                <div className="col-md-4">
                                                    <InputSelectReact
                                                            id={"penyebab_kecelakaan"}
                                                            has_error={this.state.error_field.penyebab_kecelakaan}
                                                            defaultValue={{
                                                                label: this.state.penyebab_nama,
                                                                value: this.state.penyebab_id
                                                            }}
                                                            onChange={item => {
                                                                this.handleChange("penyebab_id", item.id);
                                                                this.handleChange("penyebab_nama", item.label);
                                                            }}
                                                            error_field={this.state.error_field.penyebab_kecelakaan}
                                                            options={this.state.penyebab_kecelakaan}
                                                    />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jumlah Korban Meninggal</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.meninggal}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "meninggal",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.meninggal
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Jumlah Kendaraan Terlibat</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.jml_kendaraan}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "jml_kendaraan",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.jml_kendaraan
                                                        }
                                                    />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jumlah Luka Berat</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.luka_berat}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "luka_berat",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.luka_berat
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-sm-offset-6 col-form-label">Jumlah Luka Ringan</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.luka_ringan}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "luka_ringan",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.luka_ringan
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Patroli</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.patroli_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "patroli_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.patroli_id
                                                        }
                                                    />
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Derek</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.derek_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "derek_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.derek_id
                                                        }
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Ambulance</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.ambulance_kp_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "ambulance_kp_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.ambulance_kp_id
                                                        }
                                                    />
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong PJR</label>
                                                <div className="col-md-4">
                                                   
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.pjr_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "pjr_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.pjr_id
                                                        }
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Satgas</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.satgas_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "satgas_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.satgas_id
                                                        }
                                                    />
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Rescue</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.rescue_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "rescue_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.rescue_id
                                                        }
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Damkar</label>
                                                <div className="col-md-4">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.damkar_id}
                                                        onChange={ev =>
                                                            this.handleChange(
                                                                "damkar_id",
                                                                ev.target.value
                                                            )
                                                        }
                                                        error_field={
                                                            this.state.error_field.damkar_id
                                                        }
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-8 col-form-label">Detail Kendaraan</label>
                                                <div className="col-sm-4">{this.state.status != 1 &&<Button type="button" className="btn btn-success" style={{float:'right'}} onClick={() => this.addKendaraan()}>Tambah</Button>}</div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                              <th scope="col">No</th>
                                                              <th scope="col">Merk</th>
                                                              <th scope="col">Seri</th>
                                                              <th scope="col">Plat Nomor</th>
                                                              <th scope="col">Jml Penumpang</th>
                                                              {this.state.status != 1 &&
                                                              <th scope="col">Action</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody id="body_kendaraan">
                                                            {this.state.kendaraan_terlibat.map((row, i) => (
                                                                <tr key={i + 1}>
                                                                    <td scope="row">{i + 1}</td>
                                                                    <td>
                                                                    <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            value={row.merk}
                                                                            onChange={ev =>
                                                                                this.handleChangeKendaraan(
                                                                                    "merk",
                                                                                    ev.target.value,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                        </td>
                                                                    <td>
                                                                    <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            value={row.seri}
                                                                            onChange={ev =>
                                                                                this.handleChangeKendaraan(
                                                                                    "seri",
                                                                                    ev.target.value,
                                                                                    i
                                                                                )
                                                                            }
                                                                        /></td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            value={row.plat_nomor}
                                                                            onChange={ev =>
                                                                                this.handleChangeKendaraan(
                                                                                    "plat_nomor",
                                                                                    ev.target.value,
                                                                                    i
                                                                                )
                                                                            }
                                                                        /></td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            value={row.jml_penumpang}
                                                                            onChange={ev =>
                                                                                this.handleChangeKendaraan(
                                                                                    "jml_penumpang",
                                                                                    ev.target.value,
                                                                                    i
                                                                                )
                                                                            }
                                                                        /></td>
                                                                        {this.state.status != 1 &&
                                                                        <td>
                                                                        <Button 
                                                                            type="button" 
                                                                            className="btn btn-danger"                                                                            
                                                                            onClick={ev =>
                                                                                this.handleHapusKendaraan(
                                                                                    i
                                                                                )
                                                                            }
                                                                        >Hapus</Button></td>}
                                                                </tr>
                                                            ))}
                                                            
                                                            
                                                            {this.state.kendaraan_terlibat.length === 0 &&
                                                                <tr><td className="text-center" colSpan="5">- Tidak ada data- </td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                            </div>
                            <div className="form-group row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-8 col-form-label">Penumpang Kendaraan</label>
                                <div className="col-sm-4">{this.state.status != 1 &&<Button type="button" className="btn btn-success" style={{float:'right'}} onClick={() => this.addPenumpang()}>Tambah</Button>}</div>
                            </div>
                            <div className="form-group row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <table className="table">
                                            <thead>
                                                <tr>
                                                  <th scope="col">No</th>
                                                  <th scope="col" style={{width:'250px'}}>Kendaraan</th>
                                                  <th scope="col">NIK/Nama</th>
                                                  <th scope="col">Jenis Kelamin/Usia</th>
                                                  <th scope="col">Kondisi/Alamat</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="body_penumpang">
                                                {this.state.penumpang.map((row, i) => (
                                                    <tr key={i + 1}>
                                                        <td scope="row">{i + 1}</td>
                                                        <td>
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.combo_kendaraan}
                                                                defaultValue={{
                                                                    label: this.state.penumpang[i].kendaraan_label,
                                                                    value: this.state.penumpang[i].kendaraan_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangePenumpang("kendaraan_id", item.id, i);
                                                                    this.handleChangePenumpang("kendaraan_label", item.label,i);
                                                                }}
                                                                error_field={this.state.error_field.combo_kendaraan}
                                                                options={this.state.combo_kendaraan}
                                                        />
                                                        </td>
                                                        <td>
                                                        <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={row.nik}
                                                                onChange={ev =>
                                                                    this.handleChangePenumpang(
                                                                        "nik",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={row.nama}
                                                                onChange={ev =>
                                                                    this.handleChangePenumpang(
                                                                        "nama",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                            
                                                            </td>
                                                        <td>
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.jenis_kelamin}
                                                                defaultValue={{
                                                                    label: this.state.penumpang[i].jenis_kelamin,
                                                                    value: this.state.penumpang[i].jenis_kelamin_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangePenumpang("jenis_kelamin_id", item.id, i);
                                                                    this.handleChangePenumpang("jenis_kelamin", item.label,i);
                                                                }}
                                                                error_field={this.state.error_field.jenis_kelamin}
                                                                options={this.state.jenis_kelamin}
                                                            />
                                                        
                                                            
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={row.usia}
                                                                onChange={ev =>
                                                                    this.handleChangePenumpang(
                                                                        "usia",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                            
                                                        </td>
                                                        <td>
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.kondisi}
                                                                defaultValue={{
                                                                    label: this.state.penumpang[i].kondisi,
                                                                    value: this.state.penumpang[i].kondisi_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangePenumpang("kondisi_id", item.id, i);
                                                                    this.handleChangePenumpang("kondisi", item.label,i);
                                                                }}
                                                                error_field={this.state.error_field.kondisi}
                                                                options={this.state.kondisi}
                                                            />
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={row.alamat}
                                                                onChange={ev =>
                                                                    this.handleChangePenumpang(
                                                                        "alamat",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                            /></td>
                                                            <td>{this.state.status != 1 &&
                                                                <Button 
                                                                            type="button" 
                                                                            className="btn btn-danger"                                                                            
                                                                            onClick={ev =>
                                                                                this.handleHapusPenumpang(
                                                                                    i
                                                                                )
                                                                            }
                                                                >Hapus</Button>}
                                                            </td>
                                                    </tr>
                                                ))}


                                                {this.state.penumpang.length === 0 &&
                                                    <tr><td className="text-center" colSpan="6">- Tidak ada data- </td></tr>
                                                }
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                             <div className="form-group row">
                                <div className="col-sm-12">
                                    <div style={{float:'right'}}>
                                    <Button type="button" className="btn btn-danger" onClick={() => this.props.toogleShow('')}>Batal</Button>
                                    {this.state.status != 1 &&
                                    <Button type="submit" className="btn btn-success">Simpan</Button>
                                    }
                                    </div>
                                </div>
                            </div>
                         </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.props.toogleShow('')}
                    onSubmit={this.onSubmit}
                />
        )
    }
    
    
}

export default KecelakaanModal;
