import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact,
    InputTextarea,
    Loading,
} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class PersonelModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // datas
        this.detail_id = this.props.item.detail_id
            ? this.props.item.detail_id
            : "";
        this.title =
            this.props.mode == "edit"
                ? "Ubah Laporan Harian Petugas PIK"
                : "Tambah Laporan Harian Petugas PIK";
        // state
        this.state = {
            detail_time_receive_info: "",
            detail_activity: "",
            detail_description: "",
            // error field
            error_field: {},
            // loading
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
    }
    // component did mount
    componentDidMount() {
        if (this.detail_id) this.loadDetail();
    }
    // load detail
    loadDetail() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `pik/dailyreport/detail/${this.detail_id}`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        detail_time_receive_info:
                            result.data.detail_time_receive_info,
                        detail_activity: result.data.detail_activity,
                        detail_description: result.data.detail_description,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // handle change
    handleChange(name, value) {
        this.setState({ [name]: value });
    }
    // on submit
    onSubmit = (evt) => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                detail_id: that.detail_id,
                detail_time_receive_info: that.state.detail_time_receive_info,
                detail_activity: that.state.detail_activity,
                detail_description: that.state.detail_description,
            };
            // paggil post api
            PostPrivate(that, `pik/dailyreport/${that.props.mode}`, params)
                .then((result) => {
                    that.setState(
                        {
                            loading: false,
                        },
                        function () {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch((error) => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error,
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    this.state.loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_time_receive_info"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Waktu Terima Informasi
                                </label>
                                <div className="col-sm-3">
                                    <Input
                                        id={"detail_time_receive_info"}
                                        type="time"
                                        placeholder="Waktu Terima Informasi"
                                        has_error={
                                            this.state.error_field
                                                .detail_time_receive_info
                                        }
                                        defaultValue={
                                            this.state.detail_time_receive_info
                                        }
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_time_receive_info",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_time_receive_info
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_activity"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Uraian Kegiatan
                                </label>
                                <div className="col-sm-9">
                                    <InputTextarea
                                        id={"detail_activity"}
                                        type="text"
                                        placeholder="Uraian Kegiatan"
                                        has_error={
                                            this.state.error_field
                                                .detail_activity
                                        }
                                        defaultValue={
                                            this.state.detail_activity
                                        }
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_activity",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_activity
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_description"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Keterangan
                                </label>
                                <div className="col-sm-9">
                                    <InputTextarea
                                        id={"detail_description"}
                                        type="text"
                                        placeholder="Keterangan"
                                        has_error={
                                            this.state.error_field
                                                .detail_description
                                        }
                                        defaultValue={
                                            this.state.detail_description
                                        }
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_description",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_description
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default PersonelModal;
