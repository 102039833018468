import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import { Content, Button } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import ReportTable from "./table";

class Report extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            list_unit: [],
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // component did mount
    componentDidMount() {
        // load data
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/report`)
                .then(result => {
                    this.setState({
                        loading: false,
                        list_unit: result.data.list_unit,
                        list: result.data.list_report
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    // handleCheck
    handleCheck(index, unit_id, value) {
        let { list } = this.state;
        list[index]["list_unit"][unit_id] = value;
        this.setState({ list: list });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                report: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `referensi/report/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Daftar Laporan</h3>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <ReportTable
                                {...this.props}
                                handleChange={this.handleChange}
                                handleCheck={this.handleCheck}
                                list={this.state.list}
                                list_unit={this.state.list_unit}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default Report;
