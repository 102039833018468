import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Unit",
        selector: "nama",
        sortable: true,
        width: "75%"
    },
    {
        name: "Status",
        selector: "status_custom",
        sortable: true,
        width: "15%"
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        
        item["status_custom"] =
            item.status == "1" ? (
                <span className="label label-success">Aktif</span>
            ) : (
                <span className="label label-warning">Tidak Aktif</span>
            );
        
        /*
        item["action"] = (
            <>
                <ButtonRole
                    role_url={`${props.location.pathname}/unit/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/unit/${item.unit_id}`}
                        className="btn btn-sm btn-default mr-1"
                    >
                        Detail Form
                    </NavLink>
                </ButtonRole>
            </>
        );
        */
        return item;
    });
};

const FormTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_sarpras"}
            empty="Belum ada referensi unit, klik menu unit untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(FormTable));
