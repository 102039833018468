import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";

import logo from "../../../resources/img/logo-full.png";
import terbilang from "../../../utils/terbilang";

class Print extends Component {
     constructor (props) {
        super(props)

        this.state = {
            date: new Date(),
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
            id: null,
            persetujuan_pj: null,
            nilai_kesepakatan: 0,
            opsi_tidak_setuju: null,
            jenis_jaminan: null,
            verif_kadep: null,
            status: null,
            alasan_tidak_setuju: '',
            jenis_klarifikasi: null,
            nilai_jaminan: 0,
            lain_lain: ''
        }
        this.loadKlarifikasi = this.loadKlarifikasi.bind(this)
    }
    componentDidMount () {
        this.loadKlarifikasi()
    }
    loadKlarifikasi () {
        GetPrivate(this, `transaksi/klarifikasigantirugi/by_laka/` + this.props.id_laka).then(result => {
            if (result.data.id) {
                result.data.alasan_tidak_setuju = result.data.alasan_tidak_setuju ? result.data.alasan_tidak_setuju : ''
                result.data.lain_lain = result.data.lain_lain ? result.data.lain_lain : ''
                this.setState({
                    printable: result.data.status === 1 || result.data.status === '1',
                    id: result.data.id,
                    persetujuan_pj: result.data.persetujuan_pj,
                    nilai_kesepakatan: result.data.nilai_kesepakatan,
                    opsi_tidak_setuju: result.data.opsi_tidak_setuju,
                    jenis_jaminan: result.data.jenis_jaminan,
                    verif_kadep: result.data.verif_kadep,
                    status: result.data.status,
                    alasan_tidak_setuju: result.data.alasan_tidak_setuju,
                    jenis_klarifikasi: result.data.jenis_klarifikasi,
                    nilai_jaminan: result.data.nilai_jaminan,
                    lain_lain: result.data.lain_lain
                })
            } else {
                this.setState({
                    id: null,
                    persetujuan_pj: null,
                    nilai_kesepakatan: 0,
                    opsi_tidak_setuju: null,
                    jenis_jaminan: null,
                    verif_kadep: null,
                    status: null,
                    alasan_tidak_setuju: '',
                    jenis_klarifikasi: null,
                    nilai_jaminan: 0,
                    lain_lain: ''
                })
            }
        }).catch(error => {})
    }
    toCurrency (number) {
        const formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        })
        return formatter.format(number)
    }
    toThousandSeparated (number) {
        return this.toCurrency(number).substr(3)
    }
    nominalToSpeech (number) {
        if (number === null || number === '' || number === 0 || number === '0') {
            return 'Nol rupiah'
        } else {
            let text = terbilang(number) + ' rupiah'
            text = text.toLowerCase()
            text = text.charAt(0).toUpperCase() + text.slice(1)
            return text
        }
    }
    render () {
        return (
            <div id="receipt-container">
                <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid grey', marginBottom: '20px' }}>
                    <div style={{ float: 'left', width: '30%' }}>
                        <img src={logo} alt="logo" style={{ maxWidth: '194px', width: '194px' }} />
                    </div>
                    <div style={{ float: 'left', width: '70%', textAlign: 'right' }}>
                        <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>Jalan Tol { this.props.item.ruas_tol }</div>
                        <h1 style={{ marginTop: '0px' }}>KWITANSI GANTI RUGI</h1>
                    </div>
                </div>
                <div style={{ textAlign: 'right', paddingRight: '150px' }}>Jenis Pembayaran:</div>
                <table style={{ marginBottom: '10px', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '200px' }}>No. Kwitansi</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>Telah terima dari</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.no_wa_penanggung_jawab || '-' }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>Uang Sejumlah</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.state.id ? this.toCurrency(this.state.nilai_kesepakatan) : '' }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>Terbilang Rp</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.state.id ? this.nominalToSpeech(this.state.nilai_kesepakatan) : '' }</td>
                        </tr>
                        <tr style={{ verticalAlign: 'top' }}>
                            <td style={{ width: '200px' }}>Uraian Pembayaran</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>
                                { this.state.id &&
                                    <span>
                                        Ganti rugi dengan nilai asset { this.props.item.total_ganti_rugi } yang kemudian { this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2' ? 'tidak' : '' } disetujui dengan kesepakatan ganti rugi senilai { this.toCurrency(this.state.nilai_kesepakatan) }.&nbsp;
                                        { (this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2') &&
                                            <span>Pengendara memutuskan untuk { this.state.opsi_tidak_setuju === 1 || this.state.opsi_tidak_setuju === '1' ? 'Tidak Setuju Harga' : 'Tidak Setuju / Perbaikan Sendiri' } dengan alasan { this.state.alasan_tidak_setuju }</span>
                                        }.&nbsp;
                                        Pengendara memberikan { this.state.jenis_jaminan === 1 || this.state.jenis_jaminan === '1' ? 'Jaminan Kendaraan' : 'Jaminan Uang sebesar ' + this.toCurrency(this.state.nilai_jaminan) }.&nbsp;
                                        Ganti rugi ini diklarifikasi kepala departemen melalui { this.state.verif_kadep === 1 || this.state.verif_kadep === '1' ? 'Telefon' : 'Tatap Muka Langsung' }.
                                    </span>
                                }
                                { !this.state.id && <span>Ganti rugi dengan nilai asset { this.props.item.total_ganti_rugi }.</span> }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <table style={{ position: 'absolute', right: '25px', bottom: '25px', width: '325px', border: '1px solid grey' }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'center', padding: '3px' }}>Petugas Penerima Pembayaran</td>
                            </tr>
                            <tr>
                                <td><div style={{ height: '100px', borderTop: '1px solid grey', borderBottom: '1px solid grey', padding: '5px' }}>Tgl Pembayaran:</div></td>
                            </tr>
                            <tr>
                                <td style={{ height: '20px' }}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Print;
