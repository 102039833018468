import React from "react";

// komponen
import {
    Table,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// table
const thead = [
    { width: "5%", className: "text-center", label: "No" },
    { width: "5%", className: "text-center", label: "Icon" },
    { width: "30%", className: "text-center", label: "Judul Menu" },
    { width: "20%", className: "text-center", label: "URL" },
    { width: "10%", className: "text-center", label: "Urutan" },
    { width: "10%", className: "text-center", label: "Aktifkan ?" },
    { width: "10%", className: "text-center", label: "Tampilkan ?" },
    { width: "10%", className: "text-center", label: "" }
];

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.nav_id}>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">
                <i className={item.nav_icon}></i>
            </td>
            <td>{item.nav_title}</td>
            <td>{item.nav_url}</td>
            <td className="text-center">{item.nav_no}</td>
            <td className="text-center">
                {item.active_st == "1" ? (
                    <span className="label label-success">Aktif</span>
                ) : (
                    <span className="label label-warning">Tidak</span>
                )}
            </td>
            <td className="text-center">
                {item.display_st == "1" ? (
                    <span className="label label-success">Tampil</span>
                ) : (
                    <span className="label label-warning">Tidak</span>
                )}
            </td>
            <td className="text-center">
                <ButtonGroup>
                    <ButtonEdit onClick={() => props.toogleShow("edit", item)}>
                        <i className="fa fa-edit"></i>
                    </ButtonEdit>
                    <ButtonDelete onClick={() => props.deleteConfirm(item)}>
                        <i className="fa fa-trash"></i>
                    </ButtonDelete>
                </ButtonGroup>
            </td>
        </tr>
    ));
};

const DetailTable = props => {
    return (
        <Table
            thead={thead}
            tbody={
                <Tbody
                    list={props.list}
                    toogleShow={props.toogleShow}
                    deleteConfirm={props.deleteConfirm}
                />
            }
            empty="Belum ada menu aplikasi, klik + untuk menambahkan menu aplikasi"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default DetailTable;
