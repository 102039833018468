import React from "react";

// komponen
import {
    Input,
    InputSelectReact,
} from "../../../components/AdminLTE";

const FieldFasilitas = props => {
    return (
        <fieldset className="mt-2">
            <legend>Fasilitas : </legend>
            {/* <div className="form-group row">
                <label
                    htmlFor={"id_fasilitas"}
                    className="control-label col-md-3 mt-1"
                >
                    ID Fasilitas
                </label>
                <div className="col-md-4">
                    <Input
                        id={"id_fasilitas"}
                        type="text"
                        placeholder="ID Fasilitas"
                        has_error={props.error_field.id_fasilitas}
                        defaultValue={props.id_fasilitas}
                        onChange={ev =>
                            props.handleChange("id_fasilitas", ev.target.value)
                        }
                        error_field={props.error_field.id_fasilitas}
                    />
                </div>
            </div> */}
            <div className="form-group row">
                <label
                    htmlFor={"nama_deskripsi"}
                    className="control-label col-md-3 mt-1"
                >
                   Nama Deskripsi
                </label>
                <div className="col-md-7">
                    <Input
                        id={"nama_deskripsi"}
                        type="text"
                        placeholder="Nama Deskripsi"
                        has_error={props.error_field.nama_deskripsi}
                        defaultValue={props.nama_deskripsi}
                        onChange={ev =>
                            props.handleChange("nama_deskripsi", ev.target.value)
                        }
                        error_field={props.error_field.nama_deskripsi}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label
                    htmlFor={"id"}
                    className="control-label col-md-3 mt-1"
                >
                    Kategori
                </label>
                <div className="col-md-4">
                    <InputSelectReact
                        id={"id"}
                        has_error={props.error_field.id}
                        defaultValue={{
                            label: props.nama,
                            value: props.id
                        }}
                        onChange={item => {
                            props.handleChange("id", item.value);
                        }}
                        error_field={props.error_field.id}
                        options={props.list_kategori}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"lattitude"}
                    className="control-label col-md-3 mt-1"
                >
                    Lattitude
                </label>
                <div className="col-md-7">
                <Input
                        id={"lattitude"}
                        type="text"
                        placeholder="Lattitude"
                        has_error={props.error_field.lattitude}
                        defaultValue={props.lattitude}
                        onChange={ev =>
                            props.handleChange("lattitude", ev.target.value)
                        }
                        error_field={props.error_field.lattitude}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"longitude"}
                    className="control-label col-md-3 mt-1"
                >
                    Longitude
                </label>
                <div className="col-md-7">
                <Input
                        type="text"
                        id={"longitude"}
                        placeholder="Longitude"
                        has_error={props.error_field.longitude}
                        defaultValue={props.longitude}
                        onChange={ev =>
                            props.handleChange("longitude", ev.target.value)
                        }
                        error_field={props.error_field.longitude}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"deskripsi"}
                    className="control-label col-md-3 mt-1"
                >
                    Deskripsi
                </label>
                <span className="help-block text-red">*Deskripsi wajib diisi !</span>
            </div>

            <div className="form-group row">
                <label
                    htmlFor={"id_bujt"}
                    className="control-label col-md-3 mt-1"
                >
                    Bujt
                </label>
                <div className="col-md-5">
                    <InputSelectReact
                        id={"id_bujt"}
                        has_error={props.error_field.id_bujt}
                        defaultValue={{
                            label: props.nama_bujt,
                            value: props.id_bujt
                        }}
                        onChange={item => {
                            if (item) props.handleChange("id_bujt", item.value);
                            else props.handleChange("id_bujt", "");
                        }}
                        error_field={props.error_field.id_bujt}
                        options={props.list_bujt}
                        isClearable={true}
                    />
                </div>
            </div>
            
            
        </fieldset>
    );
};

export default FieldFasilitas;
