import React, { Component } from "react";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// komponen
import {
    Content,
    Loading,
    Button,
    Callout,
} from "../../../components/AdminLTE";
import { SwalWarning, SwalSuccess } from "../../../components/Alert";

// pages
import ReportTable from "./table";
import Timeline from "./timeline";

class PikSend extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            execution_id: "",
            send_status: true,
            is_completed: false,
            list: [],
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `pik/sendreport`)
                .then((result) => {
                    // set state
                    this.setState({
                        loading: false,
                        list: result.data.list_report.map((item) => {
                            if (item.type == "presence") {
                                item["required"] = "0";
                            }
                            return item;
                        }),
                        execution_id: result.data.execution_id,
                        send_status: result.data.send_status == "yes",
                        is_completed: result.data.is_completed,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // on submit
    onSubmit = (evt) => {
        evt.preventDefault();
        // cek konfirmasi
        if (this.checkConfirm()) {
            return;
        }
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                execution_id: this.state.execution_id,
                report: JSON.stringify(that.state.list),
            };
            // paggil post api
            PostPrivate(that, `pik/sendreport/send`, params)
                .then((result) => {
                    that.setState(
                        {
                            loading: false,
                        },
                        function () {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch((error) => {
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    };
    // checkConfirm
    checkConfirm() {
        let { list } = this.state;
        for (let index = 0; index < list.length; index++) {
            if (list[index]["send_status"] == "no") {
                // alert
                SwalWarning("Harap konfirmasi laporan sebelum dikirim");
                return true;
            }
        }
        return false;
    }
    render() {
        const { is_completed, send_status, list } = this.state;
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <div className="row">
                        <div className="col-md-8">
                            <form onSubmit={this.onSubmit}>
                                <ReportContent
                                    {...this.props}
                                    is_completed={is_completed}
                                    send_status={send_status}
                                    list={list}
                                    handleChange={this.handleChange}
                                />
                            </form>
                        </div>
                        <div className="col-md-4">
                            <Timeline {...this.props} />
                        </div>
                    </div>
                )}
            </Content>
        );
    }
}

const ReportContent = (props) => {
    const callout = (
        <Callout
            type={props.is_completed ? "info" : "warning"}
            title="Perhatian"
            content={
                props.is_completed
                    ? "Laporan sudah memenuhi syarat pengiriman"
                    : "Harap melengkapi laporan sebelum dikirimkan"
            }
        />
    );
    return (
        <>
            {!props.send_status && callout}
            <div className="box no-border">
                <div className="box-body">
                    <ReportTable
                        list={props.list}
                        handleChange={props.handleChange}
                        send_status={props.send_status}
                    />
                </div>
            </div>
            {!props.send_status && (
                <div className="text-right">
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!props.is_completed}
                    >
                        Kirimkan Laporan
                    </Button>
                </div>
            )}
        </>
    );
};

export default PikSend;
