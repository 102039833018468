import React from "react";

// komponen
import { Input, InputRadioInline } from "../../../components/AdminLTE";

const FieldAkun = props => {
    return (
        <fieldset className="mt-2">
            <legend>Akun Pegawai : </legend>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_pass"}
                    className="control-label col-md-3 mt-1"
                >
                    Password Login
                </label>
                <div className="col-md-4">
                    <Input
                        id={"pegawai_pass"}
                        type="password"
                        placeholder="Password Login"
                        has_error={props.error_field.pegawai_pass}
                        defaultValue={props.pegawai_pass}
                        onChange={ev =>
                            props.handleChange("pegawai_pass", ev.target.value)
                        }
                        error_field={props.error_field.pegawai_pass}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_st__1"}
                    className="control-label col-md-3 mt-2"
                >
                    Status Akun ?
                </label>
                <div className="col-md-9">
                    <InputRadioInline
                        id={"pegawai_st"}
                        has_error={props.error_field.pegawai_st}
                        defaultChecked={props.pegawai_st}
                        onChange={ev =>
                            props.handleChange("pegawai_st", ev.target.value)
                        }
                        error_field={props.error_field.pegawai_st}
                        list={[
                            {
                                label: "Aktif",
                                value: "1"
                            },
                            {
                                label: "Suspend",
                                value: "0"
                            }
                        ]}
                    />
                </div>
            </div>
        </fieldset>
    );
};

export default FieldAkun;
