import React from "react";

// komponen
import Breadcrumb from "./breadcrumb";

const Content = props => {
    return (
        <>
            <section className="content-header" style={{zoom: props.zoom}}>
                <h1>
                    {props.title}
                    <small>{props.subtitle}</small>
                </h1>
                <Breadcrumb breadcrumb={props.breadcrumb} />
            </section>
            <section className="content container-fluid" style={{zoom: props.zoom}}>
                {props.children}
            </section>
        </>
    );
};

export default Content;
