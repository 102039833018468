import React, { useState, useMemo } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";

// komponen
import Loading from "./loading";
import Empty from "./empty";

const customStyles = {
    header: {
        style: {
            height: "100px"
        }
    },
    headRow: {
        style: {
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: defaultThemes.default.divider.default
        }
    },
    headCells: {
        style: {
            fontWeight: "bold",
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: defaultThemes.default.divider.default
            }
        }
    },
    cells: {
        style: {
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: defaultThemes.default.divider.default
            }
        }
    }
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="row">
        <input
            id="search__datatable"
            type="text"
            placeholder="Search"
            value={filterText}
            onChange={onFilter}
            className="form-control col-md-2"
        />
    </div>
);

const TableBootstrap = props => {
    // return data
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const search = new RegExp(filterText, "gi");
    const filteredItems = props.data.filter(item => {
        // return true;
        let list = Object.keys(item);
        for (let index = 0; index < list.length; index++) {
            if (search.exec(item[list[index]]) != null) return true;
        }
        return false;
    });
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        return (
            <FilterComponent
                onFilter={e => {
                    setFilterText(e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    // return loading
    if (props.loading) {
        return <Loading />;
    }
    return (
        <DataTable
            noHeader={true}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            columns={props.columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            // dense
            // customStyles={customStyles}
            progressPending={false}
            progressComponent={<Loading />}
            noDataComponent={<Empty content={props.empty} />}
        />
    );
};

export default TableBootstrap;
