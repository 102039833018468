import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";

import logo from "../../../resources/img/logo-full.png";

class Print extends Component {
     constructor (props) {
        super(props)

        this.state = {
            date: new Date(),
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
        }     
    }
    toThousandSeparated (number) {
        const formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        })
        return formatter.format(number).substr(3)
    }
    render () {
        return (
            <div id="print-container">
                <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid grey', marginBottom: '20px' }}>
                    <div style={{ float: 'left', width: '70%' }}>
                        <h2>Klarifikasi Ganti Rugi { this.props.item.jenis_klarifikasi === 2 || this.props.item.jenis_klarifikasi === '2' ? '(KHUSUS)' : '' }</h2>
                    </div>
                    <div style={{ float: 'left', width: '30%' }}>
                        <img src={logo} alt="logo" style={{ maxWidth: '194px', width: '194px' }} />
                        <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>Jalan Tol { this.props.item.ruas_tol }</div>
                    </div>
                </div>
                <table style={{ marginBottom: '10px', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '24%' }}><b>No. Laporan</b></td>
                            <td style={{ width: '1%' }}><b>:</b></td>
                            <td style={{ width: '25%' }}>{this.props.item.no_laporan || '-'}</td>
                            <td style={{ width: '24%' }}><b>Nilai Asset Rusak</b></td>
                            <td style={{ width: '1%' }}><b>:</b></td>
                            <td style={{ width: '25%' }}>{this.props.item.total_ganti_rugi || '-'}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <span style={{ position: 'relative', top: '10px', background: 'white', left: '20px', padding: '0px 5px' }}>PERSETUJUAN PENGGUNA JALAN</span>
                    <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '24%' }}>
                                    <input
                                        type="checkbox"
                                        name="persetujuan_pj"
                                        value="1"
                                        readOnly
                                        disabled={this.props.item.printable && this.props.item.persetujuan_pj !== 1 && this.props.item.persetujuan_pj !== '1'}
                                        checked={this.props.item.persetujuan_pj === 1 || this.props.item.persetujuan_pj === '1'}
                                    />
                                    <label style={{ marginLeft: '10px' }}>YA</label>
                                </td>
                                <td style={{ width: '20%' }}>dengan nilai sebesar</td>
                                <td style={{ width: '56%' }}>Rp { this.props.item.persetujuan_pj === 1 || this.props.item.persetujuan_pj === '1' ? this.toThousandSeparated(this.props.item.nilai_kesepakatan) : '' }</td>
                            </tr>
                            <tr>
                                <td style={{ width: '24%' }}>
                                    <input
                                        type="checkbox"
                                        name="persetujuan_pj"
                                        value="2"
                                        readOnly
                                        disabled={this.props.item.printable && this.props.item.persetujuan_pj !== 2 && this.props.item.persetujuan_pj !== '2'}
                                        checked={this.props.item.persetujuan_pj === 2 || this.props.item.persetujuan_pj === '2'}
                                    />
                                    <label style={{ marginLeft: '10px' }}>TIDAK</label>
                                </td>
                                <td style={{ width: '20%' }}>nilai kesepakatan sebesar</td>
                                <td style={{ width: '56%' }}>Rp { this.props.item.persetujuan_pj === 2 || this.props.item.persetujuan_pj === '2' ? this.toThousandSeparated(this.props.item.nilai_kesepakatan) : '' }</td>
                            </tr>
                            <tr><td colSpan="3" style={{ paddingTop: '20px' }}>Alasan tidak setuju :</td></tr>
                            <tr>
                                <td colSpan="3">
                                    <div style={{ borderBottom: '1px solid grey', minHeight: '50px' }}>
                                        { (this.props.item.persetujuan_pj === 2 || this.props.item.persetujuan_pj === '2') && this.props.item.alasan_tidak_setuju }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'inline-block', width: '100%' }}>
                    <div style={{ float: 'left', width: 'calc(50% - 10px)', paddingRight: '10px' }}>
                        <div>
                            <span style={{ position: 'relative', top: '10px', background: 'white', left: '20px', padding: '0px 5px' }}>OPSI TIDAK SETUJU</span>
                            <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="opsi_tidak_setuju"
                                                value="1"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.opsi_tidak_setuju !== 1 && this.props.item.opsi_tidak_setuju !== '1'}
                                                checked={this.props.item.opsi_tidak_setuju === 1 || this.props.item.opsi_tidak_setuju === '1'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Tidak Setuju Harga</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="opsi_tidak_setuju"
                                                value="2"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.opsi_tidak_setuju !== 2 && this.props.item.opsi_tidak_setuju !== '2'}
                                                checked={this.props.item.opsi_tidak_setuju === 2 || this.props.item.opsi_tidak_setuju === '2'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Tidak Setuju / Perbaikan Sendiri</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <span style={{ position: 'relative', top: '10px', background: 'white', left: '20px', padding: '0px 5px' }}>
                                { this.props.item.jenis_klarifikasi === 2 || this.props.item.jenis_klarifikasi === '2' ? 'VERIFIKASI PROJECT MANAGER' : 'VERIFIKASI KEPALA DEPARTEMEN' }
                            </span>
                            <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="verif_kadep"
                                                value="1"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.verif_kadep !== 1 && this.props.item.verif_kadep !== '1'}
                                                checked={this.props.item.verif_kadep === 1 || this.props.item.verif_kadep === '1'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>By Phone</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="verif_kadep"
                                                value="2"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.verif_kadep !== 2 && this.props.item.verif_kadep !== '2'}
                                                checked={this.props.item.verif_kadep === 2 || this.props.item.verif_kadep === '2'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Tatap Muka Langsung</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ float: 'left', width: 'calc(50% - 10px)', paddingLeft: '10px' }}>
                        <div>
                            <span style={{ position: 'relative', top: '10px', background: 'white', left: '20px', padding: '0px 5px' }}>JENIS JAMINAN</span>
                            <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <input
                                                type="checkbox"
                                                name="jenis_jaminan"
                                                value="1"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.jenis_jaminan !== 1 && this.props.item.jenis_jaminan !== '1'}
                                                checked={this.props.item.jenis_jaminan === 1 || this.props.item.jenis_jaminan === '1'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Jaminan Kendaraan</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <input
                                                type="checkbox"
                                                name="jenis_jaminan"
                                                value="2"
                                                readOnly
                                                disabled={this.props.item.printable && this.props.item.jenis_jaminan !== 2 && this.props.item.jenis_jaminan !== '2'}
                                                checked={this.props.item.jenis_jaminan === 2 || this.props.item.jenis_jaminan === '2'}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Jaminan Uang</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '60px' }}></td>
                                        <td>Nilai Jaminan Rp { this.props.item.jenis_jaminan === 2 || this.props.item.jenis_jaminan === '2' ? this.props.item.nilai_jaminan : '' }</td>
                                    </tr>
                                    <tr><td colSpan="2">Lain-lain</td></tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div style={{ borderBottom: '1px solid grey', minHeight: '50px' }}>{ this.props.item.lain_lain }</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                { (this.props.item.jenis_klarifikasi === 1 || this.props.item.jenis_klarifikasi === '1') &&
                    <div>
                        <div style={{ display: 'inline-block', width: '100%' }}>
                            <div style={{ float: 'left', width: '30%', marginLeft: '20%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '30%' }}>Ka Shift/Senkom</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ float: 'left', width: '30%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', borderTop: '1px solid grey', borderBottom: '1px solid grey', borderRight: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '30%' }}>Pengguna Jalan</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                { (this.props.item.jenis_klarifikasi === 2 || this.props.item.jenis_klarifikasi === '2') &&
                    <div>
                        <div style={{ display: 'inline-block', width: '100%' }}>
                            <div style={{ float: 'left', width: '27%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '30%' }}>Dibuat Oleh,</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                        <tr><td>Ka Shift/Senkom</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ float: 'left', width: '27%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', borderTop: '1px solid grey', borderBottom: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '30%' }}>Diajukan Oleh,</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                        <tr><td>Pengguna Jalan</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ float: 'left', width: '46%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '40%' }}>Menyetujui AIS,</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                        <tr><td>Project Manager Layanan Operasional Tol</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: 'inline-block', width: '100%' }}>
                            <div style={{ float: 'left', width: '46%', marginLeft: '27%' }}>
                                <table style={{ marginBottom: '10px', width: '100%', border: '1px solid grey', padding: '10px', textAlign: 'center' }}>
                                    <tbody>
                                        <tr><td style={{ width: '40%' }}>Menyetujui MHI,</td></tr>
                                        <tr><td style={{ height: '75px' }}></td></tr>
                                        <tr><td>(.........................)</td></tr>
                                        <tr><td>Kepala Departemen Operasi</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                <div style={{ position: 'absolute', bottom: '0px' }}>
                    <div>Tanggal cetak:</div>
                    <div>{ this.state.date.getDate() + '-' + this.state.months[this.state.date.getMonth()] + '-' + this.state.date.getFullYear() }</div>
                </div>
            </div>
        )
    }
}

export default Print;
