import React, { Component } from "react";

class Marker extends Component {
    constructor (props) {
        super(props)

        this.state = {
            clicked: false,
            hovered: false
        }
        
        this.onClick = this.onClick.bind(this)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
    }
    
    onClick () {
        this.setState({ clicked: !this.state.clicked })
    }
    onMouseEnter () {
        this.setState({ hovered: true })
    }
    onMouseLeave () {
        this.setState({ hovered: false })
    }
    
    render() {
        return (
            <>
            <div onClick={ this.onClick } onMouseEnter={ this.onMouseEnter } onMouseLeave={ this.onMouseLeave }>
                <div style={{display: this.state.clicked || this.state.hovered ? 'block' : 'none'}}>
                    <table  style={{backgroundColor:"#FFFFFF",padding:"4px",borderRadius:"16px"}}>
                        <tr>
                            <td style={{padding:"4px"}} rowspan="2"><img width="100px" src={this.props.icon} /></td>
                            <td style={{padding:"4px", fontSize:'22px'}} nowrap="nowrap">{this.props.company}</td>
                        </tr>
                        <tr>
                            <td style={{padding:"4px", fontSize:'22px'}} nowrap="nowrap">{this.props.name}</td>
                        </tr>
                        <tr>
                        </tr>
                    </table>
                </div>
                <div> <i className="fa fa-map-marker" style={{color:'red', height:"250px", fontSize:'20px', display: !this.state.hovered ? 'block' : 'none'}}></i>&nbsp;</div>
            </div>
            </>
        )
    }
    
}

export default Marker;