import React from "react";
import moment from "moment";
// import idLocale from "moment/locale/id";

// moment.locale("id", idLocale);

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Unit",
        selector: "nama_unit",
        sortable: true,
        width: "20%",
    },
      {
        name: "Nama Petugas",
        selector: "pegawai",
        sortable: true,
        width: "20%",
    },
    {
        name: "Km Awal",
        selector: "odometer_km_start",
        sortable: true,
        width: "30%",
    },
    {
        name: "Km Akhir",
        selector: "odometer_km_finish",
        sortable: true,
        width: "20%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        return item;
    });
};

const KepalaShiftTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KepalaShiftTable;
