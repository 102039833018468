import React from "react";

// komponen
import { Input, InputSelectReact } from "../../../components/AdminLTE";

const ApprovalSearch = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <div className="form-group row mb-0">
                    <label className="control-label col-md-4 mt-2" htmlFor="q">
                        Tanggal
                    </label>
                    <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.start}
                        onChange={ev =>
                            props.handleChangeStart("start", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                     <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.end}
                        onChange={ev =>
                            props.handleChange("end", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                </div>
                <div className="form-group row mb-0">
                    <label
                        className="control-label col-md-4 mt-2"
                        htmlFor="ruas"
                    >
                        Ruas Tol
                    </label>
                    <InputSelectReact
                        id={"ruas"}
                        onChange={item => props.handleChangeSelect(item)}
                        options={props.list_ruas}
                        value={{
                            label: props.ruas_name,
                            value: props.ruas_id
                        }}
                        parentStyle="col-md-6 p-0"
                    />
                </div>
            </div>
        </div>
    );
};

export default ApprovalSearch;
