import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import { Content, Button, ButtonBack } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import DetailTable from "./table_detail";

class FormDetail extends Component {
    constructor(props) {
        super(props);
        // id
        this.title_id = props.match.params.id;
        // state
        this.state = {
            title_desc: "",
            unit_id: "",
            unit_name: "",
            list_satuan: [],
            list_sarpras: [],
            list: [],
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `referensi/form/form/${this.title_id}`),
                GetPrivate(this, `referensi/satuan`),
                GetPrivate(this, `referensi/sarpras`)
            ])
                .then(result => {
                    // form unit
                    const unit = result[0].data;
                    // satuan
                    const satuan = result[1].data.map(item => ({
                        value: item.satuan_id,
                        label: item.satuan_name
                    }));
                    // sarpras
                    const sarpras = result[2].data.map(item => ({
                        value: item.sarpras_id,
                        label: item.sarpras_name
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        title_desc: unit.title_desc,
                        unit_id: unit.unit_id,
                        unit_name: unit.unit_name,
                        list: unit.list_form,
                        list_satuan: satuan,
                        list_sarpras: sarpras
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    //addRow
    addRow() {
        this.setState({
            list: [
                ...this.state.list,
                {
                    form_id: "",
                    satuan_id: "",
                    sarpras_id: "",
                    form_qty: 0,
                    form_group: ""
                }
            ]
        });
    }
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list: list });
    }
    // konfirmasi hapus
    deleteConfirm(item, index) {
        if (item.form_id) {
            SwalConfirm(
                `Hapus Form`,
                "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
                () => this.deleteProcess(item, index)
            );
        } else {
            // remove row
            this.removeRow(index);
        }
    }
    // deleteprocess
    deleteProcess(item, index) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/form/delete/${item.form_id}`)
                .then(result => {
                    // remove row
                    this.removeRow(index);
                })
                .catch(error => {})
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                title_id: that.title_id,
                unit_id: that.state.unit_id,
                form: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `referensi/form/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Formulir Unit {this.state.unit_name} -{" "}
                            {this.state.title_desc}
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <DetailTable
                                {...this.props}
                                addRow={this.addRow}
                                handleChange={this.handleChange}
                                removeRow={this.removeRow}
                                deleteConfirm={this.deleteConfirm}
                                list={this.state.list}
                                list_sarpras={this.state.list_sarpras}
                                list_satuan={this.state.list_satuan}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default FormDetail;
