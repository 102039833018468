// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const ButtonRole = props => {
    let index = props.menu.findIndex(menu => menu.nav_url == props.role_url);
    if (index > -1 && props.menu[index][props.role_type] == "1") {
        return props.children;
    }
    return null;
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(ButtonRole));
