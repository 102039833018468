import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import {
    Content,
    ButtonBack,
    Button,
    Loading
} from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// pages
import FieldFasilitas from "./field_fasilitas";

import { EditorState, convertToRaw, ContentState,convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class FasilitasEdit extends Component {
    constructor(props) {
        super(props);
        // fasilitas id
        this.id_fasilitas = props.match.params.id;
        // state
        this.state = {
            // field
            id_fasilitas: "",
            id: "",
            nama_deskripsi: "",
            lattitude: "",
            longitude: "",
            deskripsi: "",
            gambar64:"",
            gambar_lokasi: null,
            id_bujt: "",
            nama: "",
            nama_bujt: "",
            list_bujt: [],
            // list data
            list_kategori: [],
            // error field
            error_field: {},
            // loading
            loading: false,
            loadingUpdate: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // loadDetail
        this.loadDetail();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
      };

    // load data
    loadDetail() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `fasilitas/fasilitas/kategori`),
                GetPrivate(this, `fasilitas/fasilitas/detail/${this.id_fasilitas}`),
                GetPrivate(this, `callcenter/callcenter/bujt`),
            ])
                .then(result => {
                    // kategori
                    const kategori = result[0].data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }));
                    const bujt = result[2].data.map(item => ({
                        value: item.id_bujt,
                        label: item.nama_bujt
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_bujt: bujt,
                        list_kategori: kategori,

                        // data fasilitas
                        id_fasilitas: result[1].data.id_fasilitas,
                        nama_deskripsi: result[1].data.nama_deskripsi,
                        lattitude: result[1].data.lattitude,
                        longitude: result[1].data.longitude,
                        deskripsi: result[1].data.deskripsi,
                        editorState : EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                          convertFromHTML(result[1].data.deskripsi)
                           )),
                        id: result[1].data.id,
                        nama: result[1].data.nama,
                        id_bujt: result[1].data.id_bujt,
                        nama_bujt: result[1].data.nama_bujt,
                        gambar_lokasi: result[1].data.gambar_lokasi,
                        gambar64: result[1].data.file64,
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            gambar_lokasi: picture[0]
        });
    }
    // loadData
    loadData(q, url, value, label) {
        // list kabupaten
        return GetPrivate(this, `${url}?q=${q}`)
            .then(result => {
                //return
                return result.data.map(item => ({
                    value: item[value],
                    label: item[label]
                }));
            })
            .catch(error => {
                return [];
            });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        const { editorState } = this.state;
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.gambar_lokasi) {
            image = await toBase64(that.state.gambar_lokasi)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loadingUpdate: true }, function() {
            //params
            const params = {
                id_fasilitas: that.state.id_fasilitas,
                nama_deskripsi: that.state.nama_deskripsi,
                id: that.state.id,
                lattitude: that.state.lattitude,
                id_bujt: that.state.id_bujt,
                longitude: that.state.longitude,
                deskripsi: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                gambar_lokasi: image
            };
            // paggil post api
            PostPrivate(that, `fasilitas/fasilitas/edit`, params)
                .then(result => {
                    // hapus loading
                    that.setState({
                        loadingUpdate: false,
                        error_field: {}
                    });
                    that.props.history.replace(`/dashboard/fasilitas/fasilitas`);
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loadingUpdate
                    that.setState({
                        loadingUpdate: false
                    });
                });
        });
    }
    // render
    render() {
        const { editorState } = this.state;
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Ubah Data Fasilitas</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            {this.state.loading ? (
                                <Loading />
                            ) : (
                                <div className="row">
                                    <div className="col-md-4">
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText="Pilih Gambar"
                                            onChange={this.handleDrop}
                                            imgExtension={[
                                                ".jpg",
                                                ".gif",
                                                ".png",
                                                ".gif"
                                            ]}
                                            maxFileSize={5242880}
                                            singleImage={true}
                                            withPreview={true}
                                        />
                                         {this.state.gambar64 != '' && (
                                          <img src={this.state.gambar64} style={{width:"100%"}} />          
                                        )}
                                    </div>
                                    <div className="col-md-8">
                                        <FieldFasilitas
                                            hasDefaultOptions={Boolean(
                                                this.id_fasilitas
                                            )}
                                            id_fasilitas={this.state.id_fasilitas}
                                            nama_deskripsi={this.state.nama_deskripsi}
                                            id={this.state.id}
                                            nama={this.state.nama}
                                            id_bujt={this.state.id_bujt}
                                            nama_bujt={this.state.nama_bujt}
                                            lattitude={this.state.lattitude}
                                            longitude={this.state.longitude}
                                            loadData={this.loadData}
                                            handleChange={this.handleChange}
                                            error_field={this.state.error_field}
                                            list_kategori={this.state.list_kategori}
                                            list_bujt={this.state.list_bujt}
                                        />

                                    </div>
                                    <div className="col-md-12">
                                    <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange}
                                    />
                                    {/* <textarea
                                    disabled
                                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    /> */}
                            
                                </div>
                                </div>
                            )}
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loadingUpdate}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default FasilitasEdit;
