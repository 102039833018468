import React from "react";

// redux
import { withRouter } from "react-router-dom";

const ButtonBack = props => {
    return (
        <button
            type="button"
            className={
                props.className ? props.className : "btn btn-box-tool ml-2"
            }
            onClick={() => props.history.goBack()}
        >
            <i className="fa fa-arrow-left mr-1"></i> Kembali
        </button>
    );
};

// hubungkan dengan router
export default withRouter(ButtonBack);
