import React from "react";

// komponen
import { Table, Button, Input } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        {
            width: "10%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    onClick={props.addRowLane}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            )
        },
        { width: "90%", className: "text-center", label: "Nama Jalur" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={index}>
            <td className="text-center">
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    onClick={() => props.removeRowLane(index)}
                >
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
            <td>
                <Input
                    type="text"
                    placeholder="Nama Jalur"
                    defaultValue={item}
                    onChange={ev =>
                        props.handleChangeLane(index, ev.target.value)
                    }
                />
            </td>
        </tr>
    ));
};

const KodeTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada jalur tol, silahkan klik tombol + untuk menambahkan jalur tol"
            className="table table-bordered table-hover"
        />
    );
};

export default KodeTable;
