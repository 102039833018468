import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Waktu",
        selector: "detail_time",
        sortable: true,
        width: "10%",
        center: true,
    },
    {
        name: "Info dari",
        selector: "detail_from",
        sortable: true,
        width: "10%",
    },
    {
        name: "Kategori",
        selector: "nama_pengaduan",
        sortable: true,
        center: true,
    },
    {
        name: "Uraian",
        selector: "detail_description",
        sortable: true,
        width: "15%",
    },
    {
        name: "Tindak Lanjut",
        selector: "detail_follow_up",
        sortable: true,
        width: "15%",
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["detail_time"] = item.detail_time.substr(0, 5);
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const ComplaintTable = (props) => {
    return (
        <TableBootstrap
            keyField={"pik_complaint"}
            empty="Belum ada laporan pengaduan, klik tombol + untuk menambahkan laporan baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default ComplaintTable;
