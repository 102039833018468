import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Lalu Lintas",
        selector: "traffic_name",
        sortable: true,
        width: "75%"
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const TrafficTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_traffic"}
            empty="Belum ada referensi lalu lintas, klik tombol + untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default TrafficTable;
