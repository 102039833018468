import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input,InputSelectReact } from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class TarifModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Tarif"
                : "Tambah Referensi Tarif";
        // state
        
        this.state = {
            // field input
            tarif_id: props.item ? props.item.id : "",
            tarif_nilai: props.item ? props.item.nilai : "",
            id_bujt: props.item ? props.item.id_bujt : "",
            nama_bujt: props.item ? props.item.nama_bujt : "",
            id_gerbang_masuk: props.item ? props.item.id_gerbang_masuk : "",
            nama_gerbang_masuk: props.item ? props.item.nama_gerbang_masuk : "",
            id_gerbang_keluar: props.item ? props.item.id_gerbang_keluar : "",
            nama_gerbang_keluar: props.item ? props.item.nama_gerbang_keluar : "",
            golongan_id: props.item ? props.item.golongan_id : "",
            golongan_nama: props.item ? props.item.golongan_nama : "",
            // error field
            error_field: {
                unit_name: null
            },
            // loading
            loading: false,
            bujt : props.bujt,
            gerbang:props.gerbang,
            golongan:props.golongan
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    /*
     UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps', nextProps);
        this.setState(nextProps);
        
        // try to set state based 
        if (nextProps.item != null) {
            
            this.setState(
                {
                    id_gerbang_masuk     : nextProps.item.id_gerbang_masuk,
                    nama_gerbang_masuk   : nextProps.item.nama_gerbang_masuk
                }
            );
        }
        
    }
    */
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    setGerbang() {
        
        //if (this.state.id_bujt !='') {
            
            this.setState({ loading: true }, function() {
                const params = {
                    id : this.state.id_bujt
                };

                PostPrivate(this, "referensi/gerbang/gerbang_list",params)
                    .then(result => {
                        this.setState({
                            loading : false,
                            gerbang : result.data
                        });
                        

                    })
                    .catch(error => {
                        this.setState({
                            loading: false
                        });
                    });
            });
        //}
    }
    
    // on submit
    onSubmit = evt => {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                tarif_id: that.state.tarif_id,
                tarif_nilai: that.state.tarif_nilai,
                // id_bujt : that.state.id_bujt,
                // nama_bujt : that.state.nama_bujt,
                id_gerbang_masuk : that.state.id_gerbang_masuk,
                nama_gerbang_masuk : that.state.nama_gerbang_masuk,
                id_gerbang_keluar : that.state.id_gerbang_keluar,
                nama_bujt : that.state.nama_bujt,
                golongan_id : that.state.golongan_id,
                golongan_nama : that.state.golongan_nama
            };
             console.log(params);
         
            // paggil post api
            PostPrivate(that, `referensi/tarif/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        {/* <div className="form-group row">
                            <label
                                htmlFor={"bujt"}
                                className="col-sm-3 control-label mt-1"
                            >
                                BUJT
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"bujt"}
                                    has_error={this.state.error_field.bujt}
                                    defaultValue={{
                                        label: this.state.nama_bujt,
                                        value: this.state.id_bujt
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_bujt", item.id);
                                        this.handleChange("nama_bujt", item.label);
                                        this.setGerbang();
                                    }}
                                    error_field={this.state.error_field.bujt}
                                    options={this.state.bujt}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label
                                htmlFor={"golongan"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Gerbang Masuk
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"gerbang_masuk"}
                                    has_error={this.state.error_field.gerbang}
                                    defaultValue={{
                                        label: this.state.nama_gerbang_masuk,
                                        value: this.state.id_gerbang_masuk
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_gerbang_masuk", item.id);
                                        this.handleChange("nama_gerbang_masuk", item.label);
                                    }}
                                    error_field={this.state.error_field.gerbang}
                                    options={this.state.gerbang}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"gerbang_keluar"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Gerbang Keluar
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"gerbang_keluar"}
                                    has_error={this.state.error_field.gerbang}
                                    defaultValue={{
                                        label: this.state.nama_gerbang_keluar,
                                        value: this.state.id_gerbang_keluar
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_gerbang_keluar", item.id);
                                        this.handleChange("nama_gerbang_keluar", item.label);
                                    }}
                                    error_field={this.state.error_field.gerbang}
                                    options={this.state.gerbang}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"golongan"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Golongan
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"golongan"}
                                    has_error={this.state.error_field.golongan}
                                    defaultValue={{
                                        label: this.state.golongan_nama,
                                        value: this.state.golongan_id
                                    }}
                                    onChange={item => {
                                        this.handleChange("golongan_id", item.id);
                                        this.handleChange("golongan_nama", item.label);
                                    }}
                                    error_field={this.state.error_field.golongan}
                                    options={this.state.golongan}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"tarif_nilai"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Tarif
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"tarif_nilai"}
                                    type="number"
                                    placeholder="Tarif"
                                    has_error={this.state.error_field.tarif_nilai}
                                    defaultValue={this.state.tarif_nilai}
                                    onChange={ev =>
                                        this.handleChange(
                                            "tarif_nilai",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.tarif_nilai
                                    }
                                />
                            </div>
                        </div>
                        
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default TarifModal;
