import React from "react";
import className from "classnames";

const Timeline = props => {
    return (
        <ul className="timeline">
            {props.title && (
                <li className="time-label">
                    <span className="bg-gray">{props.title}</span>
                </li>
            )}
            {props.list.map((item, index) => {
                const icon = item.icon ? item.icon : "";
                let type = "";
                switch (item.type) {
                    case "primary":
                        type = "bg-blue";
                        break;
                    case "info":
                        type = "bg-aqua";
                        break;
                    case "danger":
                        type = "bg-red";
                        break;
                    case "warning":
                        type = "bg-yellow";
                        break;
                    case "success":
                        type = "bg-green";
                        break;
                    case "muted":
                        type = "bg-gray";
                        break;
                }
                return (
                    <li key={index}>
                        <i className={className(icon, type)}></i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="fa fa-clock-o"></i> {item.time}
                            </span>
                            <h3 className="timeline-header">{item.title}</h3>
                            <div className="timeline-body">{item.content}</div>
                            <div className="timeline-footer">{item.footer}</div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default Timeline;
