import React, { useState } from "react";
// komponen
import {
    Button,
    ButtonGroup,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import Config from "../../../utils/Config";


const Header = props => {
    const [show, setShow] = useState(false);
    const toogleShow = () => {
        if (show) {
            // props.loadData();
        }
        setShow(!show);
    };
  
    return (
        <>

            <div className="hr"></div>
        </>
    );
};

export default Header;
