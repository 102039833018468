import React, { Component } from "react";
import { Modal, Button } from "../../../components/AdminLTE";
import { ExcelRenderer } from "react-excel-renderer";
import { PostPrivate } from "../../../utils/Connection";
import UploadTable from "./table_upload";

class UploadModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            // loading
            loading: false
        };
        // bind
        this.fileHandler = this.fileHandler.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.changeRow = this.changeRow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {}
    //fileHandler
    fileHandler = event => {
        let fileObj = event.target.files[0];
        // loop
        ExcelRenderer(fileObj, (err, response) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    list: response.rows
                        .filter(
                            (list_item, index) =>
                                index > 0 &&
                                (list_item[0] ||
                                    list_item[1] ||
                                    list_item[2] ||
                                    list_item[3] ||
                                    list_item[4] ||
                                    list_item[5] ||
                                    list_item[6] ||
                                    list_item[7] ||
                                    list_item[8]||
                                    list_item[9])
                        )
                        .map((list_item, index) => ({
                            key: `shift_pegawai____${index}`,
                            pegawai_nik: list_item[0],
                            pegawai_pass: list_item[1],
                            pegawai_name: list_item[2],
                            pegawai_gender: list_item[3],
                            pegawai_birth: list_item[4],
                            pegawai_birth_place: list_item[5],
                            jabatan_id: list_item[6],
                            ruas_id: list_item[7],
                            unit_id: list_item[8],
                            pegawai_st: list_item[9]
                        }))
                });
            }
        });
    };
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({
            list: list
        });
    }
    // row change
    changeRow(index, value, label) {
        const { list } = this.state;
        list[index]["pegawai_id"] = value;
        list[index]["pegawai_name"] = label;
        // set state
        this.setState({ list: list });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            // params
            let { list } = that.state;
            // post satu2
            list.map((item, index) => {
                const params = {
                    pegawai_nik: item.pegawai_nik,
                    pegawai_pass:item.pegawai_pass,
                    pegawai_name:item.pegawai_name,
                    pegawai_gender:item.pegawai_gender,
                    pegawai_birth:item.pegawai_birth,
                    pegawai_birth_place:item.pegawai_birth_place,
                    jabatan_id:item.jabatan_id,
                    ruas_id:item.ruas_id,
                    role_id:item.role_id,
                    unit_id:item.unit_id,
                    pegawai_st:item.pegawai_st,
                    status: ""
                };
                // paggil post api
                PostPrivate(that, `pegawai/pegawai/upload`, params)
                    .then(result => {
                        list[index]["status"] = result.message;
                        that.setState({
                            list: list
                        });
                    })
                    .catch(error => {})
                    .finally(result => {
                        if (index >= list.length - 1)
                            that.setState({
                                loading: false
                            });
                    });
            });
        });
    };
    //render
    render() {
        // return
        return (
            <Modal
                form={true}
                title="Upload  Pegawai"
                content={
                    <>
                        <div className="form-group mb-1">
                            <input
                                type="file"
                                onChange={this.fileHandler}
                                className="form-control"
                            />
                        </div>
                        <UploadTable
                            list={this.state.list}
                            removeRow={this.removeRow}
                            changeRow={this.changeRow}
                        />
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Upload
                    </Button>
                }
                show={this.props.show}
                onDismiss={() => this.props.toogleShow()}
                onSubmit={this.onSubmit}
                size="large"
            />
        );
    }
}

export default UploadModal;
