import React, { Component } from "react";

// komponen
import { Content, ButtonAdd } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";

// page
import GarduModal from "./modal";
import GarduTable from "./table";
import Header from "./header";

class Gardu extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: false,
            modal: false,
            mode: "",
            item: null,
            list: [],
            bujt: [],
            gerbang:[],
            gardu_id:"",
            gardu_nama:"",
            gerbang_id:"",
            gerbang_nama:"",
            bujt_id:"",
            bujt_nama:""
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/gardu")
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                    
                     this.setState({ loading: true }, function() {
                        GetPrivate(this, "referensi/ruas/ruas_list")
                            .then(result => {
                                this.setState({
                                    loading: false,
                                    bujt   : result.data
                                });


                            })
                            .catch(error => {
                                this.setState({
                                    loading: false
                                });
                            });
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Referensi Gardu [${item.nama}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/gardu/delete/${item.id}`)
                .then(result => {
                    // load data
                    this.loadData();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    
    setGerbang(mode, item) {
        if (item.id_bujt !=='') {
            
            this.setState({ loading: true }, function() {
                const params = {
                    id : item.id_bujt
                };

                PostPrivate(this, "referensi/gerbang/gerbang_list",params)
                    .then(result => {
                        this.setState({
                            loading : false,
                            gerbang : result.data
                        });
                        
                        this.setState({ mode: mode, item: item }, function() {
                            this.setState({ modal: !this.state.modal });
                        });
                        

                    })
                    .catch(error => {
                        this.setState({
                            loading: false
                        });
                    });
            });
        }
    }
    
    //toogleShow
    toogleShow(mode = "", item = null) {
        if (item !== null) {
            this.setGerbang(mode, item);
        } else {
            this.setState({ mode: mode, item: item }, function() {
                this.setState({ modal: !this.state.modal });
            });
        }
        /*
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
            
            if (item !== null) {
                // set gerbang by bujt
                const params = {
                    id : item.id_bujt
                };
            
            PostPrivate(this, "referensi/gerbang/gerbang_list",params)
                .then(result => {
                    this.setState({
                        gerbang : result.data
                    });


                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
                
                this.setState({ bujt_id: item.id_bujt, bujt_nama: item.nama_bujt, gerbang_id : item.id_gerbang, gerbang_nama : item.nama_gerbang, gardu_id : item.id, gardu_nama : item.nama });
            } else {
                
            }
            
            
            
        });
        */
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Manajemen Referensi Gardu Tol
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonAdd
                                onClick={() => this.toogleShow("add")}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                    <div className="box-body">
                    <Header
                            loading={this.state.loading}
                            show={this.state.modal}
                            toogleShow={() => this.toogleShow()}
                            loadData={() => this.loadData()}
                        />
                        <GarduTable
                            {...this.props}
                            list={this.state.list}
                            bujt={this.state.bujt}
                            toogleShow={this.toogleShow}
                            deleteConfirm={this.deleteConfirm}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <GarduModal
                        mode={this.state.mode}
                        item={this.state.item}
                        show={this.state.modal}
                        bujt={this.state.bujt}
                        gerbang={this.state.gerbang}
                        bujt_id={this.state.bujt_id}
                        bujt_nama={this.state.bujt_nama}
                        gardu_id={this.state.gardu_id}
                        gardu_nama={this.state.gardu_nama}
                        gerbang_id={this.state.gerbang_id}
                        gerbang_nama={this.state.gerbang_nama}
                        toogleShow={() => this.toogleShow()}
                        loadData={() => this.loadData()}
                    />
                )}
            </Content>
        );
    }
}

export default Gardu;
