import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class MedisModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        //console.log(props);
        
        this.state = {
            loading: false,
            popup:false,
            tanggal: props.detail ? props.detail.tanggal : "",
            waktu: props.detail ? props.detail.waktu : "",
            id: props.detail ? props.detail.id : "",
            detail_lokasi: props.detail_lokasi ? props.detail.detail_lokasi : "",
            jml_kendaraan: props.detail ? props.detail.jml_kendaraan : "",
            luka_ringan: props.detail ? props.detail.luka_ringan : "",
            luka_berat: props.detail ? props.detail.luka_berat : "",
            meninggal: props.detail ? props.detail.meninggal : "",
            penyebab_id: props.detail ? props.detail.penyebab_id : "",
            penyebab_nama: props.detail ? props.detail.penyebab_nama : "",
            uraian: props.detail ? props.detail.uraian : "",
            patroli_id: props.detail ? props.detail.patroli_id : "",
            patroli_nama: props.detail ? props.detail.patroli_nama : "",
            ambulance_kp_id: props.detail ? props.detail.ambulance_kp_id : "",
            ambulance_kp_nama: props.detail ? props.detail.ambulance_kp_nama : "",
            ambulance_kh_id: props.detail ? props.detail.ambulance_kh_id : "",
            ambulance_kh_nama: props.detail ? props.detail.ambulance_kh_nama : "",
            rescue_id: props.detail ? props.detail.rescue_id : "",
            rescue_nama: props.detail ? props.detail.rescue_nama : "",
            derek_id: props.detail ? props.detail.derek_id : "",
            derek_nama: props.detail ? props.detail.derek_nama : "",
            satgas_id: props.detail ? props.detail.satgas_id : "",
            satgas_nama: props.detail ? props.detail.satgas_nama : "",
            pjr_id: props.detail ? props.detail.pjr_id : "",
            pjr_nama: props.detail ? props.detail.pjr_nama : "",
            damkar_id: props.detail ? props.detail.damkar_id : "",
            damkar_nama: props.detail ? props.detail.damkar_nama : "",
            catatan_lain: props.detail ? props.detail.catatan_lain : "",
            barang_bukti: props.detail ? props.detail.barang_bukti : "",
            foto1: props.detail ? props.detail.foto1 : "",
            foto2: props.detail ? props.detail.foto2 : "",
            foto3: props.detail ? props.detail.foto3 : "",
            waktu_laka: props.detail ? props.detail.waktu_laka : "",
            no_laporan: props.detail ? props.detail.no_laporan : "",
            form_id: props.detail ? props.detail.form_id : "",
            shift_id: props.detail ? props.detail.shift_id : "",
            unit_id: props.detail ? props.detail.unit_id : "",
            unit_nama: props.detail ? props.detail.unit_nama : "",
            event_id: props.detail ? props.detail.event_id : "",
            jenis_laka_id: props.detail ? props.detail.jenis_laka_id : "",
            jenis_laka_nama: props.detail ? props.detail.jenis_laka_nama : "",
            km: props.detail ? props.detail.km : "",
            km_to: props.detail ? props.detail.km_to : "",
            id_lokasi: props.detail ? props.detail.id_lokasi : "",
            nama_lokasi: props.detail ? props.detail.nama_lokasi : "",
            kendaraan_terlibat:props.detail ? props.detail.kendaraan : [],
            jenis_kelamin:props.jenis_kelamin,
            kewarganegaraan:props.kewarganegaraan,
            kondisi_badan:props.kondisi_badan,
            kondisi_penumpang:props.kondisi_penumpang,
            pekerjaan:props.pekerjaan,
            posisi_penumpang:props.posisi_penumpang,
            sabuk_pengaman:props.sabuk_pengaman,
            tipe_korban:props.tipe_korban,
            tipe_pengendara:props.tipe_pengendara,
            sim:props.sim,
            status_sim:props.status_sim,
            luka_ringan: props.detail ? props.detail.luka_ringan : "",
            tempat_luka:props.tempat_luka,
            penumpang:props.penumpang,
            penumpang_index:props.penumpang_index,
            penumpang_kendaraan_id:props.penumpang_kendaraan_id,
            penumpang_id:props.penumpang_id,
            penumpang_nik:props.penumpang_nik,
            penumpang_nama:props.penumpang_nama,
            penumpang_alamat:props.penumpang_alamat,
            penumpang_tingkat_luka:props.penumpang_tingkat_luka,
            penumpang_tingkat_luka_nama:props.penumpang_tingkat_luka_nama,
            penumpang_tempat_luka:props.penumpang_tempat_luka,
            penumpang_tempat_luka_nama:props.penumpang_tempat_luka_nama,
            penumpang_sabuk_pengaman:props.penumpang_sabuk_pengaman,
            penumpang_sabuk_pengaman_nama:props.penumpang_sabuk_pengaman_nama,
            penumpang_posisi:props.penumpang_posisi,
            penumpang_posisi_nama:props.penumpang_posisi_nama,
            penumpang_kelamin:props.penumpang_kelamin,
            penumpang_kelamin_nama:props.penumpang_kelamin_nama,
            penumpang_umur:props.penumpang_umur,
            error_field: {
                gardu_nama: null
            }
        };
        // bind
        this.handleChange           = this.handleChange.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);     
        this.handleChangeSopir     = this.handleChangeSopir.bind(this);
        this.handleHapusPenumpang   = this.handleHapusPenumpang.bind(this);
        this.simpanSopir            = this.simpanSopir.bind(this);
        this.addPenumpangModal      = this.addPenumpangModal.bind(this);
        this.editPenumpang          = this.editPenumpang.bind(this);
        this.hapusPenumpang         = this.hapusPenumpang.bind(this);
    }
    
    hapusPenumpang(index) {
        this.props.hapusPenumpang(index);
    }
    
    
    editPenumpang(index) {
        this.props.editPenumpang(index);
    }
    
    addPenumpangModal(index) {
        this.props.addPenumpang(index);
    }
    
    simpanSopir(index) {
        // disini
        this.setState({ loading: true }, function() {
            const params = {
                id:this.state.kendaraan_terlibat[index].id,
                badan_id:this.state.kendaraan_terlibat[index].badan_id,
                badan_nama:this.state.kendaraan_terlibat[index].badan_nama,
                jml_penumpang:this.state.kendaraan_terlibat[index].jml_penumpang,
                kelamin_id: this.state.kendaraan_terlibat[index].kelamin_id,
                kelamin_nama: this.state.kendaraan_terlibat[index].kelamin_nama,
                kondisi_id: this.state.kendaraan_terlibat[index].kondisi_id,
                kondisi_nama: this.state.kendaraan_terlibat[index].kondisi_nama,
                merk: this.state.kendaraan_terlibat[index].merk,
                pekerjaan_id:this.state.kendaraan_terlibat[index].pekerjaan_id,
                pekerjaan_nama: this.state.kendaraan_terlibat[index].pekerjaan_nama,
                pengemudi_alamat: this.state.kendaraan_terlibat[index].pengemudi_alamat,
                pengemudi_nama: this.state.kendaraan_terlibat[index].pengemudi_nama,
                plat_nomor: this.state.kendaraan_terlibat[index].plat_nomor,
                sabuk_id: this.state.kendaraan_terlibat[index].sabuk_id,
                sabuk_nama: this.state.kendaraan_terlibat[index].sabuk_nama,
                seri: this.state.kendaraan_terlibat[index].seri,
                sim_asal: this.state.kendaraan_terlibat[index].sim_asal,
                sim_id: this.state.kendaraan_terlibat[index].sim_id,
                sim_nama: this.state.kendaraan_terlibat[index].sim_nama,
                sim_nomor: this.state.kendaraan_terlibat[index].sim_nomor,
                sim_status: this.state.kendaraan_terlibat[index].sim_status,
                sim_status_nama: this.state.kendaraan_terlibat[index].sim_status_nama,
                umur: this.state.kendaraan_terlibat[index].umur,
                warga_id: this.state.kendaraan_terlibat[index].warga_id,
                warga_nama: this.state.kendaraan_terlibat[index].warga_nama
            }
            
            PostPrivate(this, `transaksi/medis/update_sopir`, params)
                .then(result => {
                    if (result.status == 200) {
                        this.setState(
                            {
                                loading: false
                            },
                            function() {
                                SwalSuccess("Berhasil", result.message);
                            }
                        );
                    } else {
                        alert('Update data gagal');
                        
                        this.setState({
                            loading: false
                        });
                        
                    }
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });    
            
        })
    }


    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    handleChangeSopir(field, value, index) {
        // set state disini akan kompleks
        let kendaraan = this.state.kendaraan_terlibat;
        kendaraan[index][field] = value;
        console.log(kendaraan);
        this.setState({ kendaraan_terlibat: kendaraan});
        
        
        
    }

    handleHapusPenumpang( index) {
        // set state disini akan kompleks
        let penumpang = this.state.penumpang;
        penumpang.splice(index, 1);
        this.setState({ penumpang: penumpang });
    }
    

    onSubmit = evt => {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        
        that.setState({ loading: true }, function() {
            const params = {
                tanggal                 : this.state.tanggal,
                waktu                   : this.state.waktu,
                id                      : this.state.id,
                detail_lokasi           : this.state.detail_lokasi,
                jml_kendaraan           : this.state.jml_kendaraan,
                luka_ringan             : this.state.luka_ringan,
                luka_berat              : this.state.luka_berat,
                meninggal               : this.state.meninggal,
                penyebab_id             : this.state.penyebab_id,
                penyebab_nama           : this.state.penyebab_nama,
                uraian                  : this.state.uraian,
                patroli_id              : this.state.patroli_id,
                patroli_nama            : this.state.patroli_nama,
                ambulance_kp_id         : this.state.ambulance_kp_id,
                ambulance_kp_nama       : this.state.ambulance_kp_nama,
                ambulance_kh_id         : this.state.ambulance_kh_id,
                ambulance_kh_nama       : this.state.ambulance_kh_nama,
                rescue_id               : this.state.rescue_id,
                rescue_nama             : this.state.rescue_nama,
                derek_id                : this.state.derek_id,
                derek_nama              : this.state.derek_nama,
                satgas_id               : this.state.satgas_id,
                satgas_nama             : this.state.satgas_nama,
                pjr_id                  : this.state.pjr_id,
                pjr_nama                : this.state.pjr_nama,
                damkar_id               : this.state.damkar_id,
                damkar_nama             : this.state.damkar_nama,
                catatan_lain            : this.state.catatan_lain,
                barang_bukti            : this.state.barang_bukti,
                waktu_laka              : this.state.waktu_laka,
                jenis_laka_id           : this.state.jenis_laka_id,
                jenis_laka_nama         : this.state.jenis_laka_nama,
                km                      : this.state.km,
                km_to                   : this.state.km_to,
                id_lokasi               : this.state.id_lokasi,
                nama_lokasi             : this.state.nama_lokasi,
                kendaraan_terlibat      : this.state.kendaraan_terlibat,
                penumpang               : this.state.penumpang

            };
            
            
            PostPrivate(that, `transaksi/kecelakaan/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow('');
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });     
        });
    };
    
    render() {
        // return
        let counter = 0;
        
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ display: !this.state.form_mode ? 'block' : 'none' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">No. Laporan</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.no_laporan}/>
                                                </div>
                                                <label className="col-sm-2 col-form-label">Deskripsi Singkat</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.uraian}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Tanggal</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.tanggal}/>
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jam</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.waktu}/>
                                                    
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Lokasi KM</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.km}/>
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">KM Hingga</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.km_to}/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Jenis Lokasi</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.nama_lokasi}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Kategori Kejadian</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.jenis_laka_nama}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px',textAlign:'center'}}>
                                                <b>DETAIL KENDARAAN</b>
                                            </div>
                                            {this.state.kendaraan_terlibat.map((row, i) => (
                                                <>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-8 col-form-label">Pengemudi {row.merk} {row.seri} Nopol : {row.plat_nomor}</label>   
                                                        <div className="col-sm-4"><Button type="button" className="btn btn-success" style={{float:'right'}} onClick={() => this.simpanSopir(i)}>Simpan Data Sopir</Button></div>
                            
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Nama</label>
                                                        <div className="col-md-4">
                                                            <input 
                                                                type="text" 
                                                                className="form-control"
                                                                onChange={ev =>
                                                                    this.handleChangeSopir(
                                                                        "pengemudi_nama",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                                style={{width:'90%'}}
                                                                value={this.state.kendaraan_terlibat[i].pengemudi_nama}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Pekerjaan Formal</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.pekerjaan}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].pekerjaan_nama,
                                                                    value: this.state.kendaraan_terlibat[i].pekerjaan_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("pekerjaan_id", item.id, i);
                                                                    this.handleChangeSopir("pekerjaan_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.pekerjaan}
                                                                options={this.state.pekerjaan}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Alamat</label>
                                                        <div className="col-md-4">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                style={{width:'90%'}} 
                                                                onChange={ev =>
                                                                    this.handleChangeSopir(
                                                                        "pengemudi_alamat",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                                value={this.state.kendaraan_terlibat[i].pengemudi_alamat}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Tingkat Luka</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.kondisi_penumpang}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].kondisi_nama,
                                                                    value: this.state.kendaraan_terlibat[i].kondisi_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("kondisi_id", item.id, i);
                                                                    this.handleChangeSopir("kondisi_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.kondisi_penumpang}
                                                                options={this.state.kondisi_penumpang}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Jenis Kelamin</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.jenis_kelamin}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].kelamin_nama,
                                                                    value: this.state.kendaraan_terlibat[i].kelamin_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("kelamin_id", item.id, i);
                                                                    this.handleChangeSopir("kelamin_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.jenis_kelamin}
                                                                options={this.state.jenis_kelamin}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Kewarganegaraan</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.kewarganegaraan}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].warga_nama,
                                                                    value: this.state.kendaraan_terlibat[i].warga_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("warga_id", item.id, i);
                                                                    this.handleChangeSopir("warga_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.kewarganegaraan}
                                                                options={this.state.kewarganegaraan}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Umur</label>
                                                        <div className="col-md-2">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                style={{width:'50px'}} 
                                                                onChange={ev =>
                                                                    this.handleChangeSopir(
                                                                        "umur",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                                value={this.state.kendaraan_terlibat[i].umur}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Tahun</label>
                                                        <label className="col-sm-2 col-form-label">Sabuk Pengaman</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.sabuk_pengaman}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].sabuk_nama,
                                                                    value: this.state.kendaraan_terlibat[i].sabuk_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("sabuk_id", item.id, i);
                                                                    this.handleChangeSopir("sabuk_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.sabuk_pengaman}
                                                                options={this.state.sabuk_pengaman}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Kondisi Badan</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.kondisi_badan}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].badan_nama,
                                                                    value: this.state.kendaraan_terlibat[i].badan_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("badan_id", item.id, i);
                                                                    this.handleChangeSopir("badan_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.kondisi_badan}
                                                                options={this.state.kondisi_badan}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Jenis SIM</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.sim}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].sim_nama,
                                                                    value: this.state.kendaraan_terlibat[i].sim_id
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("sim_id", item.id, i);
                                                                    this.handleChangeSopir("sim_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.sim}
                                                                options={this.state.sim}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label">Nomor SIM</label>
                                                        <div className="col-md-4">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                style={{width:'90%'}} 
                                                                onChange={ev =>
                                                                    this.handleChangeSopir(
                                                                        "sim_nomor",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                                value={this.state.kendaraan_terlibat[i].sim_nomor}
                                                            />
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Asal SIM</label>
                                                        <div className="col-md-4">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                style={{width:'90%'}} 
                                                                onChange={ev =>
                                                                    this.handleChangeSopir(
                                                                        "sim_asal",
                                                                        ev.target.value,
                                                                        i
                                                                    )
                                                                }
                                                                value={this.state.kendaraan_terlibat[i].sim_asal}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-2 col-form-label"></label>
                                                        <div className="col-md-4">
                                                            
                                                        </div>
                                                        <label className="col-sm-2 col-form-label">Status SIM</label>
                                                        <div className="col-md-4">
                                                            <InputSelectReact
                                                                has_error={this.state.error_field.status_sim}
                                                                defaultValue={{
                                                                    label: this.state.kendaraan_terlibat[i].sim_status_nama,
                                                                    value: this.state.kendaraan_terlibat[i].sim_status
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeSopir("sim_status", item.id, i);
                                                                    this.handleChangeSopir("sim_status_nama", item.label, i);
                                                                }}
                                                                error_field={this.state.error_field.status_sim}
                                                                options={this.state.status_sim}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                        <label className="col-sm-8 col-form-label">Penumpang Kendaraan</label>
                                                        <div className="col-sm-4"><Button type="button" className="btn btn-success" style={{float:'right'}} onClick={() => this.addPenumpangModal(i)}>Tambah</Button></div>
                                                    </div>
                                                    <div className="row" style={{marginBottom: '10px'}}>
                                                         <div className="col-md-12">
                                                            <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                          <th scope="col">No</th>
                                                                          <th scope="col">NIK/Nama</th>
                                                                          <th scope="col">Jenis kelamin/Usia</th>
                                                                          <th scope="col">Alamat/Tingkat Luka</th>
                                                                          <th scope="col">Tempat Luka/Sabuk Pengaman/Posisi Penumpang</th>
                                                                          <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.penumpang.map((row2, ix) => (
                                                                        <>
                                                                        {row.id == row2.penumpang_kendaraan_id && (
                                                                            <tr>
                                                                                <td>{row2.counter}</td>
                                                                                <td>{row2.penumpang_nik}<br/>{row2.penumpang_nama}</td>
                                                                                <td>{row2.penumpang_kelamin_nama}<br/>{row2.penumpang_umur}</td>
                                                                                <td>{row2.penumpang_alamat}<br/>{row2.penumpang_tingkat_luka_nama}</td>
                                                                                <td>{row2.penumpang_tempat_luka_nama}<br/>{row2.penumpang_sabuk_pengaman_nama}<br/>{row2.penumpang_posisi_nama}</td>
                                                                                <td>
                                                                                    <Button type="button" className="btn btn-primary" onClick={() => this.hapusPenumpang(ix)}>Hapus</Button>
                                                                                    <Button type="button" className="btn btn-success" onClick={() => this.editPenumpang(ix)}>Edit</Button>
                                                                                </td>
                                                                            </tr>
                                                                            )}
                                                                        </>
                                                                    ))}
                                                                    </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                            
                                        </div>
                                </div>
                            </div>
                             <div className="form-group row">
                                <div className="col-sm-12">
                                    <div style={{float:'right'}}>
                                    <Button type="button" className="btn btn-danger" onClick={() => this.props.toogleShow('')}>Tutup</Button>
                                    </div>
                                </div>
                            </div>
                            
                            
                         </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.props.toogleShow('')}
                    onSubmit={this.onSubmit}
                />
                
                          
                    
                
        )
    }
    
    
}

export default MedisModal;
