import React from "react";

const Options = props => {
    return (
        <>
            <option key="0" value="0">
                ** root **
            </option>
            {props.list.map(item => (
                <option key={item.nav_id} value={item.nav_id}>
                    {item.nav_title}
                </option>
            ))}
        </>
    );
};

export default Options;
