import React, { Component } from "react";

// komponen
import { Table } from "../../components/AdminLTE";

// utils
import GetPrivate from "../../utils/Connection";
import Dates from "../../utils/Dates";

// table
const thead = [
    { width: "34%", className: "text-center", label: "IP Address" },
    { width: "33%", className: "text-center", label: "Tanggal Login" },
    { width: "33%", className: "text-center", label: "Tanggal Logout" }
];

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={index}>
            <td className="text-center">{item.ip_address}</td>
            <td className="text-center">
                {item.login_date
                    ? Dates.formatFullLongDate(item.login_date)
                    : ""}
            </td>
            <td className="text-center">
                {item.logout_date
                    ? Dates.formatFullLongDate(item.logout_date)
                    : ""}
            </td>
        </tr>
    ));
};

class ProfileHistory extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            loading: false
        };
    }
    componentDidMount() {
        this.setState({ loading: true }, function() {
            // panggil get api
            GetPrivate(this, "user/profile/login")
                .then(result => {
                    // state
                    this.setState({ list: result.data });
                })
                .catch(error => {})
                .finally(() => this.setState({ loading: false }));
        });
    }
    render() {
        return (
            <Table
                thead={thead}
                tbody={<Tbody list={this.state.list} />}
                empty="Belum ada riwayat login"
                className="table"
                loading={this.state.loading}
                icon={"fa fa-users"}
            />
        );
    }
}

export default ProfileHistory;
