import React from "react";
import classNames from "classnames";
import AsyncSelect from "react-select/async";

const customStyles = {
    control: base => ({
        ...base,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: "#dedede",
        borderRadius: 0,
        boxShadow: "none",
        "&:hover": { borderBottomColor: "#ffca05" }, // border style on hover
        "&:focus": { borderBottomColor: "#ffca05" } // border style on hover
    })
};

const InputSelectAsync = props => {
    return (
        <div
            className={classNames("form-group", {
                "has-error": props.has_error
            })}
        >
            {props.hasDefaultOptions ? (
                <AsyncSelect
                    cacheOptions
                    defaultOptions={props.defaultOptions}
                    loadOptions={props.loadOptions}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    defaultInputValue={props.defaultInputValue}
                    styles={customStyles}
                />
            ) : (
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={props.loadOptions}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    defaultInputValue={props.defaultInputValue}
                    styles={customStyles}
                />
            )}
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputSelectAsync;
