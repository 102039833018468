import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import Config from "../../../utils/Config";
import Marker from './marker';

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: -7.5367869000000001,
            lng: 112.25154209999999
        },
        zoom: 11
    };

    render() {
        return (
            <div style={{ height: '550px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: Config.googleMapKey }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    {this.props.items.map(function(item, i){
                        return <Marker
                          key={ item.id_unit + '-' + item.id_user }
                          lat={item.koordinat_lat}
                          lng={item.koordinat_lon}
                          user={ item.nama_user }
                          unit={ item.nama_unit }
                          time={ item.waktu }
                          color={ item.color }
                        />
                    })}
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;
