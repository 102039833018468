import React from "react";

// komponen
import { Input, InputSelectReact } from "../../../components/AdminLTE";

const SearchPegawai = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <form onSubmit={props.onSubmit} className="">
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="q"
                        >
                            Nama Pegawai
                        </label>
                        <Input
                            id="q"
                            type="text"
                            placeholder="Nama Pegawai"
                            defaultValue={props.q}
                            onChange={ev =>
                                props.handleChange("q", ev.target.value)
                            }
                            parentStyle="col-md-8 p-0"
                        />
                    </div>
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="ruas"
                        >
                            Ruas Tol
                        </label>
                        <InputSelectReact
                            id={"ruas"}
                            onChange={item =>
                                props.handleChange(
                                    "ruas",
                                    item ? item.value : ""
                                )
                            }
                            options={props.list_ruas}
                            isClearable={true}
                            parentStyle="col-md-5 p-0"
                        />
                    </div>
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="unit"
                        >
                            Unit
                        </label>
                        <InputSelectReact
                            id={"unit"}
                            onChange={item =>
                                props.handleChange(
                                    "unit",
                                    item ? item.value : ""
                                )
                            }
                            options={props.list_unit}
                            isClearable={true}
                            parentStyle="col-md-4 p-0"
                        />
                    </div>
                    <div className="row">
                        <button
                            className="col-md-offset-4 btn btn-primary"
                            type="submit"
                        >
                            Cari Pegawai
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SearchPegawai;
