import React from "react";

// komponen
import { Table, Button, Input } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        {
            width: "5%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={props.addRow}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            )
        },
        { width: "40%", className: "text-center", label: "Nama Seksi Ruas" },
        { width: "15%", className: "text-center", label: "KM Mulai" },
        { width: "15%", className: "text-center", label: "KM Selesai" },
        { width: "25%", className: "text-center", label: "Jalur Tol" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => {
        return (
            <tr key={index}>
                <td className="text-center">
                    <Button
                        type="button"
                        className="btn btn-sm btn-default"
                        loading={props.loading}
                        onClick={() =>
                            item.code_id != ""
                                ? props.deleteConfirm(item, index)
                                : props.removeRow(index)
                        }
                    >
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
                <td>
                    <Input
                        type="text"
                        placeholder="Nama Seksi Ruas"
                        defaultValue={item.section_name}
                        onChange={ev =>
                            props.handleChange(
                                index,
                                "section_name",
                                ev.target.value
                            )
                        }
                    />
                </td>
                <td>
                    <Input
                        type="number"
                        placeholder="KM Mulai"
                        defaultValue={item.section_km_start}
                        onChange={ev =>
                            props.handleChange(
                                index,
                                "section_km_start",
                                ev.target.value
                            )
                        }
                    />
                </td>
                <td>
                    <Input
                        type="number"
                        placeholder="KM Selesai"
                        defaultValue={item.section_km_finish}
                        onChange={ev =>
                            props.handleChange(
                                index,
                                "section_km_finish",
                                ev.target.value
                            )
                        }
                    />
                </td>
                <td className="text-center">
                    <Button
                        type="button"
                        className="btn btn-sm btn-link btn-block"
                        loading={props.loading}
                        onClick={() =>
                            props.toogleShow(index, item.section_lane)
                        }
                    >
                        {item.section_lane.length ? (
                            item.section_lane.join(", ")
                        ) : (
                            <i>Belum Ada</i>
                        )}
                    </Button>
                </td>
            </tr>
        );
    });
};

const KodeTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada seksi ruas, silahkan klik tombol + untuk menambahkan seksi ruas"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default KodeTable;
