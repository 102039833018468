import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { Button } from "../../../components/AdminLTE";
import Print from "./print";
import PrintReceipt from "./print_receipt";

class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: false,
            total_ganti_rugi: 0,
            id: null,
            id_laka: null,
            status: null,
            nama_penanggung_jawab: null,
            no_wa_penanggung_jawab: null,
            alamat_penanggung_jawab: null,
            jml_asset_rusak: null,
            kategori_pembayaran: null,
            bukti_transfer: null,
            nomor_plat: null,
            no_laporan: null,
            tanggal: null,
            km: null,
            km_to: null,
            status_senkom: null,
            ruas_tol: null,
            asset_rusak: []
        }

        this.loadDetail = this.loadDetail.bind(this)
    }
    componentDidMount () {
        this.loadDetail()
    }
    loadDetail () {
        this.setState({ loading: true })
        GetPrivate(this, `laporan/gantirugi/detail/` + this.props.id).then(result => {
            const formatter = new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
            })

            let total = 0
            for (let i in result.data.asset_rusak) {
                let asset = result.data.asset_rusak[i]
                total += parseFloat(asset.total_harga)

                result.data.asset_rusak[i].asset_harga = formatter.format(result.data.asset_rusak[i].asset_harga)
                result.data.asset_rusak[i].total_harga = formatter.format(result.data.asset_rusak[i].total_harga)
            }
            const total_raw = total
            total = formatter.format(total)

            this.setState({
                total_ganti_rugi: total,
                total_ganti_rugi_raw: total_raw,
                id: result.data.id,
                id_laka: result.data.id_laka,
                status: result.data.status,
                nama_penanggung_jawab: result.data.nama_penanggung_jawab,
                no_wa_penanggung_jawab: result.data.no_wa_penanggung_jawab,
                alamat_penanggung_jawab: result.data.alamat_penanggung_jawab,
                jml_asset_rusak: result.data.jml_asset_rusak,
                kategori_pembayaran: result.data.kategori_pembayaran,
                bukti_transfer: result.data.bukti_transfer,
                nomor_plat: result.data.nomor_plat,
                no_laporan: result.data.no_laporan,
                tanggal: result.data.tanggal,
                km: result.data.km,
                km_to: result.data.km_to,
                status_senkom: result.data.status_senkom,
                ruas_tol: result.data.ruas_tol,
                asset_rusak: result.data.asset_rusak
            })

            if (this.props.onLoad) {
                this.props.onLoad({ item: result.data, total_ganti_rugi: total, total_ganti_rugi_raw: total_raw })
            }
            this.setState({ loading: false })
        }).catch(error => { this.setState({ loading: false }) })
    }
    print (id) {
        const divContents = document.getElementById(id).innerHTML
        var printWindow = window.open('', '', 'height=772,width=1000')
        if (id === 'receipt-container') {
            printWindow.document.write('<head><style type="text/css" media="print">@page { size: landscape; }</style></head>')
        } else {
            printWindow.document.write('<head></head>')
        }
        printWindow.document.write('<html>')
        printWindow.document.write('<body>')
        printWindow.document.write(divContents)
        printWindow.document.write('</body></html>')
        printWindow.document.close()
        setTimeout(function () {
            printWindow.print()
        }, 100)
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">Nama Penanggung Jawab</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.nama_penanggung_jawab || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">No. Plat Kendaraan</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.nomor_plat || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">No. WA</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.no_wa_penanggung_jawab || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-12 col-form-label">Daftar Asset yang Rusak:</label>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-10 col-md-offset-1">
                            <table className="table">
                                <thead>
                                    <tr>
                                      <th scope="col">No</th>
                                      <th scope="col">Fasilitas</th>
                                      <th scope="col">Harga Satuan</th>
                                      <th scope="col">Jumlah</th>
                                      <th scope="col">Total Harga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.asset_rusak.map(function(row, i){
                                        return (
                                            <tr key={i + 1}>
                                                <td scope="row">{i + 1}</td>
                                                <td>{row.asset_name}</td>
                                                <td>{row.asset_harga}</td>
                                                <td>{row.jml_asset}</td>
                                                <td>{row.total_harga}</td>
                                            </tr>
                                        )
                                    })}
                                    {this.state.asset_rusak.length === 0 &&
                                        <tr><td className="text-center" colSpan="5">- Tidak ada data- </td></tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan="4">Total Ganti Rugi</th>
                                        <th>{this.state.total_ganti_rugi}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    { this.props.print &&
                        <div>
                            <div style={{ display: 'none' }}>
                                <Print item={this.state} />
                                <PrintReceipt item={this.state} id_laka={this.props.id_laka} />
                            </div>
                            <div className="col-md-12 text-center">
                                <Button className="btn btn-success" loading={ this.state.loading } onClick={() => this.print('print-container') } style={{ marginRight: '15px' }}>Cetak</Button>
                                <Button className="btn btn-success" loading={ this.state.loading } onClick={() => this.print('receipt-container') }>Cetak Kwitansi</Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Detail;
