import React, { useState } from "react";
import classNames from "classnames";

const CollapseSingle = props => {
    // hooks
    const [state, setState] = useState(false);
    let collapseopen = classNames("panel-collapse", "collapse", {
        in: state
    });
    // return
    return (
        <div className="box-group">
            <div className="panel">
                <button
                    type="button"
                    className="btn btn-block"
                    onClick={() => setState(!state)}
                >
                    {props.title}
                </button>
                <div className={collapseopen}>
                    <div className="box-body">{props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default CollapseSingle;
