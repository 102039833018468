import React from "react";

const Footer = props => {
    return (
        <footer className="main-footer">
            <strong>
                Copyright &copy; 2022{" "}
                <span className="text-primary">
                    Astra
                </span>
                .
            </strong>{" "}
            All rights reserved.
        </footer>
    );
};

export default Footer;
