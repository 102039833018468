import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// reducers
import session from "./session";
import menu from "./menu";

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        session: session,
        menu: menu
    });
export default createRootReducer;
