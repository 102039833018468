import React from "react";
import { NavLink } from "react-router-dom";

const Error404 = props => {
    return (
        <div className="error-page">
            <h2 className="headline text-yellow">404</h2>

            <div className="error-content">
                <h3>
                    <i className="fa fa-warning text-yellow"></i> Oops! Halaman
                    tidak ditemukan.
                </h3>

                <p>
                    Halaman yang anda minta belum ada di sistem kami, klik
                    tombol "Dashboard" untuk kembali ke menu dashboard.
                </p>
                <NavLink className="btn btn-default" to="/">
                    <i className="fa fa-home mr-1"></i>
                    Dashboard
                </NavLink>
            </div>
        </div>
    );
};

export default Error404;
