import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input,InputSelectReact } from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class GerbangModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Ruas Tol"
                : "Tambah Referensi Ruas Tol";
        // state
        this.state = {
            // field input
            gerbang_id: props.item ? props.item.id : "",
            gerbang_name: props.item ? props.item.nama : "",
            id_bujt:props.item ? props.item.id_bujt : "",
            nama_bujt:props.item ? props.item.nama_bujt : "",
            item:props.item,
            // error field
            error_field: {
                gerbang_name: null
            },
            // loading
            loading: false,
            bujt : props.bujt,
            nama_bujt:props.nama_bujt,
            id_bujt:props.id_bujt,
            gerbang_name:props.gerbang_name
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                gerbang_id: that.state.gerbang_id,
                gerbang_name: that.state.gerbang_name,
                id_bujt:that.state.id_bujt,
                nama_bujt:that.state.nama_bujt
            };
            // paggil post api
            PostPrivate(that, `referensi/gerbang/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                BUJT
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"bujt"}
                                    has_error={this.state.error_field.bujt}
                                    defaultValue={{
                                        label: this.state.nama_bujt,
                                        value: this.state.id_bujt
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_bujt", item.id);
                                        this.handleChange("nama_bujt", item.label);
                                    }}
                                    error_field={this.state.error_field.bujt}
                                    options={this.state.bujt}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"gerbang_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Gerbang
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"gerbang_name"}
                                    type="text"
                                    placeholder="Nama Gerbang Tol"
                                    has_error={this.state.error_field.gerbang_name}
                                    defaultValue={this.state.gerbang_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "gerbang_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.gerbang_name
                                    }
                                />
                            </div>
                        </div>
                
                        
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default GerbangModal;
