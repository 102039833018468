import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class SarprasModal extends Component {
            // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        this.state = {
            loading: false,
            detail: props.detail,
            item:props.item
        }
        
        //console.log(props.item);
    }
    
    render() {
        // return
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Tanggal</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.tanggal} style={{width:'95%'}} />
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Tanggal Shift</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.waktu} style={{width:'95%'}}/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Nama Shift</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.nama_shift} style={{width:'95%'}}/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Jabatan</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.nama_role} style={{width:'95%'}}/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Ruas Tol</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.ruas_tol} style={{width:'95%'}}/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Nama Petugas</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.petugas} style={{width:'95%'}}/>
                                </div>
                            </div>
                            {this.state.item.map((list_item) => (
                                <>
                                <div className="row" style={{marginTop: '10px'}}>
                                    <label className="col-sm-12 col-form-label">{list_item.nama}</label>
                                </div>
                                <table style={{width:"100%"}}>
                                <tr>
                                    <td style={{border:'1px solid #000000','padding':'4px','width':'500px','textAlign':'center'}}>Keterangan</td>
                                    {list_item.isian_id == 1 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>B</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>BT</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>R</td>
                                        </>
                                    )}
                                    {list_item.isian_id == 2 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>J</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>T</td>
                                        </>
                                    )}
                                    {list_item.isian_id == 3 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>B</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>R</td>
                                        </>
                                    )}
                                    {list_item.isian_id == 4 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>B</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>R</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>T</td>
                                        </>
                                    )}
                                    {list_item.isian_id == 5 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>J</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>B</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>R</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>T</td>
                                        </>
                                    )}
                                    {list_item.isian_id == 6 && (
                                        <>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>J</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>B</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>R</td>
                                        <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>T</td>
                                        </>
                                    )}
                                    <td style={{border:'1px solid #000000','padding':'4px','width':'250px','textAlign':'center'}}>Tindak Lanjut</td>
                                </tr>
                                {list_item.item.map((item_detail) => (
                                    <>
                                        <tr>
                                            <td style={{border:'1px solid #000000','padding':'4px'}}>{item_detail.sarpras_nama}</td>
                                            {list_item.isian_id == 1 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='B' && (
                                                   <>
                                                   <div>V</div>
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='BT' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='R' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               </>
                                            )}
                                
                                            {list_item.isian_id == 2 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty}</td>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty_terpakai}</td>
                                               </>
                                            )}
                                
                                            {list_item.isian_id == 3 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='B' && (
                                                   <>
                                                   <div>V</div>
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='R' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               </>
                                            )}
                                
                                            {list_item.isian_id == 4 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='B' && (
                                                   <>
                                                   <div>V</div>
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='R' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='T' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               </>
                                            )}
                                
                                            {list_item.isian_id == 5 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty}</td>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty_baik}</td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty_rusak}</td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='T' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               </>
                                            )}
                                
                                            {list_item.isian_id == 6 && (
                                               <>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'right'}}>{item_detail.qty}</td>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='B' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                                <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='R' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               <td style={{border:'1px solid #000000','padding':'4px','textAlign':'center'}}>
                                               {item_detail.kondisi_kode.trim() =='T' && (
                                                   <>
                                                   V
                                                   </>
                                                )}
                                                </td>
                                               </>
                                            )}
                                
                                
                                            <td style={{border:'1px solid #000000','padding':'4px','textAlign':'left'}}>
                                            {item_detail.tindak_nama}
                                            </td>
                                            
                                        </tr>
                                    </>
                                ))}
                                </table>
                        
                                
                                </>
                            ))}
                            {this.state.detail.saran_perbaikan != null && (
                            <div className="row" style={{marginBottom: '10px',marginTop:'10px'}}>
                                <label className="col-sm-3 col-form-label">Saran Perbaikan</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.saran_perbaikan} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            {this.state.detail.catatan_barang != null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Catatan Kondisi Barang</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.catatan_barang} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            {this.state.detail.komponen_rusak !=null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Catatan Komponen Rusak</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.komponen_rusak} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            {this.state.detail.rambu_nama !=null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Nama Rambu</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.rambu_nama} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            {this.state.detail.keterangan_rambu !=null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Catatan Rambu</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.keterangan_rambu} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                
                            {this.state.detail.check_nama !=null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Nama Checklist</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.check_nama} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            {this.state.detail.keterangan_check != null && (
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-3 col-form-label">Keterangan Checklist</label>
                                <div className="col-md-9">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail.keterangan_check} style={{width:'95%'}}/>
                                </div>
                            </div>
                            )}
                            
                                <div className="form-group row">
                                <div className="col-sm-12">
                                    <Button style={{float:"right"}} className="btn btn-primary" onClick={() => this.props.toogleShow('')}>Tutup</Button>
                                </div>
                            </div>
                        </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.props.toogleShow('')}
                    onSubmit={this.onSubmit}
                />
        )
    }
}


export default SarprasModal;
