import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "5%", className: "text-center", label: "Nama" },
        { width: "5%", className: "text-center", label: "Longitude" },
        { width: "5%", className: "text-center", label: "Latitude" },
        { width: "5%", className: "text-center", label: "Deskripsi" },
        { width: "5%", className: "text-center", label: "ID Kategori" },
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.nama_deskripsi}</td>
            <td className="text-center">{item.longitude}</td>
            <td className="text-center">{item.lattitude}</td>
            <td className="text-center">{item.deskripsi}</td>
            <td className="text-center">{item.kategori_fasilitas}</td>
        </tr>
    ));
};

const BujtTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default BujtTable;
