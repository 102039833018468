import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const ButtonEdit = props => {
    let index = props.menu.findIndex(menu => menu.nav_url == props.match.path);
    if (index > -1 && props.menu[index].role_update == "1") {
        return (
            <button
                type={props.type ? props.type : "button"}
                className={
                    props.className ? props.className : "btn btn-sm btn-default"
                }
                disabled={props.loading || props.disabled}
                onClick={props.onClick}
            >
                {props.loading ? (
                    <>
                        <i className="fa fa-spinner fa-pulse"></i> Loading ...
                    </>
                ) : props.children ? (
                    props.children
                ) : (
                    <>
                        <i className="fa fa-edit"></i> Edit
                    </>
                )}
            </button>
        );
    }
    return null;
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(ButtonEdit));
