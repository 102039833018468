import React, { Component } from "react";

class Marker extends Component {
    constructor (props) {
        super(props)

        this.state = {
            clicked: false,
            hovered: false
        }

        this.onClick = this.onClick.bind(this)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
    }
    onClick () {
        this.setState({ clicked: !this.state.clicked })
    }
    onMouseEnter () {
        this.setState({ hovered: true })
    }
    onMouseLeave () {
        this.setState({ hovered: false })
    }
    render() {
        return (
            <div onClick={ this.onClick } onMouseEnter={ this.onMouseEnter } onMouseLeave={ this.onMouseLeave }>
                <div style={{
                    padding: '10px', position: 'absolute', top: '-40px', left: '-55px', background: 'white', border: '1px solid #3f51b5', borderRadius: '10px', whiteSpace: 'nowrap',
                    display: this.state.clicked || this.state.hovered ? 'block' : 'none'
                }}
                >
                    Lokasi Pukul <b>{ this.props.time.substr(11, 8) }</b>
                     <p><b>{ this.props.user } </b></p>
                     <p className="text-center"><b>{ this.props.unit } </b></p>
                     
                </div>
                <div style={{ width: '25px', height: '25px', backgroundColor: this.props.color, borderRadius: '10px' }}></div>
                <div style={{ whiteSpace: 'nowrap', width: '200px', position: 'relative', left: '-88px', textAlign: 'center' }}>
                    
                </div>
            </div>
        )
    }
}

export default Marker;
