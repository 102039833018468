import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



// style
const styles = {
    chat: {
        height: "100%"
    }
};

const Tile = props => {
    const [state, setState] = useState(0);
    // panggil get api
    GetPrivate(this, props.url)
        .then(result => {
            setState(result.data);
        })
        .catch(error => {});
    // return
    return (
        <div className={classNames("small-box mt-2", props.height)} style={{backgroundColor: props.background}}>
            <div className="inner">
                <p className="fonts text-center" style={{color: props.titleStyle, fontSize: '24px'}}>{props.title}</p>
                <h3 className="fonts text-center mt-2" style={{color: props.titleStyle, fontSize: '48px'}} >{state} <p>{props.desc}</p></h3>
            {/* <div className="icon">
                <i className={props.icon}></i>
            </div> */}
            <p className="fonts text-center mt-2" style={{color: props.titleStyle, fontSize: '16px'}}>
            <NavLink to={props.linkTo} className="fonts text-center" style={{color: '#FFFFFF'}}>
                Informasi Detail <i className="fa fa-arrow-circle-right "></i>
            </NavLink>
            </p>
            </div>
        </div>
    );
};

const Stastik = props => {
    const [state, setState] = useState(0);
    // panggil get api
    GetPrivate(this, props.url)
        .then(result => {
            setState(result.data);
        })
        .catch(error => {});
    // return
    return (
        <div> 
        <div className={classNames("fonts-bar2", props.spasi)} style={{marginBottom: props.style2}}>{props.title}</div>
        <CircularProgressbar value={state} text={`${state} Menit`} 
        styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,
        
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
        
            // Text size
            textSize: '12px',
        
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
        
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',
        
            // Colors
            pathColor: `rgba(241, 90, 34, ${state / 100})`,
            textColor: '#121212',
            trailColor: '#DEE1E1',
            backgroundColor: '#FFF4F0',
          })}/>
          </div> 
    );
};

class ActivityReport extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
        };
        // bind
        this.loadData = this.loadData.bind(this);
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 15000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }

    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.labels[opts.dataPointIndex]) {
            case 'Dalam Proses':
                window.location.href = window.location.href + this.props.linkTo2;
            break;
            case 'Sudah Selesai':
                window.location.href = window.location.href + this.props.linkTo;
            break ;
            default:
        }
    }

    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
            GetPrivate(this, this.props.url),
            GetPrivate(this, this.props.url3)
        ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        const options = { labels: ["Dalam Proses", "Sudah Selesai"],
        noData:{text:"Empty Data"},                        
        colors:["#E7A78F","#F05423"], 
        dataLabels: { enabled: true, formatter: function (val, opt) { return opt.w.config.series[opt.seriesIndex] + " " },
        style: {
            colors: ['#121212', '#121212'],
            fontSize: 20,
            fontFamily: 'Poppins'
          } },
        tooltip: { enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) { let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex]
        return w.config.labels[seriesIndex] + ": " + selected + "(" + (selected / total * 100).toFixed(2) + "%)"; } },
        title:{ text:"Status Penanganan", style: { fontSize: 24, color:  '#2D3142', fontFamily: 'Poppins', fontWeight:  '400' }},
        legend: {
            show: true,
            labels: {
                colors: ["#121212"],
                useSeriesColors: false
            },
        },
        chart: {
            events: {
            dataPointSelection: this.handleDataPointSelection.bind(this)
    }
      },
    };
        // const series = [4, 5, 6]; //our data
        return (
            <div>              
                <Chart 
                type="pie"
                width={400}
                height={600}
              options={options} 
              series={[this.state.list, this.state.list2]}                
                >
                </Chart>
            </div>
        );
    }
}

class ActivityReport2 extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],

        };
        // bind
        this.loadData = this.loadData.bind(this);
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 15000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }

    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.labels[opts.dataPointIndex]) {
            case 'Sudah selesai':
                window.location.href = window.location.href + this.props.linkTo2;
            break;
            case 'Belum selesai':
                window.location.href = window.location.href + this.props.linkTo;
            break ;
            default:
        }
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }

    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        const options = { labels: ["Sudah selesai", "Belum selesai"],
        noData:{text:"Empty Data"},                        
        colors:["#ED3036","#E7A78F"], 
        dataLabels: { enabled: true, formatter: function (val, opt) { return opt.w.config.series[opt.seriesIndex] + " " },
        style: {
            colors: ['#121212', '#121212'],
            fontSize: 20,
            fontFamily: 'Poppins'
          } },
        tooltip: { enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) { let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex]
        return w.config.labels[seriesIndex] + ": " + selected + "(" + (selected / total * 100).toFixed(2) + "%)"; } },  
        title:{ text:"Total BA Kecelakaan", style: { fontSize: 24, color:  '#2D3142', fontFamily: 'Poppins', fontWeight:  '400' }},
        legend: {
            show: true,
            labels: {
                colors: ["#121212"],
                useSeriesColors: false
            },
        },
        
    chart: {
        events: {
        dataPointSelection: this.handleDataPointSelection
}
  },

        
    };
        // const series = [4, 5]; //our data
        return (
            <div>              
                <Chart 
                type="pie"
                width={400}
                height={600}
              options={options} 
              series={[this.state.list, this.state.list2]}                
                >
                </Chart>
            </div>
        );
    }
}

class ActivityReport3 extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                            list3: result[2].data,
                            list4: result[3].data,
                            list5: result[4].data,
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        const currentYear = new Date().getFullYear(); // 2022
        const previousYear =  currentYear-1;
        const previousYear2 =  previousYear-1;
        const previousYear3 =  previousYear2-1;
        const previousYear4 =  previousYear3-1;
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={1050}
          height={300}
          series={[
            {
              name: "Total Kendaraan",
              data: [this.state.list, this.state.list2, this.state.list3, this.state.list4, this.state.list5]
            },
          ]}
          options={{
            title: {
              text: "Traffic Kendaraan (ANPR)",
              style: { fontSize: 24, color:  '#2D3142', fontFamily: 'Poppins', fontWeight:  '400'},
            },

            colors: ["#F2693E"],
            theme: { mode: "light" },

            xaxis: {
              tickPlacement: "on",
              categories: [previousYear4, previousYear3, previousYear2, previousYear, currentYear],
        
              title: {
               
                style: { color: "#373D3F", fontSize: 30 },
              },
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
                style: { fontSize: "15", colors: ["#373D3F"] },
              },
            },

            legend: {
              show: true,
              position: "right",
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["#f4f4f4"],
                fontSize: 15,
              },
            },
          }}
        ></Chart>
            </div>
        );
    }
}

// combochart
class ActivityReport4 extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            link : this.props.linkTo,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 15000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
            ])
                .then(result => {
                    let total_ringan =[]
                    let total_berat =[]
                    let total_mati =[]
                    let total =[]
                    let lokasi_name =[]

                    for (let i in result[0].data) {                  
                        // total_ringan = result[0].data[i].total_ringan
                        total_ringan.push(result[0].data[i].total_ringan)                     
                    }
                    for (let i in result[1].data) {                  
                        // total_berat = result[1].data[i].total_berat  
                        total_berat.push(result[1].data[i].total_berat)                      
                    }
                    
                    for (let i in result[2].data) {                  
                        // total_mati = result[2].data[i].total_mati  
                        total_mati.push(result[2].data[i].total_mati)                     
                    }

                    for (let i in result[3].data) {                  
                        // total = result[3].data[i].total  
                        total.push(result[3].data[i].total)                      
                    }

                    for (let i in result[4].data) {                  
                       lokasi_name.push(result[4].data[i].nama)                       
                        // lokasi_name = result[4].data[i].nama
                    }
                    

                    this.setState(
                        {
                            loading: false,
                            total_ringan: total_ringan,
                            total_berat: total_berat,
                            total_mati: total_mati,
                            total: total,
                            lokasi_name: lokasi_name,

                        }
                    );
                    
                })
                .catch(error => {});
        });
    }

    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.series[opts.seriesIndex].name) {
            case 'Jumlah Laka':
                window.location.href = window.location.href + this.props.linkTo4;
            break;
            case 'Meninggal':
                window.location.href = window.location.href + this.props.linkTo3;
            break ;
            case 'Luka Berat':
                window.location.href = window.location.href + this.props.linkTo2;
            break ;
            case 'Luka Ringan':
                window.location.href = window.location.href + this.props.linkTo;
            break ;
            default:
        }
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    
    render() {
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={1050}
          height={320}
          series={[
              {
                  type: 'column',
                  data: this.state.total_ringan,
                  name: 'Luka Ringan',
                },
                {
                    type: 'column',
                    data: this.state.total_berat,
                    name: 'Luka Berat',
                },
                {
                    type: 'column',
                    data: this.state.total_mati,
                    name: 'Meninggal',
                },
                {
                   type: 'line',
                   data: this.state.total,
                   name: 'Jumlah Laka',
                 }
          ]}
          options={{
            title: {
              text: "Data Kecelakaan",
              align: 'left',
              style: { fontSize: 24, fontFamily: 'Poppins', color:  '#121212',  fontWeight:  '400' },
            },

            colors: ["#F58220", "#F05423", "#CF0A2C", "#515E5F"],
            

            xaxis: {
              categories: this.state.lokasi_name,
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
               
              },
            },
            chart: {
                background: '#FFFFFF',
                events: {
                    dataPointSelection: this.handleDataPointSelection
            },
                type: 'line',
                toolbar: {
                    show: true
                    },
                    fontFamily: 'Poppins'
              },
              


            legend: {
              show: true,
              position: "bottom",
            },

            dataLabels: {
                enabled: true,
                enabledOnSeries: [3]
              },
              responsive: [{
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    column: {
                      horizontal: true
                    },
                    line: {
                        horizontal: true
                      }
                  }
                }
          }]
              
          }}
        ></Chart>
            </div>
        );
    }
}

// Tahunan

class EventTotal extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 5000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
                GetPrivate(this, this.props.url6),
                GetPrivate(this, this.props.url7),
                GetPrivate(this, this.props.url8),
                GetPrivate(this, this.props.url9),
                GetPrivate(this, this.props.url10),
                GetPrivate(this, this.props.url11),
                GetPrivate(this, this.props.url12),

            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                            list3: result[2].data,
                            list4: result[3].data,
                            list5: result[4].data,
                            list6: result[5].data,
                            list7: result[6].data,
                            list8: result[7].data,
                            list9: result[8].data,
                            list10: result[9].data,
                            list11: result[10].data,
                            list12: result[11].data
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={520}
          height={320}
          series={[
            {
              name: "Total Event",
              data:[this.state.list, this.state.list2, this.state.list3, this.state.list4, this.state.list5,
                 this.state.list6, this.state.list7, this.state.list8, this.state.list9, this.state.list10,
                  this.state.list11, this.state.list12]
            },
          ]}
          options={{
            title: {
              text: "Total Event",
              style: { fontSize: 20, color:  '#121212', fontFamily: 'Poppins', fontWeight:  '400' },
            },

            colors: ["#FF8000"],
            theme: { mode: "light" },

            xaxis: {
              tickPlacement: "on",
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'],
        
              title: {
               
                style: { color: "#373D3F", fontSize: 30 },
              },
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
                style: { fontSize: "15", colors: ["#373D3F"] },
              },
            },

            chart: {
                toolbar: {
                    show: false
                    },
                    fontFamily: 'Poppins'
              },

            legend: {
              show: true,
              position: "right",
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["#f4f4f4"],
                fontSize: 15,
              },
            },
          }}
        ></Chart>
            </div>
        );
    }
}

class LakaTotal extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: [],
            list13: [],
            list14: [],
            list15: [],
            list16: [],
            list17: [],
            list18: [],
            list19: [],
            list20: [],
            list21: [],
            list22: [],
            list23: [],
            list24: [],
            list25: [],
            list26: [],
            list27: [],
            list28: [],
            list29: [],
            list30: [],
            list31: [],
            list32: [],
            list33: [],
            list34: [],
            list35: [],
            list36: [],
            list37: [],
            list38: [],
            list39: [],
            list40: [],
            list41: [],
            list42: [],
            list43: [],
            list44: [],
            list45: [],
            list46: [],
            list47: [],
            list48: [],
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 5000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
                GetPrivate(this, this.props.url6),
                GetPrivate(this, this.props.url7),
                GetPrivate(this, this.props.url8),
                GetPrivate(this, this.props.url9),
                GetPrivate(this, this.props.url10),
                GetPrivate(this, this.props.url11),
                GetPrivate(this, this.props.url12),
                GetPrivate(this, this.props.url13),
                GetPrivate(this, this.props.url14),
                GetPrivate(this, this.props.url15),
                GetPrivate(this, this.props.url16),
                GetPrivate(this, this.props.url17),
                GetPrivate(this, this.props.url18),
                GetPrivate(this, this.props.url19),
                GetPrivate(this, this.props.url20),
                GetPrivate(this, this.props.url21),
                GetPrivate(this, this.props.url22),
                GetPrivate(this, this.props.url23),
                GetPrivate(this, this.props.url24),
                GetPrivate(this, this.props.url25),
                GetPrivate(this, this.props.url26),
                GetPrivate(this, this.props.url27),
                GetPrivate(this, this.props.url28),
                GetPrivate(this, this.props.url29),
                GetPrivate(this, this.props.url30),
                GetPrivate(this, this.props.url31),
                GetPrivate(this, this.props.url32),
                GetPrivate(this, this.props.url33),
                GetPrivate(this, this.props.url34),
                GetPrivate(this, this.props.url35),
                GetPrivate(this, this.props.url36),
                GetPrivate(this, this.props.url37),
                GetPrivate(this, this.props.url38),
                GetPrivate(this, this.props.url39),
                GetPrivate(this, this.props.url40),
                GetPrivate(this, this.props.url41),
                GetPrivate(this, this.props.url42),
                GetPrivate(this, this.props.url43),
                GetPrivate(this, this.props.url44),
                GetPrivate(this, this.props.url45),
                GetPrivate(this, this.props.url46),
                GetPrivate(this, this.props.url47),
                GetPrivate(this, this.props.url48),
            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                            list3: result[2].data,
                            list4: result[3].data,
                            list5: result[4].data,
                            list6: result[5].data,
                            list7: result[6].data,
                            list8: result[7].data,
                            list9: result[8].data,
                            list10: result[9].data,
                            list11: result[10].data,
                            list12: result[11].data,
                            list13: result[12].data,
                            list14: result[13].data,
                            list15: result[14].data,
                            list16: result[15].data,
                            list17: result[16].data,
                            list18: result[17].data,
                            list19: result[18].data,
                            list20: result[19].data,
                            list21: result[20].data,
                            list22: result[21].data,
                            list23: result[22].data,
                            list24: result[23].data,
                            list25: result[24].data,
                            list26: result[25].data,
                            list27: result[26].data,
                            list28: result[27].data,
                            list29: result[28].data,
                            list30: result[29].data,
                            list31: result[30].data,
                            list32: result[31].data,
                            list33: result[32].data,
                            list34: result[33].data,
                            list35: result[34].data,
                            list36: result[35].data,
                            list37: result[36].data,
                            list38: result[37].data,
                            list39: result[38].data,
                            list40: result[39].data,
                            list41: result[40].data,
                            list42: result[41].data,
                            list43: result[42].data,
                            list44: result[43].data,
                            list45: result[44].data,
                            list46: result[45].data,
                            list47: result[46].data,
                            list48: result[47].data,
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={520}
          height={320}
          series={[
              {
                  type: 'column',
                  data: [this.state.list, this.state.list2, this.state.list3, this.state.list4, this.state.list5, this.state.list6,
                     this.state.list7, this.state.list8, this.state.list9, this.state.list10, this.state.list11, this.state.list12],
                  name: 'Luka Ringan',
                },
                {
                    type: 'column',
                    data:[this.state.list13, this.state.list14, this.state.list15, this.state.list16, this.state.list17, this.state.list18,
                        this.state.list19, this.state.list20, this.state.list21, this.state.list22, this.state.list23, this.state.list24],
                    name: 'Luka Berat',
                },
                {
                    type: 'column',
                    data: [this.state.list25, this.state.list26, this.state.list27, this.state.list28, this.state.list29, this.state.list30,
                        this.state.list31, this.state.list32, this.state.list33, this.state.list34, this.state.list35, this.state.list36],
                    name: 'Meninggal',
                },
                {
                   type: 'line',
                   data: [this.state.list37, this.state.list38, this.state.list39, this.state.list40, this.state.list41, this.state.list42,
                    this.state.list43, this.state.list44, this.state.list45, this.state.list46, this.state.list47, this.state.list48],
                   name: 'Jumlah Laka',
                 }
          ]}
          options={{
            title: {
              text: "Total Kecelakaan",
              style: { fontSize: 20, fontFamily: 'Poppins', color:  '#121212', fontWeight:  '400' },
            },

            colors: ["#F58220", "#F05423", "#CF0A2C", "#515E5F"],
            

            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'],
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
               
              },
            },
            chart: {
                background: '#FFFFFF',
                type: 'line',
                toolbar: {
                    show: false
                    },
                    fontFamily: 'Poppins'
              },


            legend: {
              show: true,
              position: "bottom",
            },

            // dataLabels: {
            //     enabled: true,
            //     enabledOnSeries: [3]
            //   },
          }}
        ></Chart>
            </div>
        );
    }
}

class BaTotal extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 5000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
                GetPrivate(this, this.props.url6),
                GetPrivate(this, this.props.url7),
                GetPrivate(this, this.props.url8),
                GetPrivate(this, this.props.url9),
                GetPrivate(this, this.props.url10),
                GetPrivate(this, this.props.url11),
                GetPrivate(this, this.props.url12),

            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                            list3: result[2].data,
                            list4: result[3].data,
                            list5: result[4].data,
                            list6: result[5].data,
                            list7: result[6].data,
                            list8: result[7].data,
                            list9: result[8].data,
                            list10: result[9].data,
                            list11: result[10].data,
                            list12: result[11].data
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={520}
          height={320}
          series={[
            {
              name: "Total BA Kecelakaan",
              data:[this.state.list, this.state.list2, this.state.list3, this.state.list4, this.state.list5,
                 this.state.list6, this.state.list7, this.state.list8, this.state.list9, this.state.list10,
                  this.state.list11, this.state.list12]
            },
          ]}
          options={{
            title: {
              text: "Total BA Kecelakaan",
              style: { fontSize: 20, color:  '#121212', fontFamily: 'Poppins', fontWeight:  '400' },
            },

            colors: ["#FF8000"],
            theme: { mode: "light" },

            xaxis: {
              tickPlacement: "on",
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'],
        
              title: {
               
                style: { color: "#373D3F", fontSize: 30 },
              },
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
                style: { fontSize: "15", colors: ["#373D3F"] },
              },
            },

            chart: {
                toolbar: {
                    show: false
                    },
                    fontFamily: 'Poppins'
              },

            legend: {
              show: true,
              position: "right",
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["#f4f4f4"],
                fontSize: 15,
              },
            },
          }}
        ></Chart>
            </div>
        );
    }
}

class AnprTotal extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        const data_interval = setInterval(() => {
            this.loadData()
          }, 5000);
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, this.props.url),
                GetPrivate(this, this.props.url2),
                GetPrivate(this, this.props.url3),
                GetPrivate(this, this.props.url4),
                GetPrivate(this, this.props.url5),
                GetPrivate(this, this.props.url6),
                GetPrivate(this, this.props.url7),
                GetPrivate(this, this.props.url8),
                GetPrivate(this, this.props.url9),
                GetPrivate(this, this.props.url10),
                GetPrivate(this, this.props.url11),
                GetPrivate(this, this.props.url12),

            ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result[0].data,
                            list2: result[1].data,
                            list3: result[2].data,
                            list4: result[3].data,
                            list5: result[4].data,
                            list6: result[5].data,
                            list7: result[6].data,
                            list8: result[7].data,
                            list9: result[8].data,
                            list10: result[9].data,
                            list11: result[10].data,
                            list12: result[11].data
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }
    
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        return (
            <div>              
                <Chart
          type="line"
          width={520}
          height={320}
          series={[
            {
              name: "Traffic Kendaraan (ANPR)",
              data:[this.state.list, this.state.list2, this.state.list3, this.state.list4, this.state.list5,
                 this.state.list6, this.state.list7, this.state.list8, this.state.list9, this.state.list10,
                  this.state.list11, this.state.list12]
            },
          ]}
          options={{
            title: {
              text: "Traffic Kendaraan (ANPR)",
              style: { fontSize: 20, color:  '#121212', fontFamily: 'Poppins', fontWeight:  '400' },
            },

            colors: ["#FF8000"],
            theme: { mode: "light" },

            xaxis: {
              tickPlacement: "on",
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'],
        
              title: {
               
                style: { color: "#373D3F", fontSize: 30 },
              },
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
                style: { fontSize: "15", colors: ["#373D3F"] },
              },
            },

            chart: {
                toolbar: {
                    show: false
                    },
                    fontFamily: 'Poppins'
              },

            legend: {
              show: true,
              position: "right",
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["#f4f4f4"],
                fontSize: 15,
              },
            },
          }}
        ></Chart>
            </div>
        );
    }
}

const Dashboard = props => {
    // datas
    const today = moment();
    const start_date = moment(today)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    const end_date = moment(today).format("YYYY-MM-DD");
    const current_month = moment(today).format("MM")
    const currentYear = new Date().getFullYear(); // 2022
    
    const [dateValue, setDateValue] = useState('');
    const [dateValue2, setDateValue2] = useState(''); 
    const [dateValue3, setDateValue3] = useState(current_month); 
    const [dateValue4, setDateValue4] = useState(currentYear); 
    
    // Tahunan
    const [dateValue5, setDateValue5] = useState(currentYear); 
    
    function handleDateUpdate(e) {
        const dateValue = e.target.value;
        console.log("dateValue", dateValue);
        setDateValue(dateValue);
    }
    
    function handleDateUpdate2(e) {
        const dateValue2 = e.target.value;
        console.log("dateValue2", dateValue2);
        setDateValue2(dateValue2);
    }
    
    function handleDateUpdate3(e) {
        const dateValue3 = e.target.value;
        console.log("dateValue3", dateValue3);
        setDateValue3(dateValue3);
    }
    
    function handleDateUpdate4(e) {
        const dateValue4 = e.target.value;
        console.log("dateValue4", dateValue4);
        setDateValue4(dateValue4);
    }

    function handleDateUpdate5(e) {
        const dateValue5 = e.target.value;
        console.log("dateValue5", dateValue5);
        setDateValue5(dateValue5);
    }

    const combineBulan = dateValue4 + '-' + dateValue3 + '-' + '01';
    const combineTahun = dateValue4 + '-' + dateValue3 + '-' + 30;

    var month = [ "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember" ];
        if (dateValue3 < 10) {    
            var monthIndex = moment().month(dateValue3[1]-1).format("M");
        } else {
            var monthIndex = moment().month(dateValue3 -1).format("M");         
        }
         var monthName = month[monthIndex-1];
    
    

    // Year
       
        const previousYear =  currentYear-1;
        const previousYear2 =  previousYear-1;
        const previousYear3 =  previousYear2-1;
        const previousYear4 =  previousYear3-1;
    
        let minOffset = 0, maxOffset = 4;
        let thisYear = (new Date()).getFullYear();
        let allYears = [];
        for(let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x)
        }

        const yearList = allYears.map((x) => {return(<option key={x}>{x}</option>)});

        // month
        const Month = [
            { value: "01", label: "Januari", key: "Januari" },
            { value: "02", label: "Februari", key: "Februari" },
            { value: "03", label: "Maret", key: "Maret" },
            { value: "04", label: "April", key: "April" },
            { value: "05", label: "Mei", key: "Mei" },
            { value: "06", label: "Juni", key: "Juni" },
            { value: "07", label: "Juli", key: "Juli" },
            { value: "08", label: "Agustus", key: "Agustus" },
            { value: "09", label: "September", key: "September" },
            { value: "10", label: "Oktober", key: "Oktober" },
            { value: "11", label: "November", key: "November" },
            { value: "12", label: "Desember", key: "Desember" }
          ];

          
        

          const [showhide, setShowhide]=useState('1');
  
          const handleshowhide=(event)=>{
            const getuser = event.target.value;    
            setShowhide(getuser);
       
          }

        // Reset Input Field handler
        const resetInputField = () => {
            setDateValue("");
            setDateValue2("");
        };
        
        const resetInputField2 = () => {
            setDateValue3("");
            // setDateValue4("");
        };
        
        // Tabs
        const [showResults, setShowResults] = useState(true)
        const [showResults2, setShowResults2] = useState(false)
        const onClick = () => {
            setShowResults(true);
            setShowResults2(false);
        }
        const onClick2 = () => {
            setShowResults2(true);
            setShowResults(false);
        }
        
    // return
    return (
        <Content
            title={props.pagetitle}
            subtitle={props.pagesubtitle}
            breadcrumb={props.breadcrumb}
        >
             {
             showhide==='1' && showResults && (
            <div className="row">
                <div className="col-lg-2">
                    <div className="form-group">
                        <select
                            id="month"
                            onChange={(e) => handleDateUpdate3(e)}
                            className="form-control dashboard-css"
                            defaultValue={current_month}
                            >
                            {Month.map((option) => (
                            <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                    </div>
                </div>
            <div className="col-lg-2">
                <div className="form-group">
                   <select
                    id="year"
                    onChange={(e) => handleDateUpdate4(e)}
                    defaultValue={currentYear}
                    className="form-control dashboard-css"
                    >
                        {yearList}
                    </select>
                </div>              
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-2">
            <Button
                    className={showResults  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick}
                >
                    Bulanan
                </Button>
                <Button
                    className={showResults2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick2}
                >
                    Tahunan
                </Button>
            </div>
            
        </div>
            )}

             { showResults2 && 
            <div className="row">
                    <div className="col-md-2">
                    <div className="form-group">
                    <select
                    id="year"
                    onChange={(e) => handleDateUpdate5(e)}
                    defaultValue={currentYear}
                    className="form-control dashboard-css"
                    >
                        {yearList}
                    </select>
                    </div>
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-2">
            <Button
                    className={showResults  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick}
                >
                    Bulanan
                </Button>
                <Button
                    className={showResults2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick2}
                >
                    Tahunan
                </Button>
            </div>
            </div>
            }
            <BoxCollapse open={true}
                    title="Peristiwa"
                    color="#121212"
                    topColor="#F05423"
                    tools={
                        <>
                        {/* <Button
                    className={showResults  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick}
                >
                    Bulanan
                </Button>
                <Button
                    className={showResults2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={onClick2}
                >
                    Tahunan
                </Button> */}
                </>
                    }
                    >
            

        { showResults2 && 
                <div className="row">
                    <div className="col-md-6">
                    <EventTotal
                    url={`user/dashboard/total_event?month=01&year=${dateValue5}`}
                    url2={`user/dashboard/total_event?month=02&year=${dateValue5}`}
                    url3={`user/dashboard/total_event?month=03&year=${dateValue5}`}
                    url4={`user/dashboard/total_event?month=04&year=${dateValue5}`}
                    url5={`user/dashboard/total_event?month=05&year=${dateValue5}`}
                    url6={`user/dashboard/total_event?month=06&year=${dateValue5}`}
                    url7={`user/dashboard/total_event?month=07&year=${dateValue5}`}
                    url8={`user/dashboard/total_event?month=08&year=${dateValue5}`}
                    url9={`user/dashboard/total_event?month=09&year=${dateValue5}`}
                    url10={`user/dashboard/total_event?month=10&year=${dateValue5}`}
                    url11={`user/dashboard/total_event?month=11&year=${dateValue5}`}
                    url12={`user/dashboard/total_event?month=12&year=${dateValue5}`}
                    />
                    </div>
                    <div className="col-md-6">
                    <LakaTotal
                    // Ringan
                    url={`user/dashboard/total_ringan_year?month=01&year=${dateValue5}`}
                    url2={`user/dashboard/total_ringan_year?month=02&year=${dateValue5}`}
                    url3={`user/dashboard/total_ringan_year?month=03&year=${dateValue5}`}
                    url4={`user/dashboard/total_ringan_year?month=04&year=${dateValue5}`}
                    url5={`user/dashboard/total_ringan_year?month=05&year=${dateValue5}`}
                    url6={`user/dashboard/total_ringan_year?month=06&year=${dateValue5}`}
                    url7={`user/dashboard/total_ringan_year?month=07&year=${dateValue5}`}
                    url8={`user/dashboard/total_ringan_year?month=08&year=${dateValue5}`}
                    url9={`user/dashboard/total_ringan_year?month=09&year=${dateValue5}`}
                    url10={`user/dashboard/total_ringan_year?month=10&year=${dateValue5}`}
                    url11={`user/dashboard/total_ringan_year?month=11&year=${dateValue5}`}
                    url12={`user/dashboard/total_ringan_year?month=12&year=${dateValue5}`}
                    // Berat
                    url13={`user/dashboard/total_berat_year?month=01&year=${dateValue5}`}
                    url14={`user/dashboard/total_berat_year?month=02&year=${dateValue5}`}
                    url15={`user/dashboard/total_berat_year?month=03&year=${dateValue5}`}
                    url16={`user/dashboard/total_berat_year?month=04&year=${dateValue5}`}
                    url17={`user/dashboard/total_berat_year?month=05&year=${dateValue5}`}
                    url18={`user/dashboard/total_berat_year?month=06&year=${dateValue5}`}
                    url19={`user/dashboard/total_berat_year?month=07&year=${dateValue5}`}
                    url20={`user/dashboard/total_berat_year?month=08&year=${dateValue5}`}
                    url21={`user/dashboard/total_berat_year?month=09&year=${dateValue5}`}
                    url22={`user/dashboard/total_berat_year?month=10&year=${dateValue5}`}
                    url23={`user/dashboard/total_berat_year?month=11&year=${dateValue5}`}
                    url24={`user/dashboard/total_berat_year?month=12&year=${dateValue5}`}
                     // mati
                     url25={`user/dashboard/total_mati_year?month=01&year=${dateValue5}`}
                     url26={`user/dashboard/total_mati_year?month=02&year=${dateValue5}`}
                     url27={`user/dashboard/total_mati_year?month=03&year=${dateValue5}`}
                     url28={`user/dashboard/total_mati_year?month=04&year=${dateValue5}`}
                     url29={`user/dashboard/total_mati_year?month=05&year=${dateValue5}`}
                     url30={`user/dashboard/total_mati_year?month=06&year=${dateValue5}`}
                     url31={`user/dashboard/total_mati_year?month=07&year=${dateValue5}`}
                     url32={`user/dashboard/total_mati_year?month=08&year=${dateValue5}`}
                     url33={`user/dashboard/total_mati_year?month=09&year=${dateValue5}`}
                     url34={`user/dashboard/total_mati_year?month=10&year=${dateValue5}`}
                     url35={`user/dashboard/total_mati_year?month=11&year=${dateValue5}`}
                     url36={`user/dashboard/total_mati_year?month=12&year=${dateValue5}`}
                     // Total
                     url37={`user/dashboard/total_laka_year?month=01&year=${dateValue5}`}
                     url38={`user/dashboard/total_laka_year?month=02&year=${dateValue5}`}
                     url39={`user/dashboard/total_laka_year?month=03&year=${dateValue5}`}
                     url40={`user/dashboard/total_laka_year?month=04&year=${dateValue5}`}
                     url41={`user/dashboard/total_laka_year?month=05&year=${dateValue5}`}
                     url42={`user/dashboard/total_laka_year?month=06&year=${dateValue5}`}
                     url43={`user/dashboard/total_laka_year?month=07&year=${dateValue5}`}
                     url44={`user/dashboard/total_laka_year?month=08&year=${dateValue5}`}
                     url45={`user/dashboard/total_laka_year?month=09&year=${dateValue5}`}
                     url46={`user/dashboard/total_laka_year?month=10&year=${dateValue5}`}
                     url47={`user/dashboard/total_laka_year?month=11&year=${dateValue5}`}
                     url48={`user/dashboard/total_laka_year?month=12&year=${dateValue5}`}
                    />
                    </div>     
                </div>

        }

{ showResults2 && 
            <div className="row padding-top-lg">
                    <div className="col-md-6">
                    <BaTotal
                    url={`user/dashboard/total_laka_selesai?month=01&year=${dateValue5}`}
                    url2={`user/dashboard/total_laka_selesai?month=02&year=${dateValue5}`}
                    url3={`user/dashboard/total_laka_selesai?month=03&year=${dateValue5}`}
                    url4={`user/dashboard/total_laka_selesai?month=04&year=${dateValue5}`}
                    url5={`user/dashboard/total_laka_selesai?month=05&year=${dateValue5}`}
                    url6={`user/dashboard/total_laka_selesai?month=06&year=${dateValue5}`}
                    url7={`user/dashboard/total_laka_selesai?month=07&year=${dateValue5}`}
                    url8={`user/dashboard/total_laka_selesai?month=08&year=${dateValue5}`}
                    url9={`user/dashboard/total_laka_selesai?month=09&year=${dateValue5}`}
                    url10={`user/dashboard/total_laka_selesai?month=10&year=${dateValue5}`}
                    url11={`user/dashboard/total_laka_selesai?month=11&year=${dateValue5}`}
                    url12={`user/dashboard/total_laka_selesai?month=12&year=${dateValue5}`}
                    />
                    </div>
                    <div className="col-md-6">
                    <AnprTotal
                    url={`user/dashboard/total_anpr_year?month=01&year=${dateValue5}`}
                    url2={`user/dashboard/total_anpr_year?month=02&year=${dateValue5}`}
                    url3={`user/dashboard/total_anpr_year?month=03&year=${dateValue5}`}
                    url4={`user/dashboard/total_anpr_year?month=04&year=${dateValue5}`}
                    url5={`user/dashboard/total_anpr_year?month=05&year=${dateValue5}`}
                    url6={`user/dashboard/total_anpr_year?month=06&year=${dateValue5}`}
                    url7={`user/dashboard/total_anpr_year?month=07&year=${dateValue5}`}
                    url8={`user/dashboard/total_anpr_year?month=08&year=${dateValue5}`}
                    url9={`user/dashboard/total_anpr_year?month=09&year=${dateValue5}`}
                    url10={`user/dashboard/total_anpr_year?month=10&year=${dateValue5}`}
                    url11={`user/dashboard/total_anpr_year?month=11&year=${dateValue5}`}
                    url12={`user/dashboard/total_anpr_year?month=12&year=${dateValue5}`}
                    />
                    </div>     
                </div>
}


              
{ showhide==='1' && showResults && 
                    <div className="row m-2" style={{background: '#F58220', borderRadius: '16px'}}>
                        <div className="col-xs-6">
                        <Tile
                            background="#F58220"
                            title="TOTAL PERISTIWA"
                            url={`user/dashboard/total_event?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                            icon="fa fa-pie-chart"
                            titleStyle="#E2E8CE"
                            linkTo={`dashboard/report/handling_response/lengkap?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                        />
                        </div>
                        <div className="col-xs-5 m-2" style={{background: '#FFF4F0', borderRadius: '16px'}}>
                        <div className="col-xs-4">
                        <Stastik
                            title="Rata-rata Waktu Respon Petugas"
                            url={`user/dashboard/avg_respon?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                        />
                        </div>
                        <div className="col-xs-4">
                        <Stastik
                            title="Rata-rata Waktu Penanganan"
                            // style2="24px"
                            url={`user/dashboard/avg_penanganan?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                        /> 
                        </div>
                        <div className="col-xs-4">
                        <Stastik
                            title="Rata-rata Waktu Selesai Event"
                            url={`user/dashboard/avg_selesai?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                        />
                        </div>
                        </div>
                        
                    </div>   

}

{ showhide==='1' && showResults && 
            <div className="row mt-2">      
            <div className="col-lg-12">            
                 <ActivityReport4
                    // Ringan
                    url={`user/dashboard/total_ringan?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url2={`user/dashboard/total_berat?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url3={`user/dashboard/total_meninggal?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url4={`user/dashboard/total_laka?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url5={`user/dashboard/lokasi?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    linkTo={`/report/lakalantas/ringan?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    linkTo2={`/report/lakalantas/berat?bujt=all&start=${combineBulan}&end=${combineTahun}`} 
                     linkTo3={`/report/lakalantas/meninggal?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                     linkTo4={`/report/lakalantas/lengkap?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    />                
            </div>         
            </div>
    
}
            </BoxCollapse>
            { showhide==='1' && showResults && 
            <BoxCollapse 
            open={true}
            topColor="#F58220"
            title=""
            >
            <div className="row">
                    <div className="col-md-6">
                    <ActivityReport2
                    url={`user/dashboard/total_laka_selesai?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url2={`user/dashboard/total_laka_belum?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    linkTo={`/report/ganti_rugi?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    linkTo2={`/report/ganti_rugi?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    />
                    
                    </div>
                    <div className="col-md-6">
                    <ActivityReport
                    url={`user/dashboard/total_respon?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url3={`user/dashboard/total_selesai?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    linkTo={`/report/handling_response/selesai?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    linkTo2={`/report/handling_response/lengkap?bujt=all&start=${combineBulan}&end=${combineTahun}`}
                    />
                    </div>     
                </div>
                </BoxCollapse>
}

{ showhide==='2' && showResults && 
            <BoxCollapse 
            topColor="#F05423"
            open={true}
                    title="">
            <div className="row">
                    <div className="col-md-6">
                    <ActivityReport2
                    url={`user/dashboard/total_laka_selesai?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url2={`user/dashboard/total_laka_belum?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    linkTo={`/report/ganti_rugi?bujt=all&start=${dateValue}&end=${dateValue2}`}
                    linkTo2={`/report/ganti_rugi?bujt=all&start=${dateValue}&end=${dateValue2}`}
                    />
                    
                    </div>
                    <div className="col-md-6">
                    <ActivityReport
                    url={`user/dashboard/total_respon?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    url3={`user/dashboard/total_selesai?start=${dateValue}&end=${dateValue2}&month=${dateValue3}&year=${dateValue4}`}
                    linkTo={`/report/handling_response/selesai?bujt=all&start=${dateValue}&end=${dateValue2}`}
                    linkTo2={`/report/handling_response/lengkap?bujt=all&start=${dateValue}&end=${dateValue2}`}
                    />
                    </div>     
                </div>
                </BoxCollapse>
}
            
            {/* { showResults && 
            <BoxCollapse open={true} topColor="#CF0A2C"
                    title="">
            <div className="row">
                    <div className="col-md-6">
                    <ActivityReport3
                    url={`user/dashboard/total_anpr?year=${previousYear4}`}
                    url2={`user/dashboard/total_anpr?year=${previousYear3}`}
                    url3={`user/dashboard/total_anpr?year=${previousYear2}`}
                    url4={`user/dashboard/total_anpr?year=${previousYear}`}
                    url5={`user/dashboard/total_anpr?year=${currentYear}`}
                    />
                    </div>  
                </div>
                </BoxCollapse>
                } */}
        </Content>
    );
};

export default Dashboard;
