import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../reducers/session/actions.js";

// komponen
import { SwalSuccess } from "../../components/Alert";
import { Button, Input } from "../../components/AdminLTE";

// utils
import toBase64 from "../../utils/File";
import { PostPrivate } from "../../utils/Connection";

class ProfileUser extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            // field
            user_alias: props.session.pegawai_name,
            user_name: props.session.pegawai_nik,
            user_pass: "",
            user_image: null,
            // error field
            error_field: {},
            // loading
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            user_image: picture[0],
        });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.user_image) {
            image = await toBase64(that.state.user_image)
                .then((result) => result)
                .catch((error) => null);
        }
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                user_alias: that.state.user_alias,
                user_name: that.state.user_name,
                user_pass: that.state.user_pass,
                user_image: image,
            };
            // paggil post api
            PostPrivate(that, `user/profile/update-profile`, params)
                .then((result) => {
                    // update redux state
                    let session = that.props.session;
                    // loop result
                    Object.keys(result.data).map(
                        (item) => (session[item] = result.data[item])
                    );
                    // set session
                    that.props.setSession(session).then(() => {
                        // pesan
                        SwalSuccess("Berhasil", result.message);
                        // reload page
                        that.props.history.replace(
                            that.props.location.pathname
                        );
                    });
                })
                .catch((error) => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error,
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    }
    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-4">
                            <ImageUploader
                                withIcon={true}
                                buttonText="Pilih Gambar"
                                onChange={this.handleDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                withPreview={true}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label
                                    htmlFor={"user_alias"}
                                    className="control-label"
                                >
                                    Nama Lengkap
                                </label>
                                <Input
                                    id={"user_alias"}
                                    type="text"
                                    placeholder="Nama Lengkap"
                                    has_error={
                                        this.state.error_field.user_alias
                                    }
                                    defaultValue={this.state.user_alias}
                                    onChange={(ev) =>
                                        this.handleChange(
                                            "user_alias",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.user_alias
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor={"user_name"}
                                    className="control-label"
                                >
                                    Username
                                </label>
                                <Input
                                    id={"user_name"}
                                    type="text"
                                    placeholder="Username"
                                    has_error={this.state.error_field.user_name}
                                    defaultValue={this.state.user_name}
                                    onChange={(ev) =>
                                        this.handleChange(
                                            "user_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.user_name
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor={"user_pass"}
                                    className="control-label"
                                >
                                    Password
                                </label>
                                <Input
                                    id={"user_pass"}
                                    type="password"
                                    placeholder="Password"
                                    has_error={this.state.error_field.user_pass}
                                    defaultValue={this.state.user_pass}
                                    onChange={(ev) =>
                                        this.handleChange(
                                            "user_pass",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.user_pass
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-footer text-right">
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                </div>
            </form>
        );
    }
}

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
    };
}
// dispatch / aksi -> props
function mapsDispatchToProps(dispatch) {
    return {
        setSession: (params) => {
            return new Promise((resolve, reject) => {
                dispatch(setSession(params));
                resolve();
            });
        },
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, mapsDispatchToProps)(ProfileUser)
);
