import React from "react";
import classNames from "classnames";

const Empty = props => {
    const icon = props.icon ? props.icon : "fa-folder-open-o";
    return (
        <div className="text-center text-muted m-3">
            <i className={classNames("fa", "fa-3x", icon)}></i>
            <p className="mt-1">{props.content}</p>
        </div>
    );
};

export default Empty;
