import React, { Component } from "react";
import classNames from "classnames";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../reducers/session/actions.js";
import setMenu from "../../reducers/menu/actions.js";

// utils
import Session from "../../utils/Session";
import GetPrivate, { PostPrivate } from "../../utils/Connection";

// router
import Router from "../../router";

//komponen
import { Header, Sidebar, Footer, LoadingBig,Input } from "../../components/AdminLTE";
import { Toast } from "../../components/Alert";

import Pusher from "pusher-js";

class Chat extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            chat_message:[],
            user_id : null,
            chat_input:"",
            chat_show:"block"
        };
        
        this.sendChat       = this.sendChat.bind(this);
        this.handleChange   = this.handleChange.bind(this);
        this.loadChat       = this.loadChat.bind(this);
        this.closeChat      = this.closeChat.bind(this);
        this.showChat       = this.showChat.bind(this);
        
    }
    
    componentDidMount() {
        this.loadChat(); 

        //Pusher.logToConsole = true;

        const pusher = new Pusher("6f462bd5e682ec0fce3b", {
            cluster: "ap1",
            encrypted: true,
        });



        const channel = pusher.subscribe("astra-channel");

        channel.bind("astra-event", (data) => {
            let message = this.state.chat_message;

            message.push(data);
            this.setState({
                chat_message: message
            })

          });
          
        this.scrollToBottom();
    }
    
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    
    closeChat() {
        //nothing
        this.setState({
            chat_show: 'none'
        })
    }
    
    showChat() {
        //nothing
        this.setState({
            chat_show: 'block'
        })
    }
    
    loadChat() {
        // load based on post login
        const params = {
                channel     : "astra-channel",
                event       : "astra-event",
                cluster     : "ap1"
            };
            
        PostPrivate(this, `chat/chat/get_message`, params)
                .then(result => {
                    // do nothing
                    this.setState({
                        chat_message: result.data
                    });
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                });
    }
    
    sendChat(ev, value) {
        if(ev.key === 'Enter') {
            // sending to server
            const params = {
                message     : value,
                to          : "",
                channel     : "astra-channel",
                event       : "astra-event",
                cluster     : "ap1"
            };
            
            PostPrivate(this, `chat/chat/send_message`, params)
                .then(result => {
                    // do nothing
                    this.setState({
                        chat_input: ""
                    });
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                });
            
            /*
            let current_message = {username:"",userid:"",message:value}
            
            let message = this.state.chat_message;
            message.push(current_message);
            
            this.setState({
                chat_message: message
            })
            */
        }
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    render() {
        return (
                <>
                <div style={{position:"fixed",bottom:"0px", right:"0px", zIndex: '9999', width:"250px", display:"inline"}}>
                    <div style={{backgroundColor:"#F2693E",padding:"5px",  "border":"1px solid #000000", width:"100%", borderRadius: '8px 8px 0px 0px'}}>
                        <table style={{width:"100%"}}>
                            <tr>
                                <td style={{width:"50%", color:"#FFFFFF", fontFamily: 'Poppins'}}>Realtime Chat</td>
                                <td style={{width:"50%",textAlign:"right"}}>
                                {this.state.chat_show == 'block' && (
                                <div onClick={this.closeChat}>
                                    X
                                </div>
                                )}
                                {this.state.chat_show == 'none' && (
                                <div onClick={this.showChat}>
                                    V
                                </div>
                                )}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div id="chat_body" ref={(el) => { this.messagesEnd = el; }} style={{width:"100%",padding:"5px", height:"400px",backgroundColor:"#FFFFFF", "border":"1px solid #000000", display:this.state.chat_show, height:"400px", overflow:"auto"}}>
                        {this.state.chat_message.map((chat) => 
                        (
                            <>
                                <div class="chat-box" style={{border:"1px solid #f9e79f", borderRadius:"5px", padding:"4px", backgroundColor:"#fef9e7",marginBottom:"5px", color:"#000000", fontFamily: 'Poppins'}}>
                                    <div className="chat-username" style={{fontWeight:"bold", width:"100%"}}>{chat.username}</div>
                                    <div className="chat-message">{chat.message}</div>
                                </div>
                            </>
                        )
                        )}

                    </div>
                    <div className="chat-input" style={{border:"1px solid #000000", display:this.state.chat_show}}>
                        <Input 
                            type="text" 
                            style={{width:"100%"}}
                            id="chat-input"
                            name="chat-input"
                            onKeyDown ={ev =>
                                {
                                    this.sendChat(ev, ev.target.value)

                                    if (ev.key ==='Enter') {
                                        ev.target.value = "";
                                    }
                                }
                            }
                        />
                    </div>
                </div>
                </>
        )
    }

}


export default Chat;