import React from "react";
import moment from "moment";
// import idLocale from "moment/locale/id";

// moment.locale("id", idLocale);

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Unit",
        selector: "nama_unit",
        sortable: true,
        width: "5%",
    },
      {
        name: "Nama Petugas",
        selector: "pegawai",
        sortable: true,
        width: "20%",
    },
    {
        name: "Komponen Rusak",
        selector: "komponen_rusak",
        sortable: true,
        width: "10%",
    },
    {
        name: "Kondisi",
        selector: "keterangan_check",
        sortable: true,
        width: "10%",
        center: true,
    },
    {
        name: "Saran",
        selector: "saran",
        sortable: true,
        width: "15%",
        center: true,
    },
    {
        name: "Catatan",
        selector: "catatan",
        sortable: true,
        width: "20%",
        center: true,
    },
    {
        name: "Rambu",
        selector: "keterangan_rambu",
        sortable: true,
        width: "15%",
        center: true,
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["detail"] = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.shift_id)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const KepalaShiftTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KepalaShiftTable;
