import React, { Component } from "react";

// komponen
import { Content, ButtonAdd } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// page
import GantiModal from "./modal";
import GantiTable from "./table";

class Ganti extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            item: null,
            // unit
            mode: "",
            modal: false,
            // kode unit
            modal_kode: false,
            // loading
            loading: false,
            satuan:[]
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
        this.toogleShowGanti = this.toogleShowGanti.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/ganti")
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                    
                    // LOAD DATA
                    this.setState({ loading: true }, function() {
                        GetPrivate(this, "referensi/satuan/satuan_list")
                            .then(result => {
                                this.setState({
                                    loading: false,
                                    satuan   : result.data
                                });
                            })
                            .catch(error => {
                                this.setState({
                                    loading: false
                                });
                            });
                    });
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Referensi Ganti [${item.nama}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/ganti/delete/${item.id}`)
                .then(result => {
                    // load data
                    this.loadData();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    //toogleShow
    toogleShow(mode = "", item = null) {
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
        });
    }
    //toogleShowGanti
    toogleShowGanti(item = null) {
        this.setState({ item: item }, function() {
            this.setState({ modal_kode: !this.state.modal_kode });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Manajemen Referensi Ganti</h3>
                        <div className="box-tools pull-right">
                            <ButtonAdd
                                onClick={() => this.toogleShow("add")}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                    <div className="box-body">
                        <GantiTable
                            list={this.state.list}
                            satuan={this.state.satuan}
                            toogleShow={this.toogleShow}
                            toogleShowGanti={this.toogleShowGanti}
                            deleteConfirm={this.deleteConfirm}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <GantiModal
                        mode={this.state.mode}
                        item={this.state.item}
                        show={this.state.modal}
                        satuan={this.state.satuan}
                        toogleShow={() => this.toogleShow()}
                        loadData={() => this.loadData()}
                    />
                )}
            </Content>
        );
    }
}

export default Ganti;
