import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama",
        selector: "nama",
        sortable: true,
        width: "72.5%"
    },
    {
        name: "",
        selector: "action",
        width: "17.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const KategoriKejadianTable = props => {
    return (
        <TableBootstrap
            keyField={props.ref_title}
            empty={`Belum ada ${props.ref_title.toLowerCase()}, klik tombol + untuk menambahkan referensi baru`}
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KategoriKejadianTable;
