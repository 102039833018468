import React from "react";

const RolePortalOptions = props => {
    return props.list.map(item => (
        <option key={item.portal_id} value={item.portal_id}>
            {item.portal_nm}
        </option>
    ));
};

export default RolePortalOptions;
