import React, { useState, Component } from "react";
import moment from "moment";
// komponen
// komponen
import { Content, ButtonAdd, BoxCollapse, Button, ContentManage} from "../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../components/Alert";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import qs from 'qs';
import Dates from "../../utils/Dates";
// utils
import GetPrivate from "../../utils/Connection";

import image_placeholder from "../../resources/img/placeholder.png";

import TileManagemen from "./card_tile";
import StastikManagemen from "./card_management";
import PieKejadian from "./pie_kejadian";
import PieKeLaka from "./pie_laka";
import EventLive from "./event_live";
import EventLiveAll from "./event_live_all";
import EventMap from "./event_map";
import EventPenanganan from "./event_penanganan";
import EventRatarata from "./event_rata";
import EventTiba from "./event_tiba";
import EventPen from "./event_pen";
import LakaBujt from "./laka_bujt";
import ProfilLaka from "./profil_laka";
import Search from "./search";
import SearchGrafik from "./search_grafik";
import SearchLaka from "./search_laka";


const ref_title = "Laporan Event Customer";
const ref_url = "customer/member";

const today = moment();
    const start_date = moment(today)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    const end_date = moment(today).format("YYYY-MM-DD");
    const current_month = moment(today).format("MM")
    const currentYear = new Date().getFullYear();

    // tahun
    const previousYear =  currentYear-1;
    const previousYear2 =  previousYear-1;
    const previousYear3 =  previousYear2-1;
    const previousYear4 =  previousYear3-1;
    var month = [ "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember" ];
    if (current_month < 10) {    
        var monthIndex = moment().month(current_month[1]-1).format("M");
    } else {
        var monthIndex = moment().month(current_month -1).format("M");         
    }
     const monthName = month[monthIndex-1];


class Ref extends Component {
    constructor(props) {
        super(props);

        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1 < 10 ? '0' + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1)) + '-01';

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;
        // state
        this.state = {
            loading: false,
            modal: false,
            mode: "",
            start: start,
            end: end,
            bulan: current_month,
            bulan_name: monthName,
            tahun: currentYear,
            tahun_name: currentYear,
            periode: 'Bulan',
            periode_name: 'Bulan',
            ruas_id: "1",
            item: null,
            list: [],
            mhi: [],
            listPie: [],
            listPie1: [],
            listPie2: [],
            listLaka: [],
            listLaka2: [],
            listLaka3: [],
            listLaka4: [],
            bujt_id:"1",
            bujt_name:"MHI | Jombang - Mojokerto",
            bujt: bujt,
            bujt2:[],
            bujt3:[],
            name: "React",
            avg_rt: "",
            showHideDemo1: false,
            Bulan: true,
            Tanggal: false,
            showHideDemo2: true,
            showHideDemo3: false,
            showHideDemo4: false,
            list_periode :  [
                { value: 'Bulan', label: 'Bulan' },
                { value: 'Tanggal', label: 'Tanggal' },
              ],
            list_tahun :  [
                { value: currentYear, label: currentYear },
                { value: previousYear, label: previousYear },
                { value: previousYear2, label: previousYear2},
                { value: previousYear3, label: previousYear3},
                { value: previousYear4, label: previousYear4}
              ],
            list_bulan :  [
                { value: "01", label: "Januari" },
                { value: "02", label: "Februari"},
                { value: "03", label: "Maret"},
                { value: "04", label: "April"},
                { value: "05", label: "Mei"},
                { value: "06", label: "Juni"},
                { value: "07", label: "Juli"},
                { value: "08", label: "Agustus"},
                { value: "09", label: "September"},
                { value: "10", label: "Oktober"},
                { value: "11", label: "November"},
                { value: "12", label: "Desember"}
              ],
            
        };
         // bind
         this.handleChangeStart = this.handleChangeStart.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.handleChangeSelect = this.handleChangeSelect.bind(this);
         this.showDetail         = this.showDetail.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadDataPieKejadian();
        this.loadDataPieLaka();
        // this.loadDataPenanganan();
        // this.loadDataRata();
        this.loadRef();
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api

        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
            GetPrivate(this, `user/dashboard/total_comparison_event?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=1`)
        ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            mhi: result[0].data,
                        },
                    );
                    
                })
                .catch(error => {});
        });
    }

    loadDataPieKejadian() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
            GetPrivate(this, `user/dashboard/total_gangguan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&gangguan=2&bujt=${this.state.ruas_id}`),
            GetPrivate(this, `user/dashboard/total_gangguan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&gangguan=1&bujt=${this.state.ruas_id}`),
            GetPrivate(this, `user/dashboard/total_gangguan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&gangguan=3&bujt=${this.state.ruas_id}`)
        ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            listPie: result[0].data,
                            listPie1: result[1].data,
                            listPie2: result[2].data,
                        }
                    );
                    
                })
                .catch(error => {});
        });
    }

    loadDataPieLaka() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
            GetPrivate(this, `user/dashboard/total_laka_year?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`),
            GetPrivate(this, `user/dashboard/total_ringan_year?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`),
            GetPrivate(this, `user/dashboard/total_berat_year?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`),
            GetPrivate(this, `user/dashboard/total_mati_year?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`)
        ])
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            listLaka: result[0].data,
                            listLaka2: result[1].data,
                            listLaka3: result[2].data,
                            listLaka4: result[3].data,
                        },
                    );
                    
                })
                .catch(error => {});
        });
    }

    loadDataPenanganan() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, `user/dashboard/total_rata_aktif?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/total_rata_respon?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/total_rata_tiba?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/total_rata_penanganan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/bujt?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
            ])
                .then(result => {
                    let rata_aktif =[]
                    let pen_aktif =[]
                    let tiba =[]
                    let pen =[]
                    let bujt2 =[]

                    for (let i in result[0].data) {                  
                        // rata_aktif = result[0].data[i].rata_aktif
                        rata_aktif.push(result[0].data[i].total)                     
                    }
                    for (let i in result[1].data) {                  
                        // pen_aktif = result[1].data[i].pen_aktif  
                        pen_aktif.push(result[1].data[i].total)                      
                    }
                    
                    for (let i in result[2].data) {                  
                        // tiba = result[2].data[i].tiba  
                        tiba.push(result[2].data[i].total)                     
                    }

                    for (let i in result[3].data) {                  
                        // total = result[3].data[i].total  
                        pen.push(result[3].data[i].total)                      
                    }

                    for (let i in result[4].data) {                  
                       bujt2.push(result[4].data[i].nama)                       
                        // bujt = result[4].data[i].nama
                    }
                    

                    this.setState(
                        {
                            loading: false,
                            rata_aktif: rata_aktif,
                            pen_aktif: pen_aktif,
                            tiba: tiba,
                            pen: pen,
                            bujt2: bujt2,

                        }
                    
                    );
                    
                })
                .catch(error => {});
        });
    }

    showDetail(item = null) {
        if (item != null) {
            
            this.setState({item: item }, function() {
                this.setState({ modal: !this.state.modal });
            }); 
        } else {
            this.setState({ modal: !this.state.modal });
        }
        
    }

    loadDataRata() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, `user/dashboard/total_rata_respon?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/total_rata_tiba?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/total_rata_penanganan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
                GetPrivate(this, `user/dashboard/bujt?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}`),
            ])
                .then(result => {
                    let respon =[]
                    let tiba =[]
                    let penanganan =[]
                    let bujt3 =[]

                    for (let i in result[0].data) {                  
                        // respon = result[0].data[i].respon
                        respon.push(result[0].data[i].total)                     
                    }
                    for (let i in result[1].data) {                  
                        // tiba = result[1].data[i].tiba  
                        tiba.push(result[1].data[i].total)                      
                    }
                    
                    for (let i in result[2].data) {                  
                        // penanganan = result[2].data[i].penanganan  
                        penanganan.push(result[2].data[i].total)                     
                    }

                    for (let i in result[3].data) {                  
                       bujt3.push(result[3].data[i].nama)                       
                        // bujt = result[3].data[i].nama
                    }
                    

                    this.setState(
                        {
                            loading: false,
                            respon: respon,
                            tiba: tiba,
                            penanganan: penanganan,
                            bujt3: bujt3,

                        }
                    
                    );
                    
                })
                .catch(error => {});
        });
    }

    loadDataSummary() {
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, `user/dashboard/grafik_laka_bar?start=${this.state.start}&end=${this.state.end}`),
                GetPrivate(this,  `user/dashboard/bujt?start=${this.state.start}&end=${this.state.end}`),
            ])
                .then(result => {
                    let total_ringan =[]
                    let total_berat =[]
                    let total_mati =[]
                    let total =[]
                    let lokasi_name =[]

                    for (let i in result[0].data.total_ringan) {                  
                        total_ringan.push(result[0].data.total_ringan[i].total_ringan)                     
                    }
                    for (let i in result[0].data.total_berat) {                  
                        total_berat.push(result[0].data.total_berat[i].total_berat)                      
                    }
                    
                    for (let i in result[0].data.total_mati) {                  
                        total_mati.push(result[0].data.total_mati[i].total_mati)                     
                    }

                    for (let i in result[0].data.total_laka) {                  
                        total.push(result[0].data.total_laka[i].total)                      
                    }

                    for (let i in result[1].data) {                  
                       lokasi_name.push(result[1].data[i].nama)                       
                        // lokasi_name = result[4].data[i].nama
                    }
                    

                    this.setState(
                        {
                            loading: false,
                            total_ringan: total_ringan,
                            total_berat: total_berat,
                            total_mati: total_mati,
                            total_semua: total,
                            lokasi_name: lokasi_name,

                        }
                    );
                    
                })
                .catch(error => {});
        });
    }

    loadDataProfilLaka() {
        console.log('tes_count',this.state.segemen3)
        this.setState({ loading: true }, function() {
            // panggil get api
            Promise.all([
                GetPrivate(this, `user/dashboard/profil_laka?start=${this.state.start}&end=${this.state.end}&ruas_id=128`),
                GetPrivate(this, `user/dashboard/profil_laka?start=${this.state.start}&end=${this.state.end}&ruas_id=129`),
                GetPrivate(this, `user/dashboard/profil_laka?start=${this.state.start}&end=${this.state.end}&ruas_id=1`),
            ])
                .then(result => {
                    // merak
                    let minutes = Math.ceil(result[0].data.rt_current / 60)
                    let seconds = result[0].data.rt_current % 60
                    let rt_minute_Merak = parseFloat(result[0].data.rt_current).toFixed(2) 
                    let avg_rt_convert_merak = minutes + ' menit ' + seconds + ' detik'+ ' ' + '(' + (rt_minute_Merak) + ')'

                    let minutes2 = Math.ceil(result[0].data.ht_current  / 60)
                    let seconds2 = result[0].data.ht_current  % 60
                    let ht_minute_merak = parseFloat(result[0].data.ht_current).toFixed(2)
                    let avg_ht_convert_merak = minutes2 + ' menit ' + seconds2 + ' detik' + ' ' + '(' + (ht_minute_merak) + ')'
                    
                    let laka_bulan_merak = Math.ceil(result[0].data.laka_month / result[0].data.laka_day_current) || 0
                    let laka_ytd_merak = Math.ceil(result[0].data.laka_ytd / result[0].data.laka_ytd_jml_day) || 0
                    let laka_periode_prev_merak =  (result[0].data.laka_month / result[0].data.laka_day_current) / (result[0].data.laka_past_month / result[0].data.laka_past_day)

                    let laka_count_Merak = laka_periode_prev_merak || 0 

                    // cikopo
                    let minutes4 = Math.ceil(result[1].data.rt_current / 60)
                    let seconds4 = result[1].data.rt_current % 60
                    let rt_minute_cikopo = parseFloat(result[1].data.rt_current).toFixed(2)
                    let avg_rt_convert_cikopo = minutes4 + ' menit ' + seconds4 + ' detik'  + ' ' + '(' + (rt_minute_cikopo) + ')'

                    let minutes5 = Math.ceil(result[1].data.ht_current  / 60)
                    let seconds6 = result[1].data.ht_current  % 60
                    let ht_minute_cikopo = parseFloat(result[1].data.ht_current).toFixed(2)
                    let avg_ht_convert_cikopo = minutes5 + ' menit ' + seconds6 + ' detik' + ' ' + '(' + (ht_minute_cikopo) + ')'
                    
                    let laka_bulan_cikopo = Math.ceil(result[1].data.laka_month / result[1].data.laka_day_current) || 0
                    let laka_ytd_cikopo = Math.ceil(result[1].data.laka_ytd / result[1].data.laka_ytd_jml_day) || 0
                    let laka_periode_prev_cikopo = (result[1].data.laka_month / result[1].data.laka_day_current) / (result[1].data.laka_past_month / result[1].data.laka_past_day)

                    let laka_count_cikopo = laka_periode_prev_cikopo || 0 

                    // jombang
                    let minutes6 = Math.floor(result[2].data.rt_current / 60)
                    let seconds7 = result[2].data.rt_current % 60
                    let rt_minute_jombang = parseFloat(result[2].data.rt_current).toFixed(2)
                    let avg_rt_convert_jombang = minutes6 + ' menit ' + seconds7 + ' detik' + ' ' + '(' + (rt_minute_jombang) + ')'

                    let minutes8 = Math.floor(result[2].data.ht_current  / 60)
                    let seconds9 = result[2].data.ht_current  % 60
                    let ht_minute_jombang = parseFloat(result[2].data.ht_current).toFixed(2)
                    let avg_ht_convert_jombang = minutes8 + ' menit ' + seconds9 + ' detik' + ' ' + '(' + (ht_minute_jombang) + ')'
                    
                    let laka_bulan_jombang = Math.ceil(result[2].data.laka_month / result[2].data.laka_day_current) || 0
                    let laka_ytd_jombang = Math.ceil(result[2].data.laka_ytd / result[2].data.laka_ytd_jml_day) || 0
                    let laka_periode_prev_jombang = parseFloat((result[2].data.laka_month / result[2].data.laka_day_current) / (result[2].data.laka_past_month / result[2].data.laka_past_day)).toFixed(2) 
                    let perbandingan_jombang1 = (result[2].data.laka_month / result[2].data.laka_day_current)
                    let perbandingan_jombang2 = (result[2].data.laka_past_month / result[2].data.laka_past_day)
                    let laka_count_jombang = laka_periode_prev_jombang || 0 
                    this.setState(
                        {
                            loading: false,
                            rt_current: avg_rt_convert_merak,
                            ht_current: avg_ht_convert_merak,
                            laka_bulan: laka_bulan_merak,
                            segemen: result[0].data.penyebab_laka,
                            laka_ytd: laka_ytd_merak,
                            laka_count: laka_count_Merak,
                            // cikopo
                            rt_current2: avg_rt_convert_cikopo,
                            ht_current2: avg_ht_convert_cikopo,
                            laka_bulan2: laka_bulan_cikopo,
                            segemen2: result[1].data.penyebab_laka,
                            laka_ytd2: laka_ytd_cikopo,
                            laka_count2: laka_count_cikopo, 
                            // jombang
                            rt_current3: avg_rt_convert_jombang,
                            ht_current3: avg_ht_convert_jombang,
                            laka_bulan3: laka_bulan_jombang,
                            segemen3: result[2].data.penyebab_laka,
                            laka_ytd3: laka_ytd_jombang,
                            laka_count3: laka_count_jombang, 
                            perbandingan_jombang1: perbandingan_jombang1, 
                            perbandingan_jombang2: perbandingan_jombang2, 

                        }
                    );
                    
                    
                })
                .catch(error => {});
        });
    }

    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                }
            );
        }).catch(error => {});
    }

       // handle change
       handleChangeStart(field, value) {
        this.setState({ [field]: value });
        if(this.state.showHideDemo2 == true){
        this.loadDataPieKejadian();
        this.loadDataPieLaka();
        }
        if(this.state.showHideDemo1 == true){
            this.loadDataPenanganan();
            // this.loadDataRata();
        }
        if(this.state.showHideDemo4 == true){
            this.loadDataSummary();
            this.loadDataProfilLaka();
        }
        this.loadData();
        
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1 < 10 ? '0' + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1)) + '-01';

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        switch (value) {
            case "Bulan":
                this.setState({ Tanggal: !this.state.Tanggal });
                this.setState({ Bulan: this.state.Tanggal });  
                this.setState({ start: '' });
                this.setState({ end: '' });
                this.setState({ bulan: current_month });
                this.setState({ tahun: currentYear }); 
                break; 
                case "Tanggal":
                    this.setState({ Bulan: !this.state.Bulan });
                    this.setState({ Tanggal: this.state.Bulan });
                    this.setState({ bulan: '' });
                    this.setState({ tahun: '' });    
                    this.setState({ start: start });
                    this.setState({ end: end });    
             
              break;
            default:
          }
        if(this.state.showHideDemo2 == true){
            this.loadDataPieKejadian();
            this.loadDataPieLaka();
            }
        if(this.state.showHideDemo1 == true){
            this.loadDataPenanganan();
            // this.loadDataRata();
        }
        if(this.state.showHideDemo4 == true){
            this.loadDataSummary();
            this.loadDataProfilLaka();
        }
        this.loadData();
    }


    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                if(this.state.showHideDemo2 == true){
                    this.loadDataPieKejadian();
                    this.loadDataPieLaka();
                    }
                if(this.state.showHideDemo1 == true){
                    this.loadDataPenanganan();
                    // this.loadDataRata();
                }
                if(this.state.showHideDemo4 == true){
                    this.loadDataSummary();
                    this.loadDataProfilLaka();
                }
                this.loadData();
            }
        );
    }

    hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ showHideDemo1: !this.state.showHideDemo1 });
            this.setState({ showHideDemo2: this.state.showHideDemo1 });
            this.setState({ showHideDemo3: this.state.showHideDemo1 });
            this.setState({ showHideDemo4: this.state.showHideDemo1 });
            this.loadDataPenanganan();
            // this.loadDataRata();
            break; 
          case "showHideDemo2":
            this.setState({ showHideDemo2: !this.state.showHideDemo2 });
            this.setState({ showHideDemo1: this.state.showHideDemo2 });
            this.setState({ showHideDemo3: this.state.showHideDemo2 });
            this.setState({ showHideDemo4: this.state.showHideDemo2 });
            break;
            case "showHideDemo3":
                this.setState({ showHideDemo3: !this.state.showHideDemo3 });
                this.setState({ showHideDemo1: this.state.showHideDemo1 });
                this.setState({ showHideDemo2: this.state.showHideDemo1 });
                this.setState({ showHideDemo4: this.state.showHideDemo1 });
                break;
            case "showHideDemo4":
                    this.setState({ showHideDemo4: !this.state.showHideDemo4 });
                    this.setState({ showHideDemo1: this.state.showHideDemo3 });
                    this.setState({ showHideDemo2: this.state.showHideDemo3 });
                    this.setState({ showHideDemo3: this.state.showHideDemo3 });
                    this.loadDataSummary();
                    this.loadDataProfilLaka();
                    break;
          default:
        }
      }

      onClick () {
        if(this.state.start != '' && this.state.end != '')
        {
        this.setState({ start: '' });
        this.setState({ end: '' });
        }

        if(this.state.start != '' && this.state.end != '' && this.state.bulan != '' && this.state.tahun != '')
        {
        this.setState({ bulan: '' });
        this.setState({ tahun: '' });
        }
    }
    // render
    render() {
        const { showHideDemo1, showHideDemo2, showHideDemo3, showHideDemo4 } = this.state;
        const iframe = '<iframe height="265" style="width: 100%;" scrolling="no" title="" src="https://sigi.pu.go.id/portalpupr/apps/dashboards/ad691982b770462d8e236f8ca7e450f4" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
        const combineBulan = this.state.tahun + '-' + this.state.bulan + '-' + '01';
        const combineTahun = this.state.tahun + '-' + this.state.bulan + '-' + '30';
        return (
            <ContentManage
                // title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
                zoom="0.61"
            >
                <div className="row m-2" style={{marginBottom:'-29px'}}>
                    <BoxCollapse open={true} topColor="#FC511F"
                    title="">
                    <div className="col-md-10">
                     <EventMap></EventMap>
                     
                    </div>
                    <div className="col-md-2" style={{fontSize:'22px'}}>
                    <div className="row mb-2"> 
                    </div>
                    <div className="row mb-2"> 
                    </div>
                    <div className="row mb-2"> 
                    </div>
                    <div className="row mb-2"> 
                    </div>
                    <div className="row"> 
                        <div className="col-md-2">
                        {this.state.mhi != '0' &&
                        <i className="fa fa-circle blink" style={{color:'red', fontSize:'20px'}}></i>
                        }
                        {this.state.mhi == '0' &&
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        }
                        </div>
                        <div className="col-md-2">
                        MHI
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                              {this.state.mhi} Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        MMS
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        JSM
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        MLJ
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        LMS
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        MTN
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        TMJ
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>
                    <div className="row mt-2"> 
                        <div className="col-md-2">
                        <i className="fa fa-circle" style={{color:'#D1D3D4', fontSize:'20px'}}></i>
                        </div>
                        <div className="col-md-2">
                        JPT
                        </div>
                        <div className="col-md-1">
                        :
                        </div>
                        <div className="col-md-6">
                             0 Event Aktif
                        </div>
                    </div>  
                    </div>
                </BoxCollapse>
                </div>
            {showHideDemo2 &&
            <div className="row m-2">
                            <BoxCollapse open={true} topColor="#FFFFFF" margin="203px" margin2="17px"
                        title="Live Penanganan">
                            <button onClick={() => this.hideComponent("showHideDemo3")} className="text-right m-4" style={{color: '#EE542F', padding: '0',
        border: 'none',
        background: 'none'}}>
                    Lihat Semua
                </button>
                    <EventLive
                    bujt_id={this.state.ruas_id}
                    start={this.state.start}
                    end={this.state.end}
                    bulan={this.state.bulan}
                    tahun={this.state.tahun}
                    url={`event/event/event_live?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`}
                    />
                </BoxCollapse>
                </div>
    }
            {showHideDemo3 &&
            <div className="row m-2 mt-2">
                    <BoxCollapse open={true} topColor="#FFFFFF" margin="203px" margin2="17px"
                    title="Penanganan Aktif">
                    <div className="row">
                    <div className="col-md-2">
            </div>
            <div className="col-lg-9"></div>
            <div className="col-lg-1">
                <Button
                   className={showHideDemo2  ? "btn-xs btn-custome2 active" : "btn-xs btn-custome2 "}
                   onClick={() => this.hideComponent("showHideDemo2")}
                >
                    Kembali
                </Button>
            </div>
            </div>
                    <EventLiveAll
                        bujt_id={this.state.ruas_id}
                        start={this.state.start}
                        end={this.state.end}
                        bulan={this.state.bulan}
                        tahun={this.state.tahun}
                        url={`event/event/event_live?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}&limit=1`}
                        />
                    
                        
                        </BoxCollapse>
            </div>
    }
            {showHideDemo2 &&
                    <div className="row m-2">
                    <BoxCollapse open={true} topColor="#F58220" margin2="17px"
                    title="Informasi Event"
                    >
                        <div className="row">
                    <div className="col-md-2">
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-2">
           
                <Button
                    className={showHideDemo2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo2")}
                >
                    Regular &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
            <Button
                    className={showHideDemo1  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo1")}
                >
                    Grafik Event
                </Button>
                <Button
                    className={showHideDemo4  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo4")}
                >
                    Grafik Laka
                </Button>
            </div>
            </div>
                        <Search
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        bulan={this.state.bulan}
                        Bulan={this.state.Bulan}
                        Tanggal={this.state.Tanggal}
                        bulan_name={this.state.bulan_name}
                        tahun={this.state.tahun}
                        tahun_name={this.state.tahun_name}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name} 
                        periode={this.state.periode}
                        periode_name={this.state.periode_name}
                        list_ruas={this.state.list_ruas}
                        list_bulan={this.state.list_bulan}
                        list_tahun={this.state.list_tahun}
                        list_periode={this.state.list_periode}
                    />
                    
                <div className="row m-2" style={{background: '#F58220', borderRadius: '16px'}}>
               <div className="col-xs-4">
               <TileManagemen
                   background="#F58220"
                   title="TOTAL PENANGANAN AKTIF"
                   url={`user/dashboard/total_event?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`}
                   icon="fa fa-pie-chart"
                   titleStyle="#E2E8CE"
                   linkTo={`report/handling_response/lengkap?bujt=${this.state.ruas_id}&start=${combineBulan}&end=${combineTahun}`}
                   height=""
               />
               </div>
               <div className="col-xs-7 m-2" style={{background: '#FFF4F0', borderRadius: '16px'}}>
               <div className="col-xs-4 mt-2 mb-2">
               <StastikManagemen
                   title="Rata-rata Respon Waktu Gangguan"
                   spasi="mt-2 mb-2"
                   url={`user/dashboard/avg_respon?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`}
               />
               </div>
               <div className="col-xs-4 mt-2 mb-2">
               <StastikManagemen
                   title="Respon Waktu Tiba Lokasi"
                   spasi="mt-2 mb-2"
                   url={`user/dashboard/avg_tiba?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`}
               /> 
               </div>
               <div className="col-xs-4 mt-2 mb-2">
               <StastikManagemen
                   title="Rata-rata Waktu Penanganan"
                   spasi="mt-2 mb-2"
                   url={`user/dashboard/avg_penanganan?start=${this.state.start}&end=${this.state.end}&month=${this.state.bulan}&year=${this.state.tahun}&bujt=${this.state.ruas_id}`}
               />
               </div>
               </div>
               
           </div>   
           <div className="row">
           <div className="col-md-2">
            </div>
            <div className="col-md-5">
            <PieKejadian
             listPie={this.state.listPie}
             listPie1={this.state.listPie1}
             listPie2={this.state.listPie2}
           />
            </div>
            <div className="col-md-5">
            <PieKeLaka
             listLaka={this.state.listLaka}
             listLaka2={this.state.listLaka2}
             listLaka3={this.state.listLaka3}
             listLaka4={this.state.listLaka4}
            //  linkTo={`/report/lakalantas/ringan?bujt=all&start=${this.state.bulan}&end=${this.state.tahun}`}
            //  linkTo2={`/report/lakalantas/berat?bujt=all&start=${this.state.bulan}&end=${this.state.tahun}`}
            //  linkTo3={`/report/lakalantas/meninggal?bujt=all&start=${this.state.bulan}&end=${this.state.tahun}`}
            //  linkTo4={`/report/lakalantas/lengkap?bujt=all&start=${this.state.bulan}&end=${this.state.tahun}`}
           />
            </div>
           </div>
                        </BoxCollapse>
                        </div>
            
                    
                    
     }

                    {showHideDemo1 &&
                    <div className="row m-2 mt-2"> 
                    <BoxCollapse open={true} topColor="#CF0A2C" align="-webkit-center" margin2="17px"
                    title="Informasi Event Semua BUJT">
                        <div className="row">
                    <div className="col-md-2">
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-2">
            <Button
                    className={showHideDemo2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo2")}
                >
                    Regular &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
            <Button
                    className={showHideDemo1  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo1")}
                >
                    Grafik Event
                </Button>
                <Button
                    className={showHideDemo4  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo4")}
                >
                    Grafik Laka
                </Button>
            </div>
            </div>
                         <SearchGrafik
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        Bulan={this.state.Bulan}
                        Tanggal={this.state.Tanggal}
                        bulan={this.state.bulan}
                        bulan_name={this.state.bulan_name}
                        tahun={this.state.tahun}
                        periode={this.state.periode}
                        periode_name={this.state.periode_name}
                        tahun_name={this.state.tahun_name}
                        list_bulan={this.state.list_bulan}
                        list_tahun={this.state.list_tahun}
                        list_periode={this.state.list_periode}
                    />
                    <div className="row m-2"> 
                    <div className="col-lg-6">
                        <EventPenanganan
                        rata_aktif={this.state.rata_aktif}
                        bujt2={this.state.bujt2}
                    />  
                        </div>
                        
                <div className="col-lg-6">
                        <EventRatarata
                     pen_aktif={this.state.pen_aktif}
                     bujt2={this.state.bujt2}
                    />  
                        </div>
                        </div>

                        <div className="row"> 
                    <div className="col-lg-6">
                        <EventTiba
                        tiba={this.state.tiba}
                        bujt2={this.state.bujt2}
                    />  
                        </div>
                        
                <div className="col-lg-6">
                <EventPen
                        pen={this.state.pen}
                        bujt2={this.state.bujt2}
                    />  
                        </div>
                        </div>
                        </BoxCollapse>
                        </div>
                }


{showHideDemo4 &&
                    <div className="row m-2 mt-2"> 
                    <BoxCollapse open={true} topColor="#CF0A2C" align="-webkit-center" margin2="17px"
                    title="Informasi Laka Semua BUJT">
                        <div className="row">
                    <div className="col-md-2">
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-2">
            <Button
                    className={showHideDemo2  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo2")}
                >
                    Regular &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
            <Button
                    className={showHideDemo1  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo1")}
                >
                    Grafik Event
                </Button>
                <Button
                    className={showHideDemo4  ? "btn-xs btn-custome2 ml-3 active" : "btn-xs btn-custome2 ml-3"}
                    onClick={() => this.hideComponent("showHideDemo4")}
                >
                    Grafik Laka
                </Button>
            </div>
            </div>
                         <SearchLaka
                         handleChange={this.handleChange}
                         handleChangeStart={this.handleChangeStart}
                         handleChangeSelect={this.handleChangeSelect}
                         start={this.state.start}
                         end={this.state.end}
                    />
                    <div className="row m-2"> 
                    <div className="col-lg-12">
                    <LakaBujt
                        total_ringan={this.state.total_ringan}
                        total_berat={this.state.total_berat}
                        total_mati={this.state.total_mati}
                        total_semua={this.state.total_semua}
                        lokasi_name={this.state.lokasi_name}
                    />  
                    
                        </div>
                        
                        </div>
                        <div className="row m-2"> 
                        <div className="col-lg-12">
                    <ProfilLaka
                    start={this.state.start}
                    end={this.state.end}
                     rt_current={this.state.rt_current}
                     ht_current={this.state.ht_current}
                     laka_bulan={this.state.laka_bulan}
                     segemen={this.state.segemen}
                     laka_ytd={this.state.laka_ytd}
                     laka_count={this.state.laka_count}
                     rt_current2={this.state.rt_current2}
                     ht_current2={this.state.ht_current2}
                     laka_bulan2={this.state.laka_bulan2}
                     segemen2={this.state.segemen2}
                     laka_ytd2={this.state.laka_ytd2}
                     laka_count2={this.state.laka_count2}
                     rt_current3={this.state.rt_current3}
                     ht_current3={this.state.ht_current3}
                     segemen3={this.state.segemen3}
                     laka_ytd3={this.state.laka_ytd3}
                     laka_bulan3={this.state.laka_bulan3}
                     laka_count3={this.state.laka_count3}
                     perbandingan_jombang1={this.state.perbandingan_jombang1}
                     perbandingan_jombang2={this.state.perbandingan_jombang2}
                    />  
                    
                        </div>
                        </div>

                        </BoxCollapse>
                        </div>
                }
            </ContentManage>
        );
    }
}

export default Ref;
