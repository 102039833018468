import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import ReportTable from "./table";
import ReportSearch from "./search";
// import ReportModal from "./modal";

class ReportGantiRugi extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1 < 10 ? '0' + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1)) + '-01';

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            total: 0,
            url: "",
            loading: false,
            modal: false,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            start: start,
            end: end,
            bujt: bujt,
            title:'Laporan Rekap Ganti Rugi'
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
    }
    loadData() {
        const formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        })
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
            GetPrivate(this, `laporan/rekapgantirugi/all?start=${this.state.start}&end=${this.state.end}`), 
            GetPrivate(this, `laporan/rekapgantirugi/korban_lr?start=${this.state.start}&end=${this.state.end}`), 
            GetPrivate(this, `laporan/rekapgantirugi/korban_lb?start=${this.state.start}&end=${this.state.end}`), 
            GetPrivate(this, `laporan/rekapgantirugi/korban_md?start=${this.state.start}&end=${this.state.end}`), 
            GetPrivate(this, `laporan/rekapgantirugi/bayar?start=${this.state.start}&end=${this.state.end}`), 
            GetPrivate(this, `laporan/rekapgantirugi/belum?start=${this.state.start}&end=${this.state.end}`), 
        
        ])
            .then(result => {
                let total = 0
                let korban = result[1].data + result[2].data + result[3].data
                for (let i in result[0].data) {
                    total += parseFloat(result[0].data[i].total_harga || 0)
                }
                this.setState({
                    total: formatter.format(total),
                    loading: false,
                    list: result[0].data,
                    list3: formatter.format(result[4].data),
                    list4: formatter.format(result[5].data),
                    korban: korban
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
        this.setState({ modal: !this.state.modal, id: id });
    }
    downloadExcel () {
        const token = Session.getSession("session__jmto_admin");
        const token_key = Config.webKey;
        axios({
            url: `${Config.endPoint}/laporan/rekapgantirugi/export?start=${this.state.start}&end=${this.state.end}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: token,
                'X-API-KEY': token_key,
                'X-Apps': 'web'
            },
            crossdomain: true
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'laporan-rekap-ganti-rugi.xls')
            document.body.appendChild(link)
            link.click()
        })
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <ReportSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <a onClick={this.downloadExcel} className="btn btn-success">
                                    <i className="fa fa-download mr-1"></i>Download Excel
                                </a>
                            </div>
                            <div className="col-md-6 text-right" style={{ fontSize: '18px' }}>
                                <b>Total Ganti Rugi: </b>{ this.state.total } <br></br>
                                <b>Total Korban: </b>{ this.state.korban }<br></br>
                                <b>Total Sudah Dibayar: </b>{ this.state.list3 }<br></br>
                                <b>Total Belum Dibayar: </b>{ this.state.list4 } 
                            </div>
                        </div>
                        <ReportTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {/* {this.state.modal && (
                    <ReportModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        id={this.state.id}
                        title={this.state.title}
                    />
                )} */}
            </Content>
        );
    }
}

export default ReportGantiRugi;
