export default {
    bln_long: {
        "01": "Januari",
        "02": "Februari",
        "03": "Maret",
        "04": "April",
        "05": "Mei",
        "06": "Juni",
        "07": "Juli",
        "08": "Agustus",
        "09": "September",
        "10": "Oktober",
        "11": "November",
        "12": "Desember"
    },
    bln_short: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "Mei",
        "06": "Jun",
        "07": "Jul",
        "08": "Agu",
        "09": "Sep",
        "10": "Okt",
        "11": "Nov",
        "12": "Des"
    },
    hari_long: {
        "0": "Ahad",
        "1": "Senin",
        "2": "Selasa",
        "3": "Rabu",
        "4": "Kamis",
        "5": "Jumat",
        "6": "Sabtu"
    },
    hari_short: {
        "0": "Min",
        "1": "Sen",
        "2": "Sel",
        "3": "Rab",
        "4": "Kam",
        "5": "Jum",
        "6": "Sab"
    },
    hari_long_array: [
        { key: "0", label: "Minggu" },
        { key: "1", label: "Senin" },
        { key: "2", label: "Selasa" },
        { key: "3", label: "Rabu" },
        { key: "4", label: "Kamis" },
        { key: "5", label: "Jumat" },
        { key: "6", label: "Sabtu" }
    ],
    jam_array: [
        { key: "00", label: "00" },
        { key: "01", label: "01" },
        { key: "02", label: "02" },
        { key: "03", label: "03" },
        { key: "04", label: "04" },
        { key: "05", label: "05" },
        { key: "06", label: "06" },
        { key: "07", label: "07" },
        { key: "08", label: "08" },
        { key: "09", label: "09" },
        { key: "10", label: "10" },
        { key: "11", label: "11" },
        { key: "12", label: "12" },
        { key: "13", label: "13" },
        { key: "14", label: "14" },
        { key: "15", label: "15" },
        { key: "16", label: "16" },
        { key: "17", label: "17" },
        { key: "18", label: "18" },
        { key: "19", label: "19" },
        { key: "20", label: "20" },
        { key: "21", label: "21" },
        { key: "22", label: "22" },
        { key: "23", label: "23" }
    ],
    menit_array: [
        { key: "00", label: "00" },
        { key: "05", label: "05" },
        { key: "10", label: "10" },
        { key: "15", label: "15" },
        { key: "20", label: "20" },
        { key: "25", label: "25" },
        { key: "30", label: "30" },
        { key: "35", label: "35" },
        { key: "40", label: "40" },
        { key: "45", label: "45" },
        { key: "50", label: "50" },
        { key: "55", label: "55" }
    ],
    formatLongDate: function(tanggal) {
        let str = tanggal.split("-");
        str = str[2] + " " + this.bln_long[str[1]] + " " + str[0];
        return str;
    },
    formatFullLongDate: function(tanggal) {
        let strBln = tanggal.split(" ");
        let str = strBln[0].split("-");
        str =
            str[2] +
            " " +
            this.bln_long[str[1]] +
            " " +
            str[0] +
            " " +
            strBln[1].substr(0, 5);
        return str;
    },
    formatFullLongDateWithoutTime: function(tanggal) {
        let strBln = tanggal.split(" ");
        let str = strBln[0].split("-");
        str = str[2] + " " + this.bln_long[str[1]] + " " + str[0];
        return str;
    },
    formatShortDate: function(tanggal) {
        let str = tanggal.split("-");
        str = str[2] + " " + this.bln_short[str[1]] + " " + str[0];
        return str;
    },
    formatFullShortDate: function(tanggal) {
        let strBln = tanggal.split(" ");
        let str = strBln[0].split("-");
        str =
            str[2] +
            " " +
            this.bln_short[str[1]] +
            " " +
            str[0] +
            " " +
            strBln[1].substr(0, 5);
        return str;
    },
    getLongMonth: function(month) {
        let str = month + 1 > 9 ? month + 1 : "0" + (month + 1);
        return this.bln_long[str];
    },
    // hari ini
    getTodayMonth: function() {
        let today = new Date().getMonth();
        let str = today + 1 > 9 ? today + 1 : "0" + (today + 1);
        return this.bln_long[str];
    },
    formatLikeToday: function(tanggal) {
        let today = new Date(tanggal);
        let date = today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        let day = today.getDay();
        return (
            this.hari_long[day] +
            ", " +
            date +
            " " +
            this.bln_long[month] +
            " " +
            year
        );
    },
    formatLikeTodayWithoutDay: function(tanggal) {
        let today = new Date(tanggal);
        let date = today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        return date + " " + this.bln_long[month] + " " + year;
    },
    getFormatToday: function() {
        let today = new Date();
        let date = today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        let day = today.getDay();
        return (
            this.hari_long[day] +
            ", " +
            date +
            " " +
            this.bln_long[month] +
            " " +
            year
        );
    },
    getFormatTodayWithoutDay: function() {
        let today = new Date();
        let date = today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        return date + " " + this.bln_long[month] + " " + year;
    },
    getFormatBlnToday: function() {
        let today = new Date();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        return this.bln_long[month] + " " + year;
    },
    getFormatBln: function(tanggal) {
        let tgl = tanggal.split("-");
        return this.bln_long[tgl[1]] + " " + tgl[0];
    },
    getTodayDB: function() {
        let today = new Date();
        let date =
            today.getDate() > 9 ? today.getDate() : "0" + today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        let day = today.getDay();
        return year + "-" + month + "-" + date;
    },
    get7DaysAgo: function() {
        let today = new Date();
        let date =
            today.getDate() > 9 ? today.getDate()-7 : "0" + today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        let day = today.getDay();
        return year + "-" + month + "-" + date;
    },
    getTodayWeekDB: function() {
        let today = new Date();
        let year = today.getFullYear();
        let week = this.getWeekNumber();
        week = week > 9 ? week : "0" + week;
        return year + "-" + week;
    },
    getTodayMonthDB: function() {
        let today = new Date();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let year = today.getFullYear();
        return year + "-" + month;
    },
    getWeekNumber: function() {
        var d = new Date(
            Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
        );
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    },
    getLocaleConfig: function() {
        let bln_long = [];
        for (let x in this.bln_long) {
            bln_long[x - 1] = this.bln_long[x];
        }
        let bln_short = [];
        for (let x in this.bln_short) {
            bln_short[x - 1] = this.bln_short[x];
        }
        let hari_long = [];
        for (let x in this.hari_long) {
            hari_long[x * 1] = this.hari_long[x];
        }
        let hari_short = [];
        for (let x in this.hari_short) {
            hari_short[x * 1] = this.hari_short[x];
        }
        return {
            monthNames: bln_long,
            monthNamesShort: bln_short,
            dayNames: hari_long,
            dayNamesShort: hari_short
        };
    },
    timeToString: function(time) {
        const date = new Date(time);
        return date.toISOString().split("T")[0];
    },
    formatDB: function(date) {
        return date.toISOString().split("T")[0];
    },
    getToday: function() {
        let today = new Date();
        let date =
            today.getDate() > 9 ? today.getDate() : "0" + today.getDate();
        let month =
            today.getMonth() + 1 > 9
                ? today.getMonth() + 1
                : "0" + (today.getMonth() + 1);
        let jam =
            today.getHours() + 1 > 9
                ? today.getHours() + 1
                : "0" + (today.getHours() + 1);
        let menit =
            today.getMinutes() + 1 > 9
                ? today.getMinutes() + 1
                : "0" + (today.getMinutes() + 1);
        let year = today.getFullYear();
        let day = today.getDay();
        return {
            y: year,
            m: month,
            d: date,
            h: jam,
            i: menit,
            day: day,
            hari_long: this.hari_long[day],
            hari_short: this.hari_short[day],
            bln_long: this.bln_long[month],
            bln_short: this.bln_short[month]
        };
    },
    createDateFromDB: function(tgl) {
        let split = tgl.split("-");
        return new Date(split[0], split[1], split[2]);
    },
    getCurrentMaxDate: function() {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    },
    getSelectedMaxDate: function(dates) {
        return new Date(dates.getFullYear(), dates.getMonth() + 1, 0).getDate();
    },
    getSelectedDate: function(dates, full_format = false) {
        const today_dates = new Date();
        // today dengan 00:00
        const today = new Date(
            today_dates.getFullYear(),
            today_dates.getMonth(),
            today_dates.getDate()
        );
        // date
        let date =
            dates.getDate() > 9 ? dates.getDate() : "0" + dates.getDate();
        // month
        let month =
            dates.getMonth() + 1 > 9
                ? dates.getMonth() + 1
                : "0" + (dates.getMonth() + 1);
        let jam =
            dates.getHours() + 1 > 9
                ? dates.getHours() + 1
                : "0" + (dates.getHours() + 1);
        let menit =
            dates.getMinutes() + 1 > 9
                ? dates.getMinutes() + 1
                : "0" + (dates.getMinutes() + 1);
        let year = dates.getFullYear();
        let day = dates.getDay();
        // compare
        let compare = "eq";
        let selected = new Date(
            dates.getFullYear(),
            dates.getMonth(),
            dates.getDate()
        );
        if (selected > today) compare = "gt";
        if (selected < today) compare = "lt";
        // cek full format return
        if (full_format) {
            // next_date
            let next_date = new Date(dates.valueOf());
            next_date.setDate(next_date.getDate() + 1);
            // next_month
            let next_month = new Date(
                dates.getFullYear(),
                dates.getMonth() + 1,
                1
            );
            // prev_date
            let prev_date = new Date(dates.valueOf());
            prev_date.setDate(prev_date.getDate() - 1);
            // prev_month
            let prev_month = new Date(
                dates.getFullYear(),
                dates.getMonth() - 1,
                1
            );
            // max date
            let max_date = this.getSelectedMaxDate(dates);
            // return
            return {
                y: year,
                m: month,
                d: date,
                h: jam,
                i: menit,
                day: day,
                hari_long: this.hari_long[day],
                hari_short: this.hari_short[day],
                bln_long: this.bln_long[month],
                bln_short: this.bln_short[month],
                compare: compare,
                format_short: year + "-" + month + "-" + date,
                format_long:
                    year + "-" + month + "-" + date + " " + jam + ":" + menit,
                format_month_year: year + "-" + month,
                real_date: dates,
                next_date: next_date,
                next_month: next_month,
                prev_date: prev_date,
                prev_month: prev_month,
                max_date: max_date,
                selected: selected
            };
        } else {
            // return
            return {
                y: year,
                m: month,
                d: date,
                h: jam,
                i: menit,
                day: day,
                hari_long: this.hari_long[day],
                hari_short: this.hari_short[day],
                bln_long: this.bln_long[month],
                bln_short: this.bln_short[month],
                compare: compare,
                format_short: year + "-" + month + "-" + date,
                format_long:
                    year + "-" + month + "-" + date + " " + jam + ":" + menit,
                format_month_year: year + "-" + month,
                selected: selected
            };
        }
    }
};
