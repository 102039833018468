import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import { Modal, Button } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import KodeTable from "./table_kode";

class UnitKodeModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // unit
        this.unit = this.props.item;
        // state
        this.state = {
            list: [],
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // component did mount
    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/unitcode/detail/${this.unit.unit_id}`)
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(index, value) {
        let { list } = this.state;
        list[index]["code_name"] = value;
        this.setState({ list: list });
    }
    //addRow
    addRow() {
        this.setState({
            list: [
                ...this.state.list,
                { code_id: "", code_name: "", unit_name: "" }
            ]
        });
    }
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list: list });
    }
    // konfirmasi hapus
    deleteConfirm(item, index) {
        SwalConfirm(
            `Hapus Referensi Kode Unit [${item.code_name}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item, index)
        );
    }
    // deleteprocess
    deleteProcess(item, index) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/unitcode/delete/${item.code_id}`)
                .then(result => {
                    // load data
                    this.removeRow(index);
                })
                .catch(error => {})
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                unit_id: that.unit.unit_id,
                unit_code: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `referensi/unitcode/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={`Referensi Kode Unit [${this.unit.unit_name} ]`}
                content={
                    <KodeTable
                        {...this.props}
                        addRow={this.addRow}
                        handleChange={this.handleChange}
                        removeRow={this.removeRow}
                        deleteConfirm={this.deleteConfirm}
                        list={this.state.list}
                    />
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default UnitKodeModal;
