import React from "react";
import classNames from "classnames";

import "icheck-material";

const InputCheckbox = props => {
    return (
        <div className="icheck-material-indigo">
            <input
                type="checkbox"
                name={props.name}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                defaultChecked={props.defaultChecked}
                checked={props.checked}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
};

export default InputCheckbox;
