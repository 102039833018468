import React, { Component } from "react";

// komponen
import { Content, ButtonAdd, ButtonBack } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// page
import DetailModal from "./modal";
import DetailOption from "./options";
import DetailTable from "./table_detail";

class MenuDetail extends Component {
    constructor(props) {
        super(props);
        // portal id
        this.portal_id = props.match.params.id;
        // state
        this.state = {
            portal_nm: "",
            parent: "0",
            list_parent: [],
            list_menu: [],
            modal: false,
            mode: "",
            item: null,
            loading: false
        };
        // temp list
        this.list_temp = [];
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
    }
    componentDidMount() {
        // get list portal
        this.loadData();
    }
    // loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `setting/menu/detail/${this.portal_id}`)
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            portal_nm: result.data.portal_nm
                        },
                        function() {
                            this.list_temp = [];
                            this.formatMenu(result.data.list_menu, "0");
                            // set state
                            this.setState({
                                list_parent: this.list_temp,
                                list_menu: this.list_temp
                            });
                        }
                    );
                })
                .catch(error => {});
        });
    }
    // formatMenu
    formatMenu(list, parent) {
        list.map(item => {
            item["parent"] = parent;
            this.list_temp.push(item);
            // jika punya child
            if (item.list_child.length) {
                this.formatMenu(item.list_child, [...parent, item.nav_id]);
            }
        });
    }
    // onChange
    onChange(event) {
        this.setState({
            list_menu: this.state.list_parent.filter(item =>
                item.parent.includes(event.target.value)
            )
        });
    }
    //toogleShow
    toogleShow(mode = "", item = null) {
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
        });
    }
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Menu [${item.nav_title_real}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `setting/menu/delete/${item.nav_id}`)
                .then(result => {
                    // load data
                    this.loadData();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Daftar Menu {this.state.portal_nm}
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                            <ButtonAdd
                                onClick={() => this.toogleShow("add")}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="form-group row">
                            <label
                                className="control-label col-md-offset-2 col-md-2 mt-1"
                                htmlFor={"parent_menu"}
                            >
                                Parent Menu
                            </label>
                            <div className="form-group col-md-5">
                                <select
                                    id={"parent_menu"}
                                    className="form-control"
                                    onChange={this.onChange}
                                >
                                    <DetailOption
                                        list={this.state.list_parent}
                                    />
                                </select>
                            </div>
                        </div>
                        <DetailTable
                            list={this.state.list_menu}
                            toogleShow={this.toogleShow}
                            deleteConfirm={this.deleteConfirm}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <DetailModal
                        mode={this.state.mode}
                        portal_id={this.portal_id}
                        item={this.state.item}
                        options={<DetailOption list={this.state.list_parent} />}
                        show={this.state.modal}
                        toogleShow={() => this.toogleShow()}
                        loadData={() => this.loadData()}
                    />
                )}
            </Content>
        );
    }
}
export default MenuDetail;
