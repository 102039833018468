import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../reducers/session/actions.js";

// komponen
import { SwalSuccess } from "../../components/Alert";
import { Button } from "../../components/AdminLTE";

// utils
import toBase64 from "../../utils/File";
import GetPrivate, { PostPrivate } from "../../utils/Connection";

// page
import FieldProfile from "./profile_pegawai_field";

class ProfileProfilePegawai extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            // field
            pegawai_nik: props.session.pegawai_nik,
            pegawai_name: props.session.pegawai_name,
            pegawai_birth: props.session.pegawai_birth,
            pegawai_birth_place: props.session.pegawai_birth_place,
            pegawai_birth_place_name: props.session.pegawai_birth_place_name,
            pegawai_gender: props.session.pegawai_gender,
            pegawai_pass: "",
            pegawai_image: null,
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            pegawai_image: picture[0]
        });
    }
    // loadData
    loadData(q, url, value, label) {
        // list kabupaten
        return GetPrivate(this, `${url}?q=${q}`)
            .then(result => {
                //return
                return result.data.map(item => ({
                    value: item[value],
                    label: item[label]
                }));
            })
            .catch(error => {
                return [];
            });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.pegawai_image) {
            image = await toBase64(that.state.pegawai_image)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                pegawai_name: that.state.pegawai_name,
                pegawai_nik: that.state.pegawai_nik,
                pegawai_birth_place: that.state.pegawai_birth_place,
                pegawai_birth: that.state.pegawai_birth,
                pegawai_gender: that.state.pegawai_gender,
                pegawai_pass: that.state.pegawai_pass,
                pegawai_image: image
            };
            // paggil post api
            PostPrivate(that, `user/profile/update-pegawai`, params)
                .then(result => {
                    // update redux state
                    let session = that.props.session;
                    // loop result
                    Object.keys(result.data).map(
                        item => (session[item] = result.data[item])
                    );
                    // set session
                    that.props.setSession(session).then(() => {
                        // pesan
                        SwalSuccess("Berhasil", result.message);
                        // reload page
                        that.props.history.replace(
                            that.props.location.pathname
                        );
                    });
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    }
    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-4">
                            <ImageUploader
                                withIcon={true}
                                buttonText="Pilih Gambar"
                                onChange={this.handleDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                withPreview={true}
                            />
                        </div>
                        <div className="col-md-8">
                            <FieldProfile
                                hasDefaultOptions={true}
                                pegawai_nik={this.state.pegawai_nik}
                                pegawai_name={this.state.pegawai_name}
                                pegawai_birth_place={
                                    this.state.pegawai_birth_place
                                }
                                pegawai_birth={this.state.pegawai_birth}
                                pegawai_gender={this.state.pegawai_gender}
                                pegawai_birth_place_name={
                                    this.state.pegawai_birth_place_name
                                }
                                loadData={this.loadData}
                                handleChange={this.handleChange}
                                error_field={this.state.error_field}
                            />
                        </div>
                    </div>
                </div>
                <div className="box-footer text-right">
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                </div>
            </form>
        );
    }
}

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session
    };
}
// dispatch / aksi -> props
function mapsDispatchToProps(dispatch) {
    return {
        setSession: params => {
            return new Promise((resolve, reject) => {
                dispatch(setSession(params));
                resolve();
            });
        }
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, mapsDispatchToProps)(ProfileProfilePegawai)
);
