import React from "react";

import { Input } from "../../../components/AdminLTE";
import TimeRangeSlider from 'react-time-range-slider';

const SearchPegawai = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <div className="form-group row mb-0">
                    <label className="control-label col-md-5" htmlFor="q">
                        Tanggal
                    </label>
                    <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.date}
                        onChange={ev =>
                            props.handleChangeDate("date", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                </div>
            </div>
            <div className="col-md-offset-3 col-md-6 mb-2">
                <div className="form-group row mb-0">
                    <label className="control-label col-md-5" htmlFor="q">
                        Waktu
                    </label>
                   
<Input
                        id="start"
                        type="time"
                        placeholder="Tanggal"
                        // defaultValue={props.start}
                        onChange={ev =>
                            props.handleChangeDate("start", ev.target.value)
                        }
                        parentStyle="col-md-3 p-0"
                    />
<Input
                        id="end"
                        type="time"
                        placeholder="Tanggal"
                        // defaultValue={props.end}
                        onChange={ev =>
                            props.handleChangeDate("end", ev.target.value)
                        }
                        parentStyle="col-md-3 p-0"
                    />
                </div>
            </div>
            <div className="col-md-offset-1 col-md-10 time-slide-picker">
            <div className="col-md-4"></div>
                {/* <TimeRangeSlider
                    disabled={false}
                    format={24}
                    maxValue={"23:59"}
                    minValue={"00:00"}
                    name={"time_range"}
                    onChangeComplete={props.handleSelectTime}
                    step={15}
                    value={props.time}
                    onChange={range =>
                        props.handleChangeTime(range)
                    }
                /> */}

            </div>
        </div>
    );
};

export default SearchPegawai;
