import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import KecelakaanTable from "./table";
import KecelakaanSearch from "./search";
import KecelakaanModal from "./modal";

class Kecelakaan extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : Dates.getTodayDB();

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            list: [],
            start: start,
            end: end,
            bujt: bujt,
            ruas_id:"",
            ruas_name:"",
            title:'Ganti Rugi',
            detail:null,
            lokasi_kecelakaan:[],
            penyebab_kecelakaan:[],
            unit_patroli:[],
            unit_ambulance_kp:[],
            unit_ambulance_kh:[],
            unit_rescue:[],
            unit_derek:[],
            unit_satgas:[],
            unit_pjr:[],
            unit_damkar:[],
            jenis_kecelakaan:[],
            penumpang:[],
            combo_kendaraan:[],
            jenis_kelamin:[{id:"1",label:"Laki-Laki"},{id:"2",label:"Perempuan"}],
            kondisi:[{id:"1",label: "Luka Ringan"},{id:"2",label:"Luka Berat"},{id:"3",label:"Meninggal Dunia"}]
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
        
        
        // LOAD ALL REFERENCE HERE
        this.setState({ loading: true }, function() {
            //params
            const params = {
                id: ""
            };
            // paggil post api
            PostPrivate(this, 'transaksi/kecelakaan/penyebab_laka', params)
                .then(result => {
                    this.setState(
                        {penyebab_kecelakaan: result.data},
                        function() {
                            PostPrivate(this, 'transaksi/kecelakaan/lokasi_laka', params)
                                .then(result => {
                                    this.setState(
                                        {lokasi_kecelakaan: result.data},
                                        function() {
                                            PostPrivate(this, 'transaksi/kecelakaan/jenis_laka', params)
                                                .then(result => {
                                                    this.setState(
                                                        {jenis_kecelakaan: result.data},
                                                        function() {
                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_patroli', params)
                                                                .then(result => {
                                                                    this.setState(
                                                                        {unit_patroli: result.data},
                                                                        function() {
                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_ambulance_kp', params)
                                                                                .then(result => {
                                                                                    this.setState(
                                                                                        {unit_ambulance_kp: result.data},
                                                                                        function() {
                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_ambulance_kh', params)
                                                                                                .then(result => {
                                                                                                    this.setState(
                                                                                                        {unit_ambulance_kh: result.data},
                                                                                                        function() {
                                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_rescue', params)
                                                                                                                .then(result => {
                                                                                                                    this.setState(
                                                                                                                        {unit_rescue: result.data},
                                                                                                                        function() {
                                                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_derek', params)
                                                                                                                                .then(result => {
                                                                                                                                    this.setState(
                                                                                                                                        {unit_derek: result.data},
                                                                                                                                        function() {
                                                                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_satgas', params)
                                                                                                                                                .then(result => {
                                                                                                                                                    this.setState(
                                                                                                                                                        {unit_satgas: result.data},
                                                                                                                                                        function() {
                                                                                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_pjr', params)
                                                                                                                                                                .then(result => {
                                                                                                                                                                    this.setState(
                                                                                                                                                                        {unit_pjr: result.data},
                                                                                                                                                                        function() {
                                                                                                                                                                            PostPrivate(this, 'transaksi/kecelakaan/unit_damkar', params)
                                                                                                                                                                                .then(result => {
                                                                                                                                                                                    this.setState(
                                                                                                                                                                                        {loading : false, unit_damkar: result.data}
                                                                                                                                                                                    );
                                                                                                                                                                                })
                                                                                                                                                                                .catch(error => {
                                                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                                                        this.setState({
                                                                                                                                                                                            error_field: error
                                                                                                                                                                                        });
                                                                                                                                                                                    }
                                                                                                                                                                                    // hapus loading
                                                                                                                                                                                    this.setState({
                                                                                                                                                                                        loading: false
                                                                                                                                                                                    });
                                                                                                                                                                                });
                                                                                                                                                                        }
                                                                                                                                                                    );
                                                                                                                                                                })
                                                                                                                                                                .catch(error => {
                                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                                        this.setState({
                                                                                                                                                                            error_field: error
                                                                                                                                                                        });
                                                                                                                                                                    }
                                                                                                                                                                    // hapus loading
                                                                                                                                                                    this.setState({
                                                                                                                                                                        loading: false
                                                                                                                                                                    });
                                                                                                                                                                });
                                                                                                                                                        }
                                                                                                                                                    );
                                                                                                                                                })
                                                                                                                                                .catch(error => {
                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                        this.setState({
                                                                                                                                                            error_field: error
                                                                                                                                                        });
                                                                                                                                                    }
                                                                                                                                                    // hapus loading
                                                                                                                                                    this.setState({
                                                                                                                                                        loading: false
                                                                                                                                                    });
                                                                                                                                                });
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                })
                                                                                                                                .catch(error => {
                                                                                                                                    if (typeof error == "object") {
                                                                                                                                        this.setState({
                                                                                                                                            error_field: error
                                                                                                                                        });
                                                                                                                                    }
                                                                                                                                    // hapus loading
                                                                                                                                    this.setState({
                                                                                                                                        loading: false
                                                                                                                                    });
                                                                                                                                });
                                                                                                                        }
                                                                                                                    );
                                                                                                                })
                                                                                                                .catch(error => {
                                                                                                                    if (typeof error == "object") {
                                                                                                                        this.setState({
                                                                                                                            error_field: error
                                                                                                                        });
                                                                                                                    }
                                                                                                                    // hapus loading
                                                                                                                    this.setState({
                                                                                                                        loading: false
                                                                                                                    });
                                                                                                                });
                                                                                                        }
                                                                                                    );
                                                                                                })
                                                                                                .catch(error => {
                                                                                                    if (typeof error == "object") {
                                                                                                        this.setState({
                                                                                                            error_field: error
                                                                                                        });
                                                                                                    }
                                                                                                    // hapus loading
                                                                                                    this.setState({
                                                                                                        loading: false
                                                                                                    });
                                                                                                });
                                                                                        }
                                                                                    );
                                                                                })
                                                                                .catch(error => {
                                                                                    if (typeof error == "object") {
                                                                                        this.setState({
                                                                                            error_field: error
                                                                                        });
                                                                                    }
                                                                                    // hapus loading
                                                                                    this.setState({
                                                                                        loading: false
                                                                                    });
                                                                                });
                                                                        }
                                                                    );
                                                                })
                                                                .catch(error => {
                                                                    if (typeof error == "object") {
                                                                        this.setState({
                                                                            error_field: error
                                                                        });
                                                                    }
                                                                    // hapus loading
                                                                    this.setState({
                                                                        loading: false
                                                                    });
                                                                });
                                                        }
                                                    );
                                                })
                                                .catch(error => {
                                                    if (typeof error == "object") {
                                                        this.setState({
                                                            error_field: error
                                                        });
                                                    }
                                                    // hapus loading
                                                    this.setState({
                                                        loading: false
                                                    });
                                                });
                                        }
                                    );
                                })
                                .catch(error => {
                                    if (typeof error == "object") {
                                        this.setState({
                                            error_field: error
                                        });
                                    }
                                    // hapus loading
                                    this.setState({
                                        loading: false
                                    });
                                });
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
        });
        
        
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const ruas = this.state.ruas_id;
            
            if (ruas && ruas !== 'undefined') {
                const params = {
                    ruas_id : this.state.ruas_id,
                    start   : this.state.start,
                    end     : this.state.end
                };
                
                PostPrivate(this, 'transaksi/kecelakaan',params).then(result => {
                    this.setState({
                        loading : false,
                        list    : result.data
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
        if (id == null || id=='') {
            this.setState({ modal: !this.state.modal});
        } else {
            // get detail post
            // panggil get api
            this.setState({ loading: true }, function() {
                const params = {
                    id : id
                };

                PostPrivate(this, 'transaksi/kecelakaan/detail',params).then(result => {
                    let combo_kendaraan = [];
                    
                    result.data.kendaraan.map((row, i) => {
                        combo_kendaraan.push({id:row.plat_nomor, label:row.merk+' '+row.seri+' '+row.plat_nomor});
                    });
                    
                    this.setState({
                        loading   : false,
                        detail    : result.data,
                        penumpang : result.penumpang,
                        modal     : !this.state.modal,
                        combo_kendaraan: combo_kendaraan
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            });
            this.loadData();
        }
        
        
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <KecelakaanSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <KecelakaanTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <KecelakaanModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        id={this.state.id}
                        detail={this.state.detail}
                        handleChange={this.handleChange}
                        lokasi_kecelakaan={this.state.lokasi_kecelakaan}
                        penyebab_kecelakaan={this.state.penyebab_kecelakaan}
                        unit_patroli={this.state.unit_patroli}
                        unit_ambulance_kp={this.state.unit_ambulance_kp}
                        unit_ambulance_kh={this.state.unit_ambulance_kh}
                        unit_rescue={this.state.unit_rescue}
                        unit_derek={this.state.unit_derek}
                        unit_satgas={this.state.unit_satgas}
                        unit_pjr={this.state.unit_pjr}
                        unit_damkar={this.state.unit_damkar}
                        jenis_kecelakaan={this.state.jenis_kecelakaan}
                        penumpang={this.state.penumpang}
                        combo_kendaraan={this.state.combo_kendaraan}
                        jenis_kelamin={this.state.jenis_kelamin}
                        kondisi={this.state.kondisi}
                    />
                )}
            </Content>
        );
    }
}

export default Kecelakaan;
