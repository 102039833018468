import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


class EventRatarata extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            link : this.props.linkTo,
            list: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list8: [],
            list9: [],
            list10: [],
            list11: [],
            list12: []
        };
        // bind
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
    }

    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.series[opts.seriesIndex].name) {
            case 'Events':
                window.location.href = window.location.href + this.props.linkTo4;
            break;
            case 'Kecelakaan':
                window.location.href = window.location.href + this.props.linkTo3;
            break ;
            case 'Ganti Ban':
                window.location.href = window.location.href + this.props.linkTo2;
            break ;
            case 'Mogok':
                window.location.href = window.location.href + this.props.linkTo;
            break ;
            default:
        }
    }
    
    render() {
        // return
        return (
            <div>              
                <Chart
          type="bar"
          width={900}
          height={450}
          series={[
              {
                 type: 'bar',
                 data: this.props.pen_aktif,
                 name: 'Event',
               }
          ]}
          options={{
            title: {
              text: "Rata-rata Penanganan Aktif",
              align:'center',
              style: { fontSize: 24, fontFamily: 'Poppins', color:  '#121212',  fontWeight:  '400' },
            },

            colors: ["#F58220", "#F05423", "#CF0A2C", "#515E5F"],
            

            xaxis: {
              categories: this.props.bujt2,
            },

            yaxis: {
              labels: {
                formatter: (val) => {
                  return `${val}`;
                },
               
              },
            },
            chart: {
                background: '#FFFFFF',
                events: {
                    dataPointSelection: this.handleDataPointSelection
            },
                type: 'line',
                toolbar: {
                    show: false
                    },
                    fontFamily: 'Poppins'
              },
              


            legend: {
              show: true,
              position: "bottom",
            },

            dataLabels: {
                enabled: true,
                enabledOnSeries: [3]
              },
              responsive: [{
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    column: {
                      horizontal: true
                    },
                    line: {
                        horizontal: true
                      }
                  }
                }
          }]
              
          }}
        ></Chart>
            </div>
        );
    }
}

export default EventRatarata;
