import React from "react";
import classNames from "classnames";
import Select from "react-select";

const customStyles = {
    control: base => ({
        ...base,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: "#dedede",
        borderRadius: 0,
        boxShadow: "none",
        "&:hover": { borderBottomColor: "#ffca05" } // border style on hover
    })
};
const errorStyles = {
    control: base => ({
        ...base,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: "#dd4b39",
        borderRadius: 0,
        boxShadow: "none",
        "&:hover": { borderBottomColor: "#ffca05" } // border style on hover
    })
};

const InputSelectReact = props => {
    const has_error = props.has_error == undefined ? false : props.has_error;
    return (
        <div
            className={classNames("form-group", props.parentStyle, {
                "has-error": has_error
            })}
        >
            <Select
                id={props.id}
                defaultValue={props.defaultValue}
                value={props.value}
                options={props.options}
                onChange={props.onChange}
                autoFocus={props.autoFocus}
                styles={
                    props.noStyle ? {} : has_error ? errorStyles : customStyles
                }
                placeholder="Pilih"
                isClearable={props.isClearable}
            />
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputSelectReact;
