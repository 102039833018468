import React from "react";

// komponen
import { Input, InputSelectReact } from "../../../components/AdminLTE";

const SearchPegawai = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <div className="form-group row mb-0">
                <div className="col-md-2"></div>
                    <InputSelectReact
                        id={"start"}
                        placeholder="Bulan"
                        defaultValue={{
                            label: props.start_name,
                            value: props.start
                        }}
                        onChange={item => {
                            props.handleChange("start", item.value);
                        }}
                        options={props.list_bulan}
                        parentStyle="col-xs-4"
                    />
            <InputSelectReact
                        id={"end"}
                        placeholder="Tahun"
                        defaultValue={{
                            label: props.end_name,
                            value: props.end
                        }}
                        onChange={item => {
                            props.handleChange("end", item.value);
                        }}
                        options={props.list_tahun}
                        parentStyle="col-xs-4"
                    />
                </div>
                <div className="form-group row mb-0">
                    {/* <label
                        className="control-label col-md-4"
                        htmlFor="ruas"
                    >
                        Ruas Tol
                    </label> */}
                    <div className="col-md-3"></div>
                    <InputSelectReact
                        id={"ruas"}
                        onChange={item => props.handleChangeSelect(item)}
                        options={props.list_ruas}
                        value={{
                            label: props.ruas_name,
                            value: props.ruas_id
                        }}
                        parentStyle="col-md-6 p-0"
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchPegawai;
