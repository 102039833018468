export default {
    basepath: "https://api-astratoll.eoviz.com/",
    endPoint: "https://api-astratoll.eoviz.com/index.php/v1/",
   // basepath: "https://dev-jasamarga.eoviz.com/jasamarga/api/",
    //endPoint: "https://dev-jasamarga.eoviz.com/jasamarga/api/index.php/v1/",
    webKey: "JMTO 4195",
    googleMapKey: "AIzaSyCwOSYe6D7eqj6z_13j4hEZthIHeNyBL6U",
    delayRequest: function (callback, delay = 0) {
        setTimeout(callback, delay);
    },
    error_code: {
        success: 200,
        forbidden: 403,
        notfound: 404,
    },
    error_desc: {
        network: "Jaringan Bermasalah!",
        database: "Database Bermasalah!",
    },
};
