import React from "react";

// komponen
import Alert from "./alert";
import Tabs from "./tabs";

// tabs content
const tabs_default = "tab_1";
const tabs_contents = [
    {
        key: "tab_1",
        title: "Tab 1",
        content: (
            <>
                <b>How to use:</b>
                <p>
                    Exactly like the original bootstrap tabs except you should
                    use the custom wrapper <code>.nav-tabs-custom</code> to
                    achieve this style.
                </p>
                A wonderful serenity has taken possession of my entire soul,
                like these sweet mornings of spring which I enjoy with my whole
                heart. I am alone, and feel the charm of existence in this spot,
                which was created for the bliss of souls like mine. I am so
                happy, my dear friend, so absorbed in the exquisite sense of
                mere tranquil existence, that I neglect my talents. I should be
                incapable of drawing a single stroke at the present moment; and
                yet I feel that I never was a greater artist than now.
            </>
        )
    },
    {
        key: "tab_2",
        title: "Tab 2",
        content: (
            <>
                The European languages are members of the same family. Their
                separate existence is a myth. For science, music, sport, etc,
                Europe uses the same vocabulary. The languages only differ in
                their grammar, their pronunciation and their most common words.
                Everyone realizes why a new common language would be desirable:
                one could refuse to pay expensive translators. To achieve this,
                it would be necessary to have uniform grammar, pronunciation and
                more common words. If several languages coalesce, the grammar of
                the resulting language is more simple and regular than that of
                the individual languages.
            </>
        )
    },
    {
        key: "tab_3",
        title: "Tab 3",
        content: (
            <>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
            </>
        )
    }
];

const IndexTabs = props => {
    return (
        <>
            <h2 className="page-header">Tabs</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="box box-default">
                        <div className="box-body">
                            <code>
                                &lt;Tabs
                                <br />
                                &nbsp;&nbsp;&nbsp;list=[array tabs[key = string,
                                title = string, content = html content]]
                                <br />
                                &nbsp;&nbsp;&nbsp;default=[string list key]
                                <br />
                                &nbsp;&nbsp;&nbsp;direction=["left", "right"]
                                <br />
                                &gt;
                            </code>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <Tabs list={tabs_contents} default={tabs_default} />
                </div>
            </div>
        </>
    );
};

export default IndexTabs;
