import React, { useState } from "react";
// komponen
import {
    Button,
    ButtonGroup,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import Config from "../../../utils/Config";

// komponen
import UploadModal from "./modal_upload";

const Header = props => {
    const [show, setShow] = useState(false);
    const toogleShow = () => {
        if (show) {
            props.loadData();
        }
        setShow(!show);
    };
    let start_date = props.start_date.format("DD MMMM YYYY");
    let end_date = props.end_date.format("DD MMMM YYYY");
    let title = `${start_date} - ${end_date}`;
    return (
        <>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Ruas Tol :</label>
                    <InputSelectReact
                        id={"ruas"}
                        onChange={item => props.changeRuas(item)}
                        options={props.list_ruas}
                        value={{
                            label: props.ruas_name,
                            value: props.ruas_id
                        }}
                        noStyle={true}
                    />
                </div>
                <div className="col-md-8 text-right">
                    <a
                        href={`${Config.basepath}/resources/template/shift_upload.xlsx`}
                        target="_BLANK"
                        className="btn btn-success mr-2"
                    >
                        <i className="fa fa-download mr-1"></i> Download
                        Template
                    </a>
                    <Button className="btn btn-success" onClick={toogleShow}>
                        <i className="fa fa-upload mr-1"></i> Upload Shift
                    </Button>
                </div>
            </div>
            <div className="hr"></div>
            <div className="row">
                <div className="col-md-3">
                    <ButtonGroup>
                        <Button
                            className="btn btn-default"
                            onClick={() => props.changeWeek("prev")}
                            loading={props.loading}
                        >
                            <i className="fa fa-chevron-left"></i>
                        </Button>
                        <Button
                            className="btn btn-default"
                            onClick={() => props.changeWeek("next")}
                            loading={props.loading}
                        >
                            <i className="fa fa-chevron-right"></i>
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="col-md-6">
                    <h2 className="h2-custom text-center">{title}</h2>
                </div>
                <div className="col-md-3 text-right">
                    <Button
                        className="btn btn-primary"
                        onClick={() => props.toogleShow()}
                        loading={props.loading}
                    >
                        Tambah Jadwal
                    </Button>
                </div>
            </div>
            <UploadModal show={show} toogleShow={toogleShow} />
        </>
    );
};

export default Header;
