import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonGroup,
    ButtonEdit,
    ButtonDelete,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Waktu Laporan",
        selector: "detail_time_receive_info",
        sortable: true,
        width: "10%",
        center: true,
    },
    {
        name: "Uraian Kegiatan",
        selector: "detail_activity",
    },
    {
        name: "Keterangan",
        selector: "detail_description",
    },
    {
        name: "",
        selector: "action",
        width: "17.5%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["detail_time_receive_info"] = item.detail_time_receive_info
            ? item.detail_time_receive_info.substr(0, 5)
            : "";
        item["detail_time_finish_handle"] = item.detail_time_finish_handle
            ? item.detail_time_finish_handle.substr(0, 5)
            : "";
        item["action"] = (
            <ButtonGroup key={`action-${index}`}>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)}>
                    <i className="fa fa-edit"></i>
                </ButtonEdit>
                <ButtonDelete onClick={() => props.deleteConfirm(item)}>
                    <i className="fa fa-trash"></i>
                </ButtonDelete>
            </ButtonGroup>
        );
        return item;
    });
};

const RealisasiTable = (props) => {
    return (
        <TableBootstrap
            keyField={"user"}
            empty="Belum ada laporan harian, klik tambah untuk menambahkan laporan harian"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RealisasiTable;
