import React from "react";
import classNames from "classnames";

import "icheck-material";

const InputRadioInline = props => {
    return (
        <div
            className={classNames("form-group", "mt-1", {
                "has-error": props.has_error
            })}
        >
            {props.list.map((item, index) => (
                <div
                    key={index}
                    className="icheck-material-indigo icheck-inline mr-1"
                >
                    <input
                        type="radio"
                        name={props.id}
                        id={`${props.id}__${item.value}`}
                        value={item.value}
                        onChange={props.onChange}
                        // defaultChecked={props.defaultChecked == item.value}
                        checked={props.defaultChecked == item.value}
                    />
                    <label htmlFor={`${props.id}__${item.value}`}>
                        {item.label}
                    </label>
                </div>
            ))}
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputRadioInline;
