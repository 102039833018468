import React, { Component } from "react";
import { Modal } from "../../../components/AdminLTE";
import Detail from "./detail";

const LakalantasModal = props => {
    return (
        <Modal
            title="Laporan Kritik dan Saran"
            show={props.show}
            onDismiss={() => props.toogleShow()}
            size="large"
            content={<Detail id={props.id}></Detail>}
        />
    );
};

export default LakalantasModal;
