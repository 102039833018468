import React, { Component } from "react";

// komponen
import { Content, ButtonBack, Button } from "../../../components/AdminLTE";
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// default image
import img_default from "../../../resources/img/img-default.jpg";

class BeritaDelete extends Component {
    constructor(props) {
        super(props);
        // user id
        this.id_berita = props.match.params.id;
        // state
        this.state = {
            // field
            id_berita: "",
            judul_berita: "",
            isi_berita: "",
            nama_bujt: "",
            nama: "",
            gambar_berita: null,
            gambar64:"",
            // loading
            loading: false,
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `berita/berita/detail/${this.id_berita}`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        id_berita: result.data.id_berita,
                        judul_berita: result.data.judul_berita,
                        nama: result.data.nama,
                        isi_berita: result.data.isi_berita,
                        nama_bujt: result.data.nama_bujt,
                        gambar_berita: result.data.gambar_berita,
                        gambar_berita: result.data.gambar_berita,
                        gambar64: result.data.file64,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm() {
        SwalConfirm(
            `Hapus Berita [${this.state.judul_berita}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess()
        );
    }
    // deleteprocess
    deleteProcess() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `berita/berita/delete/${this.id_berita}`)
                .then((result) => {
                    // reload page
                    this.props.history.goBack();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Hapus Berita</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img
                                    src={
                                        this.state.gambar64
                                            ? this.state.gambar64
                                            : img_default
                                    }
                                    alt="Profil"
                                    style={{width:"100%"}}
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label
                                        htmlFor={"id_berita"}
                                        className="control-label"
                                    >
                                        ID Berita
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.id_berita}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"judul_berita"}
                                        className="control-label"
                                    >
                                        Judul Berita
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.judul_berita}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"isi_berita"}
                                        className="control-label"
                                    >
                                        Isi Berita
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.isi_berita}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"nama"}
                                        className="control-label"
                                    >
                                        Kategori
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.nama}
                                    </p>
                                </div>
                               <div className="form-group">
                                    <label
                                        htmlFor={"nama_bujt"}
                                        className="control-label"
                                    >
                                        Bujt
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.nama_bujt}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer text-right">
                        <Button
                            type="button"
                            className="btn btn-danger"
                            loading={this.state.loading}
                            onClick={this.deleteConfirm}
                        >
                            <i className="fa fa-trash mr-1"></i> Hapus Berita
                        </Button>
                    </div>
                </div>
            </Content>
        );
    }
}

export default BeritaDelete;
