import React from "react";

// komponen
import { Table, Button, InputRadioInline } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "10%", className: "text-center", label: "No" },
        { width: "55%", className: "text-center", label: "Judul Report" },
        { width: "15%", className: "text-center", label: "Detail" },
        {
            width: "20%",
            className: "text-center",
            label: "Setujui Laporan ?"
        }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => {
        return (
            <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{item.title}</td>
                <td>
                    <Button
                        type="button"
                        className="btn btn-default btn-block"
                        onClick={() =>
                            props.toogleShow(item.webview, item.title)
                        }
                    >
                        Detail
                    </Button>
                </td>
                <td className="text-center">
                    <InputRadioInline
                        id={`reports__${index}`}
                        defaultChecked={item.approval}
                        onChange={ev =>
                            props.handleChange(
                                index,
                                "approval",
                                ev.target.value
                            )
                        }
                        list={[
                            { label: "Setujui", value: "accept" },
                            { label: "Tolak", value: "reject" }
                        ]}
                    />
                </td>
            </tr>
        );
    });
};

const ReportTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Kosong"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default ReportTable;
