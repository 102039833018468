import React from "react";

// komponen
import { Input, InputSelectReact } from "../../../components/AdminLTE";

const SearchPegawai = props => {
    
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                
                <div className="form-group row mb-0">
                    <label
                        className="control-label col-md-4 mt-2"
                        htmlFor="ruas"
                    >
                        Tahun
                    </label>
                    <InputSelectReact
                        id={"year"}
                        onChange={item => props.handleChangeSelect(item)}
                        options={props.list_year}
                        value={{
                            label: props.year_name,
                            value: props.year_name
                        }}
                        parentStyle="col-md-6 p-0"
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchPegawai;
