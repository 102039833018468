import React, { useState } from "react";
// komponen
import {
    Button,
    ButtonGroup,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import Config from "../../../utils/Config";

// komponen
import UploadModal from "./modal_upload";

const Header = props => {
    const [show, setShow] = useState(false);
    const toogleShow = () => {
        if (show) {
            props.loadData();
        }
        setShow(!show);
    };
    return (
        <>
            <div className="row">
                
                <div className="col-md-2">
                    <a
                        href={`${Config.basepath}/resources/template/tarif_upload.xlsx`}
                        target="_BLANK"
                        className="btn-xs btn btn-success mr-2"
                    >
                        <i className="fa fa-download mr-1"></i> Download
                        Template
                    </a>
                    </div>
                    <div className="col-md-2">
                    <Button className="btn-xs btn btn-success" onClick={toogleShow}>
                        <i className="fa fa-upload mr-1"></i> Upload Data
                    </Button>
                </div>
            </div>
            
            <UploadModal show={show} toogleShow={toogleShow} />
        </>
    );
};

export default Header;
