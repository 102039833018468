import React from "react";

// komponen
import {
    CollapseSingle,
    Input,
    InputTextarea,
    InputRadioInline
} from "../../../components/AdminLTE";

const MenuCollapse = props => {
    return (
        <CollapseSingle title="Setting React ( Edit jika menggunakan React )">
            <div className="form-group row">
                <label
                    htmlFor={"react_component"}
                    className="col-sm-3 control-label mt-1"
                >
                    React Component
                </label>
                <div className="col-sm-9">
                    <Input
                        id={"react_component"}
                        type="text"
                        placeholder="React Component"
                        defaultValue={props.state.react_component}
                        onChange={ev =>
                            props.handleChange(
                                "react_component",
                                ev.target.value
                            )
                        }
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"react_exact__1"}
                    className="col-sm-3 control-label mt-15"
                >
                    React Exact Path
                </label>
                <div className="col-sm-9">
                    <InputRadioInline
                        id={"react_exact"}
                        defaultChecked={props.state.react_exact}
                        onChange={ev =>
                            props.handleChange("react_exact", ev.target.value)
                        }
                        list={[
                            { label: "Ya", value: "1" },
                            { label: "Tidak", value: "0" }
                        ]}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"react_breadcrumb"}
                    className="col-sm-3 control-label mt-1"
                >
                    React BreadCrumb
                </label>
                <div className="col-sm-9">
                    <InputTextarea
                        id={"react_breadcrumb"}
                        rows={3}
                        placeholder="React BreadCrumb (json array : link, icon, title)"
                        defaultValue={props.state.react_breadcrumb}
                        onChange={ev =>
                            props.handleChange(
                                "react_breadcrumb",
                                ev.target.value
                            )
                        }
                    />
                </div>
            </div>
        </CollapseSingle>
    );
};

export default MenuCollapse;
