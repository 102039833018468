export default {
    getSession: function(name = "session__jmto_admin", method = true) {
        if (method) {
            return localStorage.getItem(name)
                ? localStorage.getItem(name)
                : sessionStorage.getItem(name);
        } else {
            return sessionStorage.getItem(name);
        }
    },
    setSession: function(
        name = "session__jmto_admin",
        value = "",
        method = true
    ) {
        if (method) {
            localStorage.setItem(name, value);
        } else {
            sessionStorage.setItem(name, value);
        }
        return;
    },
    clearSession: function(name = "session__jmto_admin", method = true) {
        if (method) {
            localStorage.removeItem(name);
        } else {
            sessionStorage.removeItem(name);
        }
        return;
    }
};
