import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup,
    ButtonRole
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Kode",
        selector: "ruas_code",
        sortable: true,
        width: "15%"
    },
    {
        name: "Nama Ruas Tol",
        selector: "ruas_name",
        sortable: true,
        width: "50%"
    },
    {
        name: "",
        selector: "action",
        width: "25%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonRole
                    role_url={`${props.location.pathname}/section/:id`}
                    role_type="role_read"
                >
                    <NavLink
                        to={`${props.location.pathname}/section/${item.ruas_id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-road"></i> Seksi Ruas
                    </NavLink>
                </ButtonRole>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const RuasTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_ruas"}
            empty="Belum ada referensi ruas tol, klik tombol + untuk menambahkan referensi ruas tol baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RuasTable;
