import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import {
    Content,
    ButtonBack,
    Button,
    Input,
    InputRadioInline,
    InputSelectReact,
} from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class UserAdd extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list_role: [],
            list_ruas: [],
            // field
            user_alias: "",
            user_name: "",
            user_pass: "",
            user_st: "",
            user_image: null,
            role_id: null,
            role_nm: null,

            ruas_id: null,
            ruas_name: null,
            // error field
            error_field: {
                user_alias: null,
                user_name: null,
                user_pass: null,
                user_st: null,
            },
            // loading
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, "setting/role")
                .then((result) => {
                    this.setState({
                        loading: false,
                        list_role: result.data.map((item) => ({
                            value: item.role_id,
                            label: item.role_nm,
                        })),
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });

                GetPrivate(this, "referensi/ruas")
                .then((result) => {
                    this.setState({
                        loading: false,
                        list_ruas: result.data.map((item) => ({
                            value: item.ruas_id,
                            label: item.ruas_name
                        })),
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            user_image: picture[0],
        });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.user_image) {
            image = await toBase64(that.state.user_image)
                .then((result) => result)
                .catch((error) => null);
        }
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                user_alias: that.state.user_alias,
                user_name: that.state.user_name,
                user_pass: that.state.user_pass,
                user_st: that.state.user_st,
                user_image: image,
                role: that.state.role_id
                    ? JSON.stringify([that.state.role_id])
                    : null,
                ruas: that.state.ruas_id
                    ? JSON.stringify([that.state.ruas_id])
                    : null,
            };
            // paggil post api
            PostPrivate(that, `setting/user/add`, params)
                .then((result) => {
                    // - redirect
                    that.props.history.replace(`/dashboard/setting/user`);
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch((error) => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error,
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Tambah User Baru</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText="Pilih Gambar"
                                        onChange={this.handleDrop}
                                        imgExtension={[
                                            ".jpg",
                                            ".gif",
                                            ".png",
                                            ".gif",
                                        ]}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label
                                            htmlFor={"user_alias"}
                                            className="control-label"
                                        >
                                            Nama Lengkap
                                        </label>
                                        <Input
                                            id={"user_alias"}
                                            type="text"
                                            placeholder="Nama Lengkap"
                                            has_error={
                                                this.state.error_field
                                                    .user_alias
                                            }
                                            defaultValue={this.state.user_alias}
                                            onChange={(ev) =>
                                                this.handleChange(
                                                    "user_alias",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field
                                                    .user_alias
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor={"user_name"}
                                            className="control-label"
                                        >
                                            Username
                                        </label>
                                        <Input
                                            id={"user_name"}
                                            type="text"
                                            placeholder="Username"
                                            has_error={
                                                this.state.error_field.user_name
                                            }
                                            defaultValue={this.state.user_name}
                                            onChange={(ev) =>
                                                this.handleChange(
                                                    "user_name",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.user_name
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor={"user_pass"}
                                            className="control-label"
                                        >
                                            Password
                                        </label>
                                        <Input
                                            id={"user_pass"}
                                            type="password"
                                            placeholder="Password"
                                            has_error={
                                                this.state.error_field.user_pass
                                            }
                                            defaultValue={this.state.user_pass}
                                            onChange={(ev) =>
                                                this.handleChange(
                                                    "user_pass",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.user_pass
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor={"user_st__1"}
                                            className="control-label"
                                        >
                                            Status User ?
                                        </label>
                                        <InputRadioInline
                                            id={"user_st"}
                                            has_error={
                                                this.state.error_field.user_st
                                            }
                                            defaultChecked={this.state.user_st}
                                            onChange={(ev) =>
                                                this.handleChange(
                                                    "user_st",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.user_st
                                            }
                                            list={[
                                                { label: "Aktif", value: "1" },
                                                {
                                                    label: "Suspend",
                                                    value: "0",
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor={"role_id"}
                                            className="control-label"
                                        >
                                            Jabatan
                                        </label>
                                        <InputSelectReact
                                            id={"role_id"}
                                            has_error={
                                                this.state.error_field.role
                                            }
                                            error_field={
                                                this.state.error_field.role
                                            }
                                            defaultValue={{
                                                label: this.state.role_nm,
                                                value: this.state.role_id,
                                            }}
                                            options={this.state.list_role}
                                            onChange={(item) =>
                                                this.handleChange(
                                                    "role_id",
                                                    item.value
                                                )
                                            }
                                        />
                                         <label
                                            htmlFor={"ruas_id"}
                                            className="control-label"
                                        >
                                            Ruas
                                        </label>
                                         <InputSelectReact
                                            id={"ruas_id"}
                                            has_error={
                                                this.state.error_field.ruas
                                            }
                                            error_field={
                                                this.state.error_field.role
                                            }
                                            defaultValue={{
                                                label: this.state.ruas_name,
                                                value: this.state.ruas_id,
                                            }}
                                            options={this.state.list_ruas}
                                            onChange={(item) =>
                                                this.handleChange(
                                                    "ruas_id",
                                                    item.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default UserAdd;
