import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";

import logo from "../../../resources/img/logo-full.png";

class Print extends Component {
     constructor (props) {
        super(props)

        this.state = {
            date: new Date(),
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
        }
    }
   render () {
        return (
            <div id="spgr-container">
                <div style={{ marginBottom: '20px' }}>
                    <img src={logo} alt="logo" style={{ maxWidth: '194px', width: '194px' }} />
                    <div style={{ marginTop: '5px', fontSize: '12px', whiteSpace: 'nowrap' }}>{ this.props.item.ruas_tol }</div>
                </div>
                <h3 style={{ textAlign: 'center', marginBottom: '5px' }}>SURAT PENYELESAIAN GANTI RUGI (SPGR)</h3>
                <div style={{ marginLeft: '22%', marginBottom: '20px' }}>No:</div>
                <div style={{ marginLeft: '35px' }}>
                    <table style={{ marginBottom: '10px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '100px' }}>Kepada</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>Kepala Induk PJR</td>
                            </tr>
                            <tr>
                                <td style={{ width: '100px' }}>Dari</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>Senkom</td>
                            </tr>
                            <tr>
                                <td style={{ width: '100px' }}>Perihal</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>Penyelesaian Ganti Rugi Sarana Tol</td>
                            </tr>
                            <tr>
                                <td style={{ width: '100px' }}>Tanggal</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.state.date.getDate() + '-' + this.state.months[this.state.date.getMonth()] + '-' + this.state.date.getFullYear() }</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr/>
                    <div style={{ marginBottom: '10px' }}>Sehubungan dengan terjadinya kecelakaan terhadap sebuah kendaraan :</div>
                    <table style={{ marginBottom: '10px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '172px' }}>ID Laka</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.props.item.laka_id }</td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Jenis Kendaraan</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Merk Kendaraan</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Pada Hari</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.props.item.laka_tanggal }</td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Pukul</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.props.item.laka_jam ? this.props.item.laka_jam.substr(0, 8) : '-' }</td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Di KM</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.props.item.laka_km }</td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Gerbang/Gardu</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td>{ this.props.item.laka_gerbang }/{ this.props.item.laka_gardu }</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginBottom: '10px', marginTop: '20px' }}>Pemilik/Penanggung Jawab</div>
                    <table style={{ marginBottom: '10px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '172px' }}>Nama Pemilik</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ width: '172px' }}>Alamat Pemilik</td>
                                <td style={{ width: '1%' }}>:</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginBottom: '10px', marginTop: '20px' }}>Dengan ini menerangkan bahwa yang bersangkutan telah mengalami kecelakaan di tol di jalan tol dan:</div>
                    <div style={{ marginBottom: '10px' }}>
                        <input type="checkbox" disabled />
                        <label style={{ marginLeft: '10px' }}>Terdapat kerugian Sarana Tol dan yang bersangkutan telah menyelesaikannya</label>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <input type="checkbox" disabled />
                        <label style={{ marginLeft: '10px' }}>Tidak ada kerugian Sarana Tol (Nihil)</label>
                    </div>
                    <div style={{ marginTop: '10px' }}>Untuk itu mohon bantuan penyelesaian selanjutnya.</div>
                    <div style={{ marginBottom: '20px' }}>Demikian hal ini kami sampaikan, atas kerjasamanya kami ucapkan terima kasih.</div>
                    <div>Petugas Senkom</div>
                    <div style={{ height: '75px', borderBottom: '1px solid grey', width: '110px' }}></div>
                    <div style={{ marginBottom: '75px' }}>NPK:</div>
                </div>
                <div><b>PT Marga Harjaya Infrastruktur</b></div>
                <div>Akses Tol Jombang Desa Pesantren Kec. Tembelang Kab. Jombang 61452</div>
                <div>Telp : (0321) 887200 (Hunting) Fax: (0321) 888003</div>
            </div>
        )
    }
}

export default Print;
