import React from "react";

// komponen
import { Table, Button, InputSelectReact } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        {
            width: "10%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={() => props.addRow()}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            )
        },
        { width: "90%", className: "text-center", label: "Nama Pegawai" }
    ];
};

const Tbody = props => {
    return props.list.map((list_item, index) => (
        <tr key={`shift_pegawai____${index}`}>
            <td className="text-center">
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={() =>
                        list_item.member_id
                            ? props.removeRowDB(list_item, index)
                            : props.removeRow(index)
                    }
                >
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
            <td>
                {list_item.member_id ? (
                    list_item.pegawai_name
                ) : (
                    <InputSelectReact
                        defaultValue={{
                            label: list_item.pegawai_name,
                            value: list_item.pegawai_id
                        }}
                        options={props.list_pegawai}
                        onChange={item =>
                            props.changeRow(index, item.value, item.label)
                        }
                    />
                )}
            </td>
        </tr>
    ));
};

const PegawaiTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada pegawai, silahkan klik tombol + untuk menambahkan pegawai"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default PegawaiTable;
