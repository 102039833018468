import React, { Component } from "react";

// komponen
import {
    SwalSuccess,
    SwalConfirm,
    SwalWarning,
} from "../../../components/Alert";
import { Content, Button } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import LangTable from "./table";

class Lang extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            list_code: [],
            // loading
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // component did mount
    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `setting/lang/code`)
                .then((result) => {
                    this.setState(
                        {
                            list_code: result.data.map((item) => ({
                                value: item.code_id,
                                label: item.code_desc,
                            })),
                        },
                        function () {
                            // load data
                            this.loadData();
                        }
                    );
                })
                .catch((error) => {});
        });
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `setting/lang`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        list: result.data.map((item) => ({
                            ...item,
                            error_field: {},
                        })),
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        if (label == "lang_key") {
            let exist = list.filter((item) => item.lang_key == value).length;
            if (exist) {
                list[index]["error_field"]["lang_key"] =
                    "Label tidak boleh sama";
            } else {
                list[index]["error_field"]["lang_key"] = "";
            }
        }
        list[index][label] = value;
        this.setState({ list: list });
    }
    //addRow
    addRow() {
        this.setState({
            list: [
                ...this.state.list,
                {
                    lang_id: "",
                    lang_code: "",
                    lang_key: "",
                    lang_desc: "",
                    error_field: {},
                },
            ],
        });
    }
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list: list });
    }
    // konfirmasi hapus
    deleteConfirm(item, index) {
        if (item.lang_id) {
            SwalConfirm(
                `Hapus Referensi Bahasa [${item.lang_desc}]`,
                "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
                () => this.deleteProcess(item, index)
            );
        } else {
            this.removeRow(index);
        }
    }
    // deleteprocess
    deleteProcess(item, index) {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `setting/lang/delete/${item.lang_id}`)
                .then((result) => {
                    // remove row
                    this.removeRow(index);
                })
                .catch((error) => {})
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // check exist
    checkExist() {
        const { list } = this.state;
        return list.filter((item) => Boolean(item.error_field.lang_key)).length;
    }
    // on submit
    onSubmit = (evt) => {
        evt.preventDefault();
        // this
        const that = this;
        // check exist
        if (this.checkExist()) {
            SwalWarning("Warning", "Periksa kembali inputan anda");
            return;
        }
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                lang: JSON.stringify(that.state.list),
            };
            // paggil post api
            PostPrivate(that, `setting/lang/update`, params)
                .then((result) => {
                    that.setState(
                        {
                            loading: false,
                        },
                        function () {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch((error) => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error,
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Translate API</h3>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <LangTable
                                {...this.props}
                                addRow={this.addRow}
                                handleChange={this.handleChange}
                                removeRow={this.removeRow}
                                deleteConfirm={this.deleteConfirm}
                                list={this.state.list}
                                list_code={this.state.list_code}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default Lang;
