import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import {
    Content,
    ButtonBack,
    Button,
    Loading
} from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// pages
import FieldBerita from "./field_berita";

import { EditorState, convertToRaw, ContentState,convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class BeritaEdit extends Component {
    constructor(props) {
        super(props);
        // berita id
        this.id_berita = props.match.params.id;
        // state
        this.state = {
            // field
            id_berita: "",
            judul_berita: "",
            id: "",
            isi_berita: "",
            id_bujt: "",

            gambar_berita: null,
            gambar64:"",
            nama_bujt: "",
            nama: "",
            // list data
            list_bujt: [],
            list_kategori: [],
            // error field
            error_field: {},
            // loading
            loading: false,
            loadingUpdate: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // loadDetail
        this.loadDetail();
    }

    // state = {
    //     editorState: EditorState.createWithContent(
    //         ContentState.createFromBlockArray(
    //           convertFromHTML(result[2].data.isi_berita)
    //         ))
    //   }
    
      onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
      };

    // load data
    loadDetail() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `callcenter/callcenter/bujt`),
                GetPrivate(this, `berita/berita/kategori`),
                GetPrivate(this, `berita/berita/detail/${this.id_berita}`)
            ])
                .then(result => {
                    const bujt = result[0].data.map(item => ({
                        value: item.id_bujt,
                        label: item.nama_bujt
                    }));
                    // kategori
                    const kategori = result[1].data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_bujt: bujt,
                        list_kategori: kategori,

                        // data berita
                        id_berita: result[2].data.id_berita,
                        judul_berita: result[2].data.judul_berita,
                        isi_berita: result[2].data.isi_berita,
                        editorState : EditorState.createWithContent(
                                     ContentState.createFromBlockArray(
                                   convertFromHTML(result[2].data.isi_berita)
                                    )),
                        id: result[2].data.id,
                        nama: result[2].data.nama,
                        id_bujt: result[2].data.id_bujt,
                        nama_bujt: result[2].data.nama_bujt,
                        gambar_berita: result[2].data.gambar_berita,
                        gambar64: result[2].data.file64,
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            gambar_berita: picture[0]
        });
    }
    // loadData
    loadData(q, url, value, label) {
        // list kabupaten
        return GetPrivate(this, `${url}?q=${q}`)
            .then(result => {
                //return
                return result.data.map(item => ({
                    value: item[value],
                    label: item[label]
                }));
            })
            .catch(error => {
                return [];
            });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        const { editorState } = this.state;
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.gambar_berita) {
            image = await toBase64(that.state.gambar_berita)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loadingUpdate: true }, function() {
            //params
            const params = {
                id_berita: that.state.id_berita,
                judul_berita: that.state.judul_berita,
                id: that.state.id,
                isi_berita: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                id_bujt: that.state.id_bujt,
                gambar_berita: image
            };
            // paggil post api
            PostPrivate(that, `berita/berita/edit`, params)
                .then(result => {
                    // hapus loading
                    that.setState({
                        loadingUpdate: false,
                        error_field: {}
                    });
                    // pesan
                     // - redirect
                     that.props.history.replace(`/dashboard/berita/berita`);
                     // pesan
                     SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loadingUpdate
                    that.setState({
                        loadingUpdate: false
                    });
                });
        });
    }
    // render
    render() {
        const { editorState } = this.state;
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Ubah Data Berita</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            {this.state.loading ? (
                                <Loading />
                            ) : (
                                <div className="row">
                                    <div className="col-md-4">
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText="Pilih Gambar"
                                            onChange={this.handleDrop}
                                            imgExtension={[
                                                ".jpg",
                                                ".gif",
                                                ".png",
                                                ".gif"
                                            ]}
                                            maxFileSize={5242880}
                                            singleImage={true}
                                            withPreview={true}
                                        />
                                        {this.state.gambar64 != '' && (
                                          <img src={this.state.gambar64} style={{width:"100%"}} />          
                                        )}
                                    </div>
                                    <div className="col-md-8">
                                        <FieldBerita
                                            hasDefaultOptions={Boolean(
                                                this.id_berita
                                            )}
                                            id_berita={this.state.id_berita}
                                            judul_berita={this.state.judul_berita}
                                            id={this.state.id}
                                            nama={this.state.nama}
                                            // isi_berita={this.state.isi_berita}
                                            id_bujt={this.state.id_bujt}
                                            nama_bujt={this.state.nama_bujt}
                                            loadData={this.loadData}
                                            handleChange={this.handleChange}
                                            error_field={this.state.error_field}
                                            list_kategori={this.state.list_kategori}
                                            list_bujt={this.state.list_bujt}
                                        />

                                    </div>

                                    <div className="col-md-12">
                                    <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange}
                                    />
                                    {/* <textarea
                                    disabled
                                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    /> */}
                            
                                </div>
                                </div>
                            )}
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loadingUpdate}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default BeritaEdit;
