import React, { Component } from "react";

// komponen
import { Content, Button } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import FormTable from "./table";

class FormReport extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: false,
            list: [],
            execution_id: ""
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "user/form/form_sarpras_pik")
                .then(result => {
                    this.setState({
                        loading: false,
                        execution_id: result.data.execution_id,
                        list: result.data.list_form
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                execution_id: this.state.execution_id,
                form: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `user/form/update/form_sarpras_pik`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Laporan Serah Terima Sarana dan Prasarana PIK
                        </h3>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <FormTable
                                list={this.state.list}
                                loading={this.state.loading}
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default FormReport;
