import React, { Component } from "react";
import { Modal, Button } from "../../../components/AdminLTE";
import { ExcelRenderer } from "react-excel-renderer";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";
import UploadTable from "./table_upload";

class UploadModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            status_delete: "",
            // loading
            loading: false
        };
        // bind
        this.fileHandler = this.fileHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.changeRow = this.changeRow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {}
    //fileHandler
    fileHandler = event => {
        let fileObj = event.target.files[0];
        // loop
        ExcelRenderer(fileObj, (err, response) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    list: response.rows
                        .filter(
                            (list_item, index) =>
                                index > 0 &&
                                (list_item[0] ||
                                    list_item[1] ||
                                    list_item[2] ||
                                    list_item[3] ||
                                    list_item[4]
                        ))
                        .map((list_item, index) => ({
                            key: `fasilitas_fasilitas____${index}`,
                            nama_deskripsi: list_item[0],
                            longitude: list_item[1],
                            lattitude: list_item[2],
                            deskripsi: list_item[3],
                            kategori_fasilitas: list_item[4],
                            status: ""
                        }))
                });
            }
        });
    };
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({
            list: list
        });
    }
    // row change
    changeRow(index, value, label) {
        const { list } = this.state;
        list[index]["pegawai_id"] = value;
        list[index]["pegawai_name"] = label;
        // set state
        this.setState({ list: list });
    }

    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }

    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;

         //params
         const params2 = {
            status_delete:that.state.status_delete
        };

        if(that.state.status_delete === '2'){
        // paggil post api
        PostPrivate(that, `fasilitas/fasilitas/uploadel`, params2)
            .then(result => {
                that.setState(
                    {
                        loading: false
                    },
                    function() {
                        // close modal
                        // that.props.toogleShow();
                        // load data parent
                        // that.props.loadData();
                        // pesan
                        SwalSuccess("Berhasil", result.message);
                    }
                );
            }).catch(error => {
                if (typeof error == "object") {
                    that.setState({
                        error_field: error
                    });
                }
                // hapus loading
                that.setState({
                    loading: false
                });
            });
        

        } 

        // api request
        that.setState({ loading: true }, function() {
            // params
            let { list } = that.state;
         
            list.map((item, index) => {
                setTimeout(function timer() {
                const params = {
                    nama_deskripsi: item.nama_deskripsi,
                    longitude: item.longitude,
                    lattitude: item.lattitude,
                    deskripsi: item.deskripsi,
                    kategori_fasilitas: item.kategori_fasilitas,
                    // status_delete:that.state.status_delete
                };
                // paggil post api
                PostPrivate(that, `fasilitas/fasilitas/upload`, params)
                    .then(result => {
                        // pesan
                        SwalSuccess("Berhasil", result.message);
                        that.setState(
                            {
                            list: list
                        },
                        );
                    })
                    .catch(error => {})
                    .finally(result => {
                        if (index >= list.length - 1)
                            that.setState({
                                loading: false
                            });
                    });
                }, index * 1000);
            });
        });
    };
    //render
    render() {
        // return
        return (
            <Modal
                form={true}
                title="Upload Fasilitas"
                content={
                    <>
                        <div className="form-group mb-1">
                            <input
                                type="file"
                                onChange={this.fileHandler}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group row">
                <label
                    htmlFor={"pegawai_st__1"}
                    className="control-label col-md-2"
                >
                    Pilih Opsi Hapus :
                </label>
                <div className="col-md-6">
                <div onChange={ev =>
                            this.handleChange("status_delete", ev.target.value)
                        }>
                        <input type="radio" value="1" name="status_delete" id={"status_delete"} defaultChecked={this.state.status_delete}/>  Tidak Hapus data sebelumnya &emsp;
                        <input type="radio" value="2" name="status_delete" id={"status_delete"} defaultChecked={this.state.status_delete}/> Hapus data sebelumnya 
                    </div>
                </div>
            </div>
                        <UploadTable
                            list={this.state.list}
                            removeRow={this.removeRow}
                            changeRow={this.changeRow}
                        />
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Upload
                    </Button>
                }
                show={this.props.show}
                onDismiss={() => this.props.toogleShow()}
                onSubmit={this.onSubmit}
                size="large"
            />
        );
    }
}

export default UploadModal;
