import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import {
    Content,
    ButtonBack,
    Button,
    Loading
} from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// pages
import FieldBiodata from "./field_biodata";
import FieldAkun from "./field_akun";

class UserEdit extends Component {
    constructor(props) {
        super(props);
        // pegawai id
        this.pegawai_id = props.match.params.id;
        // state
        this.state = {
            // field
            pegawai_nik: "",
            pegawai_name: "",
            pegawai_birth: "",
            pegawai_birth_place: "",
            pegawai_gender: "",
            ruas_id: "",
            jabatan_id: "",
            unit_id: "",
            pegawai_pass: "",
            pegawai_st: "",
            role_id: "",
            pegawai_image: null,
            pegawai_ruas: "",
            pegawai_jabatan: "",
            pegawai_unit: "",
            pegawai_birth_place_name: "",
            role_nm: "",
            // list data
            list_jabatan: [],
            list_ruas: [],
            list_unit: [],
            // error field
            error_field: {},
            // loading
            loading: false,
            loadingUpdate: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // loadDetail
        this.loadDetail();
    }
    // load data
    loadDetail() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `referensi/jabatan`),
                GetPrivate(this, `referensi/unit`),
                GetPrivate(this, `referensi/ruas`),
                GetPrivate(this, `pegawai/pegawai/detail/${this.pegawai_id}`)
            ])
                .then(result => {
                    // jabatan
                    const jabatan = result[0].data.map(item => ({
                        value: item.jabatan_id,
                        label: item.jabatan_name,
                        role_id: item.role_id
                    }));
                    // unit
                    const unit = result[1].data.map(item => ({
                        value: item.unit_id,
                        label: item.unit_name
                    }));
                    // ruas
                    const ruas = result[2].data.map(item => ({
                        value: item.ruas_id,
                        label: item.ruas_name
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_jabatan: jabatan,
                        list_unit: unit,
                        list_ruas: ruas,
                        // data pegawai
                        pegawai_nik: result[3].data.pegawai_nik,
                        pegawai_name: result[3].data.pegawai_name,
                        pegawai_birth: result[3].data.pegawai_birth,
                        pegawai_birth_place: result[3].data.pegawai_birth_place,
                        pegawai_gender: result[3].data.pegawai_gender,
                        pegawai_pass: result[3].data.pegawai_pass,
                        pegawai_st: result[3].data.pegawai_st,
                        pegawai_birth_place_name:
                            result[3].data.pegawai_birth_place_name,
                        jabatan_id: result[3].data.jabatan_id,
                        pegawai_jabatan: result[3].data.pegawai_jabatan,
                        ruas_id: result[3].data.ruas_id,
                        pegawai_ruas: result[3].data.pegawai_ruas,
                        unit_id: result[3].data.unit_id,
                        pegawai_unit: result[3].data.pegawai_unit,
                        role_id: result[3].data.role_id,
                        role_nm: result[3].data.role_nm
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            pegawai_image: picture[0]
        });
    }
    // loadData
    loadData(q, url, value, label) {
        // list kabupaten
        return GetPrivate(this, `${url}?q=${q}`)
            .then(result => {
                //return
                return result.data.map(item => ({
                    value: item[value],
                    label: item[label]
                }));
            })
            .catch(error => {
                return [];
            });
    }
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.pegawai_image) {
            image = await toBase64(that.state.pegawai_image)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loadingUpdate: true }, function() {
            //params
            const params = {
                pegawai_id: that.pegawai_id,
                pegawai_nik: that.state.pegawai_nik,
                pegawai_pass: that.state.pegawai_pass,
                pegawai_name: that.state.pegawai_name,
                pegawai_gender: that.state.pegawai_gender,
                pegawai_birth: that.state.pegawai_birth,
                pegawai_birth_place: that.state.pegawai_birth_place,
                ruas_id: that.state.ruas_id,
                jabatan_id: that.state.jabatan_id,
                role_id: that.state.role_id,
                unit_id: that.state.unit_id,
                pegawai_image: image,
                pegawai_st: that.state.pegawai_st
            };
            // paggil post api
            PostPrivate(that, `pegawai/pegawai/edit`, params)
                .then(result => {
                    // hapus loading
                    that.setState({
                        loadingUpdate: false,
                        error_field: {}
                    });
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loadingUpdate
                    that.setState({
                        loadingUpdate: false
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Ubah Data Pegawai</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            {this.state.loading ? (
                                <Loading />
                            ) : (
                                <div className="row">
                                    <div className="col-md-4">
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText="Pilih Gambar"
                                            onChange={this.handleDrop}
                                            imgExtension={[
                                                ".jpg",
                                                ".gif",
                                                ".png",
                                                ".gif"
                                            ]}
                                            maxFileSize={5242880}
                                            singleImage={true}
                                            withPreview={true}
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <FieldBiodata
                                            hasDefaultOptions={Boolean(
                                                this.pegawai_id
                                            )}
                                            pegawai_nik={this.state.pegawai_nik}
                                            pegawai_name={
                                                this.state.pegawai_name
                                            }
                                            pegawai_birth_place={
                                                this.state.pegawai_birth_place
                                            }
                                            pegawai_birth={
                                                this.state.pegawai_birth
                                            }
                                            pegawai_gender={
                                                this.state.pegawai_gender
                                            }
                                            jabatan_id={this.state.jabatan_id}
                                            unit_id={this.state.unit_id}
                                            ruas_id={this.state.ruas_id}
                                            pegawai_jabatan={
                                                this.state.pegawai_jabatan
                                            }
                                            pegawai_ruas={
                                                this.state.pegawai_ruas
                                            }
                                            pegawai_unit={
                                                this.state.pegawai_unit
                                            }
                                            pegawai_birth_place_name={
                                                this.state
                                                    .pegawai_birth_place_name
                                            }
                                            role_id={this.state.role_id}
                                            role_nm={this.state.role_nm}
                                            loadData={this.loadData}
                                            handleChange={this.handleChange}
                                            error_field={this.state.error_field}
                                            list_jabatan={
                                                this.state.list_jabatan
                                            }
                                            list_ruas={this.state.list_ruas}
                                            list_unit={this.state.list_unit}
                                        />
                                        <FieldAkun
                                            hasDefaultOptions={Boolean(
                                                this.pegawai_id
                                            )}
                                            pegawai_pass={
                                                this.state.pegawai_pass
                                            }
                                            pegawai_st={this.state.pegawai_st}
                                            loadData={this.loadData}
                                            handleChange={this.handleChange}
                                            error_field={this.state.error_field}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loadingUpdate}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default UserEdit;
