import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// default image
import img_default from "../../resources/img/img-default.jpg";

const ProfilePegawaiSidebar = (props) => {
    return (
        <div className="box">
            <div className="box-body mt-1">
                <div className="text-center">
                    <img
                        src={
                            props.session.file64
                                ? props.session.file64
                                : img_default
                        }
                        className="img-circle"
                        alt="Profil"
                        width="100"
                        height="100"
                    />
                    <h4>{props.session.pegawai_name}</h4>
                </div>
                <hr className="hr-muted" />
                <div className="row mt-2 mb-2">
                    <div className="col-md-6">Unit</div>
                    <div className="col-md-6 text-right text-bold">
                        {props.session.pegawai_unit}
                    </div>
                </div>
                <hr className="hr-muted" />
                <div className="row mt-2 mb-2">
                    <div className="col-md-6">Jabatan</div>
                    <div className="col-md-6 text-right text-bold">
                        {props.session.pegawai_jabatan}
                    </div>
                </div>
                <hr className="hr-muted" />
                <div className="row mt-2 mb-2">
                    <div className="col-md-6">Hak Akses</div>
                    <div className="col-md-6 text-right text-bold">
                        {props.session.pegawai_role}
                    </div>
                </div>
            </div>
        </div>
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, null)(ProfilePegawaiSidebar)
);
