import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const TileManagemen = props => {
    const [state, setState] = useState(0);
    // panggil get api
    GetPrivate(this, props.url)
        .then(result => {
            setState(result.data);
        })
        .catch(error => {});
    // return
    return (
        <div className={classNames("small-box mt-2", props.height)} style={{backgroundColor: props.background}}>
            <div className="inner">
                <p className="fonts text-center" style={{color: props.titleStyle, fontSize: '24px'}}>{props.title}</p>
                <h3 className="fonts text-center mt-3" style={{color: props.titleStyle, fontSize: '45px'}} >{state} <p>{props.desc}</p></h3>

            <p className="fonts text-center mt-2" style={{color: props.titleStyle, fontSize: '14px'}}>
            <NavLink to={props.linkTo} className="fonts text-center" style={{color: '#FFFFFF'}}>
            Lihat Lebih Lanjut <i className="fa fa-arrow-circle-right "></i>
            </NavLink>
            </p>
            </div>
        </div>
    );
};

export default TileManagemen;
