import React, { Component } from "react";

// komponen
import { Timeline, Loading } from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";

class MonitoringTimeline extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            loading: false
        };
    }
    componentDidMount() {
        this.loadData();
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `pik/monitoring`)
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list: result.data.map(item => {
                            let icon = "";
                            let type = "muted";
                            switch (item.report_status) {
                                case "accept":
                                    icon = "fa fa-check";
                                    type = "success";
                                    break;
                                case "reject":
                                    icon = "fa fa-times";
                                    type = "danger";
                                    break;
                                case "pending":
                                    icon = "fa fa-refresh fa-spin";
                                    type = "info";
                                    break;
                            }
                            return {
                                icon: icon,
                                type: type,
                                title: item.flow_description,
                                content: item.report_status ? (
                                    <i>[ {item.report_status} ]</i>
                                ) : (
                                    ""
                                ),
                                footer: item.report_note && (
                                    <p>
                                        <br />
                                        Catatan Penolakan : <br />
                                        {item.report_note}
                                    </p>
                                ),
                                time: item.created_at
                            };
                        })
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    render() {
        return this.state.loading ? (
            <Loading />
        ) : (
            <Timeline title="Monitoring Laporan" list={this.state.list} />
        );
    }
}

export default MonitoringTimeline;
