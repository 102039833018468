import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "ID Grup",
        selector: "group_id",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Grup",
        selector: "group_name",
        sortable: true,
        width: "25%"
    },
    {
        name: "Deskripsi Grup",
        selector: "group_desc",
        width: "50%"
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map(item => {
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const GroupsTable = props => {
    return (
        <TableBootstrap
            keyField={"grups"}
            empty="Belum ada grups, klik tombol + untuk menambahkan grups baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default GroupsTable;
