import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess, SwalNoIcon } from "../../../components/Alert";
import Header from "./header";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";

import EventTable from "./table";
import EventSearch from "./search";
import EventModal from "./modal";
import BatalModal from "./modal_batal";
import AlasanModal from "./modal_alasan";


class EventCalendar extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            event_name : "",
            event_start : "",
            event_end : "",
            loading: false,
            list: [],
            list_status:[],
            list_status_data:[],
            modal:false,
            modal2:false,
            modal3:false,
            item: null,
        }
        
        this.handleChange       = this.handleChange.bind(this);
        this.reloadData         = this.reloadData.bind(this);
        this.detailAlasan         = this.detailAlasan.bind(this);
        this.handleOnChange     = this.handleOnChange.bind(this);
        this.showDetail         = this.showDetail.bind(this);
        this.showDetail2         = this.showDetail2.bind(this);
        this.showDetail3         = this.showDetail3.bind(this);
    }
    
    
    componentDidMount() {
        this.loadRef();
        this.loadData();
    }
    
    
    handleOnChange(index) {
        let role_data = this.state.list_status_data;
        
        if (role_data[index] == false) {
            role_data[index] = true;
        } else {
            role_data[index] = false;
        }
        
       
        this.setState({ list_status_data: role_data });
    }
    
    showDetail(item = null) {
        if (item != null) {
            
            this.setState({item: item }, function() {
                this.setState({ modal: !this.state.modal });
            }); 
        } else {
            this.setState({ modal: !this.state.modal });
        }
        
    }
 
    reloadData() {
        // test
        this.setState({ loading: true }, function() {
            const params = {
                cctv  : this.state.search_cctv,
                list_status : this.state.list_status,
                list_status_data : this.state.list_status_data,
                event_start:this.state.event_start,
                event_end:this.state.event_end
            };
            
            PostPrivate(
                this,
                'event/event',
                params
            )
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
        
    }
    
    loadRef() {
        GetPrivate(this, `event/event/list_status`)
            .then(result => {
                //console.log(result);


                let role_data = [];

                result.data.map((dat, index) => {
                    role_data.push(false);
                });

                this.setState({
                    list_status: result.data,
                    list_status_data:role_data
                });
            })
            .catch(error => {});
    }
 
    
    loadData() {
        this.setState({ loading: true }, function() {
            const params = {
                cctv  : this.state.search_cctv,
                list_status : this.state.list_status,
                list_status_data : this.state.list_status_data,
                event_start:this.state.event_start,
                event_end:this.state.event_end
            };
            
            PostPrivate(
                this,
                'event/event',
                params
            )
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    showDetail2(item = null) {
        if (item != null) {          
            this.setState({item: item }, function() {
                this.setState({ modal2: !this.state.modal2 });
            }); 
        } else {
            this.setState({ modal2: !this.state.modal2 });
        }
        
    }

    showDetail3(item = null) {
        if (item != null) {          
            this.setState({item: item }, function() {
                this.setState({ modal3: !this.state.modal3 });
            }); 
        } else {
            this.setState({ modal3: !this.state.modal3 });
        }
        
    }

    detailAlasan(item) {
        SwalNoIcon(
            `Alasan Dibatalkan`,
            `${item.alasan_dibatalkan}`
        );
    }
    
    render() {
        return (
                <Content
                    title={this.props.pagetitle}
                    breadcrumb={this.props.breadcrumb}
                >
                <Header
                    toogleShow={this.toogleShow}
                />
                <BoxCollapse
                    open={false}
                    title="Event Terkirim"
                    icon_show={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                    icon_hide={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                >
                 <EventSearch
                        handleChange={this.handleChange}
                        onSubmit={this.onSubmit}
                        event_name={this.state.event_name}
                        event_start={this.state.event_start}
                        event_end={this.state.event_end}
                        handleOnChange={this.handleOnChange}
                        reloadData={this.reloadData}
                        list_status={this.state.list_status}
                        list_status_data={this.state.list_status_data}
                    />
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <EventTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            showDetail={this.showDetail}
                            detailAlasan={this.detailAlasan}
                            showDetail2={this.showDetail2}
                            showDetail3={this.showDetail3}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <EventModal
                        item={this.state.item}
                        show={this.state.modal}
                        showDetail={() => this.showDetail()}
                    />
                )}
                
                {this.state.modal2 && (
                            <BatalModal
                                showDetail2={() => this.showDetail2()}
                                item={this.state.item}
                                show={this.state.modal2}
                                reloadData={this.reloadData}
                            />
                        )}
                {this.state.modal3 && (
                            <AlasanModal
                                showDetail3={() => this.showDetail3()}
                                item={this.state.item}
                                show={this.state.modal3}
                            />
                        )}
                </Content>
        )
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
}


export default EventCalendar;