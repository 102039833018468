import React from "react";

// komponen
import Breadcrumb from "./breadcrumb";

const ContentManage = props => {
    return (
        <>
            <section className="content container-fluid" style={{zoom: props.zoom}}>
                {props.children}    
            </section>
        </>
    );
};

export default ContentManage;
