import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import {
    Content,
    ButtonBack,
    Button,
    Loading
} from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// pages
import FieldEvent from "./field_event";

class EventEdit extends Component {
    constructor(props) {
        super(props);
        // pegawai id
        this.id = props.match.params.id;
        // state
        this.state = {
            // field
            name: "",
            date: "",
            time: "",
            location: "",
            description: "L",
            status: "",
            status_name : "",
            file: null,
            file64:"",
            file_array: [],
            error_field: {},
            // loading
            loading: false,
            loadingUpdate: false,
            list_status : [{value : '1', label : 'Aktif'}, {value : '0', label : 'Tidak AKtif'}]
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // loadDetail
        this.loadDetail();
    }
    // load data
    loadDetail() {
        // panggil get api
        
        this.setState({ loading: true}, function() {
            Promise.all([
                GetPrivate(this, `event/event/detail/${this.id}`)
            ]).then(result => {
                //console.log(result);
                
                this.setState({
                    loading: false,
                    name: result[0].data.name,
                    date: result[0].data.date,
                    time: result[0].data.time,
                    location: result[0].data.location,
                    description: result[0].data.description,
                    status: result[0].data.status,
                    status_name : result[0].data.status_name,
                    file: result[0].data.file,
                    file64: result[0].data.file64,
                    file_array : [result[0].data.file]
                });
                
                if (result[0].data.file == '') {
                    
                    this.setState({
                        file_array : [result[0].data.base_url+'resources/image/event/not_found.jpg']
                    });
                }
                
            }).catch(error => {});
            
            
        });
       
        
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            file: picture[0]
        });
    }

    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.file) {
            image = await toBase64(that.state.file)
                .then(result => result)
                .catch(error => null);
        
            if (!image) {
                if (this.state.file64) {
                    image = this.state.file64;
                }
                
               
            }
        } else if (this.state.file64) {
            if (this.state.file64) {
                image = this.state.file64;
            }
        }
        
        //return false;
        // api request
        that.setState({ loadingUpdate: true }, function() {
            //params
            const params = {
                id: that.id,
                name: that.state.name,
                date: that.state.date,
                time: that.state.time,
                location: that.state.location,
                description: that.state.description,
                status: that.state.status,
                file: image
            };
            // paggil post api
            PostPrivate(that, `event/event/edit`, params)
                .then(result => {
                    // hapus loading
                    that.setState({
                        loadingUpdate: false,
                        error_field: {}
                    });
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loadingUpdate
                    that.setState({
                        loadingUpdate: false
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Ubah Data Pegawai</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            {this.state.loading ? (
                                <Loading />
                            ) : (
                                <div className="row">
                                    <div className="col-md-4">
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText="Pilih Gambar"
                                            onChange={this.handleDrop}
                                            imgExtension={[
                                                ".jpg",
                                                ".gif",
                                                ".png",
                                                ".gif"
                                            ]}
                                            maxFileSize={5242880}
                                            singleImage={true}
                                            withPreview={true}
                                            defaultImages={this.state.file_array}
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <FieldEvent
                                        event_name={this.state.name}
                                        event_date={this.state.date}
                                        event_time={this.state.time}
                                        event_location={this.state.location}
                                        event_description={this.state.description}
                                        event_status={this.state.status}
                                        event_status_name={this.state.status_name}
                                        list_status={this.state.list_status}
                                        handleChange={this.handleChange}
                                        error_field={this.state.error_field}
                                    />
                                       
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loadingUpdate}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default EventEdit;
