import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import Config from "../../../utils/Config";
import image_placeholder from "../../../resources/img/placeholder.png";
import { Button } from "../../../components/AdminLTE";
import Print from "./print";
import rupiahku from 'rupiahku';

class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: false,
            _foto1: image_placeholder,
            _foto2: image_placeholder,
            _foto3: image_placeholder,
            id: null,
            no_laporan: null,
            tanggal: null,
            jam: null,
            km: null,
            km_to: null,
            lokasi_km: null,
            lokasi_gerbang: null,
            lokasi_gardu: null,
            lokasi_lain: null,
            kategori_kejadian: null,
            deskripsi_singkat: null,
            foto1: null,
            foto2: null,
            foto3: null,
            ruas_tol: null,
            nama_penyebab_laka: null,
            jml_korban_md: null,
            jml_korban_lb: null,
            jml_korban_lr: null,
            jml_kendaraan_terlibat: null,
            unit_patroli: null,
            ambulan_kp: null,
            ambulan_kh: null,
            unit_rescue: null,
            unit_derek: null,
            unit_satgas: null,
            unit_pjr: null,
            unit_damkar: null,
            uraian_kejadian: null,
            kendaraan_terlibat: [],
            asset_rusak: [],
            ganti_rugi: [],
            list_sk: [],
        }

        this.loadDetail = this.loadDetail.bind(this)
        this.loadData = this.loadData.bind(this)
    }
    componentDidMount () {
        this.loadDetail()
        this.loadData()
    }
    loadDetail () {
        this.setState({ loading: true })
        GetPrivate(this, 'laporan/laka/detail/' + this.props.id).then(result => {
            const formatter = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
              })
  
              let total = 0
              let terbi = 0
              for (let i in result.data.asset_rusak) {
                  let asset = result.data.asset_rusak[i]
                  total += parseFloat(asset.total_harga)
  
                  result.data.asset_rusak[i].asset_harga = formatter.format(result.data.asset_rusak[i].asset_harga)
                  result.data.asset_rusak[i].total_harga = formatter.format(result.data.asset_rusak[i].total_harga)
              }
              const total_raw = total
              terbi = rupiahku.wording(total)
              total = formatter.format(total)
            this.setState({
                total_ganti_rugi: total,
                terbilang: terbi,
                total_ganti_rugi_raw: total_raw,
                id: result.data.id,
                no_laporan: result.data.no_laporan,
                tanggal: result.data.tanggal,
                jam: result.data.jam,
                km: result.data.km,
                km_to: result.data.km_to,
                lokasi_km: result.data.lokasi_km,
                lokasi_gerbang: result.data.lokasi_gerbang,
                lokasi_gardu: result.data.lokasi_gardu,
                lokasi_lain: result.data.lokasi_lain,
                kategori_kejadian: result.data.kategori_kejadian,
                deskripsi_singkat: result.data.deskripsi_singkat,
                foto1: result.data.foto1,
                foto2: result.data.foto2,
                foto3: result.data.foto3,
                ruas_tol: result.data.ruas_tol,
                nama_penyebab_laka: result.data.nama_penyebab_laka,
                jml_korban_md: result.data.jml_korban_md,
                jml_korban_lb: result.data.jml_korban_lb,
                jml_korban_lr: result.data.jml_korban_lr,
                jml_kendaraan_terlibat: result.data.jml_kendaraan_terlibat,
                unit_patroli: result.data.unit_patroli,
                ambulan_kp: result.data.ambulan_kp,
                ambulan_kh: result.data.ambulan_kh,
                unit_rescue: result.data.unit_rescue,
                unit_derek: result.data.unit_derek,
                unit_satgas: result.data.unit_satgas,
                unit_pjr: result.data.unit_pjr,
                unit_damkar: result.data.unit_damkar,
                uraian_kejadian: result.data.uraian_kejadian,
                kendaraan_terlibat: result.data.kendaraan_terlibat,
                ganti_rugi: result.data.ganti_rugi,
                asset_rusak: result.data.asset_rusak
            })
            if (result.data.foto1) {
                GetPrivate(this, 'user/lakalantas/image/' + result.data.foto1).then(image => { this.setState({ _foto1: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto1: image_placeholder })
            }
            if (result.data.foto2) {
                GetPrivate(this, 'user/lakalantas/image/' + result.data.foto2).then(image => { this.setState({ _foto2: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto2: image_placeholder })
            }
            if (result.data.foto3) {
                GetPrivate(this, 'user/lakalantas/image/' + result.data.foto3).then(image => { this.setState({ _foto3: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto3: image_placeholder })
            }
            if (this.props.onLoad) {
                this.props.onLoad({ item: result.data })
            }

            if (this.props.onLoad) {
                this.props.onLoad({ item: result.data, total_ganti_rugi: total, total_ganti_rugi_raw: total_raw, terbilang: terbi })
            }
            this.setState({ loading: false })
        }).catch(error => { this.setState({ loading: false }) })
    }

    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/DasarGantiRugi/report")
                .then(result => {
                    this.setState({
                        loading: false,
                        list_sk: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    print () {
        const divContents = document.getElementById('print-container').innerHTML
        var printWindow = window.open('', '', 'height=772,width=1000')
        printWindow.document.write('<html>')
        printWindow.document.write('<head></head>')
        printWindow.document.write('<body>')
        printWindow.document.write(divContents)
        printWindow.document.write('</body></html>')
        printWindow.document.close()
        setTimeout(function () {
            printWindow.print()
        }, 100)
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">No. Laporan</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.no_laporan || '-'}/>
                        </div>
                        { this.props.print &&
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-success" loading={ this.state.loading } onClick={() => this.print() }>Cetak</Button>
                            </div>
                        }
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Tanggal</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.tanggal || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jam</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.jam ? this.state.jam.substr(0, 8) : '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Lokasi KM</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.lokasi_km || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Lokasi Gerbang</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.lokasi_gerbang || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">Lokasi Gardu</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.lokasi_gardu || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">Kategori Kejadian</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.kategori_kejadian || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">Deskripsi Singkat</label>
                        <div className="col-md-8">
                            <input type="text" readOnly disabled className="form-control" value={this.state.deskripsi_singkat || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-4 col-form-label">Lokasi Lainnya</label>
                        <div className="col-md-8">
                            <textarea readOnly disabled className="form-control" value={this.state.lokasi_lain || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Gambar Lampiran</label>
                    </div>
                    <div className="row" style={{marginBottom: '40px'}}>
                        <div className="col-sm-4"><img src={this.state._foto1} className="img-fluid" alt="foto1" style={{maxWidth: '100%'}} /></div>
                        <div className="col-sm-4"><img src={this.state._foto2} className="img-fluid" alt="foto2" style={{maxWidth: '100%'}} /></div>
                        <div className="col-sm-4"><img src={this.state._foto3} className="img-fluid" alt="foto3" style={{maxWidth: '100%'}} /></div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Dugaan Penyebab Kecelakaan</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.nama_penyebab_laka || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jumlah Korban Meninggal</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.jml_korban_md || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Jumlah Kendaraan Terlibat</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.jml_kendaraan_terlibat || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jumlah Luka Berat</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.jml_korban_lb || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-sm-offset-6 col-form-label">Jumlah Luka Ringan</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.jml_korban_lr || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Detail Kendaraan</label>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                      <th scope="col">No</th>
                                      <th scope="col">Merk</th>
                                      <th scope="col">Seri</th>
                                      <th scope="col">Plat Nomor</th>
                                      <th scope="col">Jml Penumpang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.kendaraan_terlibat.map(function(row, i){
                                        return (
                                            <tr key={i + 1}>
                                                <td scope="row">{i + 1}</td>
                                                <td>{row.merk}</td>
                                                <td>{row.seri}</td>
                                                <td>{row.plat_nomor}</td>
                                                <td>{row.jml_penumpang}</td>
                                            </tr>
                                        )
                                    })}
                                    {this.state.kendaraan_terlibat.length === 0 &&
                                        <tr><td className="text-center" colSpan="5">- Tidak ada data- </td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit Penolong Patroli</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_patroli || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Unit Penolong Derek</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_derek || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit Penolong Ambulance</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.ambulan_kp || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Unit Penolong PJR</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_pjr || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit Penolong Satgas</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_satgas || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Unit Penolong Rescue</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_rescue || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit Penolong Damkar</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.unit_damkar || '-'}/>
                        </div>
                    </div>
                </div>
                {this.props.print &&
                    <div style={{ display: 'none' }}>
                        <Print item={this.state} />
                    </div>
                }
            </div>
        )
    }
}

export default Detail;
