import React, { Component } from "react";

// komponen
import { Modal } from "../../../components/AdminLTE";

const styles = {
    width: "100%",
    height: window.innerHeight,
    border: 0
};

const ReceiveModal = props => {
    // return
    return (
        <Modal
            size="large"
            content={<iframe style={styles} src={props.modal_content}></iframe>}
            show={props.show}
            onDismiss={props.toogleShow}
        />
    );
};

export default ReceiveModal;
