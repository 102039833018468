import React from "react";
import ReactDOM from "react-dom";
import Pace from "pace-js-amd-fix";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";

// style
import "./resources/bootstrap/dist/css/bootstrap.min.css";
import "./resources/font-awesome/css/font-awesome.min.css";
import "./resources/theme/css/AdminLTE.min.css";
import "./resources/theme/css/skins/skin-blue.css";
import "./resources/theme/css/style.css";
import "./resources/theme/css/custom.css";
// import "./resources/theme/css/font.css";
import "./resources/pace/pace-loading.css";

//redux config
const store = configureStore();

// pace js config
const paceOptions = {
    ajax: { trackMethods: ["GET", "POST", "DELETE", "PUT", "PATCH"] }
};
Pace.start(paceOptions);

ReactDOM.render(<App store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
