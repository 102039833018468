import React, { Component } from "react";
import classNames from "classnames";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../reducers/session/actions.js";
import setMenu from "../../reducers/menu/actions.js";

// utils
import Session from "../../utils/Session";
import GetPrivate, { PostPrivate } from "../../utils/Connection";

// router
import Router from "../../router";

//komponen
import { Header, Sidebar, Footer, LoadingBig, Management, Loading } from "../../components/AdminLTE";
import { Toast } from "../../components/Alert";

import Chat from "./chat";
import RefModal from "../referensi/member/modal";
import image_placeholder from "../../resources/img/placeholder.png";
const ref_title = "Laporan Event Customer";
const ref_url = "customer/member";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        //state
        this.state = {
            sidebar: true,
            loading: false,
            loading_sidebar: true,
            loading_logout: false,
            menu: [],
            list_cs: [],
            list_gr: [],
            user_id : null,
            default_page : null,
            total : null,
            total_rugi : null,
            modal: false,
            mode: "",
            item: null,
            list: [],
            q: ""
        };
        this.handleChange   = this.handleChange.bind(this);
        this.handleClick   = this.handleClick.bind(this);
        this.getProfile   = this.getProfile.bind(this);
        this.getCustomer   = this.getCustomer.bind(this);
        this.getUserMenu   = this.getUserMenu.bind(this);
        this.getTotal   = this.getTotal.bind(this);
        this.getGantiRugi   = this.getGantiRugi.bind(this);
        this.getTotalGantiRugi   = this.getTotalGantiRugi.bind(this);
        this.logoutProcess   = this.logoutProcess.bind(this);
        this.notifProcess   = this.notifProcess.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.getUserMenu();
        
    }

    handleClick () {
        this.setState({ q: "" });
        this.getUserMenu();
    }

    
    // did mount
    componentDidMount() {
        // get user profile
        this.getProfile();
        this.getCustomer();
        this.getTotal();
        this.getGantiRugi();
        this.getTotalGantiRugi();
        // this.getUserMenuSearch();
        
    }

    loadData() {
        this.getCustomer();
        this.getTotal();
        this.getGantiRugi();
        this.getTotalGantiRugi();
    }
    
    // get profile
    getProfile() {
        // panggil get api
        GetPrivate(this, "user/profile")
            .then(result => {
                // store
                    this.setState({
                        user_id: result.data.id,
                        default_page: result.data.default_page
                    })
                        
                this.props.setSession(result.data).then(result =>
                    this.getUserMenu(),
                    this.getUserMenuSearch()
                );
            })
            .catch(error => {});
    }
    // get user menu
    getUserMenu() {
        this.setState({ loading_sidebar: true }, function() {
            // panggil get api
            GetPrivate(this, `user/menu?q=${this.state.q}`)
                .then(result => {
                    this.props.setMenu(result.data).then(() =>
                        this.setState({
                            loading_sidebar: false,
                            menu: result.data
                        })
                    );
                })
                .catch(error => {
                    // set loading
                    this.setState({
                        loading_sidebar: false
                    });
                });
        });
    }

    getUserMenuSearch() {
        GetPrivate(this, `user/menu/menu?q=${this.state.q}`)
        .then(result => {
            this.setState({
                loading: false,
                menu_search: result.data
            })
        })
        .catch(error => {});
    }

    // get customer 
    getCustomer() {
            // panggil get api
            GetPrivate(this, "customer/member/notif")
                .then(result => {
                    this.setState({
                        loading: false,
                        list_cs: result.data
                    })
                })
                .catch(error => {});
    }
    
    // get customer 
    getTotal() {
            // panggil get api
            GetPrivate(this, "customer/member/total")
                .then(result => {
                        this.setState({
                            total: result.data
                        })            
                })
                .catch(error => {});
    }

    getTotalGantiRugi() {
        // panggil get api
        GetPrivate(this, "customer/member/total_rugi")
            .then(result => {
                let total_gr = result.data  
                    this.setState({
                        total_gr: result.data,
                        total_rugi: total_gr,
                    })            
            })
            .catch(error => {});
}
    getGantiRugi() {
        // panggil get api
        GetPrivate(this, "customer/member/ganti_rugi")
            .then(result => {         
                    this.setState({
                        list_gr: result.data
                    })            
            })
            .catch(error => {});
}

    //toogleShow
    toogleShow(mode = "", item = null) {
        if (item != null) {
            this.notifProcess(item)         
        }
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
        });
    }

    notifProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            const params = {
                id: item.id_notifikasi,
            };
            PostPrivate(this, `customer/member/edit`, params)
                .then(result => {
                    // load data
                    this.loadData();
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }

    //logoutProcess
    logoutProcess() {
        this.setState({ loading_logout: true }, function() {
            // panggil get api
            GetPrivate(this, "logout")
                .then(result => {
                    // remove session
                    Session.clearSession("session__jmto_admin");
                    Session.clearSession("default_page__jmto_admin");
                    // store
                    this.props.setSession({}).then(() => {
                        // redirect login
                        this.props.history.push("/");
                        // notif
                        Toast("Logout Berhasil");
                    });
                })
                .catch(error => {});
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        // load data
        this.getUserMenu();
    };
    // render
    render() {
        return (
            <>
            <div
                className={classNames(
                    "hold-transition",
                    "skin-blue",
                    "sidebar-mini",
                    { "sidebar-open": this.state.default_page === 'dashboard' ? !this.state.sidebar : this.state.sidebar},
                    { "sidebar-collapse": this.state.default_page === 'dashboard' ? !this.state.sidebar : this.state.sidebar }
                )}
            >
                <div className="wrapper">
                {this.state.default_page === 'dashboard' &&
                    <Header
                        {...this.props}
                        toogleSidebar={() =>
                            this.setState({ sidebar: !this.state.sidebar })
                        }
                        loading={this.state.loading_logout}
                        logoutProcess={this.logoutProcess}
                        notifProcess={this.notifProcess}
                        list_cs={this.state.list_cs}
                        list_gr={this.state.list_gr}
                        total_rugi={this.state.total_rugi}
                        toogleShow={this.toogleShow}
                    />
    }
    {this.state.default_page === 'dashboard' &&
                    <Sidebar
                        {...this.props}
                        menu={this.state.menu}
                        menu_search={this.state.menu_search}
                        q={this.state.q}
                        default_page={this.state.default_page}
                        handleChange={this.handleChange}
                        handleClick={this.handleClick}
                        onSubmit={this.onSubmit}
                        loading={this.state.loading_sidebar}
                    />}
                   
                     {this.state.default_page != 'dashboard' &&
                       <div>
                            {this.state.loading_sidebar ? (
                                ''
                       ) : (
                        <Management
                        {...this.props}
                        menu={this.state.menu}
                        menu_search={this.state.menu_search}
                        q={this.state.q}
                        default_page={this.state.default_page}
                        handleChange={this.handleChange}
                        handleClick={this.handleClick}
                        onSubmit={this.onSubmit}
                        loading={this.state.loading_logout}
                        logoutProcess={this.logoutProcess}
                    />
                       )}
                       {this.state.loading_sidebar && this.state.q == ''  ? (
                           <Loading />
                       ) : (
                           Router(this.state.menu)
                       )}
                   </div>
    }
                     {this.state.default_page === 'dashboard' &&
                    <div className="content-wrapper">
                        {this.state.loading_sidebar && this.state.q == ''  ? (
                            <LoadingBig />
                        ) : (
                            Router(this.state.menu)
                        )}
                    </div>
    }
                    {this.state.default_page === 'dashboard' &&
                    <Footer />}
                </div>
            </div>
            {this.state.modal && (
                    <RefModal
                        mode={this.state.mode}
                        item={this.state.item}
                        show={this.state.modal}
                        toogleShow={() => this.toogleShow()}
                        getCustomer={() => this.getCustomer()}
                        ref_title={ref_title}
                        ref_url={ref_url}
                    />
                )}
             {this.state.default_page === 'dashboard' &&
            <Chat />
                }
            </>
        );
    }
}

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
        menu: state.menu
    };
}
// dispatch / aksi -> props
function mapsDispatchToProps(dispatch) {
    return {
        setSession: params => {
            return new Promise((resolve, reject) => {
                dispatch(setSession(params));
                resolve();
            });
        },
        setMenu: params => {
            return new Promise((resolve, reject) => {
                dispatch(setMenu(params));
                resolve();
            });
        }
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, mapsDispatchToProps)(Dashboard)
);
