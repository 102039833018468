import React, { Component } from "react";

// komponen
import {
    Content,
    Input,
    InputSelectReact,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";

// pages
import Table from "./table";
import PegawaiSearch from "./search";
import Config from "../../../utils/Config";

class Realisasi extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            ruas_id: "",
            ruas_name: "",
            list_ruas: [],
            list: [],
            date: Dates.getTodayDB(),
            end: Dates.getTodayDB(),
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }
    componentDidMount() {
        // load data referensi
        this.loadRef();
    }
    // loadRef
    onExport = event => {
        GetPrivate(
            this,
            `pegawai/realisasi/export_data?d=${this.state.date}&end=${this.state.end}`
        )
            .then(result => {
                console.log(result);
                // set state
                this.setState({
                    loading: false,
                    list: result.data
                });
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
            });
    };
    loadRef() {
        GetPrivate(this, `referensi/ruas`)
            .then(result => {
                this.setState(
                    {
                        list_ruas: result.data.map(item => ({
                            value: item.ruas_id,
                            label: item.ruas_name
                        })),
                        ruas_id: result.data[0].ruas_id,
                        ruas_name: result.data[0].ruas_name
                    },
                    function() {
                        // loadData
                        this.loadData();
                    }
                );
            })
            .catch(error => {});
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(
                this,
                `pegawai/realisasi?d=${this.state.date}&end=${this.state.end}&ruas=${this.state.ruas_id}`
            )
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value }, function() {
            this.loadData();
        });
    }
    handleChangeEnd(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    // handle change select
    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                    
                <BoxCollapse open={true} title="Realisasi Jadwal Shift">
                    <PegawaiSearch
                        handleChange={this.handleChange}
                        handleChangeEnd={this.handleChangeEnd}
                        handleChangeSelect={this.handleChangeSelect}
                        date={this.state.date}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                        
                    />
                     <a
                        href={`${Config.basepath}webview/export_realisasi/${this.state.date}/${this.state.end}`}
                        // target="_BLANK"
                        className="btn btn-success mr-2"
                    >
                        <i className="fa fa-download mr-1"></i> Download
                        
                    </a>
                </BoxCollapse>
                <div className="box">
                <div className="form-group col-md-4">
                   
                </div>
                <div className="col-md-8 text-right">
                    {/* <a onClick={this.onExport}
                       className="btn btn-info mr-2"
                    >
                        <i className="fa fa-download mr-1"></i> Download
                        
                    </a> */}
                   
                </div>
                    <div className="box-body">
                        <Table
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default Realisasi;
