import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputTextarea,
    InputSelectReact,
} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class SarprasModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Laporan Pengaduan"
                : "Tambah Laporan Pengaduan";
        // state
        this.state = {
            list: [],
            // field input
            detail_id: props.item ? props.item.detail_id : "",
            detail_time: props.item ? props.item.detail_time : "",
            detail_from: props.item ? props.item.detail_from : "",
            detail_category: props.item ? props.item.pengaduan_id : "",
            detail_category_name: props.item ? props.item.nama_pengaduan : "",
            detail_description: props.item ? props.item.detail_description : "",
            detail_follow_up: props.item ? props.item.detail_follow_up : "",
            // error field
            error_field: {},
            // loading
            loading: false,
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, "referensi/kategoripengaduan")
                .then((result) => {
                    this.setState({
                        loading: false,
                        list: result.data.map((item) => ({
                            value: item.id,
                            label: item.nama,
                        })),
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = (evt) => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function () {
            //params
            const params = {
                detail_id: that.state.detail_id,
                detail_time: that.state.detail_time,
                detail_from: that.state.detail_from,
                detail_category: that.state.detail_category,
                detail_description: that.state.detail_description,
                detail_follow_up: that.state.detail_follow_up,
            };
            // paggil post api
            PostPrivate(that, `pik/complaint/${that.props.mode}`, params)
                .then((result) => {
                    that.setState(
                        {
                            loading: false,
                        },
                        function () {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch((error) => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error,
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false,
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    !this.state.loading && (
                        <>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_time"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Waktu
                                </label>
                                <div className="col-sm-3">
                                    <Input
                                        id={"detail_time"}
                                        type="time"
                                        placeholder="Waktu"
                                        has_error={
                                            this.state.error_field.detail_time
                                        }
                                        defaultValue={this.state.detail_time}
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_time",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field.detail_time
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_from"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Info Pengaduan
                                </label>
                                <div className="col-sm-9">
                                    <Input
                                        id={"detail_from"}
                                        type="text"
                                        placeholder="Info Pengaduan"
                                        has_error={
                                            this.state.error_field.detail_from
                                        }
                                        defaultValue={this.state.detail_from}
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_from",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field.detail_from
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_category"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Kategori Pengaduan
                                </label>
                                <div className="col-sm-6">
                                    <InputSelectReact
                                        defaultValue={{
                                            label: this.state
                                                .detail_category_name,
                                            value: this.state.detail_category,
                                        }}
                                        options={this.state.list}
                                        onChange={(item) =>
                                            this.handleChange(
                                                "detail_category",
                                                item.value
                                            )
                                        }
                                        has_error={
                                            this.state.error_field
                                                .detail_category
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_category
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_description"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Uraian
                                </label>
                                <div className="col-sm-9">
                                    <InputTextarea
                                        id={"detail_description"}
                                        type="text"
                                        placeholder="Uraian"
                                        has_error={
                                            this.state.error_field
                                                .detail_description
                                        }
                                        defaultValue={
                                            this.state.detail_description
                                        }
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_description",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_description
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"detail_follow_up"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Tindak Lanjut
                                </label>
                                <div className="col-sm-9">
                                    <InputTextarea
                                        id={"detail_follow_up"}
                                        type="text"
                                        placeholder="Tindak Lanjut"
                                        has_error={
                                            this.state.error_field
                                                .detail_follow_up
                                        }
                                        defaultValue={
                                            this.state.detail_follow_up
                                        }
                                        onChange={(ev) =>
                                            this.handleChange(
                                                "detail_follow_up",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .detail_follow_up
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default SarprasModal;
