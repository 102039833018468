import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumb }) => {
    // return
    return (
        <ol className="breadcrumb">
            {breadcrumb &&
                breadcrumb.map((item, index) =>
                    index < breadcrumb.length - 1 ? (
                        <li key={index}>
                            <Link to={item.link ? item.link : "#"}>
                                <i className={`${item.icon}`}></i>
                                {item.title}
                            </Link>
                        </li>
                    ) : (
                        <li key={index} className="active">
                            <i className={`${item.icon}`}></i>
                            {item.title}
                        </li>
                    )
                )}
        </ol>
    );
};

export default Breadcrumb;
