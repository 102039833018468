import React from "react";
import classNames from "classnames";

const InputSelect = props => {
    return (
        <div
            className={classNames("form-group", {
                "has-error": props.has_error
            })}
        >
            <select
                id={props.id}
                className="form-control"
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={props.onChange}
                autoFocus={props.autoFocus}
            >
                {props.option}
            </select>
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputSelect;
