import React from "react";

// komponen
import { Table, Input, InputSelectReact } from "../../../components/AdminLTE";

// list kondisi
const list_kondisi = [
    { value: "Baik", label: "Baik" },
    { value: "Cukup", label: "Cukup" },
    { value: "Rusak", label: "Rusak" },
    { value: "Habis", label: "Habis" },
    { value: "Kurang", label: "Kurang" },
];

// list follow_up
const list_follow_up = [
    { value: "Ganti", label: "Ganti" },
    { value: "Perbaikan", label: "Perbaikan" },
    { value: "Tambah", label: "Tambah" },
];

// table
const thead = (props) => {
    return [
        { width: "5%", className: "text-center", label: "No" },
        { width: "25%", className: "text-center", label: "Sarana" },
        {
            width: "10%",
            className: "text-center",
            label: "Volume",
        },
        {
            width: "10%",
            className: "text-center",
            label: "Satuan",
        },
        {
            width: "15%",
            className: "text-center",
            label: "Kondisi",
        },
        {
            width: "15%",
            className: "text-center",
            label: "Tindak Lanjut",
        },
        {
            width: "20%",
            className: "text-center",
            label: "Keterangan",
        },
    ];
};

const Tbody = (props) => {
    return props.list.map((item, index) => (
        <tr key={item.form_id}>
            <td className="text-center">{index + 1}</td>
            <td>{item.form_name}</td>
            <td>
                <Input
                    id={"form_qty" + index}
                    type="number"
                    placeholder="Volume"
                    defaultValue={item.form_qty}
                    onChange={(ev) =>
                        props.handleChange(index, "form_qty", ev.target.value)
                    }
                />
            </td>
            <td className="text-center">{item.form_unit}</td>
            <td>
                <InputSelectReact
                    id={"form_condition" + index}
                    defaultValue={{
                        label: item.form_condition,
                        value: item.form_condition,
                    }}
                    options={list_kondisi}
                    onChange={(item) =>
                        props.handleChange(index, "form_condition", item.value)
                    }
                />
            </td>
            <td>
                <InputSelectReact
                    id={"form_follow_up" + index}
                    defaultValue={{
                        label: item.form_follow_up,
                        value: item.form_follow_up,
                    }}
                    options={list_follow_up}
                    onChange={(item) =>
                        props.handleChange(
                            index,
                            "form_follow_up",
                            item ? item.value : null
                        )
                    }
                    isClearable={true}
                />
            </td>
            <td className="text-center">
                <Input
                    id={"form_description" + index}
                    type="text"
                    placeholder="Keterangan"
                    defaultValue={item.form_description}
                    onChange={(ev) =>
                        props.handleChange(
                            index,
                            "form_description",
                            ev.target.value
                        )
                    }
                />
            </td>
        </tr>
    ));
};

const FormTable = (props) => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada formulir serah terima, silahkan hubungi admin terlebih dahulu"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default FormTable;
