import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// komponen
import { Content, Tabs } from "../../components/AdminLTE";

// page
import SidebarUser from "./profile_user_sidebar";
import SidebarPegawai from "./profile_pegawai_sidebar";
import ProfileUser from "./profile_user";
import ProfilePegawai from "./profile_pegawai";
import ProfileHistory from "./profile_history";

// tabs content
const tabs_default = "tab_profile";
const tabs_contents = group_id => {
    return [
        {
            key: "tab_profile",
            title: "Update Biodata",
            content: group_id == "01" ? <ProfileUser /> : <ProfilePegawai />
        },
        {
            key: "tab_history",
            title: "Riwayat Login",
            content: <ProfileHistory />
        }
    ];
};

const Profile = props => {
    return (
        <Content
            title={props.pagetitle}
            subtitle={props.pagesubtitle}
            breadcrumb={props.breadcrumb}
        >
            <div className="row mt-1">
                <div className="col-md-3">
                    {props.session.group_id == "01" ? (
                        <SidebarUser {...props} />
                    ) : (
                        <SidebarPegawai {...props} />
                    )}
                </div>
                <div className="col-md-9">
                    <Tabs
                        list={tabs_contents(props.session.group_id)}
                        default={tabs_default}
                    />
                </div>
            </div>
        </Content>
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(Profile));
