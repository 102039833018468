import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "ID Aplikasi",
        selector: "portal_id",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Aplikasi",
        selector: "portal_nm",
        sortable: true,
        width: "60%"
    },
    {
        name: "Jumlah Menu",
        selector: "menu",
        width: "15%",
        center: true
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map(item => {
        item["action"] = (
            <ButtonRole
                role_url={`${props.location.pathname}/detail/:id`}
                role_type="role_read"
            >
                <NavLink
                    to={`${props.location.pathname}/detail/${item.portal_id}`}
                    className="btn btn-sm btn-default"
                >
                    Detail Menu
                </NavLink>
            </ButtonRole>
        );
        return item;
    });
};

const MenuTable = props => {
    return (
        <TableBootstrap
            keyField={"menu"}
            empty="Belum ada menu aplikasi, menu aplikasi dapat ditambahkan melalui menu aplikasi"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default MenuTable;
