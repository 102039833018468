import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";

class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            item: {
                id: null,
                form_status: null,
                form_send_status: null,
                form_accepted: null,
                nama_unit: null,
                pegawai: null,
                ruas_tol: null,
                section_name: null,
                lane_name: null,
                description: null,
                tanggal: null,
                time: null,
                km: null,
                km_to: null,
                weather_id: null,
                traffic_id: null,
                weather_name: null,
                traffic_name: null,
                status: null,
                refshift_nama: null,
                refshift_start: null,
                refshift_end: null,
                refshift_color: null
            }
        }

        this.loadDetail = this.loadDetail.bind(this)
    }
    componentDidMount () {
        this.loadDetail()
    }
    loadDetail () {
        GetPrivate(this, `laporan/pl02/detail/` + this.props.id).then(result => {
            const km = result.data.km ? Math.floor(result.data.km * 100) / 100 : null;
            const km_to = result.data.km_to ? Math.floor(result.data.km_to * 100) / 100 : null;
            result.data.km = km + (km_to ? ' - ' + km_to : '');

            this.setState({ item: result.data })
        }).catch(error => {})
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.nama_unit || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Pegawai</label>
                        <div className="col-md-10">
                            <textarea type="text" readOnly disabled className="form-control" value={this.state.item.pegawai || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Deskripsi</label>
                        <div className="col-md-10">
                            <textarea type="text" readOnly disabled className="form-control" value={this.state.item.description || '-'} rows="4" />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Ruas Tol</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.ruas_tol || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Section</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.section_name || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Lane</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.lane_name || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">KM</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.km || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Tanggal</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.tanggal ? this.state.item.tanggal.substr(0, 10) : '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jam</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.time ? this.state.item.time.substr(0, 8) : '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Cuaca</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.weather_name || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Traffic</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.traffic_name || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Nama Shift</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.refshift_nama || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Jam Mulai</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.refshift_start || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jam Selesai</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.refshift_end || '-'}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Detail;
