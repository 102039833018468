import React, { Component} from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    Content,
    ButtonRole,
    ButtonGroup,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";
import Header from "./header";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";

import CameraSearch from "./search";
import CameraModal from "./modal";

class EventCamera extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            event_name : "",
            event_start : "",
            event_end : "",
            loading: false,
            list: [],
            status_event:[],
            pegawai: [],
            pegawai_id : null,
            error_field: {},
            mode:"",
            modal: false,
            camera_id:null,
            camera_name:"",
            camera_location:"",
            camera_date:"",
            camera_time:"",
            camera_file:"",
            camera_bujt:"",
            camera_image:"",
            camera_full_name:"",
            camera_latitude:"",
            camera_longitude:"",
            camera_ruas_tol:"",
            last_data : "",
            is_load_data : false,
            petugas:[],
            cctv:[],
            search_cctv:"",
            camera_url:"",
            camera_url_backup:"",
            camera_url_short:"",
            camera_playing:false,
            image_display:'',
            video_display:'none',
            jenis_event:[],
            jenis_event_data:[]
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.button = React.createRef();
       

        
     
    }
    
    checkClick() {
        //console.log('play audio');
        //let audio = new Audio("/alarm.mp3"); 
        //audio.play();
    }
    
    handleOnChange(index) {
        let role_data = this.state.jenis_event_data;
        
        if (role_data[index] == false) {
            role_data[index] = true;
        } else {
            role_data[index] = false;
        }
        
       
        this.setState({ jenis_event_data: role_data });
    }
 
    
    
    componentDidMount() {
        //this._isTableMounted = true;
        this.loadRef();
        this.loadData();
        
        //this.button.current.click();
        //this.prepareNewData();
        const data_interval = setInterval(() => {
            this.prepareNewData()
          }, 5000);
        
    }
    
    async prepareNewData() {
        
        
        if (!this.state.is_load_data) {
            this.setState({
                is_load_data: true
            });
                            
            const current = new Date();
            const month = current.getMonth()+1;

            const month_full = ('0'+month).slice(-2);
            const day_full = ('0'+current.getDate()).slice(-2);
            const hours_full = ('0'+current.getHours()).slice(-2);
            const minutes_full = ('0'+current.getMinutes()).slice(-2);
            const seconds_full = ('0'+current.getSeconds()).slice(-2);
           //const newElement = document.getElementById('cctv_data');

            const current_date = current.getFullYear()+'-'+month_full+'-'+day_full+' '+hours_full+':'+minutes_full+':'+seconds_full;
            
            // LOAD NEW DATA BASED ON FILTER, IGNORE DATE
            const params = {
                cctv  : this.state.search_cctv,
                jenis : this.state.jenis_event,
                jenis_data : this.state.jenis_event_data,
                event_start:this.state.last_data,
                event_end:current_date
            };

            PostPrivate(
                    this,
                    'event/event/list',
                    params
            ).then(result => {
                
                    if (result.data && result.data.length > 0) {
                        
                        this.button.current.click();
                        let {list} = this.state;
                        let new_list= [];
                       
                        result.data.map((item) => 
                        {
                            new_list.push(item);
                        })
                        
                        
                        let merge_list = [...new_list,...list];
                        let new_merge_array = [];
                        let counter_merge = 1;
                        
                        // maintain only 200 data at the moment to prevent disaster memory leak
                        merge_list.map((item) => 
                        {
                            if (counter_merge <= 200) {
                                new_merge_array.push(item);
                            }
                            counter_merge++;
                        })
                        
                        new_merge_array.map((res, index) => 
                            {
                                if(result.status_event.indexOf(res.id) > -1) {
                                    new_merge_array[index].status = result.status_event[result.status_event.indexOf(res.id)].status;
                                } 

                                if (new_merge_array[index].status =='0' && new_merge_array[index].id_senkom !='0') {
                                    // belum diterima
                                    new_merge_array[index].status_name = 'Belum Diterima';
                                } else if (new_merge_array[index].status =='0') {
                                    new_merge_array[index].status_name = 'Belum Ditugaskan';
                                } else if (new_merge_array[index].status =='1') {
                                    new_merge_array[index].status_name = 'Telah Ditugaskan';
                                } else if (new_merge_array[index].status =='2') {
                                    new_merge_array[index].status_name = 'Ditolak Petugas';
                                }  else if (new_merge_array[index].status =='3') {
                                    new_merge_array[index].status_name = 'Auto Reject';
                                } else if (new_merge_array[index].status =='3') {
                                    new_merge_array[index].status_name = 'Auto Reject';
                                } else if (new_merge_array[index].status =='4') {
                                    new_merge_array[index].status_name = 'Tiba Dilokasi';
                                } else if (new_merge_array[index].status =='5') {
                                    new_merge_array[index].status_name = 'Telah Selesai';
                                } else if (new_merge_array[index].status =='6') {
                                    new_merge_array[index].status_name = 'Sudah Input Laporan';
                                } 
                                
                            }
                        )
                
                        
                        
                      
                        this.setState({
                            last_data: result.data[0].date.replace('/','-').replace('/','-')+' '+result.data[0].time,
                            is_load_data: false,
                            list: new_merge_array
                        });
                        
                    } else {
                        this.setState({
                            is_load_data: false
                        });
                    }
                    console.log('pengambilan event');
                })
                .catch(error => {
                    this.setState({
                        is_load_data: false
                    });
                });    
        } else {
            console.log('Masih loading');
        }
        
        
    }
    
    
    
    toogleShow(mode = "", item = null) {
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
        });
    }
    
    
    sendNotification(id) {
        console.log('Notifikasi '+id);
        return false;
    } 
    
    
    
    
    onSubmit = evt => {
        evt.preventDefault();
        
        
        // get by keyword
        this.setState({ loading: true }, function() {
            const params = {
                cctv  : this.state.search_cctv,
                jenis : this.state.jenis_event,
                jenis_data : this.state.jenis_event_data,
                event_start:this.state.event_start,
                event_end:this.state.event_end
            };
            PostPrivate(
                this,
                'event/event/list',
                params
            )
                .then(result => {
                    // rubah status jugaa
                    result.data.map((res, index) => 
                        {
                            if(result.status_event.indexOf(res.id) > -1) {
                                result.data[index].status = result.status_event[result.status_event.indexOf(res.id)].status;
                            } 
                                    
                            if (result.data[index].status =='0' && result.data[index].id_senkom !='0') {
                                // belum diterima
                                result.data[index].status_name = 'Belum Diterima';
                            } else if (result.data[index].status =='0') {
                                result.data[index].status_name = 'Belum Ditugaskan';
                            } else if (result.data[index].status =='1') {
                                result.data[index].status_name = 'Telah Ditugaskan';
                            } else if (result.data[index].status =='2') {
                                result.data[index].status_name = 'Ditolak Petugas';
                            } else if (result.data[index].status =='3') {
                                result.data[index].status_name = 'Auto Reject';
                            }  
                                    
                        }
                    )
                    
                    
                    this.setState({
                        loading: false,
                        list: result.data,
                        status_event:result.status_event
                    });
                    
                    if (result.data && result.data.length > 0) {
                        //your code here
                        this.setState({
                            last_data: result.data[0].date.replace('/','-').replace('/','-')+' '+result.data[0].time
                        });
                    }

                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
        
     }
     
    checkIsLoaded(url_short) {

        GetPrivate(this, `event/event/loaded_video?id_cctv=${url_short}`)
            .then(result => {
                if (result.data == true) {
                    this.setState(
                        {
                            loading: false,
                            camera_url:this.state.camera_url_backup,
                            video_display:'',
                            image_display:'none'
                        }
                    );    
                } else {
                    this.setState({
                        loading: true
                    });
                    setTimeout(this.checkIsLoaded(url_short).bind(this), 5000);
                }

                
            }).catch(error => {
                if (typeof error == "object") {
                    this.setState({
                        error_field: error
                    });
                }
                // hapus loading
                this.setState({
                    loading: false
                });
            });
    }
    
    
    handleNotification = (id,name,location,date,time,file,bujt,cctv_nama,latitude,longitude,ruas_tol) =>{
        // SHOW MODAL
        this.setState({ loading: true }, function() {
            const params2 = {
                id: id,
                file:file
            };
            
           
            GetPrivate(this, `event/event/download_file?id=${id}&file=${file}`)
                .then(result => {
     
                    this.setState(
                        {
                            loading: false,
                            camera_image:result.data.image,
                            petugas:result.data.petugas,
                            camera_id:id,
                            camera_name:name,
                            camera_location:location,
                            camera_date:date,
                            camera_time:time,
                            camera_file:file,
                            camera_bujt:bujt,
                            camera_full_name:cctv_nama,
                            camera_latitude:latitude,
                            camera_longitude:longitude,
                            camera_ruas_tol:ruas_tol
                        }
                    );
            
                    this.toogleShow("add");
            
                     // run CCTV
                    GetPrivate(this, `event/event/run_streaming?id=${id}`)
                    .then(result => {
                        if (result.type =='rtsp') {
                            this.setState(
                                {
                                    loading: false,
                                    camera_url_backup:result.data,
                                    camera_url_short:result.url_short,
                                    camera_playing:true
                                }
                            );

                            // check apakah running atau tidak
                            this.checkIsLoaded(result.url_short).bind(this);
                        } else {
                            this.setState({
                                loading_cctv : false,
                                camera_url_backup:result.data,
                                camera_url_short:result.url_short,
                                camera_playing:true,
                                camera_url:result.data
                            });
                        }
                        //this.toogleShow("add");
                    }).catch(error => {
                        if (typeof error == "object") {
                            this.setState({
                                error_field: error
                            });
                        }
                        // hapus loading
                        this.setState({
                            loading: false
                        });
                    });
                    
            
                    
                    
                   
                    
                    
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
        
        //console.log(this.state.camera_name+' '+this.state.camera_time);
        
        
    }
    
    loadRef() {
        GetPrivate(this, `event/event/list_cctv`)
            .then(result => {
                //console.log(result);
                this.setState({
                    cctv: result.data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }))
                });
                
                GetPrivate(this, `referensi/jenisevent`)
                    .then(result => {
                        //console.log(result);
                        
                        
                        let role_data = [];
                        
                        result.data.map((dat, index) => {
                            role_data.push(false);
                        });
                        
                        this.setState({
                            jenis_event: result.data,
                            jenis_event_data:role_data
                        });
                    })
                    .catch(error => {});

          
            })
            .catch(error => {});
        
        
    }
    
    loadData() {
        this.setState({ loading: true }, function() {
            const params = {
                cctv  : this.state.search_cctv,
                jenis : this.state.jenis_event,
                jenis_data : this.state.jenis_event_data,
                event_start:this.state.event_start,
                event_end:this.state.event_end
            };
            

            PostPrivate(this,'event/event/list',params)
                .then(result => {
                    // rubah status jugaa
                    result.data.map((res, index) => 
                        {
                            if(result.status_event.indexOf(res.id) > -1) {
                                result.data[index].status = result.status_event[result.status_event.indexOf(res.id)].status;
                            } 
                                    
                            if (result.data[index].status =='0' && result.data[index].id_senkom !='0') {
                                // belum diterima
                                result.data[index].status_name = 'Belum Diterima';
                            } else if (result.data[index].status =='0') {
                                result.data[index].status_name = 'Belum Ditugaskan';
                            } else if (result.data[index].status =='1') {
                                result.data[index].status_name = 'Telah Ditugaskan';
                            } else if (result.data[index].status =='2') {
                                result.data[index].status_name = 'Ditolak Petugas';
                            } else if (result.data[index].status =='3') {
                                result.data[index].status_name = 'Auto Reject';
                            }  
                                    
                        }
                    )
                    
                    
                    this.setState({
                        loading: false,
                        list: result.data,
                        status_event:result.status_event
                    });
                    
                    if (result.data && result.data.length > 0) {
                        //your code here
                        this.setState({
                            last_data: result.data[0].date.replace('/','-').replace('/','-')+' '+result.data[0].time
                        });
                    }

                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    getEventClick(e) {
        this.handleNotification(e.target.getAttribute("event_id"),e.target.getAttribute("event_name"),e.target.getAttribute("event_location"),e.target.getAttribute("event_date"),e.target.getAttribute("event_time"),e.target.getAttribute("event_file"),e.target.getAttribute("event_bujt"),e.target.getAttribute("event_cctv_nama"),e.target.getAttribute("event_latitude"),e.target.getAttribute("event_longitude"),e.target.getAttribute("event_ruas_tol"));
    }
    
    
    reloadData() {
        // LOAD NEW DATA BASED ON FILTER, IGNORE DATE
        const params = {
            cctv  : this.state.search_cctv,
            jenis : this.state.jenis_event,
            jenis_data : this.state.jenis_event_data,
            event_start:this.state.event_start,
            event_end:this.state.event_end
        };
        
        // GET
        this.setState({ loading: true }, function() {
            
            PostPrivate(
                this,
                'event/event/list',
                params
            )
                .then(result => {
                    // rubah status jugaa
                    result.data.map((res, index) => 
                        {
                            if(result.status_event.indexOf(res.id) > -1) {
                                result.data[index].status = result.status_event[result.status_event.indexOf(res.id)].status;
                            } 
                                    
                            if (result.data[index].status =='0' && result.data[index].id_senkom !='0') {
                                // belum diterima
                                result.data[index].status_name = 'Belum Diterima';
                            } else if (result.data[index].status =='0') {
                                result.data[index].status_name = 'Belum Ditugaskan';
                            } else if (result.data[index].status =='1') {
                                result.data[index].status_name = 'Telah Ditugaskan';
                            } else if (result.data[index].status =='2') {
                                result.data[index].status_name = 'Ditolak Petugas';
                            } else if (result.data[index].status =='3') {
                                result.data[index].status_name = 'Auto Reject';
                            }  
                                    
                        }
                    )
                    
                    
                    this.setState({
                        loading: false,
                        list: result.data,
                        status_event:result.status_event
                    });
                    
                    if (result.data && result.data.length > 0) {
                        //your code here
                        this.setState({
                            last_data: result.data[0].date.replace('/','-').replace('/','-')+' '+result.data[0].time
                        });
                    }

                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
        
    }
    
    
    
    
    render() {
        return (
                
                <Content
                    title={this.props.pagetitle}
                    breadcrumb={this.props.breadcrumb}
                >
                <Header
                    toogleShow={this.toogleShow}
                />
                <button style={{display:'none'}} ref={this.button} onClick={this.checkClick}></button>
                <BoxCollapse
                    open={false}
                    title="CCTV File List"
                    tools={
                        
                            <NavLink
                                to={`${this.props.location.pathname}/add`}
                                className="btn btn-box-tool ml-2"
                            >
                                <i className="fa fa-plus mr-1"></i> Tambah Event
                            </NavLink>
                       
                    }
                    icon_show={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                    icon_hide={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                >
                 <CameraSearch
                        handleChange={this.handleChange}
                        onSubmit={this.onSubmit}
                        handleChangeCCTV={() => this.handleChangeCCTV()}
                        handleOnChange={this.handleOnChange}
                        reloadData={this.reloadData}
                        event_name={this.state.event_name}
                        event_start={this.state.event_start}
                        event_end={this.state.event_end}
                        cctv={this.state.cctv}
                        search_cctv={this.state.search_cctv}
                        jenis_event={this.state.jenis_event}
                        jenis_event_data={this.state.jenis_event_data}
                    />
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Nama Event</th>
                                <th>Tanggal</th>
                                <th>Waktu</th>
                                <th>CCTV</th>
                                <th>BUJT</th>
                                <th>Status</th>
                                <th>Aksi</th>
                            </tr>
                            </thead>
                            <tbody id="cctv_data">
                            {this.state.list.map((list_event) => 
                                (
                                    <tr id={list_event.tr_id}>
                                        <td>{list_event.name}</td>
                                        <td>{list_event.date}</td>
                                        <td>{list_event.time}</td>
                                        <td>{list_event.location}</td>
                                        <td>{list_event.nama_bujt}</td>
                                        <td>{list_event.status_name}</td>
                                        <td>
                                            <ButtonGroup>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-default"
                                                    event_id={list_event.id}
                                                    event_name={list_event.name}
                                                    event_location={list_event.location}
                                                    event_date={list_event.date}
                                                    event_time={list_event.time}
                                                    event_file={list_event.file}
                                                    event_bujt={list_event.nama_bujt}
                                                    event_cctv_nama={list_event.nama_cctv}
                                                    event_latitude={list_event.latitude}
                                                    event_longitude={list_event.longitude}
                                                    event_ruas_tol={list_event.ruas_tol}
                                                    onClick={this.getEventClick.bind(this)}
                                                >
                                                <i className="fa fa-play mr-1"></i> Kirim
                                                </button>

                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            )}
                            </tbody>
                    
                        </table>
                    
                    </div>
                </div>
                {this.state.modal && (
                    <CameraModal
                        mode={this.state.mode}
                        show={this.state.modal}
                        modal={this.state.modal}
                        camera_id={this.state.camera_id}
                        camera_name={this.state.camera_name}
                        camera_location={this.state.camera_location}
                        camera_date={this.state.camera_date}
                        camera_time={this.state.camera_time}
                        camera_image={this.state.camera_image}
                        petugas={this.state.petugas}
                        camera_file={this.state.camera_file}
                        camera_bujt={this.state.camera_bujt}
                        camera_full_name={this.state.camera_full_name}
                        camera_latitude={this.state.camera_latitude}
                        camera_longitude={this.state.camera_longitude}
                        camera_url={this.state.camera_url}
                        camera_url_backup={this.state.camera_url_backup}
                        camera_playing={this.state.camera_playing}
                        image_display={this.state.image_display}
                        video_display={this.state.video_display}
                        camera_url_short={this.state.camera_url_short}
                        loading={this.state.loading}
                        toogleShow={() => this.toogleShow()}
                    />
                )}
                </Content>
        )
    }
    
    
    
}


export default EventCamera;