import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

let checkOpen = true;

const SidebarMenu = props => {
    // cek url
    const is_parent_open =
        props.state == props.id ||
        (props.location.pathname.indexOf(props.url) > -1 &&
            props.url != "/dashboard" &&
            props.isReload);
    const is_child_open =
        props.url == props.location.pathname ||
        (props.location.pathname.indexOf(props.url) > -1 &&
            props.url != "/dashboard" &&
            props.isReload);
    // openss
    let parentopen = classNames(
        "treeview",
        {
            "menu-open": is_parent_open
        },
        is_child_open ? "active" : ""
    );
    let childopen = { display: is_parent_open ? "block" : "none" };
    let iconopen = classNames(
        "pull-right",
        "fa",
        props.list_children
            ? props.state == props.id
                ? "fa-angle-down"
                : "fa-angle-left"
            : ""
    );
    // return
    return (
        <li className={parentopen}>
            <Link
                to={props.url}
                onClick={event => {
                    if (props.list_children) {
                        event.preventDefault();
                    }
                    props.setState(props.state == props.id ? "" : props.id);
                    // set disabled check
                    props.setIsReload(false);
                }}
            >
                <i className={props.icon} ></i> <span style={{color: props.color}}>{props.title}</span>
                <span className="pull-right-container">
                    <i className={iconopen}></i>
                </span>
            </Link>
            {props.list_children && (
                <ul className="treeview-menu" style={childopen}>
                    {props.list_children}
                </ul>
            )}
        </li>
    );
};

export default SidebarMenu;
