import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";

import idLocale from "moment/locale/id";
import { Button } from "../../../components/AdminLTE";
import { SwalConfirm } from "../../../components/Alert";
import GetPrivate from "../../../utils/Connection";
moment.locale("id", idLocale);

const now = moment();

const duty_of = ["Libur", "Sakit", "Ijin", "Cuti", "Alpha"];

const Calendar = props => {
    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover calendar">
                <thead>
                    <tr>
                        <th className="fc-day-header fc-widget-header fc-sun text-center v-center p-0">
                            <div className="date-header-shift">Shift</div>
                        </th>
                        <CalendarHeader {...props} />
                    </tr>
                </thead>
                <tbody>
                    <CalendarBody {...props} />
                </tbody>
            </table>
        </div>
    );
};

const CalendarHeader = props => {
    return props.list_header.map((item, index) => {
        const holiday =
            props.list_holiday != undefined
                ? props.list_holiday.filter(
                      list_holiday =>
                          item.format("YYYY-MM-DD") == list_holiday.holiday_date
                  ).length
                : false;
        return (
            <th
                key={`CalendarHeader__${index}`}
                className={classNames(
                    "fc-day-header fc-widget-header fc-sun p-0",
                    {
                        "day-past": item < now
                    }
                )}
            >
                <div className="clearfix date-header">
                    <div
                        className={classNames(
                            "pull-left date",
                            {
                                "day-active":
                                    item.format("YYYY-MM-DD") ==
                                    now.format("YYYY-MM-DD")
                            },
                            {
                                "text-danger":
                                    holiday ||
                                    item.format("d") == 0 ||
                                    item.format("d") == 6
                            }
                        )}
                    >
                        {item.format("DD")}
                    </div>
                    <div className="pull-left">
                        <div
                            className={classNames(
                                "day",
                                {
                                    "day-active":
                                        item.format("YYYY-MM-DD") ==
                                        now.format("YYYY-MM-DD")
                                },
                                {
                                    "text-danger":
                                        holiday ||
                                        item.format("d") == 0 ||
                                        item.format("d") == 6
                                }
                            )}
                        >
                            {item.format("ddd")}
                        </div>
                        <div className="month">{item.format("MMMM")}</div>
                    </div>
                </div>
            </th>
        );
    });
};

const CalendarBody = props => {
    const list = props.list_shift.map((shift, shift_index) =>
        props.list_unit.map((unit, unit_index) => (
            <tr key={`unit__${unit_index}`}>
                {unit_index == 0 && (
                    <td
                        className="text-white v-center text-center"
                        style={{ backgroundColor: shift.shift_color }}
                        rowSpan={props.list_unit.length}
                    >
                        <div className="section-shift">
                            <strong>{shift.label}</strong>
                            <br />
                            {`${shift.shift_start} - ${shift.shift_finish}`}
                        </div>
                    </td>
                )}
                {props.list_header.map((header, header_index) => (
                    <td
                        key={`header__${header_index}`}
                        className={classNames({
                            "day-past": header < now
                        })}
                    >
                        <CalendarCollapse
                            {...props}
                            unit={unit}
                            header={header}
                            shift={shift}
                        />
                    </td>
                ))}
            </tr>
        ))
    );
    list.push(<ShiftDO {...props} />);
    return list;
};

const CalendarCollapse = props => {
    // props
    const { header, shift, unit, list_pegawai } = props;
    // hooks
    const [state, setState] = useState(false);
    let collapseopen = classNames("box box-default box-clear", {
        "collapsed-box": !state
    });
    // shift date
    const shift_date = header.format("YYYY-MM-DD");
    const key = `${shift_date}###__###${shift.value}###__###${unit.value}`;
    // format item
    const item_data = {
        execution_id: "",
        ruas_id: props.ruas_id,
        ruas_name: props.ruas_name,
        unit_id: unit.value,
        unit_name: unit.label,
        code_id: "",
        code_name: "",
        shift_id: shift.value,
        shift_name: shift.label,
        execution_date: shift_date,
        pegawai: []
    };
    // return
    return (
        <div className={collapseopen}>
            <div className="box-header clearfix">
                <div className="pull-left">
                    {unit.label}
                    {list_pegawai[key] && (
                        <span
                            className="ml-half label"
                            style={{ backgroundColor: shift.shift_color }}
                        >
                            {list_pegawai[key].length}
                        </span>
                    )}
                </div>
                <div className="box-tools pull-right">
                    {list_pegawai[key] ? (
                        <button
                            type="button"
                            className="btn btn-box-tool"
                            onClick={() => setState(!state)}
                        >
                            {state ? (
                                <i className="fa fa-chevron-up"></i>
                            ) : (
                                <i className="fa fa-chevron-down"></i>
                            )}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-box-tool"
                            onClick={() => props.toogleShow(item_data)}
                        >
                            <i className="fa fa-plus"></i>
                        </button>
                    )}
                </div>
            </div>
            <div className="box-body">
                <CalendarCollapseBody
                    {...props}
                    pegawai={list_pegawai[key]}
                    item_data={item_data}
                />
            </div>
        </div>
    );
};

const CalendarCollapseBody = props => {
    // props
    const { pegawai } = props;
    // cek pegawai
    if (pegawai != undefined) {
        return (
            <>
                {pegawai.map((item, index) => (
                    <div
                        key={`pegawai___${index}`}
                        className="small-box text-white"
                        style={{ backgroundColor: props.shift.shift_color }}
                    >
                        <div className="inner">
                            <h3 className="text-center">{item.code_name}</h3>
                            <p>
                                {item.pegawai
                                    .map(pegawai => pegawai.pegawai_name)
                                    .join(", ")}
                            </p>
                        </div>
                        <Button
                            className="btn btn-sm btn-block small-box-footer"
                            onClick={() => props.toogleShow(item)}
                        >
                            <i className="fa fa-edit"></i>
                        </Button>
                    </div>
                ))}
                {pegawai && (
                    <div className="small-box bg-aqua">
                        <Button
                            className="btn btn-sm btn-block small-box-footer"
                            onClick={() => props.toogleShow(props.item_data)}
                        >
                            <i className="fa fa-plus"></i>
                        </Button>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div className="text-muted text-center">
                <small>
                    <i>Belum Ada Shift</i>
                </small>
            </div>
        );
    }
};

const ShiftDO = props => {
    return duty_of.map((doshift, doshift_index) => (
        <tr key={`shift_do__${doshift_index}`}>
            {doshift_index == 0 && (
                <td
                    className="text-white v-center text-center"
                    style={{ backgroundColor: "#dd4b39" }}
                    rowSpan={duty_of.length}
                >
                    <div className="section-shift">
                        <strong>Pegawai Tidak Masuk</strong>
                    </div>
                </td>
            )}
            {props.list_header.map((header, header_index) => (
                <td
                    key={`header__${header_index}`}
                    className={classNames({
                        "day-past": header < now
                    })}
                >
                    <ShiftDOCollapse
                        {...props}
                        doshift={doshift}
                        header={header}
                    />
                </td>
            ))}
        </tr>
    ));
};

const ShiftDOCollapse = props => {
    // props
    const { doshift, header, list_do } = props;
    const doshift_label = doshift.toLowerCase();
    // hooks
    const [state, setState] = useState(false);
    let collapseopen = classNames("box box-default box-clear", {
        "collapsed-box": !state
    });
    // shift date
    const shift_date = header.format("YYYY-MM-DD");
    const key = `${shift_date}###__###${doshift_label}`;
    // item
    const item_data = {
        do_date: shift_date,
        do_status: doshift_label,
        title: doshift
    };
    // return
    return (
        <div className={collapseopen}>
            <div className="box-header clearfix">
                <div className="pull-left">
                    {doshift}
                    {list_do[key] && (
                        <span
                            className="ml-half label"
                            style={{ backgroundColor: "#dd4b39" }}
                        >
                            {list_do[key].length}
                        </span>
                    )}
                </div>
                <div className="box-tools pull-right">
                    {list_do[key] ? (
                        <button
                            type="button"
                            className="btn btn-box-tool"
                            onClick={() => setState(!state)}
                        >
                            {state ? (
                                <i className="fa fa-chevron-up"></i>
                            ) : (
                                <i className="fa fa-chevron-down"></i>
                            )}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-box-tool"
                            onClick={() => props.toogleShowDO(item_data)}
                        >
                            <i className="fa fa-plus"></i>
                        </button>
                    )}
                </div>
            </div>
            <div className="box-body">
                <ShiftDOCollapseBody
                    {...props}
                    do={list_do[key]}
                    item_data={item_data}
                />
            </div>
        </div>
    );
};

const ShiftDOCollapseBody = props => {
    // hapus
    const delteDo = item => {
        SwalConfirm(
            `Hapus Pegawai Libur`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () =>
                GetPrivate(this, `pegawai/shift/delete_do/${item.do_id}`)
                    .then(result => {
                        props.loadData();
                    })
                    .catch(error => {})
        );
    };
    // cek do
    if (props.do != undefined) {
        return (
            <>
                {props.do.map((item, index) => (
                    <div
                        key={`pegawai_doo___${index}`}
                        className="small-box text-white"
                        style={{ backgroundColor: "#dd4b39" }}
                    >
                        <div className="inner">
                            <p className="text-center">{item.pegawai_name}</p>
                        </div>
                        <Button
                            className="btn btn-sm btn-block small-box-footer"
                            onClick={() => delteDo(item)}
                        >
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
                ))}
                {props.do && (
                    <div className="small-box bg-aqua">
                        <Button
                            className="btn btn-sm btn-block small-box-footer"
                            onClick={() => props.toogleShowDO(props.item_data)}
                        >
                            <i className="fa fa-plus"></i>
                        </Button>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div className="text-muted text-center">
                <small>
                    <i>Belum Ada Pegawai</i>
                </small>
            </div>
        );
    }
};

export default Calendar;
