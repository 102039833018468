import React, { Component } from "react";
import moment from "moment";

// komponen
import { Content } from "../../../components/AdminLTE";
import Header from "./header";
import Calendar from "./calendar";
import ShiftModal from "./modal";
import ShiftDOModal from "./modal_do";

// utils
import GetPrivate from "../../../utils/Connection";

class Shift extends Component {
    constructor(props) {
        super(props);
        // ref
        this.list_unit = [];
        // today
        let today = moment();
        // state
        this.state = {
            list_ruas: [],
            list_unit: [],
            list_code: {},
            list_shift: [],
            list_header: [],
            list_pegawai: {},
            list_do: {},
            ruas_id: "",
            ruas_name: "",
            selected_date: today,
            start_date: moment(today),
            end_date: moment(today),
            //--
            item: {},
            loading: false,
            modal: false,
            modal_do: false
        };
        // bind
        this.changeRuas = this.changeRuas.bind(this);
        this.changeWeek = this.changeWeek.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
        this.toogleShowDO = this.toogleShowDO.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        GetPrivate(this, `pegawai/shift/referensi`)
            .then(result => {
                const ruas = result.data.list_ruas[0];
                let list_code = {};
                result.data.list_unit.forEach(item => {
                    list_code[item.unit_id] = item.list_code.map(code => ({
                        value: code.code_id,
                        label: code.code_name
                    }));
                });
                this.setState(
                    {
                        list_ruas: result.data.list_ruas.map(item => ({
                            value: item.ruas_id,
                            label: item.ruas_name
                        })),
                        list_shift: result.data.list_shift.map(item => ({
                            value: item.shift_id,
                            label: item.shift_name,
                            shift_start: item.shift_start,
                            shift_finish: item.shift_finish,
                            shift_color: item.shift_color
                        })),
                        list_unit: result.data.list_unit.map(item => ({
                            value: item.unit_id,
                            label: item.unit_name
                        })),
                        list_code: list_code,
                        ruas_id: ruas.ruas_id,
                        ruas_name: ruas.ruas_name
                    },
                    function() {
                        this.changeWeek();
                    }
                );
            })
            .catch(error => {});
    }
    // handle change
    changeRuas(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    //changeWeek
    changeWeek(type = null) {
        let date = this.state.selected_date;
        if (type == "prev") {
            date = this.state.selected_date.subtract(1, "weeks");
        }
        if (type == "next") {
            date = this.state.selected_date.add(1, "weeks");
        }
        // make list header
        const selected_yesterday = moment(date).subtract(1, "days");
        // variabel
        let list_header = [selected_yesterday, date];
        // loop format
        for (let index = 0; index < 5; index++) {
            // header tabel
            list_header.push(moment(date).add(index + 1, "days"));
        }
        // --
        this.setState(
            {
                selected_date: date,
                list_header: list_header,
                start_date: list_header[0],
                end_date: list_header[list_header.length - 1]
            },
            function() {
                this.loadData();
            }
        );
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            let { start_date, end_date, ruas_id } = this.state;
            start_date = start_date.format("YYYY-MM-DD");
            end_date = end_date.format("YYYY-MM-DD");
            GetPrivate(
                this,
                `pegawai/shift?start=${start_date}&end=${end_date}&ruas=${ruas_id}`
            )
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list_pegawai: result.data.list_pegawai,
                        list_do: result.data.list_do
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    //toogleShow
    toogleShow(item = {}, forceReload = false) {
        this.setState(
            {
                modal: !this.state.modal,
                item: item
            },
            function() {
                if (forceReload) {
                    this.loadData();
                }
            }
        );
    }
    //toogleShowDO
    toogleShowDO(item = {}, forceReload = false) {
        this.setState(
            {
                modal_do: !this.state.modal_do,
                item: item
            },
            function() {
                if (forceReload) {
                    this.loadData();
                }
            }
        );
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                subtitle={this.props.pagesubtitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-body">
                        <Header
                            list_ruas={this.state.list_ruas}
                            ruas_name={this.state.ruas_name}
                            ruas_id={this.state.ruas_id}
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                            loading={this.state.loading}
                            changeRuas={this.changeRuas}
                            changeWeek={this.changeWeek}
                            toogleShow={this.toogleShow}
                            loadData={this.loadData}
                        />
                        <Calendar
                            list_header={this.state.list_header}
                            list_shift={this.state.list_shift}
                            list_unit={this.state.list_unit}
                            list_pegawai={this.state.list_pegawai}
                            list_do={this.state.list_do}
                            ruas_id={this.state.ruas_id}
                            ruas_name={this.state.ruas_name}
                            toogleShow={this.toogleShow}
                            toogleShowDO={this.toogleShowDO}
                            loadData={this.loadData}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <ShiftModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        loadData={this.loadData}
                        list_ruas={this.state.list_ruas}
                        list_code={this.state.list_code}
                        list_unit={this.state.list_unit}
                        list_shift={this.state.list_shift}
                        item={this.state.item}
                    />
                )}
                {this.state.modal_do && (
                    <ShiftDOModal
                        show={this.state.modal_do}
                        toogleShow={this.toogleShowDO}
                        loadData={this.loadData}
                        ruas_id={this.state.ruas_id}
                        item={this.state.item}
                    />
                )}
            </Content>
        );
    }
}

export default Shift;
