import React from "react";
import moment from "moment";

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";
import Dates from "../../../utils/Dates";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Tanggal",
        selector: "tanggal",
        sortable: true,
        width: "15%",
    },
    {
        name: "No. Laporan",
        selector: "no_laporan",
        sortable: true,
        width: "20%",
    },
    {
        name: "KM",
        selector: "km",
        sortable: true,
        width: "10%",
    },
    {
        name: "Unit",
        selector: "unit_name",
        width: "20%",
    },
    {
        name: "Status Laporan",
        selector: "status",
        width: "20%",
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        //const km = item.km ? Math.floor(item.km * 100) / 100 : null;
        //const km_to = item.km_to ? Math.floor(item.km_to * 100) / 100 : null;
        item.no = index + 1;
        //item.km = km + (km_to ? ' - ' + km_to : '');
        item.km = item.km + ' ' + item.km_to;
        
        if (item.status == 0 || item.status == '0') { item.status = 'Belum diproses' }
        else if (item.status == 1 || item.status == '1') { item.status = 'Diterima' }
        else if (item.status == 2 || item.status == '2') { item.status = 'Ditolak' }
        else { item.status = '-' }

        item.detail = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.id)}
            >
                Laporan
            </Button>
        );
        return item;
    });
};

const KecelakaanTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada kecelakaan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KecelakaanTable;
