import React from "react";
import moment from "moment";
// import idLocale from "moment/locale/id";

// moment.locale("id", idLocale);

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Unit",
        selector: "nama_unit",
        sortable: true,
        width: "20%",
    },
    {
        name: "Ruas Tol",
        selector: "ruas_tol",
        sortable: true,
        width: "20%",
    },
    {
        name: "KM",
        selector: "km",
        sortable: true,
        width: "10%",
    },
    {
        name: "Tanggal",
        selector: "tanggal_mic",
        sortable: true,
        width: "30%",
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        const km = item.km && Number.isInteger(item.km) ? Math.floor(item.km * 100) / 100 : (item.km || null);
        const km_to = item.km_to && Number.isInteger(item.km_to) ? Math.floor(item.km_to * 100) / 100 : (item.km_to || null);
        item.no = index + 1;
        item.km = (km || '') + (km && km_to ? ' - ' : '') + (km_to || '');
        item["tanggal_mic"] = item.tanggal + ' ' + item.time; 


        item.detail = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.id)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const KepalaShiftTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KepalaShiftTable;
