import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button,InputTextarea} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class ConfirmModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Konfirmasi Penolakan";
        
        this.state = {
            // field input
            id: props.id,
            alasan:"",
            error_field: {
                alasan: null
            },
            // loading
            loading: false
        };
        
        this.handleChange   = this.handleChange.bind(this);
        this.onSubmit       = this.onSubmit.bind(this);
        
        
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    onSubmit = evt => {
        evt.preventDefault();
        
        this.props.tolakLaporan(this.state.id, this.state.alasan);
        
    }
    
    render() {
        
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <div className="col-sm-12">
                              <InputTextarea 
                                    rows="10"
                                    id="alasan_penolakan"
                                    onChange={ev =>
                                        this.handleChange(
                                            "alasan",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.alasan
                                    }
                               />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <div style={{float:'right'}}>
                                <Button
                                    type="submit"
                                    className="btn btn-success"
                                    loading={this.state.loading}
                                >
                                    Simpan
                                </Button>
                                &nbsp;
                                <Button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.props.showConfirm()}
                                        loading={this.state.loading}
                                    >
                                        Tutup
                                    </Button>    
                                </div>
                            </div>
                        </div>
                    </>
                }
                show={this.props.show}
                onDismiss={this.props.showConfirm}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default ConfirmModal;
