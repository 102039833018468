import React from "react";
import moment from "moment";

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";
import Dates from "../../../utils/Dates";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Tanggal",
        selector: "tanggal",
        sortable: true,
        width: "15%",
    },
    {
        name: "No. Laporan",
        selector: "no_laporan",
        sortable: true,
        width: "18%",
    },
    {
        name: "KM",
        selector: "km",
        sortable: true,
        width: "12%",
    },
    {
        name: "Status Bayar",
        selector: "status",
        width: "20%",
    },
    {
        name: "Status Laporan",
        selector: "status_senkom",
        width: "20%",
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        const km = item.km && Number.isInteger(item.km) ? Math.floor(item.km * 100) / 100 : (item.km || null);
        const km_to = item.km_to && Number.isInteger(item.km_to) ? Math.floor(item.km_to * 100) / 100 : (item.km_to || null);
        item.no = index + 1;
        item.km = (km || '') + (km && km_to ? ' - ' : '') + (km_to || '');

        if (item.status === 1 || item.status === '1') { item.status = 'Menunggu Klarifikasi' }
        else if (item.status === 2 || item.status === '2') { item.status = 'Sudah Klarifikasi' }
        else { item.status = 'Belum Ganti Rugi' }

        if (item.status_senkom === 1 || item.status_senkom === '1') { item.status_senkom = 'Sudah Diterima Unit' }
        else if (item.status_senkom === 2 || item.status_senkom === '2') { item.status_senkom = 'Ditolak Petugas' }
        else if (item.status_senkom === 3 || item.status_senkom === '3') { item.status_senkom = 'Auto Reject' }
        else if (item.status_senkom === 4 || item.status_senkom === '4') { item.status_senkom = 'Sudah Tiba di Lokasi' }
        else if (item.status_senkom === 5 || item.status_senkom === '5') { item.status_senkom = 'Selesai Ditangani' }
        else if (item.status_senkom === 6 || item.status_senkom === '6') { item.status_senkom = 'Sudah Input Laporan' }
        else { item.status_senkom = 'Belum Dikirim' }

        item.detail = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.id, item.id_laka)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const LakalantasTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default LakalantasTable;
