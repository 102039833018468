import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// default image
import img_default from "../../resources/img/img-default.jpg";

// komponen
import Loading from "../AdminLTE/loading";
import Input from "./input";
import SidebarMenu from "./sidebarmenu";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import AutoComplete from "react-autocomplete";

const formatMenu = (list, parent = 0) => {
    let result = [];
    list.forEach((item) => {
        if (item.parent_id == parent && (item.display_st === 1 || item.display_st === '1')) {
            let list_child = formatMenu(list, item.nav_id);
            if (list_child.length) {
                item.list_child = list_child;
            }
            result.push(item);
        }
    });
    return result;
};

const SidebarMenuList = (props) => {
    // hooks
    const [state, setState] = useState("");
    return props.list.map((item) => {
        return (
            <>
                <SidebarMenu
                          {...props}
                          id={item.nav_id}
                          state={state}
                          setState={setState}
                          key={item.nav_id}
                          icon={item.nav_icon}
                          title={item.nav_title}
                          url={item.nav_url}
                          list_children={
                              item.list_child && (
                                  <SidebarMenuList {...props} list={item.list_child} />
                              )
                          }
                      />
         
            </>
        );
    });
};

const Sidebar = (props) => {
    // hooks
    const [isReload, setIsReload] = useState(true);

    const handleOnSearch = (string, results) => {
        // console.log(string, results);
      };
    
      const handleOnHover = (result) => {
        // console.log(result);
      };
    
      const handleOnSelect = (item) => {
        // console.log(item, 'ini select');
        props.history.replace(item.nav_url);
      };
    
      const handleOnFocus = () => {
        // console.log("Focused");
      };
    
      const handleOnClear = () => {
        // console.log("Cleared");
      };
    
      const formatResult = (item) => {
        console.log(item, 'format');
        return (
          <div className="result-wrapper">
            {/* <Link
                to={item.nav_url}
            > */}
            <span className="result-span">{item.nav_title}</span>
            {/* </Link> */}
          </div>
        );
      };

      const [value, setValue] = useState("");
    // return
    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <div class="input-group">

                </div>
                 
                <ul className="sidebar-menu">
                {props.default_page === 'dashboard' &&
               <div style={{ margin: 10 }}>
                 {/* <ReactSearchAutocomplete
            items={props.menu}
            fuseOptions={{ keys: ["nav_title"] }} // Search on both fields
            resultStringKeyName="nav_title" // String to display in the results
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            placeholder={"Search Menu"}
            showIcon={false}
            styling={{
              height: "34px",
              border: "1px solid #F05423",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "#F05423",
              color: "#1E282C",
              fontSize: "12px",
              fontFamily: "Poppins",
              iconColor: "green",
              lineColor: "lightgreen",
              placeholderColor: "#1E282C",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 1,
            }}
          /> */}

      <AutoComplete
        shouldItemRender={(item, value) => item.nav_title.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={item => item.nav_title}
        items={props.menu_search}
        renderItem={(item, isHighlighted) =>
             <Link
                to={item.nav_url}
            >
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item.nav_title}
          </div>
            </Link>
        }
        wrapperStyle={{
          position: "relative",
        }}
        inputProps={{
          placeholder: "Search Menu",
          style: { fontSize: 14, width: "100%", padding: 3 }
        }}
        menuStyle={{
          border: "1px solid #F05423",
          zIndex: 2,
          position: "absolute",
          top: 30,
          left: 0,
          widh: '50px',
          overflow: "auto",
          maxHeight: 100,
          borderRadius: "8px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "#F05423",
              color: "#1E282C",
              fontSize: "12px",
              fontFamily: "Poppins",
              iconColor: "#F05423",
              lineColor: "#F05423",
              placeholderColor: "#1E282C",
              clearIconMargin: "3px 8px 0 0",
        }}
        value={value}
        type="serach"
        autoHighlight={false}
        onChange={(e) => setValue(e.target.value)}
        onSelect={(value) => setValue(value)}
      />
             </div>
}
                    
                    {/* <li className="header">Navigation</li> */}
                    {props.loading ? (
                        <Loading />
                    ) : (
                        <SidebarMenuList
                            {...props}
                            isReload={isReload}
                            setIsReload={setIsReload}
                            list={formatMenu(props.menu)}
                        />
                    )}
                </ul>
            </section>
        </aside>
    );
};

export default Sidebar;
