import React, { Component } from "react";

// komponen
import { Content, ButtonBack, Button } from "../../../components/AdminLTE";
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// default image
import img_default from "../../../resources/img/img-default.jpg";

class PegawaiDelete extends Component {
    constructor(props) {
        super(props);
        // user id
        this.pegawai_id = props.match.params.id;
        // state
        this.state = {
            // field
            pegawai_nik: "",
            pegawai_name: "",
            pegawai_birth: "",
            pegawai_gender: "",
            pegawai_nikah: "",
            pegawai_jabatan: "",
            pegawai_unit: "",
            pegawai_st: "",
            role_nm: null,
            pegawai_image: null,
            // loading
            loading: false,
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `pegawai/pegawai/detail/${this.pegawai_id}`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        pegawai_nik: result.data.pegawai_nik,
                        pegawai_name: result.data.pegawai_name,
                        pegawai_birth: result.data.pegawai_birth,
                        pegawai_gender: result.data.pegawai_gender,
                        pegawai_nikah: result.data.pegawai_nikah,
                        pegawai_jabatan: result.data.pegawai_jabatan,
                        pegawai_unit: result.data.pegawai_unit,
                        pegawai_st: result.data.pegawai_st,
                        role_nm: result.data.role_nm,
                        pegawai_image: result.data.pegawai_image,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm() {
        SwalConfirm(
            `Hapus Pegawai [${this.state.pegawai_name}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess()
        );
    }
    // deleteprocess
    deleteProcess() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `pegawai/pegawai/delete/${this.pegawai_id}`)
                .then((result) => {
                    // reload page
                    this.props.history.goBack();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Hapus Pegawai</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img
                                    src={
                                        this.state.pegawai_image
                                            ? this.state.pegawai_image
                                            : img_default
                                    }
                                    className="img-circle"
                                    alt="Profil"
                                    width="100"
                                    height="100"
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label
                                        htmlFor={"user_name"}
                                        className="control-label"
                                    >
                                        NIK
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_nik}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_name"}
                                        className="control-label"
                                    >
                                        Nama Pegawai
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_name}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_st__1"}
                                        className="control-label"
                                    >
                                        Status User ?
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_st == "1"
                                            ? "Aktif"
                                            : "Tidak Aktif"}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"role_id"}
                                        className="control-label"
                                    >
                                        Hak Akses
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.role_nm}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_birth"}
                                        className="control-label"
                                    >
                                        Tanggal Lahir
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_birth}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_birth"}
                                        className="control-label"
                                    >
                                        Gender
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_gender == "L"
                                            ? "Laki - Laki"
                                            : "Perempuan"}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_nikah"}
                                        className="control-label"
                                    >
                                        Status Pernikahan
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_nikah}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_unit"}
                                        className="control-label"
                                    >
                                        Unit Pegawai
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_unit}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"pegawai_jabatan"}
                                        className="control-label"
                                    >
                                        Jabatan Pegawai
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.pegawai_jabatan}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer text-right">
                        <Button
                            type="button"
                            className="btn btn-danger"
                            loading={this.state.loading}
                            onClick={this.deleteConfirm}
                        >
                            <i className="fa fa-trash mr-1"></i> Hapus Pegawai
                        </Button>
                    </div>
                </div>
            </Content>
        );
    }
}

export default PegawaiDelete;
