import React from "react";

// komponen
import { Input } from "../../../components/AdminLTE";

const SearchPegawai = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <div className="form-group row mb-0">
                    <label className="control-label col-md-4" htmlFor="q">
                        Tanggal
                    </label>
                    <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.start}
                        onChange={ev =>
                            props.handleChangeStart("start", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                     <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.end}
                        onChange={ev =>
                            props.handleChange("end", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                </div>
                
            </div>
        </div>
    );
};

export default SearchPegawai;
