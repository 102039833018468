import React, { Component } from "react";

// komponen
import { Content, Input } from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";

// pages
import Table from "./table";

class Presensi extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            loading: false
        };
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `user/presensi`)
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list: result.data.list_user
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Daftar Hadir Pegawai</h3>
                    </div>
                    <div className="box-body">
                        <Table
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default Presensi;
