import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../components/Alert";
import { Modal, Button, Input, InputSelectReact, InputRadioInline} from "../../components/AdminLTE";
import ReactPlayer from 'react-player'
// utils
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import image_cctv from "../../resources/img/img-cctv.png";

class CctvModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "CCTV Live Stream";
        // state
        this.state = {
            // field input
            cctv_id: props.item ? props.item.id : "",
            nama: props.item ? props.item.nama : "",
            nama_bujt: props.item ? props.item.nama_bujt : "",
            bujt: props.item ? props.item.bujt : "",
            cctv_state: props.item ? props.item.cctv_state : "",
            cctv_playing: props.item ? props.item.cctv_playing : "",
            cctv_url: props.item ? props.item.cctv_url : "",
            list_cctv: props.list_cctv ? props.list_cctv : [],
            // error field
            error_field: {},
            // loading
            loading: false,
            item:null,
            laporan:null,
            unit:null,
            loading_cctv: false,
            cctv_index:0,
            camera_url:"",
            camera_url_short:"",
            camera_url_backup:"",
            camera_playing:false,
            loading_counter:0,
            error_field: {
                cctv_id: null,
                cctv_name:null,
                cctv:null
            }
        };
       
        this.loadCCTV           = this.loadCCTV.bind(this);
        this.checkIsLoaded      = this.checkIsLoaded.bind(this); 
    }

    componentDidMount() {
        // do nothing    
        console.log(this.state.camera_playing, 'camera');      
    }
  
    loadCCTV(cctv_id) {
        this.setState({ loading_cctv: true }, function() {
            const params = {
                id : cctv_id
            };
            
            PostPrivate(this, "referensi/cctv/cctv_run",params)
                .then(result => {
                    if (result.type =='rtsp') {
                        this.setState({
                            loading_cctv : true,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true
                        });

                        //this.checkIsLoaded(result.url_short).bind(this);
                        this.checkIsLoaded(result.url_short).bind(this);
                    } else {
                        this.setState({
                            loading_cctv : true,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true,
                            camera_url:result.data
                        });
                    }

                })
                .catch(error => {
                    if (this.state.loading_cctv == true) {
                        this.setState({
                            loading_cctv: false
                        });
                    }
                });
        });
    }
    
    checkIsLoaded(url_short) {
        let counter_data = this.state.loading_counter;
        
        counter_data = counter_data + 1;
        
        this.setState({
            loading_counter: counter_data
        });
                        
        this.setState({ loading_cctv: true }, function() {
        GetPrivate(this, `event/event/loaded_video?id_cctv=${url_short}`)
            .then(result => {
                if (result.data == true) {
                    this.setState(
                        {
                            loading_cctv: true,
                            camera_url:this.state.camera_url_backup
                        }
                    );    
                } else {
                    if (this.state.loading_counter > 100) {
                        this.setState({
                            loading_cctv: false,
                            loading_counter:0
                        });
                    } else {
                        this.setState({
                            loading_cctv: true
                        });
                        this.checkIsLoaded(this.state.camera_url_short).bind(this)
                        console.log(this.state.camera_playing, 'camera2');    
                    }
                    
                  
                }

                if (result.data == false) {
                    this.setState(
                        {
                            loading_cctv: true
                        }
                    );    
                    }

                this.toogleShow("add");
            }).catch(error => {
                // hapus loading
                this.setState({
                    loading_cctv: true
                });

                if (this.state.loading_cctv == true) {
                    this.setState({
                        loading_cctv: false
                    });
                }
            });
        });
    }

    render() {
        
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                         <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    BUJT &nbsp;:
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                   {this.state.bujt} | {this.state.nama_bujt}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Nama CCTV &nbsp;:
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.nama}
                                </label>
                                </div>
                            </div>
                            {this.state.camera_playing == false ? (
                                <>
                                   <div class="video" >  
                                                    <img onClick={() => this.loadCCTV(this.state.cctv_id)} src={image_cctv} style={{width:'100%', height:'460px',cursor: 'pointer', borderRadius: '8px'}} />
                                                    <p className="tes"
                                                onClick={() => this.loadCCTV(this.state.cctv_id)}
                                                >
                                                    
                                                    </p>
                                                    </div>
                                </>
                            ) : (
                                <div className="player_wrapper" onClick={() => this.loadCCTV(this.state.cctv_id)}>
                                {/* <ReactPlayer width="100%" height="460px" url={this.state.cctv_url} playing={this.state.cctv_playing} /> */}
                                <ReactPlayer width="100%" height="460px" url={this.state.camera_url} playing={this.state.camera_playing} />
                            </div>
                            )} 
                            
                                              
                                                
                                              
                         
                                                   
                                                    
                                            
                    </>
                }
                footer={
                    <Button
                        type="button"
                        className="btn btn-primary"
                        loading={this.state.loading}
                        onClick={this.props.showDetail}
                    >
                        Tutup
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.showDetail}
            />
        );
    }
}

export default CctvModal;
