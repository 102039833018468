import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import ReportSearch from "./search";
import Map from "./map";

class ReportMovingUnit extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        // state
        this.state = {
            url: "",
            loading: false,
            list: [],
            list_color: [],
            date: Dates.getTodayDB(),
            range: { start: '00:00', end: '23:59' },
            start: '00:00', 
            end: '23:59',
            title:'Laporan Moving Unit'
        }
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleChangeTime = this.handleChangeTime.bind(this)
        this.handleSelectTime = this.handleSelectTime.bind(this)
    }
    componentDidMount() {
        this.loadData();
        this.loadColor();
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `laporan/movingunit/all?date=${this.state.date}&start=${this.state.start}:00&end=${this.state.end}:00`).then(result => {

                this.setState({
                    loading: false,
                    list: result.data
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
        });
    }

    loadColor() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `laporan/movingunit/color`).then(result => {

                this.setState({
                    loading: false,
                    list_color: result.data
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
        });
    }
    handleChangeDate (field, value) {
        this.setState({ [field]: value });
        console.log(this.state.list_color)
        this.loadData();
    }
    handleChangeTime (range) {
        this.setState({ range: range })
        console.log(range)
    }
    handleSelectTime (range) {
        this.loadData()
    }
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <BoxCollapse open={true} title="">
                    <ReportSearch
                        handleChangeDate={this.handleChangeDate}
                        handleChangeTime={this.handleChangeTime}
                        handleSelectTime={this.handleSelectTime}
                        date={this.state.date}
                        time={this.state.range}
                        start={this.state.start}
                        end={this.state.end}
                    />
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                    <ul className="Legend">
                    {this.state.list_color.map((item, index) => 
                                        (
                    <td className="Legend-item">
                        <span className="Legend-colorBox mr-1" style={{backgroundColor: item.color}}>
                      
                        </span>
                        <span className="Legend-label">
                        Unit {item.nama_unit} &nbsp; &nbsp; 
                        </span>
                    </td>
                                        ))}
                    </ul>
                            <Map items={this.state.list} />
                    </div>
                </div>
            </Content>
        );
    }
}

export default ReportMovingUnit;
