import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    Button
} from "../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Unit",
        selector: "unit_nama",
        sortable: true,
        width: "15%"
    },
    {
        name: "Jam Mulai",
        selector: "shift_start",
        sortable: true,
        width: "12%"
    },
    {
        name: "Jam Selesai",
        selector: "shift_end",
        sortable: true,
        width: "12%"
    },
    {
        name: "Nama",
        selector: "shift_nama",
        sortable: true,
        width: "15%"
    },
    {
        name: "Laporan",
        selector: "laporan_custom",
        sortable: true,
        width: "15%"
    },
    {
        name: "Action",
        selector: "action",
        width: "20%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item['laporan_custom'] = (
            <Button className="btn btn-success" onClick={() => props.showDetail(item)} >Detail Laporan</Button>
        );

        if (item.is_accepted === 0 || item.is_accepted === '0') {
            item["action"] = (
                <>
                <Button className="btn btn-success" onClick={() => props.terimaLaporan(item.form_id, false)} >Terima</Button>
                <Button className="btn btn-danger" onClick={() => props.showConfirm(item, false)} >Tolak</Button>
                </>
            );    
        } else if (item.is_accepted === 1 || item.is_accepted === '1') {
            item["action"] = (
                <>
                <Button className="btn btn-warning" onClick={() => props.batalkanLaporan(item.form_id, false)} >Batalkan</Button>
                <Button className="btn btn-danger" onClick={() => props.showConfirm(item, false)} >Tolak</Button>
                </>
            );    
        } else {
            item["action"] = (
                <>
                <Button className="btn btn-warning" onClick={() => props.batalkanLaporan(item.form_id, false)} >Batalkan</Button>
                <Button className="btn btn-success" onClick={() => props.terimaLaporan(item.form_id, false)} >Terima</Button>
                </>
            );    
        }
        
        return item;
    });
};

const PenyerahanshiftTable = props => {
    return (
        <TableBootstrap
            keyField={"serah_shift"}
            empty="Belum ada Penyerahan Shift, klik tombol + untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(PenyerahanshiftTable));
