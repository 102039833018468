import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelect,
    InputTextarea,
    InputFeedback,
    InputRadioInline
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

// page
import MenuCollapse from "./collapse";

class DetailModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // portal id
        this.portal_id = props.portal_id;
        // mode
        this.title = props.mode == "edit" ? "Ubah Menu" : "Tambah Menu";
        // options
        this.options = props.options;
        // state
        this.state = {
            // field input
            nav_id: props.item ? props.item.nav_id : "",
            parent_id: props.item ? props.item.parent_id : "",
            nav_title: props.item ? props.item.nav_title_real : "",
            nav_desc: props.item ? props.item.nav_desc : "",
            nav_url: props.item ? props.item.nav_url : "",
            nav_no: props.item ? props.item.nav_no : "",
            active_st: props.item ? props.item.active_st : "",
            display_st: props.item ? props.item.display_st : "",
            nav_icon: props.item ? props.item.nav_icon : "",
            react_component: props.item ? props.item.react_component : "",
            react_exact: props.item ? props.item.react_exact : "",
            react_breadcrumb: props.item ? props.item.react_breadcrumb : "",
            // error field
            error_field: {
                parent_id: null,
                nav_title: null,
                nav_url: null,
                nav_no: null,
                active_st: null,
                display_st: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                nav_id: that.state.nav_id,
                portal_id: that.portal_id,
                parent_id: that.state.parent_id,
                nav_title: that.state.nav_title,
                nav_desc: that.state.nav_desc,
                nav_url: that.state.nav_url,
                nav_no: that.state.nav_no,
                active_st: that.state.active_st,
                display_st: that.state.display_st,
                nav_icon: that.state.nav_icon,
                react_component: that.state.react_component,
                react_exact: that.state.react_exact,
                react_breadcrumb: that.state.react_breadcrumb
            };
            // paggil post api
            PostPrivate(that, `setting/menu/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"parent_id"}
                                className="col-sm-3 control-label"
                            >
                                Parent Menu
                            </label>
                            <div className="col-sm-7">
                                <InputSelect
                                    id={"parent_id"}
                                    has_error={this.state.error_field.parent_id}
                                    defaultValue={this.state.parent_id}
                                    onChange={ev =>
                                        this.handleChange(
                                            "parent_id",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.parent_id
                                    }
                                    option={this.options}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nav_title"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Judul Menu
                            </label>
                            <div className="col-sm-7">
                                <Input
                                    id={"nav_title"}
                                    type="text"
                                    placeholder="Judul Menu"
                                    has_error={this.state.error_field.nav_title}
                                    defaultValue={this.state.nav_title}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nav_title",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.nav_title
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nav_url"}
                                className="col-sm-3 control-label mt-1"
                            >
                                URL
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nav_url"}
                                    type="text"
                                    placeholder="URL"
                                    has_error={this.state.error_field.nav_url}
                                    defaultValue={this.state.nav_url}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nav_url",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nav_url}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nav_no"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Urutan Menu
                            </label>
                            <div className="col-sm-3">
                                <Input
                                    id={"nav_no"}
                                    type="number"
                                    placeholder="Urutan Menu"
                                    has_error={this.state.error_field.nav_no}
                                    defaultValue={this.state.nav_no}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nav_no",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nav_no}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nav_desc"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Menu
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"nav_desc"}
                                    type="text"
                                    placeholder="Deskripsi Menu"
                                    rows={2}
                                    defaultValue={this.state.nav_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nav_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nav_icon"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Icon Menu
                            </label>
                            <div className="col-sm-5">
                                <InputFeedback
                                    id={"nav_icon"}
                                    type="text"
                                    placeholder="Icon Menu, ex: fa fa-icon"
                                    has_error={this.state.error_field.nav_icon}
                                    defaultValue={this.state.nav_icon}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nav_icon",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.nav_icon
                                    }
                                    icon={this.state.nav_icon}
                                />
                                <a
                                    href="https://fontawesome.com/v4.7.0/icons/"
                                    target="_BLANK"
                                >
                                    https://fontawesome.com/v4.7.0/icons/
                                </a>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"active_st__1"}
                                className="col-sm-3 control-label mt-15"
                            >
                                Aktifkan ?
                            </label>
                            <div className="col-sm-5">
                                <InputRadioInline
                                    id={"active_st"}
                                    has_error={this.state.error_field.active_st}
                                    defaultChecked={this.state.active_st}
                                    onChange={ev =>
                                        this.handleChange(
                                            "active_st",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.active_st
                                    }
                                    list={[
                                        { label: "Ya", value: "1" },
                                        { label: "Tidak", value: "0" }
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"display_st__1"}
                                className="col-sm-3 control-label mt-15"
                            >
                                Ditampilkan ?
                            </label>
                            <div className="col-sm-5">
                                <InputRadioInline
                                    id={"display_st"}
                                    has_error={
                                        this.state.error_field.display_st
                                    }
                                    defaultChecked={this.state.display_st}
                                    onChange={ev =>
                                        this.handleChange(
                                            "display_st",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.display_st
                                    }
                                    list={[
                                        { label: "Ya", value: "1" },
                                        { label: "Tidak", value: "0" }
                                    ]}
                                />
                            </div>
                        </div>
                        <MenuCollapse
                            {...this.props}
                            state={this.state}
                            handleChange={this.handleChange}
                        />
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default DetailModal;
