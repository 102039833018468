import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { Content, InputSelectReact, Input } from "../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../components/Alert";


// utils
import { PostPrivate } from "../../utils/Connection";
import GetPrivate from "../../utils/Connection";

import PenyerahanshiftTable from "./table";
import PenyerahanshiftModal from "./modal";
import ConfirmModal from "./confirm";

const ref_title = "Penyerahan Shift";
const ref_url = "penyerahanshift";



class Penyerahanshift extends Component {
    constructor(props) {
        super(props);
        
        var today = new Date();
        
        // state
        this.state = {
            loading: false,
            modal: false,
            confirm:false,
            mode: "",
            shift_date : today.getFullYear() + '-' + this.padString(today.getMonth() + 1, 2) + '-' + this.padString(today.getDate(), 2),
            error_field: {
                shift_date: null
            },
            shift:[],
            item:null,
            detail:null,
            gallery:null
        };
        
        this.showDetail = this.showDetail.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.terimaLaporan = this.terimaLaporan.bind(this);
        this.tolakLaporan = this.tolakLaporan.bind(this);
        this.batalkanLaporan = this.batalkanLaporan.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);

    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
        
    }
    
    padString(str, max) {
        str = str.toString();
        return str.length < max ? this.padString("0" + str, max) : str;
    }
    
    showConfirm(item) {
        // show modal
        this.setState({ confirm: !this.state.confirm, item:item });
    }
    
    showDetail(item = null) {
        if (item === null) {
            this.setState({ modal: !this.state.modal });
        } else if (typeof item !== null && item.id != null) {
            // get info detail and gallery
            this.setState({ loading: true }, function() {
                if (item != null) {
                    const params = {
                        id : item.id
                    };

                    PostPrivate(this, "penyerahanshift/shift/detail",params)
                        .then(result => {
                            this.setState({loading : false, detail:result.detail, gallery:result.gallery, item: item }, function() {
                                this.setState({ modal: !this.state.modal });
                            });

                        })
                        .catch(error => {
                            this.setState({
                                loading: false
                            });
                        });
                } else {
                    this.setState({
                        loading: false,
                        modal: !this.state.modal
                    });
                }
            });
        } else {
            this.setState({ modal: !this.state.modal });
        }
        
        
    }
    
    terimaLaporan(id, is_modal = null) {
        this.setState({ loading: true }, function() {
            const params = {
                id : id
            };

            PostPrivate(this, "penyerahanshift/shift/terima_laporan",params)
                .then(result => {
                    if (result.status == 200) {
                        alert('Penerimaan berhasil');
                        if (is_modal == null) {
                            this.setState({ modal: !this.state.modal });
                        }
                        // refresh
                        this.loadData();
                    }
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    batalkanLaporan(id,is_modal = null) {
        this.setState({ loading: true }, function() {
            const params = {
                id : id
            };

            PostPrivate(this, "penyerahanshift/shift/batal_laporan",params)
                .then(result => {
                    if (result.status == 200) {
                        alert('Pembatalan berhasil');
                
                        if (is_modal == null) {
                            this.setState({ modal: !this.state.modal });
                        }
                        
                        
                        // refresh
                        this.loadData();
                    }
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    
     tolakLaporan(id, alasan, is_modal = null) {
        this.setState({ loading: true }, function() {
            const params = {
                id : id,
                alasan:alasan
            };

            PostPrivate(this, "penyerahanshift/shift/tolak_laporan",params)
                .then(result => {
                    if (result.status == 200) {
                        alert('Penolakan berhasil');
                
                        if (is_modal == null) {
                             this.setState({ modal: !this.state.modal,confirm: !this.state.confirm });
                        } else if (is_modal == 2) {
                             this.setState({ confirm: !this.state.confirm });
                        }
                        
                        
                       
                        
                        // refresh
                        this.loadData();
                    }
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }


    // load data
    loadData() {
        this.setState({ loading: true }, function() {
            const params = {
                date : this.state.shift_date
            };
            
            PostPrivate(this, "penyerahanshift/shift/index",params)
                .then(result => {
                    this.setState({
                        loading : false,
                        shift:result.data
                    });
                    

                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Manajemen {ref_title}</h3>
                        
                    </div>
                    <div className="box-body">
                       <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Tanggal
                            </label>
                            <div className="col-sm-5">
                                <Input
                                        id={"tanggal"}
                                        type="date"
                                        placeholder="Tanggal"
                                        defaultValue={this.state.shift_date}
                                        onChange={ev =>
                                            this.handleChange("shift_date", ev.target.value)
                                        }
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                       <PenyerahanshiftTable
                            list={this.state.shift}
                            showConfirm={this.showConfirm}
                            showDetail={this.showDetail}
                            terimaLaporan={this.terimaLaporan}
                            tolakLaporan={this.tolakLaporan}
                            batalkanLaporan={this.batalkanLaporan}
                            loading={this.state.loading}
                        />
                    </div>
                    {this.state.modal && (
                        <PenyerahanshiftModal
                            item={this.state.item}
                            detail={this.state.detail}
                            gallery={this.state.gallery}
                            show={this.state.modal}
                            showConfirm={this.showConfirm}
                            showDetail={this.showDetail}
                            terimaLaporan={this.terimaLaporan}
                            tolakLaporan={this.tolakLaporan}
                            batalkanLaporan={this.batalkanLaporan}
                            loading={this.state.loading}
                        />
                    )}
        
                    {this.state.confirm && (
                        <ConfirmModal
                            item={this.state.item}
                            show={this.state.confirm}
                            tolakLaporan={this.tolakLaporan}
                            showConfirm={this.showConfirm}
                            loading={this.state.loading}
                        />
                    )}
                          
                </div>
                
            </Content>
        );
    }
}

export default Penyerahanshift;
