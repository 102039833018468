import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import img_jombang from "../../resources/img/Astra5.png";
import img_merak from "../../resources/img/Astra1.png";
import img_cikopo from "../../resources/img/Astra8.png";


class ProfilLaka extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,

        };
        // bind
    }
    componentDidMount() {
    }


    
    render() {
        const date = moment(this.props.end).format("DD MMMM YYYY");   
        const date_start = moment(this.props.start).format("MMMM YYYY");
        const start_date = moment(this.props.end)
        .subtract(1, "months")
        .format("MMMM YYYY");   
        // return
        return (
            <div>              
                <h2>Profil Laka YTD</h2>
                <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th rowspan="2">Ruas</th>
                                                <th rowspan="2">RT - HT (min.) <br></br> MTD {date}</th>
                                                <th rowspan="2">Avg. Accident / month</th>
                                                <th colspan="2">Blangspot (Segmen) YTD {date}</th>
                                                <th rowspan="2">{start_date}</th>
                                            </tr>
                                            <tr>
                                                <th>Segmen</th>
                                                <th>Avg.Accident/month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                       <tr>
                                           <td>Tanggerang-Merak <br></br><img width="250px" src={img_merak} /></td>
                                           <td>RT = {this.props.rt_current}<br></br> HT = {this.props.ht_current} </td>
                                           <td>{this.props.laka_bulan}</td>
                                           <td>(Ciujung-Sertim) <br></br>{this.props.segemen}</td>
                                           <td>{this.props.laka_ytd}</td>
                                           <td>
                                           {this.props.laka_count > 0 &&
                                           <>
                                           <i className="fa fa-arrow-up" style={{color:'green'}}></i> {this.props.laka_count} %
                                           </>
                                           }
                                            {this.props.laka_count < 0 &&
                                           <>
                                          <i className="fa fa-arrow-down" style={{color:'red'}}></i> {this.props.laka_count} %
                                           </>
                                           }

                                        {this.props.laka_count == 0 &&
                                           <>
                                          {this.props.laka_count} %
                                           </>
                                           }

                                        </td>
                                       </tr>
                                       <tr>
                                           <td>Cikopo-Palimanan <br></br><img width="250px" src={img_cikopo} /></td>
                                           <td>RT = {this.props.rt_current2}<br></br> HT = {this.props.ht_current2}</td>
                                           <td>{this.props.laka_bulan2}</td>
                                           <td>(Subang-Cikedung) <br></br>{this.props.segemen2}</td>
                                           <td>{this.props.laka_ytd2}</td>
                                           <td>
                                           {this.props.laka_count2 > 0 &&
                                           <>
                                           <i className="fa fa-arrow-up" style={{color:'green'}}></i> {this.props.laka_count2} %
                                           </>
                                           }
                                            {this.props.laka_count2 < 0 &&
                                           <>
                                          <i className="fa fa-arrow-down" style={{color:'red'}}></i> {this.props.laka_count2} %
                                           </>
                                           }

                                        {this.props.laka_count2 == 0 &&
                                           <>
                                          {this.props.laka_count2} %
                                           </>
                                           }

                                        </td>
                                       </tr>
                                       <tr>
                                           <td>Jombang-Mojekorto Barat <br></br><img width="250px" src={img_jombang} /></td>
                                           <td>RT = {this.props.rt_current3}<br></br> HT = {this.props.ht_current3}</td>
                                           <td>{this.props.laka_bulan3}</td>
                                           <td>(Jombang-Mojo Barat) <br></br>{this.props.segemen3}</td>
                                           <td>{this.props.laka_ytd3}</td>
                                           <td>
                                               {/* {this.props.laka_count3 > 0 || this.props.laka_count3 == 0 ?
                                           <>
                                           <i className="fa fa-arrow-up" style={{color:'green'}}></i> {this.props.laka_count3}
                                           </>
                                           :  <>
                                           <i className="fa fa-arrow-down" style={{color:'red'}}></i> {this.props.laka_count3}
                                           </>} */}

                                           {this.props.perbandingan_jombang1 > this.props.perbandingan_jombang2 &&
                                           <>
                                           <i className="fa fa-arrow-up" style={{color:'green'}}></i> {this.props.laka_count3} %
                                           </>
                                           }
                                            {this.props.perbandingan_jombang1 < this.props.perbandingan_jombang2 &&
                                           <>
                                          <i className="fa fa-arrow-down" style={{color:'red'}}></i> {this.props.laka_count3} %
                                           </>
                                           }

                                        {this.props.perbandingan_jombang1 == this.props.perbandingan_jombang2 &&
                                           <>
                                          {this.props.laka_count3} %
                                           </>
                                           }

                                        </td>
                                       </tr>
                                        </tbody>
                                    </table>
            </div>
        );
    }
}

export default ProfilLaka;
