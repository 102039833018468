import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import Button from "../AdminLTE/button";
import Loading from "../AdminLTE/loading";
import Input from "./input";
import SidebarMenu from "./sidebarmenu";
import logo_full from "../../resources/img/logo-full.png";

const formatMenu = (list, parent = 0) => {
    let result = [];
    list.forEach((item) => {
        if (item.parent_id == parent && (item.display_st === 1 || item.display_st === '1')) {
            let list_child = formatMenu(list, item.nav_id);
            if (list_child.length) {
                item.list_child = list_child;
            }
            result.push(item);
        }
    });
    return result;
};

const SidebarMenuList = (props) => {
    // hooks
    const [state, setState] = useState("");
    return props.list.map((item) => {
        return (
            <>
                <SidebarMenu
                          {...props}
                          id={item.nav_id}
                          state={state}
                          setState={setState}
                          key={item.nav_id}
                          title={item.nav_title}
                          url={item.nav_url}
                          list_children={
                              item.list_child && (
                                  <SidebarMenuList {...props} list={item.list_child} />
                              )
                          }
                      />
         
            </>
        );
    });
};

const Management = props => {
    // hooks
    const [state, setState] = useState(false);
    const [isReload, setIsReload] = useState(true);
    //open dropdown
    let dropdown = classNames("dropdown", "notifications-menu", "ml-2 mt-2",{
        open: state
    });
    //return
    return (
        <div className="row" style={{backgroundColor: '#FAFAFA'}}>
        <div className="col-md-4">
        <NavLink to="/" className="logo">
                <span className="logo-lg">
                    <img src={logo_full} alt="Logo" width="168px" height="35.88px" className="mt-1 ml-2 mb-2"/>
                </span>
            </NavLink>
</div>
<div className="col-lg-7"></div>
<div className="col-lg-1">
        <p className={dropdown}>
            <NavLink
                to="#"
                className="dropdown-toggle"
                onClick={event => {
                    event.preventDefault();
                    setState(!state);
                }}
            >
                <i className="fa fa-ellipsis-v" style={{fontSize:'20px', color:'#333333', marginLeft: '38px'}}></i>
            </NavLink>
            <ul className="dropdown-menu dropdown-menu-right">
                <li>
                    <ul className="menu">
                    {props.loading ? (
                        <Loading />
                    ) : (
                        <SidebarMenuList
                            {...props}
                            isReload={isReload}
                            color="#333333"
                            setIsReload={setIsReload}
                            list={formatMenu(props.menu)}
                        />
                    )}
                        <li>
                            {/* <i class="fa fa-sign-out"></i>  */}
                            <Button
                            type="button"
                            className="btn btn-link"
                            onClick={() => props.logoutProcess()}
                            loading={props.loading}
                            style={{color: '#333333'}}
                        >
                            Keluar
                        </Button></li>
                    </ul>
                </li>
                <li className="footer">
                </li>
            </ul>
        </p>
        </div>
            </div>
    );
};

export default Management;
