import React from "react";

// komponen
import { Table, InputCheckbox } from "../../../components/AdminLTE";

// style
const styles = {
    not_complete: {
        backgroundColor: "#ffebc7"
    }
};

// table
const thead = props => {
    let list = [
        { width: "10%", className: "text-center", label: "No" },
        { width: "60%", className: "text-center", label: "Judul Report" }
    ];
    if (!props.send_status) {
        list.push({
            width: "15%",
            className: "text-center",
            label: "Konfirmasi Laporan ?"
        });
    }
    return list;
};

const Tbody = props => {
    return props.list.map((item, index) => {
        return (
            <tr
                key={index}
                style={
                    (item.required == "1" && !item.id) ||
                    item.send_status == "no"
                        ? styles.not_complete
                        : null
                }
            >
                <td className="text-center">{index + 1}</td>
                <td>{item.title}</td>
                {!props.send_status && (
                    <td className="text-center">
                        <InputCheckbox
                            id={`reports__${index}`}
                            checked={item.send_status == "yes"}
                            onChange={() =>
                                props.handleChange(
                                    index,
                                    "send_status",
                                    item.send_status == "yes" ? "no" : "yes"
                                )
                            }
                        />
                    </td>
                )}
            </tr>
        );
    });
};

const ReportTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Kosong"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default ReportTable;
