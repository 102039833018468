import React from "react";
import moment from "moment";
// import idLocale from "moment/locale/id";

// moment.locale("id", idLocale);

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Ruas Tol",
        selector: "ruas_tol",
        sortable: true,
        width: "10%",
    },
    {
        name: "Unit",
        selector: "nama_unit",
        sortable: true,
        width: "5%",
    },
    {
        name: "CCTV",
        selector: "id_cctv",
        sortable: true,
        width: "10%",
    },
    {
        name: "Kejadian",
        selector: "nama_event",
        sortable: true,
        width: "20%",
    },
    {
        name: "Status Senkom",
        selector: "status_senkom",
        sortable: true,
        width: "15%",
    },
    {
        name: "Waktu Penanganan",
        selector: "waktu_penanganan",
        sortable: true,
        width: "15%",
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;

        if (item.status_senkom === 1 || item.status_senkom === '1') { item.status_senkom = 'Sudah Diterima Unit' }
        else if (item.status_senkom === 2 || item.status_senkom === '2') { item.status_senkom = 'Ditolak Petugas' }
        else if (item.status_senkom === 3 || item.status_senkom === '3') { item.status_senkom = 'Auto Reject' }
        else if (item.status_senkom === 4 || item.status_senkom === '4') { item.status_senkom = 'Sudah Tiba di Lokasi' }
        else if (item.status_senkom === 5 || item.status_senkom === '5') { item.status_senkom = 'Selesai Ditangani' }
        else if (item.status_senkom === 6 || item.status_senkom === '6') { item.status_senkom = 'Sudah Input Laporan' }
        else { item.status_senkom = 'Belum Dikirim' }

        if (item.waktu_penanganan) {
            let minutes = Math.floor(item.waktu_penanganan / 60)
            let seconds = item.waktu_penanganan % 60
            item.waktu_penanganan = minutes + ' menit ' + seconds + ' detik'
        }else{
            item.waktu_penanganan = '-'
        }

        item["detail"] = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.id)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const KepalaShiftTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KepalaShiftTable;
