import React from "react";
import classNames from "classnames";

const InputFeedback = props => {
    return (
        <div
            className={classNames("form-group", "has-feedback",{
                "has-error": props.has_error
            })}
        >
            <input
                id={props.id}
                type={props.type}
                className="form-control input-login"
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                autoFocus={props.autoFocus}
            />
            <span
                className={classNames("form-control-feedback", props.icon)}
                style={{Background: '#E7A78F'}}
            ></span>
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputFeedback;
