import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputRadioInline } from "../../../components/AdminLTE";

// utils
import toBase64 from "../../../utils/File";
import { PostPrivate } from "../../../utils/Connection";

class RekeningModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            rekening_id: props.item ? props.item.id : "",
            bank: props.item ? props.item.bank : "",
            nomor: props.item ? props.item.nomor : "",
            nama: props.item ? props.item.nama : "",
            status: props.item ? props.item.status : "",
            // error field
            error_field: {},
            // loading
            loading: false,
            status:[{id:"1",label:"Aktif"},{id:"0",label:"Tidak Aktif"}],
            qris: props.item ? props.item.qris : "",
        };
        // bind
        this.handleChange   = this.handleChange.bind(this);
        this.onSubmit       = this.onSubmit.bind(this);
        this.handleDrop     = this.handleDrop.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    handleDrop(picture) {
        this.setState({
            qris: picture[0]
        });
    }
    
    // on submit
    async onSubmit(evt) {
        evt.preventDefault();
        // this
        const that = this;
        
        let image = null;
            
        if (that.state.qris) {
            image = await toBase64(that.state.qris)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loading: true }, function() {
            
            

            //params
            const params = {
                rekening_id: that.state.rekening_id,
                bank: that.state.bank,
                nomor: that.state.nomor,
                nama: that.state.nama,
                status: that.state.status,
                qris:image
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>

                        <div className="form-group row">
                            <label
                                htmlFor={"bank"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Bank
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"bank"}
                                    type="text"
                                    placeholder="Nama Bank"
                                    has_error={this.state.error_field.bank}
                                    defaultValue={this.state.bank}
                                    onChange={ev =>
                                        this.handleChange(
                                            "bank",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.bank}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nomor"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nomor Rekening
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nomor"}
                                    type="text"
                                    placeholder="Nomor Rekening"
                                    has_error={this.state.error_field.nomor}
                                    defaultValue={this.state.nomor}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nomor",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nomor}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"gambar"}
                                className="col-sm-3 control-label mt-1"
                            >
                                QRIS
                            </label>
                            <div className="col-sm-5">
                                <ImageUploader
                                        withIcon={true}
                                        buttonText="Pilih Gambar"
                                        onChange={this.handleDrop}
                                        imgExtension={[
                                            ".jpg",
                                            ".gif",
                                            ".png",
                                            ".gif"
                                        ]}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nama"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Pemilik
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nama"}
                                    type="text"
                                    placeholder="Nama Pemilik"
                                    has_error={this.state.error_field.nama}
                                    defaultValue={this.state.nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_fieldnamanomor}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label
                            htmlFor={"status"}
                            className="control-label col-md-3 mt-2"
                        >
                            Status ?
                        </label>
                        <div className="col-md-9">
                            <InputRadioInline
                                id={"status"}
                                has_error={this.state.error_field.status}
                                defaultChecked={this.state.status}
                                onChange={ev =>
                                    this.handleChange("status", ev.target.value)
                                }
                                error_field={this.state.error_field.status}
                                list={[
                                    {
                                        label: "Aktif",
                                        value: "1"
                                    },
                                    {
                                        label: "Tidak aktif",
                                        value: "0"
                                    }
                                ]}
                            />
                        </div>
            </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RekeningModal;
