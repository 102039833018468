import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';

import { SwalSuccess } from "../../../components/Alert";

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import MedisTable from "./table";
import MedisSearch from "./search";
import MedisModal from "./modal";
import PopupModal from "./popup";

class Medis extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : Dates.getTodayDB();

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            popup:false,
            list: [],
            start: start,
            end: end,
            bujt: bujt,
            ruas_id:"",
            ruas_name:"",
            title:'Laporan Medis',
            detail:null,
            combo_kendaraan:[],
            counter_kendaraan:[],
            counter_kendaraan_key:[],
            jenis_kelamin:[],
            kewarganegaraan:[],
            kondisi_badan:[],
            kondisi_penumpang:[],
            pekerjaan:[],
            posisi_penumpang:[],
            sabuk_pengaman:[],
            tipe_korban:[],
            tipe_pengendara:[],
            sim:[],
            status_sim:[{id:"1",label:"Berlaku"},{id:"0",label:"Tidak Berlaku"}],
            tempat_luka:[],
            penumpang:[],
            penumpang_laka_id:null,
            penumpang_index:null,
            penumpang_kendaraan_id:null,
            penumpang_id:null,
            penumpang_nik:null,
            penumpang_nama:null,
            penumpang_alamat:null,
            penumpang_tingkat_luka:null,
            penumpang_tingkat_luka_nama:"",
            penumpang_tempat_luka:null,
            penumpang_tempat_luka_nama:"",
            penumpang_sabuk_pengaman:null,
            penumpang_sabuk_pengaman_nama:"",
            penumpang_posisi:null,
            penumpang_posisi_nama:"",
            penumpang_kelamin:null,
            penumpang_kelamin_nama:"",
            penumpang_umur:null
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.loadData = this.loadData.bind(this);
        this.addPenumpang = this.addPenumpang.bind(this);
        this.editPenumpang = this.editPenumpang.bind(this);
        this.setPenumpang = this.setPenumpang.bind(this);
        this.hapusPenumpang = this.hapusPenumpang.bind(this);
    }
    
    
    hapusPenumpang(index) {
        // USING POST REMOVE
        
        if (window.confirm("Yakin menghapus data ?")) {
            const params = {
                id : this.state.penumpang[index].penumpang_id
            };

            PostPrivate(this, `transaksi/medis/delete_penumpang`, params)
                    .then(result => {
                        if (result.status == 200) {
                            this.setState(
                                {
                                    loading: false
                                },
                                function() {
                                    // replacing index
                                    let penumpang = this.state.penumpang;
                                    penumpang.splice(index, 1);
                                    
                                    let counter_kendaraan = this.state.counter_kendaraan;
                                    
                                    counter_kendaraan.map((row, i) => {
                                        counter_kendaraan[i] = 0;
                                    });
                                    let index_of = 0;
                                    //di perlukan counter baru
                                    penumpang.map((row, i) => {
                                        // nothing 
                                        index_of = this.state.counter_kendaraan_key.indexOf(row.penumpang_kendaraan_id);
                                        counter_kendaraan[index_of]++;

                                        penumpang[i].counter = counter_kendaraan[index_of];

                                    });

                                    this.setState({ penumpang: penumpang });

                                    SwalSuccess("Berhasil", result.message);
                                }
                            );
                        } else {
                            alert('Update data gagal');

                            this.setState({
                                loading: false
                            });

                        }
                    })
                    .catch(error => {
                        if (typeof error == "object") {
                            this.setState({
                                error_field: error
                            });
                        }
                        // hapus loading
                        this.setState({
                            loading: false
                        });
                    });  
        }
    }
    
    setPenumpang(item) {
        // DARI LOAD PERTAMA KALI MODAL,
        let item_check = item;
        let counter_kendaraan = this.state.counter_kendaraan;
        let index_of = 0;
        
        counter_kendaraan.map((row, i) => {
            counter_kendaraan[i] = 0;
        });
        
        //di perlukan counter baru
        item_check.map((row, i) => {
            // nothing 
            index_of = this.state.counter_kendaraan_key.indexOf(row.penumpang_kendaraan_id);
            counter_kendaraan[index_of]++;

            item_check[i].counter = counter_kendaraan[index_of];
            
        });
        
        this.setState({
            penumpang: item_check
        });
    }
    
    editPenumpang(index) {
        this.setState({
            penumpang_index:index,
            penumpang_laka_id:this.state.penumpang[index].penumpang_laka_id,
            penumpang_kendaraan_id:this.state.penumpang[index].penumpang_kendaraan_id,
            penumpang_id:this.state.penumpang[index].penumpang_id,
            penumpang_nik:this.state.penumpang[index].penumpang_nik,
            penumpang_nama:this.state.penumpang[index].penumpang_nama,
            penumpang_alamat:this.state.penumpang[index].penumpang_alamat,
            penumpang_tingkat_luka:this.state.penumpang[index].penumpang_tingkat_luka,
            penumpang_tingkat_luka_nama:this.state.penumpang[index].penumpang_tingkat_luka_nama,
            penumpang_tempat_luka:this.state.penumpang[index].penumpang_tempat_luka,
            penumpang_tempat_luka_nama:this.state.penumpang[index].penumpang_tempat_luka_nama,
            penumpang_sabuk_pengaman:this.state.penumpang[index].penumpang_sabuk_pengaman,
            penumpang_sabuk_pengaman_nama:this.state.penumpang[index].penumpang_sabuk_pengaman_nama,
            penumpang_posisi:this.state.penumpang[index].penumpang_posisi,
            penumpang_posisi_nama:this.state.penumpang[index].penumpang_posisi_nama,
            penumpang_kelamin:this.state.penumpang[index].penumpang_kelamin,
            penumpang_kelamin_nama:this.state.penumpang[index].penumpang_kelamin_nama,
            penumpang_umur:this.state.penumpang[index].penumpang_umur,
            popup: !this.state.popup
        });
    }
    
    addPenumpang(index) {
        // index adalah index kendaraan
        
        let kendaraan_index = this.state.detail.kendaraan[index];
        
        if (typeof(kendaraan_index) === 'undefined' ) {
            this.setState({
                popup: !this.state.popup
            });
        } else {
            //console.log(this.state.detail.kendaraan);
            this.setState({
                penumpang_index:index,
                penumpang_kendaraan_id:kendaraan_index.id,
                popup: !this.state.popup
            });
            
        }
    }
    
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
        
        
        // LOAD ALL REFERENSI
        this.setState({ loading: true }, function() {
            //params
            const params = {
                id: ""
            };
            // paggil post api
            PostPrivate(this, 'transaksi/medis/jenis_kelamin', params)
                .then(result => {
                    this.setState(
                        {jenis_kelamin: result.data},
                        function() {
                            PostPrivate(this, 'transaksi/medis/kewarganegaraan', params)
                                .then(result => {
                                    this.setState(
                                        {kewarganegaraan: result.data},
                                        function() {
                                            PostPrivate(this, 'transaksi/medis/kondisi_badan', params)
                                                .then(result => {
                                                    this.setState(
                                                        {kondisi_badan: result.data},
                                                        function() {
                                                            PostPrivate(this, 'transaksi/medis/kondisi_penumpang', params)
                                                                .then(result => {
                                                                    this.setState(
                                                                        {kondisi_penumpang: result.data},
                                                                        function() {
                                                                            PostPrivate(this, 'transaksi/medis/pekerjaan', params)
                                                                                .then(result => {
                                                                                    this.setState(
                                                                                        {pekerjaan: result.data},
                                                                                        function() {
                                                                                            PostPrivate(this, 'transaksi/medis/posisi_penumpang', params)
                                                                                                .then(result => {
                                                                                                    this.setState(
                                                                                                        {posisi_penumpang: result.data},
                                                                                                        function() {
                                                                                                            PostPrivate(this, 'transaksi/medis/sabuk_pengaman', params)
                                                                                                                .then(result => {
                                                                                                                    this.setState(
                                                                                                                        {sabuk_pengaman: result.data},
                                                                                                                        function() {
                                                                                                                            PostPrivate(this, 'transaksi/medis/tipe_korban', params)
                                                                                                                                .then(result => {
                                                                                                                                    this.setState(
                                                                                                                                        {tipe_korban: result.data},
                                                                                                                                        function() {
                                                                                                                                            PostPrivate(this, 'transaksi/medis/tipe_pengendara', params)
                                                                                                                                                .then(result => {
                                                                                                                                                    this.setState(
                                                                                                                                                        {tipe_pengendara: result.data},
                                                                                                                                                        function() {
                                                                                                                                                            PostPrivate(this, 'transaksi/medis/sim', params)
                                                                                                                                                                .then(result => {
                                                                                                                                                                    this.setState(
                                                                                                                                                                        {sim: result.data},
                                                                                                                                                                        function() {
                                                                                                                                                                            PostPrivate(this, 'transaksi/medis/tempat_luka', params)
                                                                                                                                                                                .then(result => {
                                                                                                                                                                                    this.setState(
                                                                                                                                                                                        {tempat_luka: result.data}
                                                                                                                                                                                    );
                                                                                                                                                                                })
                                                                                                                                                                                .catch(error => {
                                                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                                                        this.setState({
                                                                                                                                                                                            error_field: error
                                                                                                                                                                                        });
                                                                                                                                                                                    }
                                                                                                                                                                                    // hapus loading
                                                                                                                                                                                    this.setState({
                                                                                                                                                                                        loading: false
                                                                                                                                                                                    });
                                                                                                                                                                                });
                                                                                                                                                                        }
                                                                                                                                                                    );
                                                                                                                                                                })
                                                                                                                                                                .catch(error => {
                                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                                        this.setState({
                                                                                                                                                                            error_field: error
                                                                                                                                                                        });
                                                                                                                                                                    }
                                                                                                                                                                    // hapus loading
                                                                                                                                                                    this.setState({
                                                                                                                                                                        loading: false
                                                                                                                                                                    });
                                                                                                                                                                });
                                                                                                                                                        }
                                                                                                                                                    );
                                                                                                                                                })
                                                                                                                                                .catch(error => {
                                                                                                                                                    if (typeof error == "object") {
                                                                                                                                                        this.setState({
                                                                                                                                                            error_field: error
                                                                                                                                                        });
                                                                                                                                                    }
                                                                                                                                                    // hapus loading
                                                                                                                                                    this.setState({
                                                                                                                                                        loading: false
                                                                                                                                                    });
                                                                                                                                                });
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                })
                                                                                                                                .catch(error => {
                                                                                                                                    if (typeof error == "object") {
                                                                                                                                        this.setState({
                                                                                                                                            error_field: error
                                                                                                                                        });
                                                                                                                                    }
                                                                                                                                    // hapus loading
                                                                                                                                    this.setState({
                                                                                                                                        loading: false
                                                                                                                                    });
                                                                                                                                });
                                                                                                                        }
                                                                                                                    );
                                                                                                                })
                                                                                                                .catch(error => {
                                                                                                                    if (typeof error == "object") {
                                                                                                                        this.setState({
                                                                                                                            error_field: error
                                                                                                                        });
                                                                                                                    }
                                                                                                                    // hapus loading
                                                                                                                    this.setState({
                                                                                                                        loading: false
                                                                                                                    });
                                                                                                                });
                                                                                                        }
                                                                                                    );
                                                                                                })
                                                                                                .catch(error => {
                                                                                                    if (typeof error == "object") {
                                                                                                        this.setState({
                                                                                                            error_field: error
                                                                                                        });
                                                                                                    }
                                                                                                    // hapus loading
                                                                                                    this.setState({
                                                                                                        loading: false
                                                                                                    });
                                                                                                });
                                                                                        }
                                                                                    );
                                                                                })
                                                                                .catch(error => {
                                                                                    if (typeof error == "object") {
                                                                                        this.setState({
                                                                                            error_field: error
                                                                                        });
                                                                                    }
                                                                                    // hapus loading
                                                                                    this.setState({
                                                                                        loading: false
                                                                                    });
                                                                                });
                                                                        }
                                                                    );
                                                                })
                                                                .catch(error => {
                                                                    if (typeof error == "object") {
                                                                        this.setState({
                                                                            error_field: error
                                                                        });
                                                                    }
                                                                    // hapus loading
                                                                    this.setState({
                                                                        loading: false
                                                                    });
                                                                });
                                                        }
                                                    );
                                                })
                                                .catch(error => {
                                                    if (typeof error == "object") {
                                                        this.setState({
                                                            error_field: error
                                                        });
                                                    }
                                                    // hapus loading
                                                    this.setState({
                                                        loading: false
                                                    });
                                                });
                                        }
                                    );
                                })
                                .catch(error => {
                                    if (typeof error == "object") {
                                        this.setState({
                                            error_field: error
                                        });
                                    }
                                    // hapus loading
                                    this.setState({
                                        loading: false
                                    });
                                });
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
        });
        
    }
    
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const ruas = this.state.ruas_id;
            
            if (ruas && ruas !== 'undefined') {
                const params = {
                    ruas_id : this.state.ruas_id,
                    start   : this.state.start,
                    end     : this.state.end
                };
                
                PostPrivate(this, 'transaksi/medis',params).then(result => {
                    this.setState({
                        loading : false,
                        list    : result.data
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
        if (id == null || id=='') {
            this.setState({ modal: !this.state.modal});
        } else {
            // get detail post
            // panggil get api
            
            this.setState({ penumpang_laka_id:id, loading: true }, function() {
                const params = {
                    id : id
                };

                PostPrivate(this, 'transaksi/medis/detail',params).then(result => {
                    let combo_kendaraan         = [];
                    let counter_kendaraan       = [];
                    let counter_kendaraan_key   = [];
                    
                    result.data.kendaraan.map((row, i) => {
                        combo_kendaraan.push({id:row.plat_nomor, label:row.merk+' '+row.seri+' '+row.plat_nomor});
                        counter_kendaraan.push(0);
                        counter_kendaraan_key.push(row.id);
                    });
                    
                    this.setState({
                        loading   : false,
                        detail    : result.data,
                        penumpang : result.penumpang,
                        modal     : !this.state.modal,
                        combo_kendaraan: combo_kendaraan,
                        counter_kendaraan:counter_kendaraan,
                        counter_kendaraan_key:counter_kendaraan_key
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            });
        }
        
        
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <MedisSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <MedisTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <MedisModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        addPenumpang={this.addPenumpang}
                        editPenumpang={this.editPenumpang}
                        hapusPenumpang={this.hapusPenumpang}
                        id={this.state.id}
                        detail={this.state.detail}
                        handleChange={this.handleChange}
                        jenis_kelamin={this.state.jenis_kelamin}
                        kewarganegaraan={this.state.kewarganegaraan}
                        kondisi_badan={this.state.kondisi_badan}
                        kondisi_penumpang={this.state.kondisi_penumpang}
                        pekerjaan={this.state.pekerjaan}
                        posisi_penumpang={this.state.posisi_penumpang}
                        sabuk_pengaman={this.state.sabuk_pengaman}
                        tipe_korban={this.state.tipe_korban}
                        tipe_pengendara={this.state.tipe_pengendara}
                        sim={this.state.sim}
                        status_sim={this.state.status_sim}
                        tempat_luka={this.state.tempat_luka}
                        penumpang={this.state.penumpang}
                        penumpang_laka_id={this.state.penumpang_laka_id}
                        penumpang_index={this.state.penumpang_index}
                        penumpang_kendaraan_id={this.state.penumpang_kendaraan_id}
                        penumpang_id={this.state.penumpang_id}
                        penumpang_nik={this.state.penumpang_nik}
                        penumpang_nama={this.state.penumpang_nama}
                        penumpang_alamat={this.state.penumpang_alamat}
                        penumpang_tingkat_luka={this.state.penumpang_tingkat_luka}
                        penumpang_tingkat_luka_nama={this.state.penumpang_tingkat_luka_nama}
                        penumpang_tempat_luka={this.state.penumpang_tempat_luka}
                        penumpang_tempat_luka_nama={this.state.penumpang_tempat_luka_nama}
                        penumpang_sabuk_pengaman={this.state.penumpang_sabuk_pengaman}
                        penumpang_sabuk_pengaman_nama={this.state.penumpang_sabuk_pengaman_nama}
                        penumpang_posisi={this.state.penumpang_posisi}
                        penumpang_posisi_nama={this.state.penumpang_posisi_nama}
                        penumpang_kelamin={this.state.penumpang_kelamin}
                        penumpang_kelamin_nama={this.state.penumpang_kelamin_nama}
                        penumpang_umur={this.state.penumpang_umur}
                    />
                )}
    
                {this.state.popup && (
                    <PopupModal
                        show={this.state.popup}
                        toogleShow={this.toogleShow}
                        addPenumpang={this.addPenumpang}
                        setPenumpang={this.setPenumpang}
                        hapusPenumpang={this.hapusPenumpang}
                        id={this.state.id}
                        detail={this.state.detail}
                        handleChange={this.handleChange}
                        jenis_kelamin={this.state.jenis_kelamin}
                        kewarganegaraan={this.state.kewarganegaraan}
                        kondisi_badan={this.state.kondisi_badan}
                        kondisi_penumpang={this.state.kondisi_penumpang}
                        pekerjaan={this.state.pekerjaan}
                        posisi_penumpang={this.state.posisi_penumpang}
                        sabuk_pengaman={this.state.sabuk_pengaman}
                        tipe_korban={this.state.tipe_korban}
                        tipe_pengendara={this.state.tipe_pengendara}
                        sim={this.state.sim}
                        status_sim={this.state.status_sim}
                        tempat_luka={this.state.tempat_luka}
                        penumpang={this.state.penumpang}
                        penumpang_laka_id={this.state.penumpang_laka_id}
                        penumpang_index={this.state.penumpang_index}
                        penumpang_kendaraan_id={this.state.penumpang_kendaraan_id}
                        penumpang_id={this.state.penumpang_id}
                        penumpang_nik={this.state.penumpang_nik}
                        penumpang_nama={this.state.penumpang_nama}
                        penumpang_alamat={this.state.penumpang_alamat}
                        penumpang_tingkat_luka={this.state.penumpang_tingkat_luka}
                        penumpang_tingkat_luka_nama={this.state.penumpang_tingkat_luka_nama}
                        penumpang_tempat_luka={this.state.penumpang_tempat_luka}
                        penumpang_tempat_luka_nama={this.state.penumpang_tempat_luka_nama}
                        penumpang_sabuk_pengaman={this.state.penumpang_sabuk_pengaman}
                        penumpang_sabuk_pengaman_nama={this.state.penumpang_sabuk_pengaman_nama}
                        penumpang_posisi={this.state.penumpang_posisi}
                        penumpang_posisi_nama={this.state.penumpang_posisi_nama}
                        penumpang_kelamin={this.state.penumpang_kelamin}
                        penumpang_kelamin_nama={this.state.penumpang_kelamin_nama}
                        penumpang_umur={this.state.penumpang_umur}
                    />
                )}
    
            </Content>
        );
    }
}

export default Medis;
