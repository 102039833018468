import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import { Modal, Button, Input } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import TablePersonel from "./table_personel";

class PersonelModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // datas
        this.detail_id = this.props.item.detail_id
            ? this.props.item.detail_id
            : "";
        this.title =
            this.props.mode == "edit"
                ? "Ubah Patroli Jalan Raya"
                : "Tambah Patroli Jalan Raya";
        // state
        this.state = {
            detail_name: this.props.item.detail_name
                ? this.props.item.detail_name
                : "",
            list: this.props.item.list_personel
                ? this.props.item.list_personel
                : [],
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRow = this.handleChangeRow.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(name, value) {
        this.setState({ [name]: value });
    }
    // handle change list
    handleChangeRow(index, value) {
        let { list } = this.state;
        list[index]["personel_name"] = value;
        this.setState({ list: list });
    }
    //addRow
    addRow() {
        this.setState({
            list: [...this.state.list, { personel_id: "", personel_name: "" }]
        });
    }
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list: list });
    }
    // konfirmasi hapus
    deleteConfirm(item, index) {
        SwalConfirm(
            `Hapus Personel Patroli [${item.personel_name}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item, index)
        );
    }
    // deleteprocess
    deleteProcess(item, index) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `pik/pjr/delete_personel/${item.personel_id}`)
                .then(result => {
                    // load data
                    this.removeRow(index);
                })
                .catch(error => {})
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                detail_id: that.detail_id,
                detail_name: that.state.detail_name,
                personel: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `pik/pjr/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"detail_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Patroli
                            </label>
                            <div className="col-sm-8">
                                <Input
                                    id={"detail_name"}
                                    type="text"
                                    placeholder="Nama Patroli"
                                    has_error={
                                        this.state.error_field.detail_name
                                    }
                                    defaultValue={this.state.detail_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "detail_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.detail_name
                                    }
                                />
                            </div>
                        </div>
                        <TablePersonel
                            {...this.props}
                            addRow={this.addRow}
                            handleChange={this.handleChangeRow}
                            removeRow={this.removeRow}
                            deleteConfirm={this.deleteConfirm}
                            list={this.state.list}
                        />
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default PersonelModal;
