import React, { Component } from "react";
import ImageUploader from "react-images-upload";

// komponen
import { Content, ButtonBack, Button } from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import { PostPrivate } from "../../../utils/Connection";

// pages
import FieldEvent from "./field_event";

class EventAdd extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            // field
            name: "",
            date: "",
            time: "",
            location: "",
            description: "L",
            status: "",
            status_name:"",
            file: null,
            error_field: {},
            // loading
            loading: false,
            list_status : [{value : '1', label : 'Aktif'}, {value : '0', label : 'Tidak AKtif'}]
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }
    componentDidMount() {
        // sementara nggak ada
       
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            file: picture[0]
        });
    }
    // loadData
    /*
    loadData(q, url, value, label) {
        // list kabupaten
        return GetPrivate(this, `${url}?q=${q}`)
            .then(result => {
                //return
                return result.data.map(item => ({
                    value: item[value],
                    label: item[label]
                }));
            })
            .catch(error => {
                return [];
            });
    }
    */
    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // cek upload image
        let image = null;
        if (that.state.file) {
            image = await toBase64(that.state.file)
                .then(result => result)
                .catch(error => null);
        }
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                name: that.state.event_name,
                date: that.state.event_date,
                time: that.state.event_time,
                location: that.state.event_location,
                description: that.state.event_description,
                status: that.state.event_status,
                file : image
            };
            // paggil post api
            PostPrivate(that, `event/event/add`, params)
                .then(result => {
                    // - redirect
                    that.props.history.replace(`/dashboard/event/event`);
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Tambah Event</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText="Pilih Gambar"
                                        onChange={this.handleDrop}
                                        imgExtension={[
                                            ".jpg",
                                            ".gif",
                                            ".png",
                                            ".gif"
                                        ]}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <FieldEvent
                                        name={this.state.name}
                                        date={this.state.date}
                                        time={this.state.time}
                                        location={this.state.location}
                                        description={this.state.description}
                                        status={this.state.status}
                                        list_status={this.state.list_status}
                                        handleChange={this.handleChange}
                                        error_field={this.state.error_field}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default EventAdd;
