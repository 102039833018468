import React from "react";

// komponen
import {
    Input,
    InputSelectReact,
} from "../../../components/AdminLTE";

// import EditorConvertToHTML from "../../../components/TextEditor/EditorConvertToHTML"


const FieldBerita = props => {
    return (
        <fieldset className="mt-2">
            
            <legend>Berita : </legend>
            {/* <div className="form-group row">
                <label
                    htmlFor={"id_berita"}
                    className="control-label col-md-3 mt-1"
                >
                    ID Berita
                </label>
                <div className="col-md-4">
                    <Input
                        id={"id_berita"}
                        type="text"
                        placeholder="ID Berita"
                        has_error={props.error_field.id_berita}
                        defaultValue={props.id_berita}
                        onChange={ev =>
                            props.handleChange("id_berita", ev.target.value)
                        }
                        error_field={props.error_field.id_berita}
                    />
                </div>
            </div> */}
            <div className="form-group row">
                <label
                    htmlFor={"judul_berita"}
                    className="control-label col-md-3 mt-1"
                >
                   Judul Berita
                </label>
                <div className="col-md-12">
                    <Input
                        id={"judul_berita"}
                        type="text"
                        placeholder="Judul Berita"
                        has_error={props.error_field.judul_berita}
                        defaultValue={props.judul_berita}
                        onChange={ev =>
                            props.handleChange("judul_berita", ev.target.value)
                        }
                        error_field={props.error_field.judul_berita}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label
                    htmlFor={"id"}
                    className="control-label col-md-3 mt-1"
                >
                    Kategori
                </label>
                <div className="col-md-4">
                    <InputSelectReact
                        id={"id"}
                        has_error={props.error_field.id}
                        defaultValue={{
                            label: props.nama,
                            value: props.id
                        }}
                        onChange={item => {
                            props.handleChange("id", item.value);
                        }}
                        error_field={props.error_field.id}
                        options={props.list_kategori}
                    />
                </div>
            </div>
            {/* <div className="form-group row">
                <label
                    htmlFor={"isi_berita"}
                    className="control-label col-md-3 mt-1"
                >
                    Isi Berita
                </label>
                <div className="col-md-12"> */}
                    {/* <textarea
                        id={"isi_berita"}
                        placeholder="Isi Berita"
                        has_error={props.error_field.isi_berita}
                        defaultValue={props.isi_berita}
                        onChange={ev =>
                            props.handleChange("isi_berita", ev.target.value)
                        }
                        error_field={props.error_field.isi_berita}
                        rows={8} 
                        cols={50} 
                    /> */}
                    {/* <EditorConvertToHTML />
                </div>
            </div> */}
            <div className="form-group row">
                <label
                    htmlFor={"id_bujt"}
                    className="control-label col-md-3 mt-1"
                >
                    Bujt
                </label>
                <div className="col-md-5">
                    <InputSelectReact
                        id={"id_bujt"}
                        has_error={props.error_field.id_bujt}
                        defaultValue={{
                            label: props.nama_bujt,
                            value: props.id_bujt
                        }}
                        onChange={item => {
                            if (item) props.handleChange("id_bujt", item.value);
                            else props.handleChange("id_bujt", "");
                        }}
                        error_field={props.error_field.id_bujt}
                        options={props.list_bujt}
                        isClearable={true}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label
                    htmlFor={"isi_berita"}
                    className="control-label col-md-3 mt-1"
                >
                    Isi Berita
                </label>
                <span className="help-block text-red">*Isi Berita wajib diisi !</span>
            </div>
            
        </fieldset>
    );
};

export default FieldBerita;
