import React from "react";

const Loading = () => {
    return (
        <div className="text-center text-muted m-2">
            <i className="fa fa-spinner fa-spin fa-2x"></i>
            <br />
            Loading ...
        </div>
    );
};

export default Loading;
