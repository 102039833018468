import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// komponen
import { Content, ButtonRole, BoxCollapse } from "../../../components/AdminLTE";
// import Header from "./header";
// utils
import GetPrivate from "../../../utils/Connection";

// page
import BeritaTable from "./table";
// import BeritaSearch from "./search";

class Berita extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            q: "",
            loading: false,
            list: [],
            list_bujt: [],
            list_kategori: []
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
        // load ref
        this.loadRef();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(
                this,
                `berita/berita`
            )
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // loadRef
    loadRef() {
        GetPrivate(this, `callcenter/callcenter/bujt`)
            .then(result => {
                this.setState({
                    list_bujt: result.data.map(item => ({
                        value: item.id_bujt,
                        label: item.nama_bujt
                    }))
                });
            })
            .catch(error => {});
        
            GetPrivate(this, `berita/berita/kategori`)
            .then(result => {
                this.setState({
                    list_kategori: result.data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }))
                });
            })
            .catch(error => {});
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                 
                <BoxCollapse
                    open={false}
                    title="Manajemen Data Berita"
                    tools={
                        <ButtonRole
                            role_url={`${this.props.location.pathname}/add`}
                            role_type="role_create"
                        >
                            <NavLink
                                to={`${this.props.location.pathname}/add`}
                                className="btn btn-box-tool ml-2"
                            >
                                <i className="fa fa-plus mr-1"></i> Tambah
                            </NavLink>
                        </ButtonRole>
                    }
                   
                   
                >
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <BeritaTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default Berita;
