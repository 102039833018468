import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import ApprovalTable from "./table";
import ApprovalSearch from "./search";
import ApprovalModal from "./modal";
import ConfirmModal from "./confirm";

class Approval extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : Dates.getTodayDB();

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            confirm:false,
            list: [],
            start: start,
            end: end,
            bujt: bujt,
            ruas_id:"",
            ruas_name:"",
            title:'Ganti Rugi',
            detail:null,
            lokasi_kecelakaan:[],
            penyebab_kecelakaan:[],
            unit_patroli:[],
            unit_ambulance_kp:[],
            unit_ambulance_kh:[],
            unit_rescue:[],
            unit_derek:[],
            unit_satgas:[],
            unit_pjr:[],
            unit_damkar:[],
            jenis_kecelakaan:[],
            penumpang:[],
            combo_kendaraan:[],
            jenis_kelamin:[{id:"1",label:"Laki-Laki"},{id:"2",label:"Perempuan"}],
            kondisi:[{id:"1",label: "Luka Ringan"},{id:"2",label:"Luka Berat"},{id:"3",label:"Meninggal Dunia"}],
            id_laka:""
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.loadData = this.loadData.bind(this);
        this.terima = this.terima.bind(this);
        this.tolakLaporan = this.tolakLaporan.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
    }
    
    terima(id) {
       this.setState({ loading: true }, function() {
            const params = {
                id : id,
                alasan:"",
                status:1
            };

            PostPrivate(this, "transaksi/approval/update",params)
                .then(result => {
                    if (result.status == 200) {
                        alert('penerimaan berhasil');
                        
                        // refresh
                        this.loadData();
                    } else {
                        alert('penerimaan gagal');
                       
                    }
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    
    showConfirm(id) {
        // show modal
        if (id == null || typeof id === 'object') {
            this.setState({ confirm: !this.state.confirm});
        } else {
            this.setState({ confirm: !this.state.confirm, id_laka:id });
        }
        
    }
    
    tolakLaporan(id,message) {
        this.setState({ loading: true }, function() {
            const params = {
                id : id,
                alasan:message,
                status:2
            };

            PostPrivate(this, "transaksi/approval/update",params)
                .then(result => {
                    if (result.status == 200) {
                        alert('Penolakan berhasil');
                        this.setState({ confirm: !this.state.confirm });
                        
                        // refresh
                        this.loadData();
                    } else {
                        alert('penolakan gagal');
                        this.setState({ confirm: !this.state.confirm });
                    }
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
    }
    
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const ruas = this.state.ruas_id;
            
            if (ruas && ruas !== 'undefined') {
                const params = {
                    ruas_id : this.state.ruas_id,
                    start   : this.state.start,
                    end     : this.state.end
                };
                
                PostPrivate(this, 'transaksi/approval',params).then(result => {
                    this.setState({
                        loading : false,
                        list    : result.data
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
        if (id == null || id=='') {
            this.setState({ modal: !this.state.modal});
        } else {
            // get detail post
            // panggil get api
            this.setState({ loading: true }, function() {
                const params = {
                    id : id
                };

                PostPrivate(this, 'transaksi/kecelakaan/detail',params).then(result => {
                    let combo_kendaraan = [];
                    
                    result.data.kendaraan.map((row, i) => {
                        combo_kendaraan.push({id:row.plat_nomor, label:row.merk+' '+row.seri+' '+row.plat_nomor});
                    });
                    
                    this.setState({
                        loading   : false,
                        detail    : result.data,
                        penumpang : result.penumpang,
                        modal     : !this.state.modal,
                        combo_kendaraan: combo_kendaraan
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            });
            this.loadData();
        }
        
        
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <ApprovalSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <ApprovalTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <ApprovalModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        id={this.state.id}
                        detail={this.state.detail}
                        handleChange={this.handleChange}
                        penumpang={this.state.penumpang}
                        terima={this.terima}
                        showConfirm={this.showConfirm}
                    />
                )}
    
                {this.state.confirm && (
                        <ConfirmModal
                            id={this.state.id_laka}
                            show={this.state.confirm}
                            tolakLaporan={this.tolakLaporan}
                            showConfirm={this.showConfirm}
                            loading={this.state.loading}
                        />
                )}
            </Content>
        );
    }
}

export default Approval;
