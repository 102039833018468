import React from "react";

// komponen
import { Input, InputSelectReact } from "../../components/AdminLTE";

const Search = props => {
    return (
        <div>
            <div className="row">
            <div className="col-xs-2">
                </div>
            <div className="col-xs-1">
                    <p className="robotos" style={{color: '#0C0C0C'}}>
                        Periode :
                    </p>
                </div>
                    <div className="col-xs-6">
                    <InputSelectReact
                        id={"periode"}
                        placeholder="periode"
                        defaultValue={{
                            label: props.periode_name,
                            value: props.periode
                        }}
                        onChange={item => {
                            props.handleChange("periode", item.value);
                        }}
                        options={props.list_periode}
                        parentStyle="col-xs-3"
                    />
            </div>
            </div>
        <div className="row">
            <div className="col-xs-12">
            <div className="form-group row mb-0" style={{marginLeft: '6px'}}>
            <div className="col-xs-3">
                </div>
            {props.Bulan &&
            <div>
            <InputSelectReact
                        id={"bulan"}
                        placeholder="Bulan"
                        defaultValue={{
                            label: props.bulan_name,
                            value: props.bulan
                        }}
                        onChange={item => {
                            props.handleChange("bulan", item.value);
                        }}
                        options={props.list_bulan}
                        parentStyle="col-xs-2"
                    />
            <InputSelectReact
                        id={"tahun"}
                        placeholder="Tahun"
                        defaultValue={{
                            label: props.tahun_name,
                            value: props.tahun
                        }}
                        onChange={item => {
                            props.handleChange("tahun", item.value);
                        }}
                        options={props.list_tahun}
                        parentStyle="col-xs-2"
                    />
            </div>
}
            
                    {props.Tanggal &&
                    <div className="col-xs-3">

                    <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.start}
                        onChange={ev =>
                            props.handleChangeStart("start", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
                     <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.end}
                        onChange={ev =>
                            props.handleChange("end", ev.target.value)
                        }
                        parentStyle="col-md-4 p-0"
                    />
            </div>
}
                        
                </div>
            </div>
        </div>

        <div className="row">
        <div className="col-xs-2">
                </div>
            <div className="col-xs-1">
                    <p className="robotos" style={{color: '#0C0C0C'}}>
                        Ruas Tol :
                    </p>
                </div>
        <div className="col-xs-6">
        <div className="form-group">
        <InputSelectReact
                        id={"ruas"}
                        onChange={item => props.handleChangeSelect(item)}
                        options={props.list_ruas}
                        value={{
                            label: props.ruas_name,
                            value: props.ruas_id
                        }}
                        parentStyle="col-xs-12"
                    />
        </div>
        <div className="col-xs-3">
            </div>
        </div>
        </div>
  </div>
    );
};

export default Search;
