import React from "react";

// komponen
import { Table, InputCheckbox } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "5%", className: "text-center", label: "No" },
        {
            width: "5%",
            className: "text-center",
            label: (
                <InputCheckbox
                    id="allcheck"
                    checked={props.allcheck == "1"}
                    onChange={props.onAllChecked}
                />
            )
        },
        { width: "30%", className: "text-center", label: "Judul Menu" },
        {
            width: "12.5%",
            className: "text-center",
            label: (
                <InputCheckbox
                    id="column_create"
                    label="Create"
                    checked={props.column_create == "1"}
                    onChange={() => props.onColumnChecked("create")}
                />
            )
        },
        {
            width: "12.5%",
            className: "text-center",
            label: (
                <InputCheckbox
                    id="column_read"
                    label="Read"
                    checked={props.column_read == "1"}
                    onChange={() => props.onColumnChecked("read")}
                />
            )
        },
        {
            width: "12.5%",
            className: "text-center",
            label: (
                <InputCheckbox
                    id="column_update"
                    label="Update"
                    checked={props.column_update == "1"}
                    onChange={() => props.onColumnChecked("update")}
                />
            )
        },
        {
            width: "12.5%",
            className: "text-center",
            label: (
                <InputCheckbox
                    id="column_delete"
                    label="Delete"
                    checked={props.column_delete == "1"}
                    onChange={() => props.onColumnChecked("delete")}
                />
            )
        }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.nav_id}>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">
                <InputCheckbox
                    id={`rows__${index}`}
                    checked={item.rows}
                    onChange={() => props.onChangeRoles(index, "rows")}
                />
            </td>
            <td>{item.nav_title}</td>
            <td className="text-center">
                <InputCheckbox
                    id={`role_create__${index}`}
                    checked={item.role_create == "1"}
                    onChange={() => props.onChangeRoles(index, "role_create")}
                />
            </td>
            <td className="text-center">
                <InputCheckbox
                    id={`role_read__${index}`}
                    checked={item.role_read == "1"}
                    onChange={() => props.onChangeRoles(index, "role_read")}
                />
            </td>
            <td className="text-center">
                <InputCheckbox
                    id={`role_update__${index}`}
                    checked={item.role_update == "1"}
                    onChange={() => props.onChangeRoles(index, "role_update")}
                />
            </td>
            <td className="text-center">
                <InputCheckbox
                    id={`role_delete__${index}`}
                    checked={item.role_delete == "1"}
                    onChange={() => props.onChangeRoles(index, "role_delete")}
                />
            </td>
        </tr>
    ));
};

const DetailTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={
                <Tbody onChangeRoles={props.onChangeRoles} list={props.list} />
            }
            empty="Belum ada menu aplikasi, silahkan tambahkan menu terlebih dahulu"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default DetailTable;
