import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputSelectReact, InputTextarea,} from "../../../components/AdminLTE";
import ReactPlayer from 'react-player'
// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import image_cctv from "../../../resources/img/img-cctv.png";

class AlasanModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Alasan Dibatalkan";
        // state
        this.state = {
            // field input
            // error field
            alasan_dibatalkan: props.item ? props.item.alasan_dibatalkan : "",
            error_field: {},
            // loading
            loading: false,
        };
       
    }

    componentDidMount() {
       
    }


  
    render() {
        // return
        return (
            <Modal
                form={true}
                width={600}
                title={this.title}
                content={
                    <>
                    <div className="row">
                    {/* <label className="col-sm-6 col-form-label"><h3>Alasan Dibatalkan</h3></label> */}
                    <div className="col-md-12">
                    <textarea readOnly disabled className="form-control" value={this.state.alasan_dibatalkan || '-'}/>
                    </div>
                    </div>
                                            
                    </>
                }
                footer={
                    <>

                    </>
                }
                show={this.props.show}
                onDismiss={this.props.showDetail3}
            />
        );
    }
}

export default AlasanModal;
