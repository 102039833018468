import React from "react";


// komponen
import { Input, InputSelectReact,InputCheckbox } from "../../../components/AdminLTE";

const SearchEvent = props => {
    return (
        <div className="row">
            <div className="col-md-offset-3 col-md-6">
                <form onSubmit={props.onSubmit} className="">
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="event_name"
                        >
                            ID Event
                        </label>
                        <Input
                            id="event_name"
                            type="text"
                            placeholder="Nama Event"
                            defaultValue={props.event_name}
                            onChange={ev =>
                                props.handleChange("event_name", ev.target.value)
                            }
                            parentStyle="col-md-8 p-0"
                        />
                    </div>
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="event_start"
                        >
                            Date Start
                        </label>
                        <div className="col-md-7">
                        <Input
                            id={"event_start"}
                            type="date"
                            placeholder="Date Start"
                            defaultValue={props.event_start}
                            onChange={ev =>
                                props.handleChange("event_start", ev.target.value)
                            }
                        />
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="event_end"
                        >
                            Date End
                        </label>
                        <div className="col-md-7">
                        <Input
                            id={"event_end"}
                            type="date"
                            placeholder="Date End"
                            defaultValue={props.event_end}
                            onChange={ev =>
                                props.handleChange("event_end", ev.target.value)
                            }
                        />
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label
                            className="control-label col-md-4 mt-2"
                            htmlFor="list_status"
                        >
                            Status
                        </label>
                        <div class="form-group col-md-8 p-0">
                            {props.list_status.map((dat, index) =>
                                (                
                                    <>
                                    <tr><td><InputCheckbox 
                                            id={`list_status_${index}`} 
                                            checked={props.list_status_data[index]} 
                                            value={dat.id} 
                                            onChange= {ev =>
                                                props.handleOnChange(index)
                                            }

                                    /></td><td>{dat.label}</td></tr>
                                    </>
                                )
                            )}
                        </div>
                    </div>
            
                    <div className="row">
                        <button
                            className="col-md-offset-4 btn btn-primary"
                            type="button"
                            onClick={ev =>
                                props.reloadData()
                            }
                        >
                            Cari Event
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SearchEvent;
