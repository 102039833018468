import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact,
    InputRadioInline,
    InputCheckbox
} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class SarprasModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Sarana dan Prasarana"
                : "Tambah Referensi Sarana dan Prasarana";
                
                
        let role_data = [];        
        props.role.map(role_list => {  
            role_data.push(false);
        });  
        // state
        this.state = {
            // field input
            sarpras_id: props.item ? props.item.sarpras_id : "",
            sarpras_name: props.item ? props.item.sarpras_name : "",
            sarpras_kategori_id: props.item ? props.item.sarpras_kategori_id : "",
            sarpras_satuan: props.item ? props.item.sarpras_satuan : "",
            sarpras_status: props.item ? props.item.sarpras_status : "",
            kategori_id: props.item ? props.item.kategori_id : "",
            kategori_nama: props.item ? props.item.kategori_nama : "",
            satuan_id: props.item ? props.item.satuan_id : "",
            satuan_nama: props.item ? props.item.satuan_nama : "",
            // error field
            error_field: {
                sarpras_name: null
            },
            // loading
            loading: false,
            role:props.role,
            role_data:role_data
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `referensi/sarpras/kategori`),
                GetPrivate(this, `referensi/sarpras/satuan`)
            ])
                .then(result => {
                    // jabatan
                    const kategori = result[0].data.map(item => ({
                        value: item.kategori_id,
                        label: item.kategori_nama
                    }));
                    const satuan = result[1].data.map(item => ({
                        value: item.satuan_id,
                        label: item.satuan_nama
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_kategori: kategori,
                        list_satuan: satuan
                    });
                    
                    // GET ROLE
                    
                    if (this.state.sarpras_id !='') {
                        this.setState({ loading: true }, function() {
                            //params
                            const params = {
                                sarpras_id: this.state.sarpras_id
                            };
                            // paggil post api
                            PostPrivate(this, 'referensi/sarpras/detail', params)
                                .then(result => {
                                    // GET ROLE
                                    let list_role = [];
                                    
                                    result.data.map((data) => {
                                        list_role.push(data.role_id);
                                    });
                                    
                                    let role_data = [];
                                    
                                    this.state.role.map((data, index) => {
                                        if (list_role.includes(data.id) == true) {
                                            role_data[index] = true;
                                        } else {
                                            role_data[index] = false;
                                        }
                                    });
                                    
                                    
                                    this.setState({
                                        loading: false,
                                        role_data:role_data
                                    });
                                })
                                .catch(error => {
                                    if (typeof error == "object") {
                                        this.setState({
                                            error_field: error
                                        });
                                    }
                                    // hapus loading
                                    this.setState({
                                        loading: false
                                    });
                                });
                        });
                    }
                    
                    
                })
                .catch(error => {});
        });
    }

    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    handleOnChange(index) {
        let role_data = this.state.role_data;
        
        if (role_data[index] == false) {
            role_data[index] = true;
        } else {
            role_data[index] = false;
        }
        
        this.setState({ role_data: role_data });
        
    }
    
    
    // on submit
    onSubmit = evt => {
        //console.log(this.state.role_data);
        
        //return false;
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                sarpras_id: that.state.sarpras_id,
                sarpras_name: that.state.sarpras_name,
                sarpras_kategori_id: that.state.sarpras_kategori_id,
                sarpras_satuan: that.state.sarpras_satuan,
                sarpras_status: that.state.sarpras_status,
                role_data:that.state.role_data,
                role:that.state.role
            };
            // paggil post api
            PostPrivate(that, `referensi/sarpras/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                    <div className="form-group row">
                                <label
                                    htmlFor={"sarpras_kategori_id"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Kategori
                                </label>
                                <div className="col-sm-5">
                                    <InputSelectReact
                                        id={"sarpras_kategori_id"}
                                        has_error={this.state.error_field.sarpras_kategori_id}
                                        defaultValue={{
                                            label: this.state.kategori_nama,
                                            value: this.state.kategori_id
                                        }}
                                        onChange={item => {
                                            this.handleChange("sarpras_kategori_id", item.value);
                                        }}
                                        error_field={this.state.error_field.sarpras_kategori_id}
                                        options={this.state.list_kategori}
                                    />
                                </div>
                            </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"sarpras_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Sarana
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"sarpras_name"}
                                    type="text"
                                    placeholder="Nama Sarana"
                                    has_error={
                                        this.state.error_field.sarpras_name
                                    }
                                    defaultValue={this.state.sarpras_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "sarpras_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.sarpras_name
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                                <label
                                    htmlFor={"sarpras_satuan"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Satuan
                                </label>
                                <div className="col-sm-5">
                                    <InputSelectReact
                                        id={"sarpras_satuan"}
                                        has_error={this.state.error_field.sarpras_satuan}
                                        defaultValue={{
                                            label: this.state.satuan_nama,
                                            value: this.state.satuan_id
                                        }}
                                        onChange={item => {
                                            this.handleChange("sarpras_satuan", item.value);
                                        }}
                                        error_field={this.state.error_field.sarpras_satuan}
                                        options={this.state.list_satuan}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"sarpras_role"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Jabatan
                                </label>
                                <div className="col-sm-5">
                                    <table>
                                     {this.state.role.map((dat, index) =>
                                         (
                                                
                                                <>
                                                <tr><td><InputCheckbox id={`role_${index}`} checked={this.state.role_data[index]} value={dat.id} onChange={() => this.handleOnChange(index)}
                                                    
                                                /></td><td>{dat.label}</td></tr>
                                                </>
                                            )
                                      )}
                                      </table>
                                </div>
                            </div>

                            <div className="form-group row">
                <label
                    htmlFor={"pegawai_st__1"}
                    className="control-label col-md-3 mt-2"
                >
                    Status ?
                </label>
                <div className="col-md-9">
                    <InputRadioInline
                        id={"sarpras_status"}
                        has_error={this.state.error_field.sarpras_status}
                        defaultChecked={this.state.sarpras_status}
                        onChange={ev =>
                            this.handleChange("sarpras_status", ev.target.value)
                        }
                        error_field={this.state.error_field.sarpras_status}
                        list={[
                            {
                                label: "Aktif",
                                value: "1"
                            },
                            {
                                label: "Tidak aktif",
                                value: "0"
                            }
                        ]}
                    />
                </div>
            </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default SarprasModal;
