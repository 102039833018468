import React, { Component } from "react";
import classNames from "classnames";

// komponen
import { SwalSuccess } from "../../../components/Alert";

// komponen
import {
    Modal,
    Button,
    Input,
    InputTextarea
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class PortalModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = props.mode == "edit" ? "Ubah Aplikasi" : "Tambah Aplikasi";
        // state
        this.state = {
            // field input
            portal_id: props.item ? props.item.portal_id : "",
            portal_nm: props.item ? props.item.portal_nm : "",
            site_title: props.item ? props.item.site_title : "",
            site_desc: props.item ? props.item.site_desc : "",
            meta_desc: props.item ? props.item.meta_desc : "",
            meta_keyword: props.item ? props.item.meta_keyword : "",
            // error field
            error_field: {
                portal_id: null,
                portal_nm: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                portal_id: that.state.portal_id,
                portal_nm: that.state.portal_nm,
                site_title: that.state.site_title,
                site_desc: that.state.site_desc,
                meta_desc: that.state.meta_desc,
                meta_keyword: that.state.meta_keyword
            };
            // paggil post api
            PostPrivate(that, `setting/portal/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"portal_id"}
                                className="col-sm-3 control-label mt-1"
                            >
                                ID Aplikasi
                            </label>
                            {this.props.mode == "edit" ? (
                                <div className="col-sm-9">
                                    <p className="form-control-static">
                                        {this.state.portal_id}
                                    </p>
                                </div>
                            ) : (
                                <div className="col-sm-3">
                                    <Input
                                        id={"portal_id"}
                                        type="text"
                                        placeholder="ID Portal"
                                        has_error={
                                            this.state.error_field.portal_id
                                        }
                                        defaultValue={this.state.portal_id}
                                        onChange={ev =>
                                            this.handleChange(
                                                "portal_id",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field.portal_id
                                        }
                                        autoFocus={true}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"portal_nm"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Aplikasi
                            </label>
                            <div className="col-sm-9">
                                <Input
                                    id={"portal_nm"}
                                    type="text"
                                    placeholder="Nama Aplikasi"
                                    has_error={this.state.error_field.portal_nm}
                                    defaultValue={this.state.portal_nm}
                                    onChange={ev =>
                                        this.handleChange(
                                            "portal_nm",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.portal_nm
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"site_title"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Judul Situs
                            </label>
                            <div className="col-sm-9">
                                <Input
                                    id={"site_title"}
                                    type="text"
                                    placeholder="Judul Situs"
                                    defaultValue={this.state.site_title}
                                    onChange={ev =>
                                        this.handleChange(
                                            "site_title",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"site_desc"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Situs
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"site_desc"}
                                    type="text"
                                    placeholder="Deskripsi Situs"
                                    rows={3}
                                    defaultValue={this.state.site_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "site_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"meta_desc"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Meta
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"meta_desc"}
                                    type="text"
                                    placeholder="Deskripsi Meta"
                                    rows={3}
                                    defaultValue={this.state.meta_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "meta_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"meta_keyword"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Kata Kunci Meta
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"meta_keyword"}
                                    type="text"
                                    placeholder="Kata Kunci Meta"
                                    rows={3}
                                    defaultValue={this.state.meta_keyword}
                                    onChange={ev =>
                                        this.handleChange(
                                            "meta_keyword",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default PortalModal;
