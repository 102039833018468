import React, { Component } from "react";

// komponen
import { Content, ButtonAdd } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";

// page
import TarifModal from "./modal";
import TarifTable from "./table";
import Header from "./header";

class Tarif extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            list: [],
            item: null,
            // tarif
            mode: "",
            modal: false,
            // kode tarif
            modal_kode: false,
            // loading
            loading: false,
            bujt:[],
            gerbang :[],
            golongan:[]
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
        this.toogleShowTarif = this.toogleShowTarif.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    
    setGerbang(mode, item) {
        if (item.id_bujt !='') {
            
            this.setState({ loading: true }, function() {
                const params = {
                    id : item.id_bujt
                };

                PostPrivate(this, "referensi/gerbang/gerbang_list",params)
                    .then(result => {
                        this.setState({
                            loading : false,
                            gerbang : result.data
                        });
                        
                        this.setState({ mode: mode, item: item }, function() {
                            this.setState({ modal: !this.state.modal });
                        });
                        

                    })
                    .catch(error => {
                        this.setState({
                            loading: false
                        });
                    });
            });
        }
    }
    
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/tarif")
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                    
                    // LOAD DATA
                    this.setState({ loading: true }, function() {
                        GetPrivate(this, "referensi/ruas/ruas_list")
                            .then(result => {
                                this.setState({
                                    loading: false,
                                    bujt   : result.data
                                });
                                
                                this.setState({ loading: true }, function() {
                                    GetPrivate(this, "referensi/gerbang/gerbang_list")
                                        .then(result => {
                                            this.setState({
                                                loading: false,
                                                gerbang   : result.data
                                            });
                                            
                                            this.setState({ loading: true }, function() {
                                                GetPrivate(this, "referensi/golongankendaraan/golongan_list")
                                                    .then(result => {
                                                        this.setState({
                                                            loading: false,
                                                            golongan   : result.data
                                                        });


                                                    })
                                                    .catch(error => {
                                                        this.setState({
                                                            loading: false
                                                        });
                                                    });
                                            });
                                            
                                            
                                        })
                                        .catch(error => {
                                            this.setState({
                                                loading: false
                                            });
                                        });
                                });
                            })
                            .catch(error => {
                                this.setState({
                                    loading: false
                                });
                            });
                    });
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Referensi Tarif [${item.nama_gerbang_masuk} - ${item.nama_gerbang_keluar} - ${item.golongan_nama}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/tarif/delete/${item.id}`)
                .then(result => {
                    // load data
                    this.loadData();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    //toogleShow
    toogleShow(mode = "", item = null) {
        // set gerbang duluan biar ngak kacau
        if (item != null) {
            this.setGerbang(mode, item);
        } else {
            this.setState({ mode: mode, item: item }, function() {
                /*
                if (item != null) {
                    this.setGerbang();
                }
                */
                this.setState({ modal: !this.state.modal });

            
            });
        }
        
        
    }
    //toogleShowTarif
    toogleShowTarif(item = null) {
        this.setState({ item: item }, function() {
            this.setState({ modal_kode: !this.state.modal_kode });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Manajemen Referensi Tarif</h3>
                        <div className="box-tools pull-right">
                            <ButtonAdd
                                onClick={() => this.toogleShow("add")}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                    <div className="box-body">
                    <Header
                            loading={this.state.loading}
                            show={this.state.modal}
                            toogleShow={() => this.toogleShow()}
                            loadData={() => this.loadData()}
                        />
                        <TarifTable
                            list={this.state.list}
                            bujt={this.state.bujt}
                            bujt_id={this.state.bujt_id}
                            bujt_name={this.state.bujt_name}
                            tipe={this.state.tipe}
                            tipe_id={this.state.tipe_id}
                            tipe_name={this.state.tipe_name}
                            toogleShow={this.toogleShow}
                            toogleShowTarif={this.toogleShowTarif}
                            deleteConfirm={this.deleteConfirm}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <TarifModal
                        mode={this.state.mode}
                        item={this.state.item}
                        show={this.state.modal}
                        bujt={this.state.bujt}
                        gerbang={this.state.gerbang}
                        golongan={this.state.golongan}
                        toogleShow={() => this.toogleShow()}
                        loadData={() => this.loadData()}
                    />
                )}
                
            </Content>
        );
    }
}

export default Tarif;
