import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../../components/Alert";
import { Modal, Button, Input } from "../../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../../utils/Connection";

class RefModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            id: props.item ? props.item.id : "",
            nama: props.item ? props.item.nama : "",
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                id: that.state.id,
                nama: that.state.nama
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"nama"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nama"}
                                    type="text"
                                    placeholder="Nama"
                                    has_error={this.state.error_field.nama}
                                    defaultValue={this.state.nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nama}
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RefModal;
