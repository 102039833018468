import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputSelectReact, InputTextarea,} from "../../../components/AdminLTE";
import ReactPlayer from 'react-player'
// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import image_cctv from "../../../resources/img/img-cctv.png";

class BatalModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "";
        // state
        this.state = {
            // field input
            // error field
            id :props.camera_id,
            name :props.camera_name,
            location :props.camera_location,
            error_field: {},
            // loading
            loading: false,
        };
       
    }

    componentDidMount() {
       
    }

    sendProcess(id) {
        // nanti dulu
        this.setState({ loading: true }, function() {
            // parameter petugas should be first top
            
            
            const params = {
                id: this.state.id,
                alasan_dibatalkan: this.state.alasan_dibatalkan,
                location: this.state.location,
                name: this.state.name,
            };
            
            
            PostPrivate(this, `event/event/event_reject`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal2
                            this.props.toogleShow();
                            // this.props.history.replace(`/dashboard/event/camera`);
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
        
    }

    handleChange(field, value) {
        this.setState({ [field]: value });
    }
  
    render() {
        console.log('event', this.state.name)
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                width={600}
                content={
                    <>
                    <div className="row">
            <div className="text-center">
                    <h2 >Anda yakin ingin membatalkan event ini ? </h2>
            </div>
            </div>
                        <div className="row">
            <div className="form-group">
                <label
                    htmlFor={"deskripsi_event"}
                    className="control-label"
                    style={{marginRight: '448px'}}
                >
                    Alasan dibatalkan
                </label>
                    
                </div>
            </div>      
            <div className="row">
            <div className="col-md-12">
            <InputTextarea
                        id={"alasan_dibatalkan"}
                        placeholder="Alasan"
                        has_error={this.state.error_field.alasan_dibatalkan}
                        defaultValue={this.state.alasan_dibatalkan}
                        onChange={ev =>
                            this.handleChange("alasan_dibatalkan", ev.target.value)
                        }
                        error_field={this.state.error_field.alasan_dibatalkan}
                        rows="8" 
                        cols="40" 
                    />
                </div>                  
                </div>                    
                                            
                    </>
                }
                footer={
                    <>
                    <Button
                            type="button"
                            className="btn btn-primary"
                            loading={this.state.loading}
                            onClick={this.sendProcess.bind(this)}
                        >
                            &nbsp;&nbsp; Ya &nbsp;&nbsp;
                        </Button>
                    <Button
                        type="button"
                        className="btn btn-danger"
                        loading={this.state.loading}
                        onClick={this.props.showDetail}
                    >
                        Tidak
                    </Button>
                    </>
                }
                show={this.props.show}
                onDismiss={this.props.showDetail}
            />
        );
    }
}

export default BatalModal;
