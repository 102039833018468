import React from "react";
import moment from "moment";

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";
import Dates from "../../../utils/Dates";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "No. Laporan",
        selector: "no_laporan",
        sortable: true,
        width: "20%",
    },
    {
        name: "Total Korban Luka Berat",
        selector: "jml_korban_lb",
        sortable: true,
        width: "10%",
    },
    {
        name: "Total Korban Meninggal",
        selector: "jml_korban_md",
        sortable: true,
        width: "10%",
    },
    {
        name: "Total Korban Luka Ringan",
        selector: "jml_korban_lr",
        width: "10%",
    },
    {
        name: "Tanggal",
        selector: "tanggal",
        sortable: true,
        width: "15%",
    },
    {
        name: "Biaya Ganti Rugi",
        selector: "total_harga_formatted",
        sortable: true,
        width: "15%",
    },
    
    {
        name: "Status Bayar",
        selector: "status",
        width: "15%",
    },
];

const data = (props) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    })

    return props.list.map((item, index) => {
        item.no = index + 1;

        // if (item.status === 1 || item.status === '1') { item.status = 'Sudah Bayar' }
        // if (item.status === 0 || item.status === '0') { item.status = 'Belum Bayar' }
        // if (item.status === 1 || item.status === '1') { item.status = 'Sudah Bayar' }
        if (item.status === 2 || item.status === '2') { item.status = 'Sudah Bayar' }
        else { item.status = 'Belum Bayar' }

        if (item.jml_korban_lb === null || item.jml_korban_lb === 'null') { item.jml_korban_lb = '0' }
        if (item.jml_korban_lr === null || item.jml_korban_lr === 'null') { item.jml_korban_lr = '0' }
        if (item.jml_korban_md === null || item.jml_korban_md === 'null') { item.jml_korban_md = '0' }
        if (item.total_harga === null || item.total_harga === 'null') { item.total_harga = '0' }

        item.total_harga_formatted = formatter.format(item.total_harga)

        return item;
    });
};

const LakalantasTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default LakalantasTable;
