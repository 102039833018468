import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const StastikManagemen = props => {
    const [state, setState] = useState(0);
    // panggil get api
    GetPrivate(this, props.url)
        .then(result => {
            setState(result.data);
        })
        .catch(error => {});
    // return
    return (
        <div> 
        <div className={classNames("fonts-bar",)}>{props.title}</div>
        <div className={classNames("fonts-bar", props.spasi)}>{state}</div>
        <div className={classNames("fonts-bar",)}>Menit</div>
        {/* <CircularProgressbar value={state} text={`${state} Menit`} 
        styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,
        
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
        
            // Text size
            textSize: '12px',
        
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
        
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',
        
            // Colors
            pathColor: `rgba(242, 38, 19, ${state / 100})`,
            textColor: '#121212',
            trailColor: '#DEE1E1',
            backgroundColor: '#FFF4F0',
          })}/> */}
          </div> 
    );
};

export default StastikManagemen;
