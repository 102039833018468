import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


class PieKeLaka extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
            list4: [],
        };
        // bind
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {

    }

    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.labels[opts.dataPointIndex]) {
            case 'test':
                window.location.href = this.props.linkTo4;
            break;
            case 'test1':
                window.location.href = this.props.linkTo1;
            break ;
            case 'test2':
                window.location.href = this.props.linkTo2;
            break ;
            case 'test3':
                window.location.href = this.props.linkTo3;
            break ;
            default:
        }
    }


    render() {
        // return
        const options = { labels: ["Jumlah kecelakaan", "Luka Ringan", "Luka Berat", "Meninggal"],
        noData:{text:"Empty Data"},                        
        colors:["#E02A2F","#FC511F","#EC343D","#999999"], 
        dataLabels: { enabled: true, formatter: function (val, opt) { return opt.w.config.series[opt.seriesIndex] + " " },
        style: {
            colors: ['#FAFAFA', '#FAFAFA', '#FAFAFA', '#FAFAFA'],
            fontSize: 12,
            fontFamily: 'Poppins'
          } },
        tooltip: { enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) { let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex]
        return w.config.labels[seriesIndex] + ": " + selected + "(" + (selected / total * 100).toFixed(2) + "%)"; } },
        title:{ text:"Informasi Kecelakaan", align:'center', style: { fontSize: 18, color:  '#2D3142', fontFamily: 'Poppins', fontWeight:  '400' }},
        legend: {
            show: true,
            labels: {
                colors: ["#121212"],
                useSeriesColors: false
            },
            position: 'bottom',
        },
        chart: {
            events: {
            dataPointSelection: this.handleDataPointSelection.bind(this)
    }
      },
    };
        // const series = [4, 5, 6]; //our data
        return (
            <div>              
                <Chart 
                type="pie"
                width={500}
                height={500}
              options={options} 
              series={[this.props.listLaka, this.props.listLaka2, this.props.listLaka3, this.props.listLaka4]}                
                >
                </Chart>
            </div>
        );
    }
}

export default PieKeLaka;
