import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input,InputSelectReact } from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class KameraModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            id: props.item ? props.item.id : "",
            nama: props.item ? props.item.nama : "",
            stream_url: props.item ? props.item.stream_url : "",
            stream_type_name:props.item ? props.item.stream_type : "",
            keterangan_arah: props.item ? props.item.keterangan_arah : "",
            longitude: props.item ? props.item.longitude : "",
            latitude: props.item ? props.item.latitude : "",
            id_bujt: props.item ? props.item.id_bujt : "",
            nama_bujt: props.item ? props.item.nama_bujt : "",
            id_status: props.item ? props.item.id_status : "",
            nama_status: props.item ? props.item.nama_status : "",
            bujt:props.bujt,
            stream_type:props.stream_type,
            // error field
            error_field: {},
            // loading
            loading: false,
            status:[{'id':1,'label':'Aktif'},{'id':0,'label':'Tidak Aktif'}]
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                id: that.state.id,
                nama: that.state.nama,
                stream_url: that.state.stream_url,
                keterangan_arah: that.state.keterangan_arah,
                longitude: that.state.longitude,
                latitude: that.state.latitude,
                id_bujt: that.state.id_bujt,
                status:that.state.id_status,
                stream_type:that.state.stream_type_name
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                BUJT
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"bujt"}
                                    has_error={this.state.error_field.bujt}
                                    defaultValue={{
                                        label: this.state.nama_bujt,
                                        value: this.state.id_bujt
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_bujt", item.id);
                                        this.handleChange("nama_bujt", item.label);
                                    }}
                                    error_field={this.state.error_field.bujt}
                                    options={this.state.bujt}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"nama"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nama"}
                                    type="text"
                                    placeholder="Nama"
                                    has_error={this.state.error_field.nama}
                                    defaultValue={this.state.nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nama}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"stream_url"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Stream URL
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"stream_url"}
                                    type="text"
                                    placeholder="Stream URL"
                                    has_error={this.state.error_field.stream_url}
                                    defaultValue={this.state.stream_url}
                                    onChange={ev =>
                                        this.handleChange(
                                            "stream_url",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.stream_url}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"stream_type"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Stream Type
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"stream_type"}
                                    has_error={this.state.error_field.stream_type}
                                    defaultValue={{
                                        label: this.state.stream_type_name,
                                        value: this.state.stream_type_name
                                    }}
                                    onChange={item => {
                                        this.handleChange("stream_type_name", item.id);
                                    }}
                                    error_field={this.state.error_field.stream_type}
                                    options={this.state.stream_type}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"keterangan_arah"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Keterangan Arah
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"keterangan_arah"}
                                    type="text"
                                    placeholder="Keterangan Arah"
                                    has_error={this.state.error_field.keterangan_arah}
                                    defaultValue={this.state.keterangan_arah}
                                    onChange={ev =>
                                        this.handleChange(
                                            "keterangan_arah",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.keterangan_arah}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"longitude"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Longitude
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"longitude"}
                                    type="text"
                                    placeholder="Longitude"
                                    has_error={this.state.error_field.longitude}
                                    defaultValue={this.state.longitude}
                                    onChange={ev =>
                                        this.handleChange(
                                            "longitude",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.longitude}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"latitude"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Latitude
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"latitude"}
                                    type="text"
                                    placeholder="Latitude"
                                    has_error={this.state.error_field.latitude}
                                    defaultValue={this.state.latitude}
                                    onChange={ev =>
                                        this.handleChange(
                                            "latitude",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.latitude}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"status"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Status
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"status"}
                                    has_error={this.state.error_field.status}
                                    defaultValue={{
                                        label: this.state.nama_status,
                                        value: this.state.id_status
                                    }}
                                    onChange={item => {
                                        this.handleChange("id_status", item.id);
                                        this.handleChange("nama_status", item.label);
                                    }}
                                    error_field={this.state.error_field.status}
                                    options={this.state.status}
                                />
                            </div>
                        </div>
                
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default KameraModal;
