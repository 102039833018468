import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputSelectReact, InputRadioInline} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class RefModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            kendaraan_id: props.item ? props.item.kendaraan_id : "",
            kendaraan_nama: props.item ? props.item.kendaraan_nama : "",
            kendaraan_unit_id: props.item ? props.item.kendaraan_unit_id : "",
            kendaraan_status: props.item ? props.item.kendaraan_status : "",
            no_polisi: props.item ? props.item.no_polisi : "",
            masa_berlaku_stnk: props.item ? props.item.masa_berlaku_stnk : "",
            pajak_stnk: props.item ? props.item.pajak_stnk : "",
            masa_berlaku_kir: props.item ? props.item.masa_berlaku_kir : "",
            masa_berlaku_olie: props.item ? props.item.masa_berlaku_olie : "",
            id_unit: props.item ? props.item.id_unit : "",
            nama_unit: props.item ? props.item.nama_unit : "",
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `referensi/kendaraan/unit`)
            ])
                .then(result => {
                    // jabatan
                    const unit = result[0].data.map(item => ({
                        value: item.id_unit,
                        label: item.nama_unit
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_unit: unit
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                kendaraan_id: that.state.kendaraan_id,
                kendaraan_unit_id: that.state.kendaraan_unit_id,
                kendaraan_nama: that.state.kendaraan_nama,
                kendaraan_status: that.state.kendaraan_status,
                no_polisi: that.state.no_polisi,
                masa_berlaku_stnk: that.state.masa_berlaku_stnk,
                pajak_stnk: that.state.pajak_stnk,
                masa_berlaku_kir: that.state.masa_berlaku_kir,
                masa_berlaku_olie: that.state.masa_berlaku_olie,
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>

                <div className="form-group row">
                                <label
                                    htmlFor={"kendaraan_unit_id"}
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Unit
                                </label>
                                <div className="col-sm-5">
                                    <InputSelectReact
                                        id={"kendaraan_unit_id"}
                                        has_error={this.state.error_field.kendaraan_unit_id}
                                        defaultValue={{
                                            label: this.state.nama_unit,
                                            value: this.state.id_unit
                                        }}
                                        onChange={item => {
                                            this.handleChange("kendaraan_unit_id", item.value);
                                        }}
                                        error_field={this.state.error_field.kendaraan_unit_id}
                                        options={this.state.list_unit}
                                    />
                                </div>
                            </div>

                        <div className="form-group row">
                            <label
                                htmlFor={"kendaraan_nama"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Kendaraan
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"kendaraan_nama"}
                                    type="text"
                                    placeholder="nama kendaraan"
                                    has_error={this.state.error_field.kendaraan_nama}
                                    defaultValue={this.state.kendaraan_nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "kendaraan_nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.kendaraan_nama}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                htmlFor={"no_polisi"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nomor Polisi
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"no_polisi"}
                                    type="text"
                                    placeholder="Nomor Polisi"
                                    has_error={this.state.error_field.no_polisi}
                                    defaultValue={this.state.no_polisi}
                                    onChange={ev =>
                                        this.handleChange(
                                            "no_polisi",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.no_polisi}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label
                                htmlFor={"masa_berlaku_stnk"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Masa Berlaku STNK
                            </label>
                            <div className="col-sm-5">
                            <Input
                                        id={"masa_berlaku_stnk"}
                                        type="date"
                                        placeholder="masa berlaku STNK"
                                        has_error={
                                            this.state.error_field
                                                .masa_berlaku_stnk
                                        }
                                        defaultValue={this.state.masa_berlaku_stnk}
                                        onChange={ev =>
                                            this.handleChange(
                                                "masa_berlaku_stnk",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .masa_berlaku_stnk
                                        }
                                    />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                htmlFor={"pajak_stnk"}
                                className="col-sm-3 control-label mt-1"
                            >
                                 Pajak STNK
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"pajak_stnk"}
                                    type="number"
                                    placeholder="pajak STNK"
                                    has_error={this.state.error_field.pajak_stnk}
                                    defaultValue={this.state.pajak_stnk}
                                    onChange={ev =>
                                        this.handleChange(
                                            "pajak_stnk",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.pajak_stnk}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label
                                htmlFor={"masa_berlaku_kir"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Masa Berlaku KIR
                            </label>
                            <div className="col-sm-5">
                            <Input
                                        id={"masa_berlaku_kir"}
                                        type="date"
                                        placeholder="masa berlaku KIR"
                                        has_error={
                                            this.state.error_field
                                                .masa_berlaku_kir
                                        }
                                        defaultValue={this.state.masa_berlaku_kir}
                                        onChange={ev =>
                                            this.handleChange(
                                                "masa_berlaku_kir",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .masa_berlaku_kir
                                        }
                                    />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                htmlFor={"masa_berlaku_olie"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Masa Berlaku Oli
                            </label>
                            <div className="col-sm-5">
                            <Input
                                        id={"masa_berlaku_olie"}
                                        type="date"
                                        placeholder="masa berlaku Oli"
                                        has_error={
                                            this.state.error_field
                                                .masa_berlaku_olie
                                        }
                                        defaultValue={this.state.masa_berlaku_olie}
                                        onChange={ev =>
                                            this.handleChange(
                                                "masa_berlaku_olie",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .masa_berlaku_olie
                                        }
                                    />
                            </div>
                        </div>

                        <div className="form-group row">
                <label
                    htmlFor={"pegawai_st__1"}
                    className="control-label col-md-3 mt-2"
                >
                    Status ?
                </label>
                <div className="col-md-9">
                    <InputRadioInline
                        id={"kendaraan_status"}
                        has_error={this.state.error_field.kendaraan_status}
                        defaultChecked={this.state.kendaraan_status}
                        onChange={ev =>
                            this.handleChange("kendaraan_status", ev.target.value)
                        }
                        error_field={this.state.error_field.kendaraan_status}
                        list={[
                            {
                                label: "Aktif",
                                value: "1"
                            },
                            {
                                label: "Tidak aktif",
                                value: "0"
                            }
                        ]}
                    />
                </div>
            </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RefModal;
