import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";

import logo from "../../../resources/img/logo-full.png";

class Print extends Component {
     constructor (props) {
        super(props)

        this.state = {
            date: new Date(),
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
        }
    }
    render () {
        return (
            <div id="print-container">
                <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid grey', marginBottom: '20px' }}>
                    <div style={{ float: 'left', width: '70%' }}>
                        <h2>Detail Ganti Rugi</h2>
                    </div>
                    <div style={{ float: 'left', width: '30%' }}>
                        <img src={logo} alt="logo" style={{ maxWidth: '194px', width: '194px' }} />
                        <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>Jalan Tol { this.props.item.ruas_tol }</div>
                    </div>
                </div>
                <table style={{ marginBottom: '10px', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '200px' }}>Nama Penanggung Jawab</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.nama_penanggung_jawab || '-' }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>No. Plat Kendaraan</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.nomor_plat || '-' }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>No. WA</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.no_wa_penanggung_jawab || '-' }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '200px' }}>Daftar Asset yang Rusak</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <table style={{ width: '100%', marginBottom: '20px' }}>
                                    <thead>
                                        <tr style={{ textAlign: 'left' }}>
                                          <th scope="col">No</th>
                                          <th scope="col">Fasilitas</th>
                                          <th scope="col">Harga Satuan</th>
                                          <th scope="col">Jumlah</th>
                                          <th scope="col">Total Harga</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.item.asset_rusak.map(function(row, i){
                                            return (
                                                <tr key={i + 1}>
                                                    <td scope="row">{i + 1}</td>
                                                    <td>{row.asset_name}</td>
                                                    <td>{row.asset_harga}</td>
                                                    <td>{row.jml_asset}</td>
                                                    <td>{row.total_harga}</td>
                                                </tr>
                                            )
                                        })}
                                        {this.props.item.asset_rusak.length === 0 &&
                                            <tr><td colSpan="5" style={{ textAlign: 'center' }}>- Tidak ada data- </td></tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ textAlign: 'left' }}>
                                            <th colSpan="4">Total Ganti Rugi</th>
                                            <th>{this.props.item.total_ganti_rugi}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Print;
