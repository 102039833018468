import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { Content, InputSelectReact } from "../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../components/Alert";
import Loading from "../../components/AdminLTE/loading";


// utils
import { PostPrivate } from "../../utils/Connection";
import GetPrivate from "../../utils/Connection";

const ref_title = "CCTV Streaming";
const ref_url = "cctv";

class Cctv extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: false,
            loading_cctv: false,
            modal: false,
            mode: "",
            cctv_id : "" ,
            cctv_name:"",
            bujt_id:"",
            bujt_name:"",
            cctv:[],
            bujt:[],
            camera_url:"",
            camera_url_short:"",
            camera_url_backup:"",
            camera_playing:false,
            loading_counter:0,
            error_field: {
                cctv_id: null,
                cctv_name:null,
                cctv:null
            }
        };

    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    
    handleChange(field, value) {
        this.setState({ [field]: value });
        
    }
    
    loadRefCCTV(bujt_id) {
        const params = {
                id_bujt :bujt_id
            };
            
            PostPrivate(this, "referensi/cctv/cctv_list",params)
                .then(result => {
                    this.setState({
                        loading: false,
                        cctv: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
    }
    
    loadCCTV() {
        this.setState({ loading_cctv: true }, function() {
            const params = {
                id : this.state.cctv_id
            };
            
            PostPrivate(this, "referensi/cctv/cctv_run",params)
                .then(result => {
                    if (result.type =='rtsp') {
                        this.setState({
                            loading_cctv : false,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true
                        });

                        //this.checkIsLoaded(result.url_short).bind(this);
                        this.checkIsLoaded(result.url_short).bind(this);
                    } else {
                        this.setState({
                            loading_cctv : false,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true,
                            camera_url:result.data
                        });
                    }

                })
                .catch(error => {
                    if (this.state.loading_cctv == true) {
                        this.setState({
                            loading_cctv: false
                        });
                    }
                });
        });
    }
    
    checkIsLoaded(url_short) {
        let counter_data = this.state.loading_counter;
        
        counter_data = counter_data + 1;
        
        this.setState({
            loading_counter: counter_data
        });
                        
        this.setState({ loading_cctv: true }, function() {
        GetPrivate(this, `event/event/loaded_video?id_cctv=${url_short}`)
            .then(result => {
                if (result.data == true) {
                    this.setState(
                        {
                            loading_cctv: true,
                            camera_url:this.state.camera_url_backup
                        }
                    );    
                } else {
                    if (this.state.loading_counter > 100) {
                        this.setState({
                            loading_cctv: false,
                            loading_counter:0
                        });
                    } else {
                        this.setState({
                            loading_cctv: true
                        });
                        this.checkIsLoaded(this.state.camera_url_short).bind(this)    
                    }
                    
                  
                }

                if (result.data == false) {
                    this.setState(
                        {
                            loading_cctv: true
                        }
                    );    
                    }

                this.toogleShow("add");
            }).catch(error => {
                // hapus loading
                this.setState({
                    loading_cctv: true
                });

                if (this.state.loading_cctv == true) {
                    this.setState({
                        loading_cctv: false
                    });
                }
            });
        });
    }
    

    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const params = {
                id_bujt :""
            };
            
            PostPrivate(this, "referensi/cctv/cctv_list",params)
                .then(result => {
                    this.setState({
                        loading: false,
                        cctv: result.data
                    });
                    
                    GetPrivate(this, "referensi/ruas/ruas_list")
                        .then(result => {
                            this.setState({
                                loading: false,
                                bujt: result.data
                            });
                        })
                        .catch(error => {
                            this.setState({
                                loading: false
                            });
                        });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Manajemen {ref_title}</h3>
                        
                    </div>
                    <div className="box-body">
                        <div className="form-group row">
                            <label
                                htmlFor={"bujt"}
                                className="col-sm-3 control-label mt-1"
                            >
                                BUJT
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"bujt"}
                                    has_error={this.state.error_field.bujt}
                                    defaultValue={{
                                        label: this.state.bujt_name,
                                        value: this.state.bujt_id
                                    }}
                                    onChange={item => {
                                            
                                        this.handleChange("bujt_id", item.id);
                                        this.handleChange("bujt_name", item.label);
                                        this.loadRefCCTV(item.id);
                                    }}
                                    error_field={this.state.error_field.bujt}
                                    options={this.state.bujt}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                CCTV
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"cctv"}
                                    has_error={this.state.error_field.cctv}
                                    defaultValue={{
                                        label: this.state.cctv_name,
                                        value: this.state.cctv_id
                                    }}
                                    onChange={item => {
                                        this.handleChange("cctv_id", item.id);
                                        this.handleChange("cctv_name", item.label);
                                        this.loadCCTV();
                                    }}
                                    error_field={this.state.error_field.cctv}
                                    options={this.state.cctv}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                       <div className="form-group row">
                            <div className="col-sm-12 col-md-12" >
                            {this.state.loading_cctv ? (
                                <>
                                    <Loading />
                                </>
                            ) : (
                                this.state.children
                            )}       
                            <ReactPlayer url={this.state.camera_url} playing={this.state.camera_playing} />
                            </div>
                        </div>
                    </div>

                          
                </div>
                
            </Content>
        );
    }
}

export default Cctv;
