import React, { Component } from "react";

// komponen
import { Modal } from "../../../components/AdminLTE";
import TableLane from "./table_lane";

const RuasLaneModal = props => {
    return (
        <Modal
            title="Jalur Tol"
            content={
                <TableLane
                    list={props.item}
                    addRowLane={props.addRowLane}
                    removeRowLane={props.removeRowLane}
                    handleChangeLane={props.handleChangeLane}
                />
            }
            show={props.show}
            onDismiss={props.toogleShow}
        />
    );
};

export default RuasLaneModal;
