import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonGroup,
    ButtonEdit,
    ButtonDelete
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Nama Patroli",
        selector: "detail_name",
        sortable: true,
        width: "25%"
    },
    {
        name: "Nama Petugas",
        selector: "detail_pesonel",
        sortable: true,
        width: "55%"
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["detail_pesonel"] = item.list_personel
            .map(personel => personel.personel_name)
            .join(", ");
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const RealisasiTable = props => {
    return (
        <TableBootstrap
            keyField={"user"}
            empty="Belum ada patroli, klik tambah untuk menambahkan patroli baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RealisasiTable;
