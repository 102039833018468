import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

// default alert
const SwalDefault = (title = "", message = "") =>
    mySwal.fire({
        title: title,
        text: message,
        allowOutsideClick: true
    });

// success alert
export const SwalSuccess = (title = "", message = "") =>
    mySwal.fire({
        icon: "success",
        title: title,
        text: message,
        allowOutsideClick: true
    });

// error alert
export const SwalError = (title = "", message = "") =>
    mySwal.fire({
        icon: "error",
        title: title,
        text: message,
        allowOutsideClick: true
    });

// warning alert
export const SwalWarning = (title = "", message = "") =>
    mySwal.fire({
        icon: "warning",
        title: title,
        text: message,
        allowOutsideClick: true
    });

// warning alert
export const SwalNoIcon = (title = "", message = "") =>
    mySwal.fire({
        // icon: "warning",
        title: title,
        text: message,
        allowOutsideClick: true
    });
// custom alert
export const SwalCustom = options => mySwal.fire(options);

// hapus alert
export const SwalConfirm = (
    title = "Hapus Ini?",
    message = "",
    callback,
    confirmButton = "Ya",
    confirmColor = "#dd4b39"
) => {
    mySwal
        .fire({
            title: title,
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: confirmColor,
            confirmButtonText: confirmButton,
            cancelButtonText: "Batal",
            allowOutsideClick: true
        })
        .then(result => {
            if (result.value) {
                callback();
            }
        });
};

// toast
export const Toast = (title = "") => {
    mySwal
        .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener("mouseenter", mySwal.stopTimer);
                toast.addEventListener("mouseleave", mySwal.resumeTimer);
            }
        })
        .fire({
            icon: "success",
            title: title
        });
};

export const SwalConfirmSave = (
    title = "Yakin Simpan?",
    message = "",
    callback,
    confirmButton = "Ya, simpan!",
    confirmColor = "#00c0ef"
) => {
    mySwal
        .fire({
            title: title,
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: confirmColor,
            confirmButtonText: confirmButton,
            cancelButtonText: "Batal",
            allowOutsideClick: true
        })
        .then(result => {
            if (result.value) {
                callback();
            }
        });
};


// export
export default SwalDefault;
