import React, { Component } from "react";
import classNames from "classnames";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputTextarea,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import GroupOptions from "./options";

class RoleModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit" ? "Ubah Hak Akses" : "Tambah Hak Akses";
        // state
        this.state = {
            // field input
            role_id: props.item ? props.item.role_id : "",
            group_id: props.item ? props.item.group_id : "",
            group_name: props.item ? props.item.group_name : "",
            role_nm: props.item ? props.item.role_nm : "",
            role_desc: props.item ? props.item.role_desc : "",
            default_page: props.item ? props.item.default_page : "",
            list_group: [],
            list_page :  [
                { value: "dashboard", label: "dashboard" },
                { value: "dashboard/management", label: "dashboard/management"},
              ],
            // error field
            error_field: {
                role_id: null,
                role_nm: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // component did mount
    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "setting/group")
                .then(result => {
                    this.setState({
                        loading: false,
                        list_group: result.data.map(item => ({
                            value: item.group_id,
                            label: item.group_name
                        }))
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                role_id: that.state.role_id,
                group_id: that.state.group_id,
                role_nm: that.state.role_nm,
                role_desc: that.state.role_desc,
                default_page: that.state.default_page
            };
            // paggil post api
            PostPrivate(that, `setting/role/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"group_id"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Grup Akses
                            </label>
                            {this.props.mode == "edit" ? (
                                <div className="col-sm-9">
                                    <p className="form-control-static">
                                        {this.state.group_name}
                                    </p>
                                </div>
                            ) : (
                                <div className="col-sm-7">
                                    <InputSelectReact
                                        id={"group_id"}
                                        has_error={
                                            this.state.error_field.group_id
                                        }
                                        defaultValue={{
                                            label: this.state.group_name,
                                            value: this.state.group_id
                                        }}
                                        onChange={item =>
                                            this.handleChange(
                                                "group_id",
                                                item.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field.group_id
                                        }
                                        options={this.state.list_group}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"role_nm" + this.state.role_id}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Hak Akses
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"role_nm" + this.state.role_id}
                                    type="text"
                                    placeholder="Nama Hak Akses"
                                    has_error={this.state.error_field.role_nm}
                                    defaultValue={this.state.role_nm}
                                    onChange={ev =>
                                        this.handleChange(
                                            "role_nm",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.role_nm}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"role_desc" + this.state.role_id}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Hak Akses
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"role_desc" + this.state.role_id}
                                    type="text"
                                    placeholder="Deskripsi Hak Akses"
                                    rows={5}
                                    defaultValue={this.state.role_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "role_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"default_page" + this.state.role_id}
                                className="col-sm-3 control-label mt-1"
                            >
                                Landing Page
                            </label>
                            <div className="col-sm-4">
                                {/* <Input
                                    id={"default_page" + this.state.role_id}
                                    type="text"
                                    placeholder="Landing Page"
                                    has_error={
                                        this.state.error_field.default_page
                                    }
                                    defaultValue={this.state.default_page}
                                    onChange={ev =>
                                        this.handleChange(
                                            "default_page",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.default_page
                                    }
                                /> */}
                                <InputSelectReact
                                id={"bulan"}
                                placeholder="Bulan"
                                defaultValue={{
                                    label: this.state.default_page,
                                    value: this.state.default_page
                                }}
                                onChange={item => {
                                    this.handleChange("default_page", item.value);
                                }}
                                options={this.state.list_page}
                            />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RoleModal;
