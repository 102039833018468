import React from "react";
import classNames from "classnames";

// komponen
import Loading from "./loading";
import Empty from "./empty";

const Table = props => {
    function getContent() {
        if (props.loading) {
            return (
                <tr>
                    <td colSpan={props.thead.length}>
                        <Loading />
                    </td>
                </tr>
            );
        } else if (props.tbody.props.list != null && props.tbody.props.list.length) {
                   return props.tbody;
               } else {
                   return (
                       <tr>
                           <td colSpan={props.thead.length}>
                               <Empty content={props.empty} icon={props.icon} />
                           </td>
                       </tr>
                   );
               }
    }
    // return
    return (
        <div
            className={classNames({
                "table-responsive": props.responsive == true
            })}
        >
            <table className={props.className}>
                <thead>
                    <tr>
                        {props.thead.map((item, index) => (
                            <th
                                key={index}
                                width={item.width}
                                className={item.className}
                            >
                                {item.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{getContent()}</tbody>
            </table>
        </div>
    );
};

export default Table;
