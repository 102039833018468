import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";

class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            item: {
                id: null,
                id_cctv: null,
                nama_event: null,
                waktu_event: null,
                status_senkom: null,
                eta: null,
                range: null,
                latitude: null,
                longitude: null,
                prioritas: null,
                nama_unit: null,
                nama_cctv: null,
                stream_url: null,
                status_kamera: null,
                arah_kamera: null,
                latitude_kamera: null,
                longitude_kamera: null,
                web_streaming: null,
                ruas_tol: null
            }
        }

        this.loadDetail = this.loadDetail.bind(this)
    }
    componentDidMount () {
        this.loadDetail()
    }
    loadDetail () {
        GetPrivate(this, `laporan/handlingresponse/detail/` + this.props.id).then(result => {
            if (result.data.status_senkom === 1 || result.data.status_senkom === '1') { result.data.status_senkom = 'Sudah Diterima Unit' }
            else if (result.data.status_senkom === 2 || result.data.status_senkom === '2') { result.data.status_senkom = 'Ditolak Petugas' }
            else if (result.data.status_senkom === 3 || result.data.status_senkom === '3') { result.data.status_senkom = 'Auto Reject' }
            else if (result.data.status_senkom === 4 || result.data.status_senkom === '4') { result.data.status_senkom = 'Sudah Tiba di Lokasi' }
            else if (result.data.status_senkom === 5 || result.data.status_senkom === '5') { result.data.status_senkom = 'Selesai Ditangani' }
            else if (result.data.status_senkom === 6 || result.data.status_senkom === '6') { result.data.status_senkom = 'Sudah Input Laporan' }
            else { result.data.status_senkom = 'Belum Dikirim' }
            this.setState({ item: result.data })
        }).catch(error => {})
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Unit</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.nama_unit || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Ruas Tol</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.ruas_tol || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">CCTV</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.id_cctv || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Kejadian</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.nama_event || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Waktu Kejadian</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.waktu_event || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Latitude</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.latitude || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Longitude</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.longitude || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">ETA</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.eta || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Jarak</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.range || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Status Senkom</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.status_senkom || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Prioritas</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.prioritas || '-'}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label" style={{marginBottom: '10px', marginTop: '20px'}}>Detail CCTV</label>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Nama CCTV</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.nama_cctv || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">URL Stream</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.stream_url || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Status Kamera</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.status_kamera || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Arah Kamera</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.arah_kamera || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Latitude Kamera</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.latitude_kamera || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Longitude Kamera</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.longitude_kamera || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Web Streaming</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.item.web_streaming || '-'}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Detail;
