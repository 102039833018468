import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import ReactPlayer from 'react-player'
// komponen
import { Content, ButtonBack, Button } from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import toBase64 from "../../../utils/File";
import Dates from "../../../utils/Dates";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// pages
import FieldEvent from "./field_event";
import Map from "./map";

class EventAdd extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            // field
            nama_event: "",
            id_cctv: "",
            deskripsi_event: "",
            id_unit: "",
            sumber_event: "",
            list_cctv: [],
            list_event: [],
            list_unit: [],
            // loading
            loading: false,
            loading_cctv: false,
            list_sumber : [{value : '1', label : 'Petugas'}, {value : '0', label : 'Customer'}],
            camera_url:"",
            camera_url_short:"",
            camera_url_backup:"",
            camera_playing:false,
            error_field: {
                cctv_id: null,
                cctv_name:null,
                cctv:null
            },

            // moving unit
            name: "React",
            showHideDemo1: false,
            list: [],
            date: Dates.getTodayDB(),
            range: { start: '00:00', end: '23:59' },
            start: '00:00', 
            end: '23:59',
            title:'Laporan Moving Unit'
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.loadCCTV = this.loadCCTV.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        // moving unit
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.handleSelectTime = this.handleSelectTime.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
    }
    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            
            Promise.all([
                GetPrivate(this, `event/event/list_cctv`),
                GetPrivate(this, `referensi/jenisevent`),
                GetPrivate(this, `event/event/list_unit_patroli`),
                // PostPrivate(this, "referensi/cctv/cctv_run",params2)
            ])
                .then(result => {
                    // cctv
                    const cctv = result[0].data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }));
                    // event
                    const event = result[1].data.map(item => ({
                        value: item.label,
                        label: item.label
                    })); 
                    // unit
                    const unit = result[2].data.map(item => ({
                        value: item.id,
                        label: item.nama
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_cctv: cctv,
                        list_event: event,
                        list_unit: unit,
                    });
                })
                .catch(error => {});
        });
        this.loadData();
       
    }

    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `laporan/movingunit/all?date=${this.state.date}&start=${this.state.start}:00&end=${this.state.end}:00`).then(result => {
                this.setState({
                    loading: false,
                    list: result.data
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
        });
    }

    loadCCTV() {
        this.setState({ loading_cctv: true }, function() {
            const params2 = {
                id : this.state.id_cctv
            };
            
            PostPrivate(this, "event/event/run_streaming_cs",params2)
                .then(result => {
                    if (result.type =='rtsp') {
                        this.setState({
                            loading_cctv : false,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true
                        });

                        //this.checkIsLoaded(result.url_short).bind(this);
                        this.checkIsLoaded(result.url_short).bind(this);
                    } else {
                        this.setState({
                            loading_cctv : false,
                            camera_url_backup:result.data,
                            camera_url_short:result.url_short,
                            camera_playing:true,
                            camera_url:result.data
                        });
                    }

                })
                .catch(error => {
                    this.setState({
                        loading_cctv: false
                    });
                });
        });
    }
    handleChangeDate (field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChangeTime (range) {
        this.setState({ range: range })
    }
    handleSelectTime (range) {
        this.loadData()
    }
    hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ showHideDemo1: !this.state.showHideDemo1 });
            break;
          default:
        }
      }
    checkIsLoaded(url_short) {
        this.setState({ loading_cctv: true }, function() {
        GetPrivate(this, `event/event/loaded_video?id_cctv=${url_short}`)
            .then(result => {
                if (result.data == true) {
                    this.setState(
                        {
                            camera_url:this.state.camera_url_backup,
                            loading: false
                        }
                    );    
                } else {
                    this.setState({
                        loading: true
                    });
                    setTimeout(this.checkIsLoaded(url_short).bind(this), 5000);
                }

                if (result.data != true) {
                    this.setState(
                        {
                            loading: true
                        }
                    );    
                    }

                this.toogleShow("add");
            }).catch(error => {
                if (typeof error == "object") {
                    this.setState({
                        error_field: error
                    });
                }
                // hapus loading
                this.setState({
                    loading_cctv: true
                });

                if (this.state.loading_cctv == true) {
                    this.setState({
                        loading_cctv: false
                    });
                }
            });
            });
    }

    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // handleDrop
    handleDrop(picture) {
        this.setState({
            file: picture[0]
        });
    }

    //onSubmit
    async onSubmit(event) {
        event.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                nama_event: that.state.nama_event,
                id_cctv: that.state.id_cctv,
                deskripsi_event: that.state.deskripsi_event,
                id_unit: that.state.id_unit,
                sumber_event: that.state.sumber_event,
                priority:0,
            };
            // paggil post api
            PostPrivate(that, `event/event/notification_to_unit`, params)
                .then(result => {
                    // - redirect
                    that.props.history.replace(`/dashboard/event/camera`);
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    }
    // render
    render() {
        const { showHideDemo1 } = this.state;
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Tambah Event</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <FieldEvent
                                        id_cctv={this.state.id_cctv}
                                        nama_event={this.state.nama_event}
                                        deskripsi_event={this.state.deskripsi_event}
                                        id_unit={this.state.id_unit}
                                        sumber_event={this.state.sumber_event}
                                        list_event={this.state.list_event}
                                        list_cctv={this.state.list_cctv}
                                        list_unit={this.state.list_unit}
                                        list_sumber={this.state.list_sumber}
                                        handleChange={this.handleChange}
                                        loadCCTV={this.loadCCTV}
                                        error_field={this.state.error_field}
                                        camera_url={this.state.camera_url}
                                        camera_url_backup={this.state.camera_url_backup}
                                        camera_playing={this.state.camera_playing}
                                        image_display={this.state.image_display}
                                        video_display={this.state.video_display}
                                        camera_url_short={this.state.camera_url_short}
                                        loading_cctv={this.state.loading_cctv}
                                    />
                        
                                </div>
                            </div>
                        </div>
                        <div className="row">
                                <div className="col-md-12">
                    
                        <Button type="button" className="btn btn-primary mb-2 ml-1" onClick={() => this.hideComponent("showHideDemo1")}>
                        {showHideDemo1 ? 'Hide Moving Unit' : 'View Moving Unit'}
                    </Button> 
                        {showHideDemo1 &&
                        <div className="box">
                        <div className="box-body">
                        <Map items={this.state.list} />
                        </div>
                        </div>
                        }
                        </div>
                        </div>
               
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-warning"
                                loading={this.state.loading}
                            >
                                Send To Unit
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}

export default EventAdd;
