import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup,
    ButtonRole
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "BUJT",
        selector: "nama_bujt",
        sortable: true,
        width: "30%"
    },
    {
        name: "Nama Gerbang",
        selector: "nama",
        sortable: true,
        width: "45%"
    },
    {
        name: "",
        selector: "action",
        width: "25%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const GerbangTable = props => {
    return (
        <TableBootstrap
            keyField={"gerbang"}
            empty="Belum ada referensi gerbang tol, klik tombol + untuk menambahkan referensi gerbang tol baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default GerbangTable;
