import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input,InputSelectReact } from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";


class GarduModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Ruas Tol"
                : "Tambah Referensi Ruas Tol";
        // state
        this.state = {
            // field input
            gerbang_id: props.item ? props.item.id_gerbang : "",
            gerbang_nama: props.item ? props.item.nama_gerbang : "",
            bujt_id:props.item ? props.item.id_bujt : "",
            bujt_nama:props.item ? props.item.nama_bujt : "",
            gardu_id:props.item ? props.item.id : "",
            gardu_nama:props.item ? props.item.nama : "",
            // error field
            error_field: {
                gardu_nama: null
            },
            // loading
            loading: false,
            item:props.item,
            bujt:props.bujt,
            gerbang:props.gerbang
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        //console.log(this.state);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    
    setGerbang() {
        this.setState({ loading: true }, function() {
            const params = {
                id : this.state.bujt_id
            };
            
            PostPrivate(this, "referensi/gerbang/gerbang_list",params)
                .then(result => {
                    this.setState({
                        loading : false,
                        gerbang : result.data
                    });


                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                gerbang_id: that.state.gerbang_id,
                gerbang_nama: that.state.gerbang_nama,
                bujt_id:that.state.bujt_id,
                bujt_nama:that.state.bujt_nama,
                gardu_id:that.state.gardu_id,
                gardu_nama:that.state.gardu_nama
            };
            // paggil post api
            PostPrivate(that, `referensi/gardu/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                BUJT
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"bujt"}
                                    has_error={this.state.error_field.bujt}
                                    defaultValue={{
                                        label: this.state.bujt_nama,
                                        value: this.state.bujt_id
                                    }}
                                    onChange={item => {
                                        this.handleChange("bujt_id", item.id);
                                        this.handleChange("bujt_nama", item.label);
                                        this.setGerbang();
                                    }}
                                    error_field={this.state.error_field.bujt}
                                    options={this.state.bujt}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"ruas_code"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Gerbang
                            </label>
                            <div className="col-sm-5">
                                <InputSelectReact
                                    id={"gerbang"}
                                    has_error={this.state.error_field.gerbang}
                                    defaultValue={{
                                        label: this.state.gerbang_nama,
                                        value: this.state.gerbang_id
                                    }}
                                    onChange={item => {
                                        this.handleChange("gerbang_id", item.id);
                                        this.handleChange("gerbang_nama", item.label);
                                    }}
                                    error_field={this.state.error_field.gerbang}
                                    options={this.state.gerbang}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"gerbang_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Gardu
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"gardu_nama"}
                                    type="text"
                                    placeholder="Nama Gardu Tol"
                                    has_error={this.state.error_field.gardu_nama}
                                    defaultValue={this.state.gardu_nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "gardu_nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.gardu_nama
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default GarduModal;
