import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputSelectReact, InputRadioInline} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class EventModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "Detail Laporan";
        // state
        this.state = {
            // field input
            event_id: props.item ? props.item.id : "",
            // error field
            error_field: {},
            // loading
            loading: false,
            item:null,
            laporan:null,
            unit:null
        };
        this.loadRef         = this.loadRef.bind(this);
        
    }
    
    loadRef() {
        
        this.setState({ loading: true }, function() {
            //params
            const params = {
                event_id: this.state.event_id
            };
            
            
            // paggil post api
            PostPrivate(this, 'event/event/detail', params)
                .then(result => {
                    //console.log(result.data);
                    this.setState(
                        {
                            loading: false,
                            item:result.data
                        });
                        
                        PostPrivate(this, 'event/event/laporan', params)
                            .then(result => {
                                //console.log(result.data);
                                this.setState(
                                    {
                                        loading: false,
                                        laporan:result.data,
                                        unit:result.unit
                                    });



                            })
                            .catch(error => {
                                if (typeof error == "object") {
                                    this.setState({
                                        error_field: error
                                    });
                                }
                                // hapus loading
                                this.setState({
                                    loading: false
                                });
                            });
                    
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
        });
    }

    componentDidMount() {
        // do nothing
        this.loadRef();
       
    }
  
    render() {
        let event_id = '';
        if (this.state.item != null) {
            event_id= this.state.item.id;
        } else {
            event_id = '-';
        }
        
        let event_name = '';
        if (this.state.item != null) {
            event_name= this.state.item.name;
        } else {
            event_name = '-';
        }
        
        let event_date = '';
        if (this.state.item != null) {
            event_date= this.state.item.date;
        } else {
            event_date = '-';
        }
        
        let event_time = '';
        if (this.state.item != null) {
            event_time= this.state.item.time;
        } else {
            event_time = '-';
        }
        
        let event_cctv = '';
        if (this.state.item != null) {
            event_cctv= this.state.item.location;
        } else {
            event_cctv = '-';
        }
        
        let event_file = '';
        if (this.state.item != null) {
            event_file= (
                <a href={this.state.item.file_path} target="_blank">{this.state.item.file_short}</a>
            );
        } else {
            event_file = '-';
        }
        
        
        let kategori_kejadian = '';
        if (this.state.laporan != null) {
            kategori_kejadian= this.state.laporan.nama_kejadian;
        } else {
            kategori_kejadian = '-';
        }
        
        let jenis_gangguan = '';
        if (this.state.laporan != null) {
            jenis_gangguan= this.state.laporan.nama_gangguan;
        } else {
            jenis_gangguan = '-';
        }
        
        let deskripsi = '';
        if (this.state.laporan != null) {
            deskripsi= this.state.laporan.deskripsi_singkat;
        } else {
            deskripsi = '-';
        }
        
        let kilometer = '';
        if (this.state.laporan != null) {
            kilometer= this.state.laporan.lokasi_km;
        } else {
            kilometer = '-';
        }
        
        let gerbang = '';
        if (this.state.laporan != null) {
            gerbang= this.state.laporan.lokasi_gerbang;
        } else {
            gerbang = '-';
        }
        
        let gardu = '';
        if (this.state.laporan != null) {
            gardu= this.state.laporan.lokasi_gardu;
        } else {
            gardu = '-';
        }
        
        let lokasi_lain = '';
        if (this.state.laporan != null) {
            lokasi_lain= this.state.laporan.lokasi_lain;
        } else {
            lokasi_lain = '-';
        }
        
        let tanggal_laporan = '';
        if (this.state.laporan != null) {
            tanggal_laporan= this.state.laporan.tanggal_laporan;
        } else {
            tanggal_laporan = '-';
        }
        
        let tindak_lanjut = '';
        if (this.state.laporan != null) {
            if (this.state.laporan.tindak_lanjut == 1) {
                tindak_lanjut= 'Ya';
            } else {
                tindak_lanjut= 'Tidak';
            }
            
        } else {
            tindak_lanjut = '-';
        }
        
        
        let unit_list = '';
        if (this.state.item != null) {
            unit_list= this.state.item.nama_unit;
            
            if (this.state.unit != null) {
                // nothing
                this.state.unit.map(function(item,index) {
                    unit_list = unit_list + ', '+item.nama;
                })
            }
            
        } else {
            unit_list = '-';
        }
        
        let gambar1 = '';
        if (this.state.laporan != null) {
            if (this.state.laporan.gambar1 !='') {
                gambar1 = (
                    <img  src={this.state.laporan.gambar1} width="800" />
                )
            } else {
                gambar1 = '';
            }
            
        } else {
            gambar1 = '';
        }
        
        let gambar2 = '';
        if (this.state.laporan != null) {
            if (this.state.laporan.gambar2 !='') {
                gambar2 = (
                    <img  src={this.state.laporan.gambar2} width="800" />
                )
            } else {
                gambar2 = '';
            }
            
        } else {
            gambar2 = '';
        }
        
        let gambar3 = '';
        if (this.state.laporan != null) {
            if (this.state.laporan.gambar3 !='') {
                gambar3 = (
                    <img  src={this.state.laporan.gambar3} width="800" />
                )
            } else {
                gambar3 = '';
            }
            
        } else {
            gambar3 = '';
        }
        
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                ID Event
                            </div>
                            <div className="col-sm-3">
                                {event_id}
                            </div>
                            <div className="col-sm-3">
                                Nama Event
                            </div>
                            <div className="col-sm-3">
                                {event_name}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Tanggal
                            </div>
                            <div className="col-sm-3">
                                {event_date}
                            </div>
                            <div className="col-sm-3">
                                Waktu
                            </div>
                            <div className="col-sm-3">
                                {event_time}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                CCTV
                            </div>
                            <div className="col-sm-3">
                                {event_cctv}
                            </div>
                            <div className="col-sm-3">
                                Kategori kejadian
                            </div>
                            <div className="col-sm-3">
                                {kategori_kejadian}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Jenis Gangguan
                            </div>
                            <div className="col-sm-3">
                                {jenis_gangguan}
                            </div>
                            <div className="col-sm-3">
                                Deskripsi
                            </div>
                            <div className="col-sm-3">
                                {deskripsi}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                KM
                            </div>
                            <div className="col-sm-3">
                                {kilometer}
                            </div>
                            <div className="col-sm-3">
                                Gerbang
                            </div>
                            <div className="col-sm-3">
                                {gerbang}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Gardu
                            </div>
                            <div className="col-sm-3">
                                {gardu}
                            </div>
                            <div className="col-sm-3">
                                Lokasi Lain
                            </div>
                            <div className="col-sm-3">
                                {lokasi_lain}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Tanggal Laporan
                            </div>
                            <div className="col-sm-3">
                                {tanggal_laporan}
                            </div>
                            <div className="col-sm-3">
                                Tindak Lanjut
                            </div>
                            <div className="col-sm-3">
                                {tindak_lanjut}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Unit Terlibat
                            </div>
                            <div className="col-sm-9">
                                {unit_list}
                            </div>
                            
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                            {gambar1}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                            {gambar2}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                            {gambar3}
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="button"
                        className="btn btn-primary"
                        loading={this.state.loading}
                        onClick={this.props.showDetail}
                    >
                        Tutup
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.showDetail}
            />
        );
    }
}

export default EventModal;
