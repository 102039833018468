import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputRadioInline, InputSelectReact } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import image_placeholder from "../../../resources/img/placeholder.png";

class RefModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `${this.props.ref_title}`
                : `${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            id: props.item ? props.item.id : "",
            no_kritik_saran: props.item ? props.item.no_kritik_saran : "",
            nama: props.item ? props.item.nama : "",
            waktu: props.item ? props.item.waktu : "",
            keterangan: props.item ? props.item.keterangan : "",
            ruas: props.item ? props.item.ruas : "",
            nama_bujt: props.item ? props.item.nama_bujt : "",
            ruas_tol: props.item ? props.item.ruas_tol : "",
            lainnya: props.item ? props.item.lainnya : "",
            no_telp: props.item ? props.item.no_telp : "",
            member: props.item ? props.item.member : "",
            koordinat_lat: props.item ? props.item.koordinat_lat : "",
            koordinat_lon: props.item ? props.item.koordinat_lon : "",
            jenis_kejadian: props.item ? props.item.jenis_kejadian : "",
            status: props.item ? props.item.status : "",
            petugas:[],
            _foto1: image_placeholder,
            _foto2: image_placeholder,
            _foto3: image_placeholder,
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.loadDetail();
    }

    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `event/event/download_file_cs?id=${this.state.id}`)
                .then(result => {
     
                    this.setState(
                        {
                            loading: false,
                            petugas:result.data.petugas,
                        }
                    )
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }

    loadDetail() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const params = {
                id: this.state.id,
            };
            PostPrivate(this, `customer/member/laporan`, params)
                .then(result => {
     
                    if (result.data.foto) {
                        this.setState({loading: false, _foto1: result.data.foto })
                    } else {
                        this.setState({ _foto1: image_placeholder })
                    }
                    if (result.data.foto_2) {
                        this.setState({loading: false, _foto2: result.data.foto_2 })
                    } else {
                        this.setState({ _foto2: image_placeholder })
                    }
                    if (result.data.foto_3) {
                        this.setState({loading: false, _foto3: result.data.foto_3 })
                    } else {
                        this.setState({ _foto3: image_placeholder })
                    }
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }

    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }

    sendNotificationAll() {
        
        this.setState({ loading: true }, function() {
            const params = {
                id: this.state.id,
                name: this.state.keterangan,
                location: this.state.nama_bujt,
                date: this.state.waktu,
                bujt:this.state.nama_bujt,
                latitude:this.state.koordinat_lat,
                longitude:this.state.koordinat_lon,
                event:this.state.jenis_kejadian,
                petugas:this.state.petugas,
                priority:0
            };
            
            
            PostPrivate(this, `event/event/notification_cs`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            this.props.toogleShow();
                            this.props.loadData();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
    }

    sendNotificationPriority() {
        // nanti dulu
        this.setState({ loading: true }, function() {
            // parameter petugas should be first top
            
            
            const params = {
                id: this.state.id,
                name: this.state.keterangan,
                location: this.state.nama_bujt,
                date: this.state.waktu,
                bujt:this.state.nama_bujt,
                latitude:this.state.koordinat_lat,
                longitude:this.state.koordinat_lon,
                event:this.state.jenis_kejadian,
                petugas:this.state.petugas,
                priority:1,
            };
            
            
            PostPrivate(this, `event/event/notification_cs`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            this.props.toogleShow();
                            this.props.loadData();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
        
    }
    

    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                     {this.state.status == 0 &&
                    <span className="label label-primary text-right">Sudah Diproses</span>
                }
                    <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Nomor Voice of Customer
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.no_kritik_saran}
                                </label>
                                </div>
                            </div>
                             <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Nama Member
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.member}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    No Telp
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.no_telp || '-'}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Deskripsi Laporan
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.keterangan}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Keluhan
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.jenis_kejadian == '1' ? 'Road Disturbance' : ''}
                                    {this.state.jenis_kejadian == '2' ? 'Accident' : ''}
                                    {this.state.jenis_kejadian == '3' ? 'Vehicle Trouble' : ''}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Lokasi
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                  {this.state.ruas_tol} ({this.state.nama_bujt}) 
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Lokasi Lainnya
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.lainnya || '-'}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Foto Laporan
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                        <div className="row"><img src={this.state._foto1} className="img-fluid" alt="foto1" style={{maxWidth: '100%'}} /></div>
                        <div className="row"><img src={this.state._foto2} className="img-fluid" alt="foto2" style={{maxWidth: '100%'}} /></div>
                        <div className="row"><img src={this.state._foto3} className="img-fluid" alt="foto3" style={{maxWidth: '100%'}} /></div>
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor={"pegawai"}
                                    className="col-sm-2 control-label mt-1"
                                >
                                    Petugas
                                </label>
                                <div className="col-sm-10">
                                <div className="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Unit</th>
                                                <th>Lokasi Terakhir</th>
                                                <th>ETA</th>
                                                <th>Jarak</th>
                                                <th>Prioritas</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.petugas.map((list_petugas) => 
                                        (
                                            <tr>
                                                <td style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.nama_unit}</td>
                                                <td style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.latitude}, {list_petugas.longitude}</td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>
                                                    {list_petugas.eta} (driving)<br/>
                                                    {list_petugas.eta_walking} (backward)
                                                </td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>
                                                {list_petugas.distance} (driving)<br/>
                                                {list_petugas.distance_walking} (backward)
                                                </td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.prioritas}</td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.available}</td>
                                            </tr>
                                        ))}

                                                {this.state.petugas.length === 0 &&
                                        <tr><td className="text-center" colSpan="5">-Tidak ada data- </td></tr>
                                    }
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                    </>
                }
                footer={
                    <>
                 
                    <Button className="btn btn-danger" onClick={() => this.props.toogleShow('')}>Batal</Button>
                          
                   {this.state.status != 0 &&
                   <Button type="button" loading={this.state.loading} className="btn btn-success" onClick={this.sendNotificationAll.bind(this)}>Send All</Button>
                }     
                   {this.state.status != 0 &&
                        <Button
                            type="button"
                            className="btn btn-primary"
                            loading={this.state.loading}
                            onClick={this.sendNotificationPriority.bind(this)}
                        >
                            Send By Prioritas
                        </Button>
                }
                        </>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RefModal;
