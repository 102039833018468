import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "15%", className: "text-center", label: "Tanggal Shift" },
        { width: "15%", className: "text-center", label: "NIK Pegawai" },
        { width: "10%", className: "text-center", label: "Shift Ke -" },
        { width: "15%", className: "text-center", label: "Ruas Tol" },
        { width: "15%", className: "text-center", label: "Unit" },
        { width: "15%", className: "text-center", label: "Kode Unit" },
        { width: "15%", className: "text-center", label: "Status" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.execution_date}</td>
            <td>{item.pegawai_id}</td>
            <td className="text-center">{item.shift_id}</td>
            <td>{item.ruas_id}</td>
            <td className="text-center">{item.unit_id}</td>
            <td className="text-center">{item.code_id}</td>
            <td className="text-center">{item.status}</td>
        </tr>
    ));
};

const PegawaiTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default PegawaiTable;
