import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse,
} from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// page
import UserTable from "./table";

class User extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            q: "",
            loading: false,
            list: [],
        };
        
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }
    
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Referensi pengguna [${item.user_alias}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `setting/user/delete/${item.user_id}`)
                .then(result => {
                    
                    if (!result.data) {
                        // load data
                        this.loadData();
                        SwalSuccess("Berhasil", result.message);
                        
                        // pesan
                        
                        
                    } else {
                        alert(result.message);
                    }
                    
                    this.setState({
                        loading: false
                    });
                    
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        
        this.setState({ loading: true }, function () {
            GetPrivate(this, `setting/user?q=${this.state.q}`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        list: result.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = (event) => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <BoxCollapse
                    open={false}
                    title="Manajemen Data User"
                    tools={
                        <ButtonRole
                            role_url={`${this.props.location.pathname}/add`}
                            role_type="role_create"
                        >
                            <NavLink
                                to={`${this.props.location.pathname}/add`}
                                className="btn btn-box-tool ml-2"
                            >
                                <i className="fa fa-plus mr-1"></i> Tambah
                            </NavLink>
                        </ButtonRole>
                    }
                    icon_show={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                    icon_hide={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                >
                    <form onSubmit={this.onSubmit} className="form-horizontal">
                        <div className="form-group">
                            <label
                                className="control-label col-md-4"
                                htmlFor="q"
                            >
                                Nama Lengkap
                            </label>
                            <div className="col-md-4">
                                <Input
                                    id="q"
                                    type="text"
                                    placeholder="Nama Lengkap"
                                    defaultValue={this.state.q}
                                    onChange={(ev) =>
                                        this.handleChange("q", ev.target.value)
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                >
                                    Cari User
                                </button>
                            </div>
                        </div>
                    </form>
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <UserTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            deleteConfirm={this.deleteConfirm}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default User;
