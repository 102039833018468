import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "15%", className: "text-center", label: "Nama Bujt" },
        { width: "15%", className: "text-center", label: "Ruas Tol" },
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.nama_bujt}</td>
            <td className="text-center">{item.ruas_tol}</td>
        </tr>
    ));
};

const BujtTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default BujtTable;
