import React, { Component } from "react";
import className from "classnames";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../../reducers/session/actions.js";

// komponen
import { Content, Button } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import moment from "moment";
import Session from "../../../utils/Session";
import { Toast, SwalConfirm } from "../../../components/Alert/index.js";

class Shift extends Component {
    // ref
    tick = null;
    // constructor
    constructor(props) {
        super(props);
        // state
        this.state = {
            execution_id: "",
            shift_start: "",
            shift_finish: "",
            shift_on_duty: false,
            check_in: "",
            check_out: "",
            now: new Date(),
            // loading
            loading: false,
        };
    }
    componentDidMount() {
        // loadData
        this.loadData();
        // tickTime
        this.tickTime();
    }
    componentWillUnmount() {
        clearTimeout(this.tick);
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, "user/shift")
                .then((result) => {
                    this.setState({
                        loading: false,
                        execution_id: result.data.execution_id,
                        shift_start: result.data.shift_start,
                        shift_finish: result.data.shift_finish,
                        shift_on_duty: result.data.shift_on_duty === "true",
                        check_in: result.data.check_in,
                        check_out: result.data.check_out,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // tickTime
    tickTime() {
        this.tick = setTimeout(() => {
            this.setState(
                {
                    now: new Date(),
                },
                function () {
                    this.tickTime();
                }
            );
        }, 500);
    }
    //logoutConfirm
    logoutConfirm() {
        SwalConfirm(
            "Peringatan",
            "Dengan menyelesaikan shift anda akan keluar dari sistem. Lanjutkan proses?",
            () => this.processShift(),
            "Ya, Lanjutkan",
            "-"
        );
    }
    // processShift
    processShift() {
        // type
        const type = this.state.shift_on_duty ? "finish" : "start";
        // params
        const params = {
            execution_id: this.state.execution_id,
        };
        // panggil get api
        this.setState({ loading: true }, function () {
            PostPrivate(this, `user/shift/${type}`, params)
                .then((result) => {
                    this.setState(
                        {
                            shift_on_duty: true,
                            loading: false,
                        },
                        function () {
                            if (type === "finish") {
                                this.logoutProcess();
                            } else {
                                this.loadData();
                            }
                        }
                    );
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    //logoutProcess
    logoutProcess() {
        this.setState({ loading: true }, function () {
            // panggil get api
            GetPrivate(this, "logout")
                .then((result) => {
                    // remove session
                    Session.clearSession("session__jmto_admin");
                    Session.clearSession("default_page__jmto_admin");
                    // store
                    this.props.setSession({}).then(() => {
                        // redirect login
                        this.props.history.push("/");
                        // notif
                        Toast("Logout Berhasil");
                    });
                })
                .catch((error) => {});
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="row">
                    <div className="col-md-offset-3 col-md-6">
                        <h3 className="text-center">
                            {moment(this.state.now).format("LLLL")}
                        </h3>
                        <div className="text-center">
                            <Button
                                type="button"
                                className={className(
                                    "shift",
                                    {
                                        "shift-finish": this.state
                                            .shift_on_duty,
                                    },
                                    { "shift-start": !this.state.shift_on_duty }
                                )}
                                onClick={() =>
                                    this.state.shift_on_duty
                                        ? this.logoutConfirm()
                                        : this.processShift()
                                }
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            >
                                {this.state.shift_on_duty
                                    ? "Selesai Shift"
                                    : "Mulai Shift"}
                            </Button>
                        </div>
                        <div className="box no-border">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <p className="text-center text-muted">
                                            Jadwal Mulai Shift
                                        </p>
                                        <h4 className="text-center text-primary">
                                            {this.state.shift_start}
                                        </h4>
                                    </div>
                                    <div className="col-xs-6">
                                        <p className="text-center text-muted">
                                            Aktual Mulai Shift
                                        </p>
                                        <h4 className="text-center text-primary">
                                            {this.state.check_in}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box no-border">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <p className="text-center text-muted">
                                            Jadwal Selesai Shift
                                        </p>
                                        <h4 className="text-center text-primary">
                                            {this.state.shift_finish}
                                        </h4>
                                    </div>
                                    <div className="col-xs-6">
                                        <p className="text-center text-muted">
                                            Aktual Selesai Shift
                                        </p>
                                        <h4 className="text-center text-primary">
                                            {this.state.check_out}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
}

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
    };
}
// dispatch / aksi -> props
function mapsDispatchToProps(dispatch) {
    return {
        setSession: (params) => {
            return new Promise((resolve, reject) => {
                dispatch(setSession(params));
                resolve();
            });
        },
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, mapsDispatchToProps)(Shift)
);
