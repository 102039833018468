import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "15%", className: "text-center", label: "Nik" },
        { width: "15%", className: "text-center", label: "Password" },
        { width: "10%", className: "text-center", label: "Nama" },
        { width: "15%", className: "text-center", label: "Jnis Kelamin" },
        { width: "15%", className: "text-center", label: "Tanggal Lahir" },
        { width: "15%", className: "text-center", label: "Tempat Lahir" },
        { width: "15%", className: "text-center", label: "Jabatan" },
        { width: "15%", className: "text-center", label: "Ruas" },
        { width: "15%", className: "text-center", label: "Role" },
        { width: "15%", className: "text-center", label: "Unit" },
        { width: "15%", className: "text-center", label: "Status Pegawai" },
        { width: "15%", className: "text-center", label: "Status" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.pegawai_nik}</td>
            <td>{item.pegawai_pass}</td>
            <td className="text-center">{item.pegawai_name}</td>
            <td>{item.pegawai_gender}</td>
            <td className="text-center">{item.pegawai_birth}</td>
            <td className="text-center">{item.pegawai_birth_place}</td>
            <td className="text-center">{item.jabatan_id}</td>
            <td className="text-center">{item.ruas_id}</td>
            <td className="text-center">{item.role_id}</td>
            <td className="text-center">{item.unit_id}</td>
            <td className="text-center">{item.pegawai_st}</td>
            <td className="text-center">{item.status}</td>
        </tr>
    ));
};

const PegawaiTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default PegawaiTable;
