import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button, BoxCollapse, Input, ButtonRole } from "../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../utils/Connection";
import { NavLink } from "react-router-dom";

import  Chart  from "react-apexcharts";
import Dates from "../../utils/Dates";
import DatePicker from "react-date-picker";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


class PieKejadian extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: [],
            list2: [],
            list3: [],
        };
        // bind
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
    }


    handleDataPointSelection(event, chartContext, opts) {
        switch(opts.w.config.labels[opts.dataPointIndex]) {
            case 'Dalam Proses':
                window.location.href = window.location.href + this.props.linkTo2;
            break;
            case 'Sudah Selesai':
                window.location.href = window.location.href + this.props.linkTo;
            break ;
            default:
        }
    }

    render() {
        // return
        const options = { labels: ["Kendaraan Mogok", "Ganti Ban", "Kecelakaan"],
        noData:{text:"Empty Data"},                        
        colors:["#FD9326","#FC511F","#E02A2F"], 
        dataLabels: { enabled: true, formatter: function (val, opt) { return opt.w.config.series[opt.seriesIndex] + " " },
        style: {
            colors: ['#FAFAFA', '#FAFAFA', '#FAFAFA'],
            fontSize: 12,
            fontFamily: 'Poppins'
          } },
        tooltip: { enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) { let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex]
        return w.config.labels[seriesIndex] + ": " + selected + "(" + (selected / total * 100).toFixed(2) + "%)"; } },
        title:{ text:"Kategori Kejadian", align:'center', style: { fontSize: 18, color:  '#2D3142', fontFamily: 'Poppins', fontWeight:  '400' }},
        legend: {
            show: true,
            labels: {
                colors: ["#121212"],
                useSeriesColors: false
            },
            position: 'bottom',
        },
        chart: {
            events: {
            dataPointSelection: this.handleDataPointSelection.bind(this)
    }
      },
    };
        // const series = [4, 5, 6]; //our data
        return (
            <div>              
                <Chart 
                type="pie"
                width={500}
                height={500}
              options={options} 
              series={[this.props.listPie, this.props.listPie1, this.props.listPie2]}                
                >
                </Chart>
            </div>
        );
    }
}

export default PieKejadian;
