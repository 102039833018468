import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';
import moment from "moment";

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import ReportTable from "./table";
import ReportSearch from "./search";
import ReportModal from "./modal";

const today = moment();
    const start_date = moment(today)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    const end_date = moment(today).format("YYYY-MM-DD");
    const current_month = moment(today).format("MM")
    const currentYear = new Date().getFullYear();

    // tahun
    const previousYear =  currentYear-1;
    const previousYear2 =  previousYear-1;
    const previousYear3 =  previousYear2-1;
    const previousYear4 =  previousYear3-1;

    var month = [ "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember" ];
    if (current_month < 10) {    
        var monthIndex = moment().month(current_month[1]-1).format("M");
    } else {
        var monthIndex = moment().month(current_month -1).format("M");         
    }
     const monthName = month[monthIndex-1];

class Lakalantas extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1 < 10 ? '0' + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1)) + '-01';

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            list: [],
            // start: start,
            // end: end,
            bujt: bujt,
            list_241_terima: "",
            list_242_terima: "",
            list_243_terima: "",
            list_241_tolak: "",
            list_242_tolak: "",
            list_243_tolak: "",
            start: current_month,
            start_name: monthName,
            end: currentYear,
            end_name: currentYear,
            list_tahun :  [
                { value: currentYear, label: currentYear },
                { value: previousYear, label: previousYear },
                { value: previousYear2, label: previousYear2},
                { value: previousYear3, label: previousYear3},
                { value: previousYear4, label: previousYear4}
              ],
            list_bulan :  [
                { value: "01", label: "Januari" },
                { value: "02", label: "Februari"},
                { value: "03", label: "Maret"},
                { value: "04", label: "April"},
                { value: "05", label: "Mei"},
                { value: "06", label: "Juni"},
                { value: "07", label: "Juli"},
                { value: "08", label: "Agustus"},
                { value: "09", label: "September"},
                { value: "10", label: "Oktober"},
                { value: "11", label: "November"},
                { value: "12", label: "Desember"}
              ],
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            let list_ruas = [{ value: 'all', label: '- Semua -' }]

            let ruas_id = 'all'
            let ruas_name = '- Semua -'

            for (let i in result.data) {
                list_ruas.push({ value: result.data[i].ruas_id, label: result.data[i].ruas_name })
                if (this.state.bujt && result.data[i].ruas_id === this.state.bujt) {
                    ruas_id = result.data[i].ruas_id
                    ruas_name = result.data[i].ruas_name
                }
            }
            this.setState(
                {
                    list_ruas: list_ruas,
                    ruas_id: ruas_id,
                    ruas_name: ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const ruas = `${this.state.ruas_id}`
            if (ruas && ruas !== 'undefined') {
                Promise.all([
                    GetPrivate(this, `laporan/kpi/all?ruas_id=${this.state.ruas_id}&start=${this.state.start}&end=${this.state.end}`)              
                ])
                .then(result => {
                    let respon1 = 0
                    let tiba1 = 0
                    let penanganan1 = 0
                    let selesai1 = 0
                    for (let i in result[0].data.unit1) {
                        let minutes = Math.floor(result[0].data.unit1[i].waktu_respon / 60)
                        let seconds = result[0].data.unit1[i].waktu_respon % 60
                        respon1 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit1) {
                        let minutes = Math.floor(result[0].data.unit1[i].waktu_tiba / 60)
                        let seconds = result[0].data.unit1[i].waktu_tiba % 60
                        tiba1 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit1) {
                        let minutes = Math.floor(result[0].data.unit1[i].waktu_penanganan / 60)
                        let seconds = result[0].data.unit1[i].waktu_penanganan % 60
                        penanganan1 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit1) {
                        let minutes = Math.floor(result[0].data.unit1[i].waktu_penyelesaian / 60)
                        let seconds = result[0].data.unit1[i].waktu_penyelesaian % 60
                        selesai1 = minutes + ' menit ' + seconds + ' detik'
                    }

                    // 242
                    let respon2 = 0
                    let tiba2 = 0
                    let penanganan2 = 0
                    let selesai2 = 0
                    for (let i in result[0].data.unit2) {
                        let minutes = Math.floor(result[0].data.unit2[i].waktu_respon / 60)
                        let seconds = result[0].data.unit2[i].waktu_respon % 60
                        respon2 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit2) {
                        let minutes = Math.floor(result[0].data.unit2[i].waktu_tiba / 60)
                        let seconds = result[0].data.unit2[i].waktu_tiba % 60
                        tiba2 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit2) {
                        let minutes = Math.floor(result[0].data.unit2[i].waktu_penanganan / 60)
                        let seconds = result[0].data.unit2[i].waktu_penanganan % 60
                        penanganan2 = minutes + ' menit ' + seconds + ' detik'
                    }
                    for (let i in result[0].data.unit2) {
                        let minutes = Math.floor(result[0].data.unit2[i].waktu_penyelesaian / 60)
                        let seconds = result[0].data.unit2[i].waktu_penyelesaian % 60
                        selesai2 = minutes + ' menit ' + seconds + ' detik'
                    }

                     // 243
                     let respon3 = 0
                     let tiba3 = 0
                     let penanganan3 = 0
                     let selesai3 = 0
                     for (let i in result[0].data.unit3) {
                         let minutes = Math.floor(result[0].data.unit3[i].waktu_respon / 60)
                         let seconds = result[0].data.unit3[i].waktu_respon % 60
                         respon3 = minutes + ' menit ' + seconds + ' detik'
                     }
                     for (let i in result[0].data.unit3) {
                         let minutes = Math.floor(result[0].data.unit3[i].waktu_tiba / 60)
                         let seconds = result[0].data.unit3[i].waktu_tiba % 60
                         tiba3 = minutes + ' menit ' + seconds + ' detik'
                     }
                     for (let i in result[0].data.unit3) {
                         let minutes = Math.floor(result[0].data.unit3[i].waktu_penanganan / 60)
                         let seconds = result[0].data.unit3[i].waktu_penanganan % 60
                         penanganan3 = minutes + ' menit ' + seconds + ' detik'
                     }
                     for (let i in result[0].data.unit3) {
                         let minutes = Math.floor(result[0].data.unit3[i].waktu_penyelesaian / 60)
                         let seconds = result[0].data.unit3[i].waktu_penyelesaian % 60
                         selesai3 = minutes + ' menit ' + seconds + ' detik'
                     }
                    this.setState({
                        loading: false,
                        list_241_respon: respon1,
                        list_241_tiba: tiba1,
                        list_241_pen: penanganan1,
                        list_241_selesai: selesai1,
                        // 242
                        list_242_respon: respon2,
                        list_242_tiba: tiba2,
                        list_242_pen: penanganan2,
                        list_242_selesai: selesai2,
                        // 243
                        list_243_respon: respon3,
                        list_243_tiba: tiba3,
                        list_243_pen: penanganan3,
                        list_243_selesai: selesai3,
                        //terima
                        list_241_terima: result[0].data.unit1_terima,
                        list_242_terima: result[0].data.unit2_terima,
                        list_243_terima: result[0].data.unit3_terima,
                        //tolak
                        list_241_tolak: result[0].data.unit1_tolak,
                        list_242_tolak: result[0].data.unit2_tolak,
                        list_243_tolak: result[0].data.unit3_tolak,

                        list_total: result[0].data.total,
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
        this.setState({ modal: !this.state.modal, id: id });
    }
    downloadExcel () {
        const token = Session.getSession("session__jmto_admin");
        const token_key = Config.webKey;
        axios({
            url: `${Config.endPoint}/laporan/member/export?ruas_id=${this.state.ruas_id}&start=${this.state.start}&end=${this.state.end}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: token,
                'X-API-KEY': token_key,
                'X-Apps': 'web'
            },
            crossdomain: true
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'laporan-kritik-saran.xls')
            document.body.appendChild(link)
            link.click()
        })
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <ReportSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        start_name={this.state.start_name}
                        end_name={this.state.end_name}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                        list_bulan={this.state.list_bulan}
                        list_tahun={this.state.list_tahun}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        {/* <a onClick={this.downloadExcel} className="btn btn-success">
                            <i className="fa fa-download mr-1"></i>Download Excel
                        </a> */}
                        {/* <div className="col-md-2 text-left" style={{ fontSize: '14px' }}>
                                <b>Jumlah Event: </b>{ this.state.list_total } <br></br>
                            </div>   */}
                        <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Unit</th>
                                                <th>Terima Event</th>
                                                <th>Tolak Event</th>
                                                <th>Waktu Respon</th>
                                                <th>Waktu Sampai</th>
                                                <th>Waktu Penanganan</th>
                                                <th>Waktu Selesai</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                       <tr>
                                           <td>1</td>
                                           <td>241</td>
                                           <td>{this.state.list_241_terima}</td>
                                           <td>{this.state.list_241_tolak}</td>
                                           <td>{this.state.list_241_respon}</td>
                                           <td>{this.state.list_241_tiba}</td>
                                           <td>{this.state.list_241_pen}</td>
                                           <td>{this.state.list_241_selesai}</td>
                                       </tr>
                                       <tr>
                                           <td>2</td>
                                           <td>242</td>
                                           <td>{this.state.list_242_terima}</td>
                                           <td>{this.state.list_242_tolak}</td>
                                           <td>{this.state.list_242_respon}</td>
                                           <td>{this.state.list_242_tiba}</td>
                                           <td>{this.state.list_242_pen}</td>
                                           <td>{this.state.list_242_selesai}</td>
                                       </tr>
                                       <tr>
                                           <td>3</td>
                                           <td>243</td>
                                           <td>{this.state.list_243_terima}</td>
                                           <td>{this.state.list_243_tolak}</td>
                                           <td>{this.state.list_243_respon}</td>
                                           <td>{this.state.list_243_tiba}</td>
                                           <td>{this.state.list_243_pen}</td>
                                           <td>{this.state.list_243_selesai}</td>
                                       </tr>
                                        </tbody>
                                    </table>
                    </div>
                </div>
                {this.state.modal && (
                    <ReportModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        id={this.state.id}
                    />
                )}
            </Content>
        );
    }
}

export default Lakalantas;
