import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";
import Loading from "../../../components/AdminLTE/loading";
import BatalModal from "./modal_batal";

class CameraModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Kirim Notifikasi ke Senkom";
        
        //console.log(props);
        
        this.state = {
            // field input
            pegawai :props.pegawai,
            camera_id: props.camera_id,
            camera_name: props.camera_name,
            camera_location: props.camera_location,
            camera_date: props.camera_date,
            camera_time: props.camera_time,
            camera_bujt:props.camera_bujt,
            camera_image:props.camera_image,
            petugas:props.petugas,
            camera_full_name:props.camera_full_name,
            camera_latitude:props.camera_latitude,
            camera_longitude:props.camera_longitude,
            error_field: {
            },
            // loading
            loading: false,
            pegawai_id:null,
            notification_status: 0,
            camera_file:props.camera_file,
            camera_url:'',
            camera_url_backup:'',
            camera_url_short:'',
            image_display :'',
            modal2:false,
            item: null,
            mode:"",
            video_display : 'none'
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showDetail         = this.showDetail.bind(this);
        
      
        
    }
    

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps', nextProps);
        this.setState(nextProps);
    }
    
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
        
    }
    
    sendNotificationAll() {
        
        this.setState({ loading: true }, function() {
            const params = {
                id: this.state.camera_id,
                name: this.state.camera_name,
                location: this.state.camera_location,
                date: this.state.camera_date,
                time: this.state.camera_time,
                pegawai_id: this.state.pegawai_id,
                file:this.state.camera_file,
                bujt:this.state.camera_bujt,
                cctv_nama:this.state.camera_full_name,
                latitude:this.state.camera_latitude,
                longitude:this.state.camera_longitude,
                petugas:this.state.petugas,
                priority:0
            };
            
            
            PostPrivate(this, `event/event/notification`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal2
                            this.props.toogleShow();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
    }
    
    
    sendNotificationPriority() {
        // nanti dulu
        this.setState({ loading: true }, function() {
            // parameter petugas should be first top
            
            
            const params = {
                id: this.state.camera_id,
                name: this.state.camera_name,
                location: this.state.camera_location,
                date: this.state.camera_date,
                time: this.state.camera_time,
                pegawai_id: this.state.pegawai_id,
                file:this.state.camera_file,
                bujt:this.state.camera_bujt,
                cctv_nama:this.state.camera_full_name,
                latitude:this.state.camera_latitude,
                longitude:this.state.camera_longitude,
                priority:1,
                petugas:this.state.petugas
            };
            
            
            PostPrivate(this, `event/event/notification`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal2
                            this.props.toogleShow();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
        
    }
    
    
    onSubmit = evt => {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        
        that.setState({ loading: true }, function() {
            const params = {
                id: that.state.camera_id,
                name: that.state.camera_name,
                location: that.state.camera_location,
                date: that.state.camera_date,
                time: that.state.camera_time,
                pegawai_id: that.state.pegawai_id,
                file:that.state.camera_file,
                bujt:that.state.camera_bujt,
                cctv_nama:that.state.camera_full_name,
                latitude:that.state.camera_latitude,
                longitude:that.state.camera_longitude
                
            };
            
            
            PostPrivate(that, `event/event/notification`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal2
                            that.props.toogleShow();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
                
        });
        
  
    };
    
    sendConfirm(id, unit) {
        SwalConfirm(
            `Unit [${unit}]`,
            "Apa anda yakin ingin mengirim unit ini ?",
            () => this.sendProcess(id)
        );
    }

    sendProcess(id) {
        // nanti dulu
        this.setState({ loading: true }, function() {
            // parameter petugas should be first top
            
            
            const params = {
                id: this.state.camera_id,
                name: this.state.camera_name,
                location: this.state.camera_location,
                // date: this.state.camera_date,
                // time: this.state.camera_time,
                // pegawai_id: this.state.pegawai_id,
                file:this.state.camera_file,
                // bujt:this.state.camera_bujt,
                // cctv_nama:this.state.camera_full_name,
                // latitude:this.state.camera_latitude,
                // longitude:this.state.camera_longitude,

                nama_event: this.state.camera_name,
                id_cctv: this.state.camera_full_name,
                deskripsi_event: '',
                id_unit: id,
                sumber_event: '',
                priority:1,
                // petugas:this.state.petugas
            };
            
            
            PostPrivate(this, `event/event/notification_to_unit_spes`, params)
                .then(result => {
                    this.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal2
                            this.props.toogleShow();
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
                
        });
        
    }
    
    showDetail(item = null) {
        console.log('modal2', this.state.modal2)
        if (item != null) {          
            this.setState({item: item }, function() {
                this.setState({ modal2: !this.state.modal2 });
            }); 
        } else {
            this.setState({ modal2: !this.state.modal2 });
        }
        
    }
    
    render() {
        // return
        console.log('modal2', this.state.modal2)
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    BUJT
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.camera_bujt}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Event
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.camera_name}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    DateTime
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.camera_date} {this.state.camera_time}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Lokasi
                                </label>
                                <div className="col-sm-8">
                                    <label
                                    className="col-sm-12 control-label mt-1"
                                    >
                                    {this.state.camera_location}
                                </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    className="col-sm-3 control-label mt-1"
                                >
                                    Gambar 
                                </label>
                                <div className="col-sm-8">
                                 <img  src={this.state.camera_image} width="400" />
                                <div className="App" >
                                {this.props.loading ? (
                                        <>
                                            <Loading />
                                        </>
                                    ) : (
                                        this.props.children
                                    )}     
                                                <ReactPlayer url={this.state.camera_url} playing={this.state.camera_playing} />
                                  </div>

                                   
      
                                </div>
                            </div>
                         
                            <div className="form-group row">
                                <label
                                    htmlFor={"pegawai"}
                                    className="col-sm-2 control-label mt-1"
                                >
                                    Petugas
                                </label>
                                <div className="col-sm-10">
                                <div className="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Unit</th>
                                                <th>Lokasi Terakhir</th>
                                                <th>ETA</th>
                                                <th>Jarak</th>
                                                <th>Prioritas</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.petugas.map((list_petugas) => 
                                        (
                                            <tr onClick={() => this.sendConfirm(list_petugas.id_unit, list_petugas.nama_unit)}>
                                                <td style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.nama_unit}</td>
                                                <td style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.latitude}, {list_petugas.longitude}</td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>
                                                    {list_petugas.eta} (driving)<br/>
                                                    {list_petugas.eta_walking} (backward)
                                                </td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>
                                                {list_petugas.distance} (driving)<br/>
                                                {list_petugas.distance_walking} (backward)
                                                </td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.prioritas}</td>
                                                <td nowrap="nowrap" style={{ backgroundColor: list_petugas.background_color, color: list_petugas.color }}>{list_petugas.available}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                         </>
                    }
                    footer={
                        <>
                        <a className="btn btn-danger" onClick={() => this.showDetail(this.state.item)}>Batal</a>
                        <Button type="button" loading={this.state.loading} className="btn btn-success" onClick={this.sendNotificationAll.bind(this)}>Send All</Button>
                        <Button
                            type="button"
                            className="btn btn-primary"
                            loading={this.state.loading}
                            onClick={this.sendNotificationPriority.bind(this)}
                        >
                            Send By Prioritas
                        </Button>
                        
                        {this.state.modal2 && (
                            <BatalModal
                                showDetail={() => this.showDetail()}
                                item={this.state.item}
                                camera_id={this.state.camera_id}
                                camera_name={this.state.camera_name}
                                camera_location={this.state.camera_location}
                                show={this.state.modal2}
                                toogleShow={this.props.toogleShow}
                            />
                        )}
                        </>
                    }
                    show={this.props.show}
                    onDismiss={this.props.toogleShow}
                    onSubmit={this.onSubmit}
                />
        )
    }
    
    
}

export default CameraModal;