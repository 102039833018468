import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalConfirmSave, SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";

import Print from "./print";
import PrintSpgr from "./print_spgr";

class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            form_mode: false,
            loading: false,
            printable: false,
            no_laporan: null,
            total_ganti_rugi_raw: 0,
            ruas_tol: null,
            // Laka
            laka_id: this.props.id_laka,
            laka_tanggal: null,
            laka_jam: null,
            laka_km: null,
            laka_gerbang: null,
            laka_gardu: null,
            // Di bawah ini data untuk save
            id: null,
            persetujuan_pj: null,
            nilai_kesepakatan: 0,
            opsi_tidak_setuju: null,
            jenis_jaminan: null,
            verif_kadep: null,
            status: null,
            alasan_tidak_setuju: '',
            jenis_klarifikasi: null,
            nilai_jaminan: 0,
            lain_lain: '',
            jenis_pembayaran:0,
            status_pembayaran:0
        }
        this.loadKlarifikasi = this.loadKlarifikasi.bind(this)
        this.onUpdateLaka = this.onUpdateLaka.bind(this)
        this.onUpdateGantiRugi = this.onUpdateGantiRugi.bind(this)
        this.instantApprove = this.instantApprove.bind(this)
        this.getDetail = this.getDetail.bind(this)
        this.save = this.save.bind(this)
        this.saveConfirmation = this.saveConfirmation.bind(this)
    }
    componentDidMount () {
        this.loadKlarifikasi();
        this.getDetail();
    }
    
    getDetail() {
        // belum operasional
        this.setState({ loading: true }, function() {
                const params = {
                    laka_id : this.state.laka_id
                };
                
                PostPrivate(this, 'user/gantirugi/detail', params).then(result => {
                    if (result.status == 200) {
                        this.setState({
                            loading: false,
                            jenis_pembayaran:result.data.kategori,
                            status_pembayaran:result.data.status
                        })
                    }
                }).catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        })
                    }
                    this.setState({
                        loading: false
                    })
                })
            
            });
            
    }
    
    instantApprove() {
        // FORCE APP
        if (window.confirm("Apakah anda yakin melakukan approval pada ganti rugi ini ?")) {
            this.setState({ loading: true }, function() {
                const params = {
                    laka_id : this.state.laka_id
                };
                
                PostPrivate(this, 'user/gantirugi/instant', params).then(result => {
                    if (result.status =='200') {
                        this.setState({ loading: false }, function() {
                            this.setState({
                                status_pembayaran: '2',
                                printable:true
                            });
                            SwalSuccess("Berhasil", result.message)
                        })    
                    } else {
                        this.setState({ loading: false }, function() {
                            SwalSuccess("Gagal", result.message)
                        })
                    }
                    
                    
                    
                }).catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        })
                    }
                    this.setState({
                        loading: false
                    })
                })
            
            });
            
            
            
        }
    }
    
    loadKlarifikasi () {
        GetPrivate(this, `transaksi/klarifikasigantirugi/by_laka/` + this.props.id_laka).then(result => {
            if (result.data.id) {
                result.data.alasan_tidak_setuju = result.data.alasan_tidak_setuju ? result.data.alasan_tidak_setuju : ''
                result.data.lain_lain = result.data.lain_lain ? result.data.lain_lain : ''
                this.setState({
                    printable: result.data.status === 1 || result.data.status === '1',
                    id: result.data.id,
                    persetujuan_pj: result.data.persetujuan_pj,
                    nilai_kesepakatan: result.data.nilai_kesepakatan,
                    opsi_tidak_setuju: result.data.opsi_tidak_setuju,
                    jenis_jaminan: result.data.jenis_jaminan,
                    verif_kadep: result.data.verif_kadep,
                    status: result.data.status,
                    alasan_tidak_setuju: result.data.alasan_tidak_setuju,
                    jenis_klarifikasi: result.data.jenis_klarifikasi,
                    nilai_jaminan: result.data.nilai_jaminan,
                    lain_lain: result.data.lain_lain
                })
            } else {
                this.setState({
                    id: null,
                    persetujuan_pj: null,
                    nilai_kesepakatan: 0,
                    opsi_tidak_setuju: null,
                    jenis_jaminan: null,
                    verif_kadep: null,
                    status: null,
                    alasan_tidak_setuju: '',
                    jenis_klarifikasi: null,
                    nilai_jaminan: 0,
                    lain_lain: ''
                })
            }
        }).catch(error => {})
    }
    onUpdateLaka (data) {
        this.setState({
            no_laporan: data.item.no_laporan, ruas_tol: data.item.ruas_tol, laka_tanggal: data.item.tanggal, laka_jam: data.item.jam,
            laka_km: data.item.lokasi_km, laka_gerbang: data.item.lokasi_gerbang, laka_gardu: data.item.lokasi_gardu
        })
    }
    toThousandSeparated (number) {
        const formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        })
        return formatter.format(number).substr(3)
    }
    onUpdateGantiRugi (data) {
        this.setState({ total_ganti_rugi: data.total_ganti_rugi, total_ganti_rugi_raw: data.total_ganti_rugi_raw })
    }
    getDifference () {
        const difference = parseFloat(this.state.total_ganti_rugi_raw ? this.state.total_ganti_rugi_raw : 0) - parseFloat(this.state.nilai_kesepakatan ? this.state.nilai_kesepakatan : 0)
        return this.toThousandSeparated(difference)
    }
    print (id) {
        const divContents = document.getElementById(id).innerHTML
        var printWindow = window.open('', '', 'height=772,width=1000')
        printWindow.document.write('<html>')
        printWindow.document.write('<head></head>')
        printWindow.document.write('<body>')
        printWindow.document.write(divContents)
        printWindow.document.write('</body></html>')
        printWindow.document.close()
        setTimeout(function () {
            printWindow.print()
        }, 100)
    }
    save () {
        const that = this
        this.setState({ loading: true }, function() {
            const params = {
                id: that.state.id,
                id_ganti_rugi: that.props.id,
                id_laka: that.props.id_laka,
                persetujuan_pj: that.state.persetujuan_pj,
                nilai_kesepakatan: that.state.nilai_kesepakatan,
                opsi_tidak_setuju : that.state.opsi_tidak_setuju,
                jenis_jaminan : that.state.jenis_jaminan,
                verif_kadep : that.state.verif_kadep,
                status : that.state.status,
                alasan_tidak_setuju : that.state.alasan_tidak_setuju,
                jenis_klarifikasi : that.state.jenis_klarifikasi,
                nilai_jaminan : that.state.nilai_jaminan,
                lain_lain : that.state.lain_lain
            }

            PostPrivate(that, 'transaksi/klarifikasigantirugi/' + (this.state.id ? 'edit' : 'add'), params).then(result => {
                that.setState({ loading: false }, function() {
                    that.loadKlarifikasi()
                    SwalSuccess("Berhasil", result.message)
                })
            }).catch(error => {
                if (typeof error == "object") {
                    that.setState({
                        error_field: error
                    })
                }
                that.setState({
                    loading: false
                })
            })
        })
    }
    saveConfirmation () {
        if (this.state.status === 1 || this.state.status === '1') {
            SwalConfirmSave('Mohon Perhatian', 'Klarifikasi yang dinyatakan Sudah Selesai tidak dapat diubah kembali. Apakah Anda yakin ingin menyimpan?', this.save)
        } else {
            this.save()
        }
    }
    toggleMode (form) {
        this.setState({ form_mode: form })
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12" style={{ display: !this.state.form_mode ? 'block' : 'none' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="modal-title" style={{marginBottom: '20px', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5'}}>Detail Kecelakaan</h4>
                            <LakaDetail id={this.props.id_laka} onLoad={this.onUpdateLaka} />
                            <h4 className="modal-title" style={{marginTop: '30px', marginBottom: '20px', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5'}}>Detail Ganti Rugi</h4>
                            <GantiRugiDetail id={this.props.id} onLoad={this.onUpdateGantiRugi} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                        {(this.state.jenis_pembayaran == '1' || this.state.jenis_pembayaran == '2') && this.state.status_pembayaran == '1' &&
                            (
                                <Button
                                    type="button"
                                    className="btn btn-success"
                                    loading={this.state.loading}
                                    onClick={() => this.instantApprove()}
                                >
                                    Approve Tanpa Proses Klarifikasi 
                                </Button>
                            )
                            }
                            {this.state.jenis_pembayaran =='3' && this.state.status_pembayaran == '1' && (
                            <Button
                                className="btn btn-success"
                                loading={this.state.loading}
                                onClick={() => this.toggleMode(true) }
                            >
                                Klarifikasi
                            </Button>
                            )}
                
                            {this.state.status_pembayaran =='2' && (
                                <Button
                                    className="btn btn-success"
                                    style={{ marginRight: '15px' }}
                                    loading={this.state.loading}
                                    onClick={() => this.print('print-container') }
                                >
                                    Cetak
                                </Button>
                                
                            )}
                
                            {this.state.status_pembayaran =='2' && this.state.jenis_klarifikasi =='2'  &&  (
                            <Button
                                    className="btn btn-success"
                                    style={{ marginRight: '15px' }}
                                    loading={this.state.loading}
                                    onClick={() => this.print('spgr-container') }
                                >
                                    Cetak SPGR
                                </Button>
                            )}
                    
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ display: this.state.form_mode ? 'block' : 'none' }}>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-3 col-form-label">No. Laporan</label>
                        <div className="col-md-3">
                            <input type="text" readOnly disabled className="form-control" value={this.state.no_laporan || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-8">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-7 col-form-label">Nilai Asset Rusak</label>
                                <div className="col-md-1 text-right" style={{ paddingTop: '6px', paddingRight: '0px' }}>Rp</div>
                                <div className="col-md-4">
                                    <input type="text" readOnly disabled className="form-control" value={this.state.total_ganti_rugi_raw ? this.toThousandSeparated(this.state.total_ganti_rugi_raw) : '-'}/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Persetujuan Pengguna Jalan*</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="persetujuan_pj"
                                            value="1"
                                            disabled={this.state.printable && this.state.persetujuan_pj !== 1 && this.state.persetujuan_pj !== '1'}
                                            checked={this.state.persetujuan_pj === 1 || this.state.persetujuan_pj === '1'}
                                            onChange={ev => this.setState({ persetujuan_pj: 1, nilai_kesepakatan: null, alasan_tidak_setuju: null })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Ya</label>
                                    </div>
                                </div>
                                <div className="col-md-5">dengan nilai sebesar{ this.state.persetujuan_pj === 1 || this.state.persetujuan_pj === '1' ? '*' : ''}</div>
                                <div className="col-md-1 text-right" style={{ paddingTop: '6px', paddingRight: '0px' }}>Rp</div>
                                <div className="col-md-4">
                                    {(this.state.persetujuan_pj === 1 || this.state.persetujuan_pj === '1') &&
                                        <input
                                            type={ !this.state.printable ? 'number' : 'text' }
                                            disabled={this.state.printable}
                                            className="form-control"
                                            defaultValue={ !this.state.printable ? this.state.nilai_kesepakatan : this.toThousandSeparated(this.state.nilai_kesepakatan) }
                                            onChange={ev => this.setState({ nilai_kesepakatan: ev.target.value })}
                                        />
                                    }
                                    {this.state.persetujuan_pj !== 1 && this.state.persetujuan_pj !== '1' &&
                                        <input type="number" readOnly disabled className="form-control"/>
                                    }
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="persetujuan_pj"
                                            value="2"
                                            disabled={this.state.printable && this.state.persetujuan_pj !== 2 && this.state.persetujuan_pj !== '2'}
                                            checked={this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2'}
                                            onChange={ev => this.setState({ persetujuan_pj: 2, nilai_kesepakatan: null })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Tidak</label>
                                    </div>
                                </div>
                                <div className="col-md-5">nilai kesepakatan sebesar{ this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2' ? '*' : ''}</div>
                                <div className="col-md-1 text-right" style={{ paddingTop: '6px', paddingRight: '0px' }}>Rp</div>
                                <div className="col-md-4">
                                    {(this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2') &&
                                        <input
                                            type={ !this.state.printable ? 'number' : 'text' }
                                            disabled={this.state.printable}
                                            className="form-control"
                                            defaultValue={ !this.state.printable ? this.state.nilai_kesepakatan : this.toThousandSeparated(this.state.nilai_kesepakatan) }
                                            onChange={ev => this.setState({ nilai_kesepakatan: ev.target.value })}
                                        />
                                    }
                                    {this.state.persetujuan_pj !== 2 && this.state.persetujuan_pj !== '2' &&
                                        <input type="number" readOnly disabled className="form-control"/>
                                    }
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-7 col-form-label">Selisih Tagihan dan Kesepakatan</label>
                                <div className="col-md-1 text-right" style={{ paddingTop: '6px', paddingRight: '0px' }}>Rp</div>
                                <div className="col-md-4">
                                    <input type="text" readOnly disabled className="form-control" value={this.getDifference()}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Klarifikasi Ganti Rugi*</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jenis_klarifikasi"
                                            value="1"
                                            disabled={this.state.printable && this.state.jenis_klarifikasi !== 1 && this.state.jenis_klarifikasi !== '1'}
                                            checked={this.state.jenis_klarifikasi === 1 || this.state.jenis_klarifikasi === '1'}
                                            onChange={ev => this.setState({ jenis_klarifikasi: 1 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Reguler</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jenis_klarifikasi"
                                            value="2"
                                            disabled={this.state.printable && this.state.jenis_klarifikasi !== 2 && this.state.jenis_klarifikasi !== '2'}
                                            checked={this.state.jenis_klarifikasi === 2 || this.state.jenis_klarifikasi === '2'}
                                            onChange={ev => this.setState({ jenis_klarifikasi: 2 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Khusus</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-md-12 col-form-label" style={{marginBottom: '10px'}}>Alasan Tidak Setuju</label>
                        <div className="col-md-12">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-8">
                                    { (this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2') &&
                                        <textarea className="form-control" disabled={this.state.printable} defaultValue={this.state.alasan_tidak_setuju} onChange={ev => this.setState({ alasan_tidak_setuju: ev.target.value })}/>
                                    }
                                    { this.state.persetujuan_pj !== 2 && this.state.persetujuan_pj !== '2' &&
                                        <textarea className="form-control" disabled readOnly />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-4">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Opsi Tidak Setuju</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="opsi_tidak_setuju"
                                            value="1"
                                            disabled={!(this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2') || (this.state.printable && this.state.opsi_tidak_setuju !== 1 && this.state.opsi_tidak_setuju !== '1')}
                                            checked={this.state.opsi_tidak_setuju === 1 || this.state.opsi_tidak_setuju === '1'}
                                            onChange={ev => this.setState({ opsi_tidak_setuju: 1 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Tidak Setuju Harga</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="opsi_tidak_setuju"
                                            value="2"
                                            disabled={!(this.state.persetujuan_pj === 2 || this.state.persetujuan_pj === '2') || (this.state.printable && this.state.opsi_tidak_setuju !== 2 && this.state.opsi_tidak_setuju !== '2')}
                                            checked={this.state.opsi_tidak_setuju === 2 || this.state.opsi_tidak_setuju === '2'}
                                            onChange={ev => this.setState({ opsi_tidak_setuju: 2 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Tidak Setuju / Perbaikan Sendiri</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Jenis Jaminan*</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jenis_jaminan"
                                            value="1"
                                            disabled={this.state.printable && this.state.jenis_jaminan !== 1 && this.state.jenis_jaminan !== '1'}
                                            checked={this.state.jenis_jaminan === 1 || this.state.jenis_jaminan === '1'}
                                            onChange={ev => this.setState({ jenis_jaminan: 1 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Jaminan Kendaraan</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="jenis_jaminan"
                                            value="2"
                                            disabled={this.state.printable && this.state.jenis_jaminan !== 2 && this.state.jenis_jaminan !== '2'}
                                            checked={this.state.jenis_jaminan === 2 || this.state.jenis_jaminan === '2'}
                                            onChange={ev => this.setState({ jenis_jaminan: 2 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Jaminan Uang</label>
                                    </div>
                                </div>
                                <label className="col-sm-3 col-form-label">Nilai Jaminan</label>
                                <div className="col-md-1 text-right" style={{ paddingTop: '6px', paddingRight: '0px' }}>Rp</div>
                                <div className="col-md-4">
                                    {(this.state.jenis_jaminan === 2 || this.state.jenis_jaminan === '2') &&
                                        <input
                                            type={ !this.state.printable ? 'number' : 'text' }
                                            disabled={this.state.printable}
                                            className="form-control"
                                            defaultValue={ !this.state.printable ? this.state.nilai_jaminan : this.toThousandSeparated(this.state.nilai_jaminan) }
                                            onChange={ev => this.setState({ nilai_jaminan: ev.target.value })}
                                        />
                                    }
                                    {this.state.jenis_jaminan !== 2 && this.state.jenis_jaminan !== '2' &&
                                        <input type="number" readOnly disabled className="form-control"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-4">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Verifikasi Kepala Departemen*</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="verif_kadep"
                                            value="1"
                                            disabled={this.state.printable && this.state.verif_kadep !== 1 && this.state.verif_kadep !== '1'}
                                            checked={this.state.verif_kadep === 1 || this.state.verif_kadep === '1'}
                                            onChange={ev => this.setState({ verif_kadep: 1 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>By Phone</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="verif_kadep"
                                            value="2"
                                            disabled={this.state.printable && this.state.verif_kadep !== 2 && this.state.verif_kadep !== '2'}
                                            checked={this.state.verif_kadep === 2 || this.state.verif_kadep === '2'}
                                            onChange={ev => this.setState({ verif_kadep: 2 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Tatap Muka Langsung</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Lain-lain</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <textarea className="form-control" disabled={this.state.printable} defaultValue={this.state.lain_lain} onChange={ev => this.setState({ lain_lain: ev.target.value })}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className="col-md-4">
                            <div className="row" style={{marginBottom: '10px'}}>
                                <label className="col-md-12 col-form-label">Status Verifikasi</label>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            value="0"
                                            disabled={this.state.printable && this.state.status !== 0 && this.state.status !== '0'}
                                            checked={this.state.status === 0 || this.state.status === '0'}
                                            onChange={ev => this.setState({ status: 0 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Belum Selesai</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            value="1"
                                            disabled={this.state.printable && this.state.status !== 1 && this.state.status !== '1'}
                                            checked={this.state.status === 1 || this.state.status === '1'}
                                            onChange={ev => this.setState({ status: 1 })}
                                        />
                                        <label className="form-check-label" style={{ marginLeft: '10px' }}>Sudah Selesai</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {this.state.printable &&
                                <Button
                                    className="btn btn-success"
                                    style={{ marginRight: '15px' }}
                                    loading={this.state.loading}
                                    onClick={() => this.print('print-container') }
                                >
                                    Cetak
                                </Button>
                            }
                            {this.state.printable && (this.state.jenis_klarifikasi === 2 || this.state.jenis_klarifikasi === '2') &&
                                <Button
                                    className="btn btn-success"
                                    style={{ marginRight: '15px' }}
                                    loading={this.state.loading}
                                    onClick={() => this.print('spgr-container') }
                                >
                                    Cetak SPGR
                                </Button>
                            }
                            {!this.state.printable &&
                                <Button
                                    className="btn btn-info"
                                    style={{ marginRight: '15px' }}
                                    loading={this.state.loading}
                                    onClick={this.saveConfirmation}
                                >
                                    Simpan
                                </Button>
                            }
                            <Button
                                className="btn btn-danger"
                                loading={this.state.loading}
                                onClick={() => this.toggleMode(false) }
                            >
                                Batal
                            </Button>
                        </div>
                    </div>
                    {this.state.printable &&
                        <div style={{ display: 'none' }}>
                            <Print item={this.state} />
                            <PrintSpgr item={this.state} />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Detail;
