import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputSelectReact, } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class RefModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            id_call: props.item ? props.item.id_call : "",
            nama_call: props.item ? props.item.nama_call : "",
            nomor_call: props.item ? props.item.nomor_call : "",
            id_bujt: props.item ? props.item.id_bujt : "",
            nama_bujt: props.item ? props.item.nama_bujt : "",
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            Promise.all([
                GetPrivate(this, `callcenter/callcenter/bujt`)
            ])
                .then(result => {
                    // jabatan
                    const bujt = result[0].data.map(item => ({
                        value: item.id_bujt,
                        label: item.nama_bujt
                    }));
                    // set state
                    this.setState({
                        loading: false,
                        list_bujt: bujt
                    });
                })
                .catch(error => {});
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                id_call: that.state.id_call,
                nama_call: that.state.nama_call,
                nomor_call: that.state.nomor_call,
                id_bujt: that.state.id_bujt
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        {/* <div className="form-group row">
                            <label
                                htmlFor={"id_call"}
                                className="col-sm-3 control-label mt-1"
                            >
                                ID Call
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"id_call"}
                                    type="text"
                                    placeholder="ID call"
                                    has_error={this.state.error_field.id_call}
                                    defaultValue={this.state.id_call}
                                    onChange={ev =>
                                        this.handleChange(
                                            "id_call",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.id_call}
                                />
                            </div>
                        </div> */}

                        <div className="form-group row">
                            <label
                                htmlFor={"nama_call"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Call
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nama_call"}
                                    type="text"
                                    placeholder="nama call"
                                    has_error={this.state.error_field.nama_call}
                                    defaultValue={this.state.nama_call}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nama_call",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nama_call}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label
                                htmlFor={"nomor_call"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nomor Call
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"nomor_call"}
                                    type="number"
                                    placeholder="nomor call"
                                    has_error={this.state.error_field.nomor_call}
                                    defaultValue={this.state.nomor_call}
                                    onChange={ev =>
                                        this.handleChange(
                                            "nomor_call",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.nomor_call}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                <label
                    htmlFor={"id_bujt"}
                    className="col-sm-3 control-label mt-1"
                >
                    BUJT
                </label>
                <div className="col-sm-5">
                    <InputSelectReact
                        id={"id_bujt"}
                        has_error={this.state.error_field.id_bujt}
                        defaultValue={{
                            label: this.state.nama_bujt,
                            value: this.state.id_bujt
                        }}
                        onChange={item => {
                            this.handleChange("id_bujt", item.value);
                        }}
                        error_field={this.state.error_field.id_bujt}
                        options={this.state.list_bujt}
                    />
                </div>
            </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RefModal;
