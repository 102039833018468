import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";


class PopupModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        //console.log(props);
        
        this.state = {
            loading: false,
            penumpang_laka_id: props.penumpang_laka_id ? props.penumpang_laka_id : "",
            penumpang_index: props.penumpang_index ? props.penumpang_index : "",
            penumpang_kendaraan_id:props.penumpang_kendaraan_id ? props.penumpang_kendaraan_id : "",
            penumpang_id: props.penumpang_id ? props.penumpang_id : "",
            penumpang_nik: props.penumpang_nik ? props.penumpang_nik : "",
            penumpang_nama: props.penumpang_nama ? props.penumpang_nama : "",
            penumpang_alamat: props.penumpang_alamat ? props.penumpang_alamat : "",
            penumpang_tingkat_luka: props.penumpang_tingkat_luka ? props.penumpang_tingkat_luka : "",
            penumpang_tingkat_luka_nama: props.penumpang_tingkat_luka_nama ? props.penumpang_tingkat_luka_nama : "",
            penumpang_tempat_luka: props.penumpang_tempat_luka ? props.penumpang_tempat_luka : "",
            penumpang_tempat_luka_nama: props.penumpang_tempat_luka_nama ? props.penumpang_tempat_luka_nama : "",
            penumpang_sabuk_pengaman: props.penumpang_sabuk_pengaman ? props.penumpang_sabuk_pengaman : "",
            penumpang_sabuk_pengaman_nama: props.penumpang_sabuk_pengaman_nama ? props.penumpang_sabuk_pengaman_nama : "",
            penumpang_posisi: props.penumpang_posisi ? props.penumpang_posisi : "",
            penumpang_posisi_nama: props.penumpang_posisi_nama ? props.penumpang_posisi_nama : "",
            penumpang_kelamin: props.penumpang_kelamin ? props.penumpang_kelamin : "",
            penumpang_kelamin_nama: props.penumpang_kelamin_nama ? props.penumpang_kelamin_nama : "",
            penumpang_umur: props.penumpang_umur ? props.penumpang_umur : "",
            penumpang:props.penumpang,
            jenis_kelamin:props.jenis_kelamin,
            kewarganegaraan:props.kewarganegaraan,
            kondisi_badan:props.kondisi_badan,
            kondisi_penumpang:props.kondisi_penumpang,
            pekerjaan:props.pekerjaan,
            posisi_penumpang:props.posisi_penumpang,
            sabuk_pengaman:props.sabuk_pengaman,
            tipe_korban:props.tipe_korban,
            tipe_pengendara:props.tipe_pengendara,
            sim:props.sim,
            status_sim:props.status_sim,
            tempat_luka:props.tempat_luka,
            error_field: {
                gardu_nama: null
            }
        };
        // bind
        this.handleChange           = this.handleChange.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);     
        this.addPenumpang           = this.addPenumpang.bind(this);
        this.addPenumpangModal      = this.addPenumpangModal.bind(this);
        this.simpanPenumpang        = this.simpanPenumpang.bind(this);
    }
    
    simpanPenumpang() {
        // send to main code
        let penumpang = this.state.penumpang;
        let tambahan_penumpang = {
            penumpang_laka_id:this.state.penumpang_laka_id,
            penumpang_kendaraan_id:this.state.penumpang_kendaraan_id,
            penumpang_id:this.state.penumpang_id,
            penumpang_nik:this.state.penumpang_nik,
            penumpang_nama:this.state.penumpang_nama,
            penumpang_alamat:this.state.penumpang_alamat,
            penumpang_tingkat_luka:this.state.penumpang_tingkat_luka,
            penumpang_tingkat_luka_nama:this.state.penumpang_tingkat_luka_nama,
            penumpang_tempat_luka:this.state.penumpang_tempat_luka,
            penumpang_tempat_luka_nama:this.state.penumpang_tempat_luka_nama,
            penumpang_sabuk_pengaman:this.state.penumpang_sabuk_pengaman,
            penumpang_sabuk_pengaman_nama:this.state.penumpang_sabuk_pengaman_nama,
            penumpang_posisi:this.state.penumpang_posisi,
            penumpang_posisi_nama:this.state.penumpang_posisi_nama,
            penumpang_kelamin:this.state.penumpang_kelamin,
            penumpang_kelamin_nama:this.state.penumpang_kelamin_nama,
            penumpang_umur:this.state.penumpang_umur,
            counter:0
        }; 
        
        // coba simpan dulu, jika berhasil baru push
        PostPrivate(this, `transaksi/medis/add_penumpang`, tambahan_penumpang)
                .then(result => {
                    if (result.status == 200) {
                        this.setState(
                            {
                                loading: false
                            },
                            function() {
                                if (this.state.penumpang_id !='') {
                                    //edit gimana ?
                                    penumpang[this.state.penumpang_index] = tambahan_penumpang;
                                    //console.log(penumpang);
                                    this.props.setPenumpang(penumpang);
                                } else {
                                    tambahan_penumpang.penumpang_id = result.id;
                                    penumpang.push(tambahan_penumpang);
                                    this.props.setPenumpang(penumpang);
                                }
                                
                                SwalSuccess("Berhasil", result.message);
                                this.addPenumpangModal('');
                            }
                        );
                    } else {
                        alert('Update data gagal');
                        
                        this.setState({
                            loading: false
                        });
                        
                    }
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });    
    }
    
    addPenumpangModal(index) {
        this.props.addPenumpang(index);
    }
    
    
    addPenumpang(index) {
        // disini
        this.setState({ loading: true }, function() {
            const params = {
                penumpang_laka_id:this.state.penumpang_laka_id,
                penumpang_index:this.state.penumpang_index,
                penumpang_kendaraan_id:this.state.penumpang_kendaraan_id,
                penumpang_id:this.state.penumpang_id,
                penumpang_nik:this.state.penumpang_nik,
                penumpang_nama:this.state.penumpang_nama,
                penumpang_alamat:this.state.penumpang_alamat,
                penumpang_tingkat_luka:this.state.penumpang_tingkat_luka,
                penumpang_tingkat_luka_nama:this.state.penumpang_tingkat_luka_nama,
                penumpang_tempat_luka:this.state.penumpang_tempat_luka,
                penumpang_tempat_luka_nama:this.state.penumpang_tempat_luka_nama,
                penumpang_sabuk_pengaman:this.state.penumpang_sabuk_pengaman,
                penumpang_umur:this.state. penumpang_umur,
                penumpang_sabuk_pengaman_nama:this.state.penumpang_sabuk_pengaman_nama,
                penumpang_posisi:this.state.penumpang_posisi,
                penumpang_posisi_nama:this.state.penumpang_posisi_nama,
                penumpang_kelamin:this.state.penumpang_kelamin,
                penumpang_kelamin_nama:this.state. penumpang_kelamin_nama
            }
            
            PostPrivate(this, `transaksi/medis/add_penumpang`, params)
                .then(result => {
                    if (result.status == 200) {
                        this.setState(
                            {
                                loading: false
                            },
                            function() {
                                SwalSuccess("Berhasil", result.message);
                            }
                        );
                    } else {
                        alert('Update data gagal');
                        
                        this.setState({
                            loading: false
                        });
                        
                    }
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });    
        })
    }

    
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    

    onSubmit = evt => {
        evt.preventDefault();
    };
    
    render() {
        // return
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">NIK</label>
                                        <div className="col-md-9">
                                            <input 
                                                type="text"  
                                                className="form-control" 
                                                value={this.state.penumpang_nik}
                                                onChange={ev =>
                                                    this.handleChange(
                                                        "penumpang_nik",
                                                        ev.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Nama</label>
                                        <div className="col-md-9">
                                            <input 
                                                type="text"  
                                                className="form-control" 
                                                value={this.state.penumpang_nama}
                                                onChange={ev =>
                                                    this.handleChange(
                                                        "penumpang_nama",
                                                        ev.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Alamat</label>
                                        <div className="col-md-9">
                                            <input 
                                                type="text"  
                                                className="form-control" 
                                                value={this.state.penumpang_alamat}
                                                onChange={ev =>
                                                    this.handleChange(
                                                        "penumpang_alamat",
                                                        ev.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Tingkat Luka</label>
                                        <div className="col-md-9">
                                            <InputSelectReact
                                                has_error={this.state.error_field.kondisi_penumpang}
                                                defaultValue={{
                                                    label: this.state.penumpang_tingkat_luka_nama,
                                                    value: this.state.penumpang_tingkat_luka
                                                }}
                                                onChange={item => {
                                                    this.handleChange("penumpang_tingkat_luka", item.id);
                                                    this.handleChange("penumpang_tingkat_luka_nama", item.label);
                                                }}
                                                error_field={this.state.error_field.kondisi_penumpang}
                                                options={this.state.kondisi_penumpang}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Tempat Luka</label>
                                        <div className="col-md-9">
                                            <InputSelectReact
                                                has_error={this.state.error_field.tempat_luka}
                                                defaultValue={{
                                                    label: this.state.penumpang_tempat_luka_nama,
                                                    value: this.state.penumpang_tempat_luka
                                                }}
                                                onChange={item => {
                                                    this.handleChange("penumpang_tempat_luka", item.id);
                                                    this.handleChange("penumpang_tempat_luka_nama", item.label);
                                                }}
                                                error_field={this.state.error_field.tempat_luka}
                                                options={this.state.tempat_luka}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Sabuk Pengaman</label>
                                        <div className="col-md-9">
                                            <InputSelectReact
                                                has_error={this.state.error_field.sabuk_pengaman}
                                                defaultValue={{
                                                    label: this.state.penumpang_sabuk_pengaman_nama,
                                                    value: this.state.penumpang_sabuk_pengaman
                                                }}
                                                onChange={item => {
                                                    this.handleChange("penumpang_sabuk_pengaman", item.id);
                                                    this.handleChange("penumpang_sabuk_pengaman_nama", item.label);
                                                }}
                                                error_field={this.state.error_field.sabuk_pengaman}
                                                options={this.state.sabuk_pengaman}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Posisi Penumpang</label>
                                        <div className="col-md-9">
                                            <InputSelectReact
                                                has_error={this.state.error_field.posisi_penumpang}
                                                defaultValue={{
                                                    label: this.state.penumpang_posisi_nama,
                                                    value: this.state.penumpang_posisi
                                                }}
                                                onChange={item => {
                                                    this.handleChange("penumpang_posisi", item.id);
                                                    this.handleChange("penumpang_posisi_nama", item.label);
                                                }}
                                                error_field={this.state.error_field.posisi_penumpang}
                                                options={this.state.posisi_penumpang}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Jenis Kelamin</label>
                                        <div className="col-md-9">
                                            <InputSelectReact
                                                has_error={this.state.error_field.jenis_kelamin}
                                                defaultValue={{
                                                    label: this.state.penumpang_kelamin_nama,
                                                    value: this.state.penumpang_kelamin
                                                }}
                                                onChange={item => {
                                                    this.handleChange("penumpang_kelamin", item.id);
                                                    this.handleChange("penumpang_kelamin_nama", item.label);
                                                }}
                                                error_field={this.state.error_field.jenis_kelamin}
                                                options={this.state.jenis_kelamin}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row" style={{marginBottom: '10px'}}>
                                        <label className="col-sm-3 col-form-label">Umur</label>
                                        <div className="col-md-1">
                                            <input 
                                                type="text"  
                                                className="form-control" 
                                                value={this.state.penumpang_umur}
                                                onChange={ev =>
                                                    this.handleChange(
                                                        "penumpang_umur",
                                                        ev.target.value
                                                    )
                                                }
                                            />
                                            
                                        </div>
                                        <label className="col-sm-3 col-form-label">Tahun</label>
                                    </div>
                                </div>
                            </div>
                             <div className="form-group row">
                                <div className="col-sm-12">
                                    <div style={{float:'right'}}>
                                    <Button type="button" className="btn btn-danger" onClick={() => this.addPenumpangModal('')}>Batal</Button>
                                    <Button type="button" className="btn btn-success" onClick={() => this.simpanPenumpang()}>Simpan</Button>
                                    </div>
                                </div>
                            </div>
                         </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.addPenumpangModal('')}
                />
        )
    }
    
    
}

export default PopupModal;
