import React, { Component } from "react";
import { Modal, Button, InputSelectReact } from "../../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";

class ShiftDOModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // title
        this.title = `Pegawai ${this.props.item.title}`;
        // state
        this.state = {
            list_pegawai: [],
            pegawai_id: "",
            ruas_id: this.props.ruas_id || "",
            do_date: this.props.item.do_date || "",
            do_status: this.props.item.do_status || "",
            error_field: {},
            // --
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const { ruas_id, do_date } = this.state;
            GetPrivate(
                this,
                `pegawai/shift/pegawai_not_set?ruas_id=${ruas_id}&date=${do_date}`
            )
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list_pegawai: result.data.map(item => ({
                            value: item.pegawai_id,
                            label: item.pegawai_name
                        }))
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handleChange
    handleChange(field, value) {
        this.setState({
            [field]: value
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        const { pegawai_id, do_date, ruas_id, do_status } = this.state;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                pegawai_id: pegawai_id,
                do_date: do_date,
                ruas_id: ruas_id,
                do_status: do_status
            };
            // paggil post api
            PostPrivate(that, `pegawai/shift/add_do`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    //render
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    !this.state.loading && (
                        <InputSelectReact
                            id={"pegawai_id"}
                            has_error={this.state.error_field.pegawai_id}
                            onChange={item => {
                                this.handleChange("pegawai_id", item.value);
                            }}
                            error_field={this.state.error_field.pegawai_id}
                            options={this.state.list_pegawai}
                        />
                    )
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={() => this.props.toogleShow({}, true)}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default ShiftDOModal;
