import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "5%", className: "text-center", label: "Nama CCTV" },
        { width: "5%", className: "text-center", label: "Stream URL" },
        { width: "5%", className: "text-center", label: "Keterangan Arah" },
        { width: "5%", className: "text-center", label: "Longitude" },
        { width: "5%", className: "text-center", label: "Latitude" },
        { width: "5%", className: "text-center", label: "ID Bujt" },
        { width: "5%", className: "text-center", label: "Status" },
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.nama_cctv}</td>
            <td className="text-center">{item.stream_url}</td>
            <td className="text-center">{item.keterangan_arah}</td>
            <td className="text-center">{item.longitude}</td>
            <td className="text-center">{item.latitude}</td>
            <td className="text-center">{item.id_bujt}</td>
            <td className="text-center">{item.status_kamera}</td>
        </tr>
    ));
};

const BujtTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default BujtTable;
