import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sort: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Putar Balik",
        selector: "putar_nama",
        sort: true,
        width: "30%"
    },
    {
        name: "Latitude",
        selector: "putar_latitude",
        sort: true,
        width: "20%"
    },
    {
        name: "Longitude",
        selector: "putar_longitude",
        sort: true,
        width: "20%"
    },
    {
        name: "",
        selector: "action",
        width: "17.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const SatuanTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_sarpras"}
            empty="Belum ada referensi putar balik, klik tombol + untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default SatuanTable;
