import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Nama Pegawai",
        selector: "pegawai_name",
        sortable: true,
        width: "29%"
    },
    {
        name: "Nik",
        selector: "pegawai_nik",
        sortable: true,
        width: "29%"
    },
    {
        name: "Unit",
        selector: "unit",
        sortable: true,
        width: "10%"
    },
    {
        name: "Shift",
        selector: "shift_name",
        sortable: true,
        width: "10%"
    },
    {
        name: "Mulai Shift",
        selector: "shift_start",
        sortable: true,
        width: "11.5%",
        center: true
    },
    {
        name: "Check In",
        selector: "check_in",
        sortable: true,
        width: "11.5%",
        center: true
    },
    {
        name: "Selesai Shift",
        selector: "shift_finish",
        sortable: true,
        width: "11.5%",
        center: true
    },
    {
        name: "Check Out",
        selector: "check_out",
        sortable: true,
        width: "11.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        return item;
    });
};

const RealisasiTable = props => {
    return (
        <TableBootstrap
            keyField={"user"}
            empty="Belum ada riwayat"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RealisasiTable;
