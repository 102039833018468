import React from "react";

// komponen
import {
    Input,
    InputRadioInline,
    InputSelectAsync
} from "../../components/AdminLTE";

const FieldProfile = props => {
    return (
        <>
            <fieldset className="mt-2">
                <legend>Biodata Pegawai : </legend>
                <div className="form-group row">
                    <label
                        htmlFor={"pegawai_nik"}
                        className="control-label col-md-3 mt-1"
                    >
                        Nomor NIK Pegawai
                    </label>
                    <div className="col-md-4">
                        <Input
                            id={"pegawai_nik"}
                            type="text"
                            placeholder="Nomor NIK Pegawai"
                            has_error={props.error_field.pegawai_nik}
                            defaultValue={props.pegawai_nik}
                            onChange={ev =>
                                props.handleChange(
                                    "pegawai_nik",
                                    ev.target.value
                                )
                            }
                            error_field={props.error_field.pegawai_nik}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor={"pegawai_name"}
                        className="control-label col-md-3 mt-1"
                    >
                        Nama Pegawai
                    </label>
                    <div className="col-md-7">
                        <Input
                            id={"pegawai_name"}
                            type="text"
                            placeholder="Nama Pegawai"
                            has_error={props.error_field.pegawai_name}
                            defaultValue={props.pegawai_name}
                            onChange={ev =>
                                props.handleChange(
                                    "pegawai_name",
                                    ev.target.value
                                )
                            }
                            error_field={props.error_field.pegawai_name}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor={"pegawai_birth_place"}
                        className="control-label col-md-3 mt-1"
                    >
                        Tempat, Tanggal Lahir
                    </label>
                    <div className="col-md-5">
                        <InputSelectAsync
                            has_error={props.error_field.pegawai_birth_place}
                            defaultValue={props.pegawai_birth_place}
                            defaultInputValue={props.pegawai_birth_place_name}
                            hasDefaultOptions={props.hasDefaultOptions}
                            defaultOptions={[
                                {
                                    value: props.pegawai_birth_place,
                                    label: props.pegawai_birth_place_name
                                }
                            ]}
                            onChange={item =>
                                props.handleChange(
                                    "pegawai_birth_place",
                                    item.value
                                )
                            }
                            error_field={props.error_field.pegawai_birth_place}
                            loadOptions={q =>
                                props.loadData(
                                    q,
                                    "referensi/kabupaten",
                                    "kabupaten_id",
                                    "kabupaten_name"
                                )
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            id={"pegawai_birth"}
                            type="date"
                            placeholder="Tanggal Lahir"
                            has_error={props.error_field.pegawai_birth}
                            defaultValue={props.pegawai_birth}
                            onChange={ev =>
                                props.handleChange(
                                    "pegawai_birth",
                                    ev.target.value
                                )
                            }
                            error_field={props.error_field.pegawai_birth}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor={"pegawai_gender__L"}
                        className="control-label col-md-3 mt-2"
                    >
                        Jenis Kelamin
                    </label>
                    <div className="col-md-9">
                        <InputRadioInline
                            id={"pegawai_gender"}
                            has_error={props.error_field.pegawai_gender}
                            defaultChecked={props.pegawai_gender}
                            onChange={ev =>
                                props.handleChange(
                                    "pegawai_gender",
                                    ev.target.value
                                )
                            }
                            error_field={props.error_field.pegawai_gender}
                            list={[
                                {
                                    label: "Laki - Laki",
                                    value: "L"
                                },
                                {
                                    label: "Perempuan",
                                    value: "P"
                                }
                            ]}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className="mt-2">
                <legend>Akun Pegawai : </legend>
                <div className="form-group row">
                    <label
                        htmlFor={"pegawai_pass"}
                        className="control-label col-md-3 mt-1"
                    >
                        Password Login
                    </label>
                    <div className="col-md-4">
                        <Input
                            id={"pegawai_pass"}
                            type="password"
                            placeholder="Password Login"
                            has_error={props.error_field.pegawai_pass}
                            defaultValue={props.pegawai_pass}
                            onChange={ev =>
                                props.handleChange(
                                    "pegawai_pass",
                                    ev.target.value
                                )
                            }
                            error_field={props.error_field.pegawai_pass}
                        />
                    </div>
                </div>
            </fieldset>
        </>
    );
};

export default FieldProfile;
