import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input, InputRadioInline, InputSelectReact } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class RefModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? `Ubah ${this.props.ref_title}`
                : `Tambah ${this.props.ref_title}`;
        // state
        this.state = {
            // field input
            tipe_id: props.item ? props.item.tipe_id : "",
            tipe_nama: props.item ? props.item.tipe_nama : "",
            tipe_status: props.item ? props.item.tipe_status : "",
            tipe_kode: props.item ? props.item.tipe_kode : "",
            // error field
            error_field: {},
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }



    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                tipe_id: that.state.tipe_id,
                tipe_nama: that.state.tipe_nama,
                tipe_status: that.state.tipe_status,
                tipe_kode: that.state.tipe_kode,
            };
            // paggil post api
            PostPrivate(
                that,
                `${that.props.ref_url}/${that.props.mode}`,
                params
            )
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                    
                    <div className="form-group row">
                            <label
                                htmlFor={"tipe_kode"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Kode
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"tipe_kode"}
                                    type="text"
                                    placeholder="Kode Tipe Korban"
                                    has_error={this.state.error_field.tipe_kode}
                                    defaultValue={this.state.tipe_kode}
                                    onChange={ev =>
                                        this.handleChange(
                                            "tipe_kode",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.tipe_kode}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                htmlFor={"tipe_nama"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Tipe Korban
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"tipe_nama"}
                                    type="text"
                                    placeholder="nama Tipe Korban"
                                    has_error={this.state.error_field.tipe_nama}
                                    defaultValue={this.state.tipe_nama}
                                    onChange={ev =>
                                        this.handleChange(
                                            "tipe_nama",
                                            ev.target.value
                                        )
                                    }
                                    error_field={this.state.error_field.tipe_nama}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                <label
                    htmlFor={"pegawai_st__1"}
                    className="control-label col-md-3 mt-2"
                >
                    Status ?
                </label>
                <div className="col-md-9">
                    <InputRadioInline
                        id={"tipe_status"}
                        has_error={this.state.error_field.tipe_status}
                        defaultChecked={this.state.tipe_status}
                        onChange={ev =>
                            this.handleChange("tipe_status", ev.target.value)
                        }
                        error_field={this.state.error_field.tipe_status}
                        list={[
                            {
                                label: "Aktif",
                                value: "1"
                            },
                            {
                                label: "Tidak aktif",
                                value: "0"
                            }
                        ]}
                    />
                </div>
            </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default RefModal;
