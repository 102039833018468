import React from "react";

// komponen
import {
    Table,
    Button,
    Input,
    InputSelectReact,
} from "../../../components/AdminLTE";

// table
const thead = (props) => {
    return [
        {
            width: "5%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={props.addRow}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            ),
        },
        { width: "13%", className: "text-center", label: "Bahasa" },
        { width: "32%", className: "text-center", label: "Label" },
        { width: "50%", className: "text-center", label: "Deskripsi" },
    ];
};

const Tbody = (props) => {
    return props.list.map((item, index) => (
        <tr key={index}>
            <td className="text-center">
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={() =>
                        item.code_id != ""
                            ? props.deleteConfirm(item, index)
                            : props.removeRow(index)
                    }
                >
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
            <td>
                <InputSelectReact
                    defaultValue={{
                        label: item.code_desc,
                        value: item.lang_code,
                    }}
                    options={props.list_code}
                    onChange={(item) =>
                        props.handleChange(index, "lang_code", item.value)
                    }
                />
            </td>
            <td>
                <Input
                    type="text"
                    placeholder="Label"
                    defaultValue={item.lang_key}
                    onChange={(ev) =>
                        props.handleChange(index, "lang_key", ev.target.value)
                    }
                    has_error={item.error_field.lang_key}
                    error_field={item.error_field.lang_key}
                />
            </td>
            <td>
                <Input
                    type="text"
                    placeholder="Deskripsi"
                    defaultValue={item.lang_desc}
                    onChange={(ev) =>
                        props.handleChange(index, "lang_desc", ev.target.value)
                    }
                />
            </td>
        </tr>
    ));
};

const KodeTable = (props) => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada bahasa, silahkan klik tombol + untuk menambahkan bahasa"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default KodeTable;
