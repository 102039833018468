import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Jabatan",
        selector: "jabatan_name",
        sortable: true,
        width: "25%"
    },
    {
        name: "Deskripsi Jabatan",
        selector: "jabatan_desc",
        width: "37.5%"
    },
    {
        name: "Level",
        selector: "jabatan_level",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "",
        selector: "action",
        width: "17.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const JabatanTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_jabatan"}
            empty="Belum ada referensi jabatan, klik tombol + untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default JabatanTable;
