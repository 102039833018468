import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import { SwalSuccess } from "../../../components/Alert";

import LakaDetail from "../../report/lakalantas/detail";
import GantiRugiDetail from "../../report/ganti_rugi/detail";
import {
    Button, Input, InputSelectReact
} from "../../../components/AdminLTE";
import logo from "../../../resources/img/logo-full.png";

class Print extends Component {
     constructor (props) {
        super(props)

        this.state = {
            date: new Date(),
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
            weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        }
    }
   render () {
        return (
            <div id="print-container">
                 <div style={{ display: 'inline-block', width: '100%', borderBottom: '1px solid grey', marginBottom: '20px' }}>
                    <div style={{ float: 'left', width: '70%' }}>
                    <img src={logo} alt="logo" style={{ maxWidth: '194px', width: '194px', marginBottom: '10px'
 }} />
                    </div>
                    <div style={{ float: 'left', width: '30%' }}>     
                        <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>Jalan Tol { this.props.item.ruas_tol }</div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '-36px'}}>
                        <h3><u>Laporan Kecelakaan (LK)</u></h3>
                        <p>Nomor : { this.props.item.no_laporan }</p>
                    </div>
                <table style={{ marginBottom: '10px', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '1%' }}>1.</td>
                            <td style={{ width: '200px' }}>Waktu Kejadian</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginRight: '20px' }}><b>Hari</b> { this.state.weekdays[(new Date(this.props.item.tanggal)).getDay()] }</td>
                                            <td style={{ marginRight: '20px' }}><b>/Tanggal</b> { this.props.item.tanggal }</td>
                                            <td style={{ marginRight: '20px' }}><b>/Jam</b> { this.props.item.jam ? this.props.item.jam.substr(0, 8) : '-' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>2.</td>
                            <td style={{ width: '200px' }}>Jenis Kecelakaan</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>3-3</td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>3.</td>
                            <td style={{ width: '200px' }}>Lokasi Kejadian</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginRight: '20px' }}><b>Gardu</b> { this.props.item.lokasi_gardu }</td>
                                            <td style={{ marginRight: '20px' }}><b>/Gerbang</b> { this.props.item.lokasi_gerbang }</td>
                                            <td style={{ marginRight: '20px' }}><b>/KM</b> { this.props.item.lokasi_km }</td>
                                            <td style={{ marginRight: '20px' }}><b>/Jalur</b> { this.props.item.ruas_tol }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>4.</td>
                            <td style={{ width: '200px' }}>Jumlah Kendaraan Terlibat</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.jml_kendaraan_terlibat } Unit</td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>5.</td>
                            <td style={{ width: '200px' }}>Detail Kendaraan Terlibat</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}></td>
                            <td colSpan="3">
                                <table style={{ width: '100%', marginBottom: '20px' }}>
                                    <thead>
                                        <tr style={{ textAlign: 'left' }}>
                                          <th>No</th>
                                          <th>Merk</th>
                                          <th>Seri</th>
                                          <th>Plat Nomor</th>
                                          <th>Jml Penumpang</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.item.kendaraan_terlibat.map(function(row, i){
                                            return (
                                                <tr key={i + 1}>
                                                    <td scope="row">{i + 1}</td>
                                                    <td>{row.merk}</td>
                                                    <td>{row.seri}</td>
                                                    <td>{row.plat_nomor}</td>
                                                    <td>{row.jml_penumpang}</td>
                                                </tr>
                                            )
                                        })}
                                        {this.props.item.kendaraan_terlibat.length === 0 &&
                                            <tr><td colSpan="5" style={{ textAlign: 'center' }}>- Tidak ada data- </td></tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>6.</td>
                            <td style={{ width: '200px' }}>Jumlah Korban</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginRight: '20px' }}><b>Luka Ringan</b> { this.props.item.jml_korban_lr } org</td>
                                            <td style={{ marginRight: '20px' }}><b>/Luka Berat</b> { this.props.item.jml_korban_lb }</td>
                                            <td style={{ marginRight: '20px' }}><b>/Meninggal</b> { this.props.item.jml_korban_md }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>7.</td>
                            <td style={{ width: '200px' }}>Dugaan Penyebab Kecelakaan</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td>{ this.props.item.nama_penyebab_laka }</td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}>8.</td>
                            <td style={{ width: '200px' }}>Uraian Kejadian</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}></td>
                            <td colSpan="3"><div style={{ minHeight: '150px' }}>{ this.props.item.uraian_kejadian }</div></td>
                        </tr>
                        <tr style={{ verticalAlign: 'top' }}>
                            <td style={{ width: '1%', paddingTop: '3px' }}>9.</td>
                            <td style={{ width: '200px', paddingTop: '3px' }}>Unit Penolong TKP</td>
                            <td style={{ width: '1%', paddingTop: '3px' }}>:</td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '150px' }}>Patroli</td>
                                            <td style={{ width: '150px' }}>Unit { this.props.item.unit_patroli }</td>
                                            <td style={{ width: '50px' }}>Cuaca</td>
                                            <td style={{ width: '1%' }}>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '150px' }}>Ambulance</td>
                                            <td>Unit { this.props.item.ambulan_kh }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '150px' }}>Rescue</td>
                                            <td>Unit { this.props.item.unit_rescue }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '150px' }}>Derek</td>
                                            <td>Unit { this.props.item.unit_derek }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '150px' }}>Satgas</td>
                                            <td>Unit { this.props.item.unit_satgas }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '150px' }}>PJR</td>
                                            <td>Unit { this.props.item.unit_pjr }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: '1%' }}>10.</td>
                            <td colSpan="3">Perincian Perhitungan Ganti Rugi</td>
                        </tr>
                        <tr>
                            <td style={{ width: '1%' }}></td>
                            <td>Dasar Ganti Rugi</td>
                            <td style={{ width: '1%' }}>:</td>
                            {/* <td>1.</td>
                            <td>PP No. 15 Tahun 2005 BAB VIII Pasal 86</td> */}
                        </tr>
                        {/* <tr>
                            <td colSpan="3"></td>
                            <td>2.</td>
                            <td>SK. Direksi PT. MHI No. SKD-MHI/003/II/2005</td>
                        </tr> */}

                        {this.props.item.list_sk.map(function(row, i){
                                            return (
                                                <tr key={i + 1}>
                                                    <td colSpan="3"></td>
                                                    <td scope="row">{i + 1}.</td>
                                                    <td>{row.deskripsi}</td>
                                                </tr>
                                            )
                                        })}
                    </tbody>
                </table>
                
                <table style={{ marginBottom: '10px', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '200px' }}>Daftar Asset yang Rusak</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <table style={{ width: '100%', marginBottom: '20px' }}>
                                    <thead>
                                        <tr style={{ textAlign: 'left' }}>
                                          <th scope="col">No</th>
                                          <th scope="col">Fasilitas</th>
                                          <th scope="col">Harga Satuan</th>
                                          <th scope="col">Jumlah</th>
                                          <th scope="col">Total Harga</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.item.asset_rusak.map(function(row, i){
                                            return (
                                                <tr key={i + 1}>
                                                    <td scope="row">{i + 1}</td>
                                                    <td>{row.asset_name}</td>
                                                    <td>{row.asset_harga}</td>
                                                    <td>{row.jml_asset}</td>
                                                    <td>{row.total_harga}</td>
                                                </tr>
                                            )
                                        })}
                                        {this.props.item.asset_rusak.length === 0 &&
                                            <tr><td colSpan="5" style={{ textAlign: 'center' }}>- Tidak ada data- </td></tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ textAlign: 'left' }}>
                                            <th colSpan="4">Total Ganti Rugi</th>
                                            <th>{this.props.item.total_ganti_rugi}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ position: 'absolute', bottom: '0px' }}>
                    <tbody>
                        <tr style={{ textAlign: 'top' }}>
                            <td style={{ width: '200px' }}>Terbilang</td>
                            <td style={{ width: '1%' }}>:</td>
                            <td><div style={{ border: '1px solid grey', minHeight: '30px' }}>&nbsp; {this.props.item.asset_rusak.length != 0 ? this.props.item.terbilang : ''} </div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Print;
