import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// komponen
import { Content, ButtonRole, BoxCollapse } from "../../../components/AdminLTE";
import Header from "./header";
// utils
import GetPrivate from "../../../utils/Connection";

// page
import PegawaiTable from "./table";
import PegawaiSearch from "./search";

class Pegawai extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            q: "",
            ruas: "",
            unit: "",
            loading: false,
            list: [],
            list_ruas: [],
            list_unit: []
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
        // load ref
        this.loadRef();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(
                this,
                `pegawai/pegawai?q=${this.state.q}&ruas=${this.state.ruas}&unit=${this.state.unit}`
            )
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // loadRef
    loadRef() {
        GetPrivate(this, `referensi/ruas`)
            .then(result => {
                this.setState({
                    list_ruas: result.data.map(item => ({
                        value: item.ruas_id,
                        label: item.ruas_name
                    }))
                });
            })
            .catch(error => {});
        GetPrivate(this, `referensi/unit`)
            .then(result => {
                this.setState({
                    list_unit: result.data.map(item => ({
                        value: item.unit_id,
                        label: item.unit_name
                    }))
                });
            })
            .catch(error => {});
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
             <Header
                            // list_ruas={this.state.list_ruas}
                            // ruas_name={this.state.ruas_name}
                            // ruas_id={this.state.ruas_id}
                            // start_date={this.state.start_date}
                            // end_date={this.state.end_date}
                            // loading={this.state.loading}
                            // changeRuas={this.changeRuas}
                            // changeWeek={this.changeWeek}
                            toogleShow={this.toogleShow}
                            // loadData={this.loadData}
                        />
                <BoxCollapse
                    open={false}
                    title="Manajemen Data Pegawai"
                    tools={
                        <ButtonRole
                            role_url={`${this.props.location.pathname}/add`}
                            role_type="role_create"
                        >
                            <NavLink
                                to={`${this.props.location.pathname}/add`}
                                className="btn btn-box-tool ml-2"
                            >
                                <i className="fa fa-plus mr-1"></i> Tambah
                            </NavLink>
                        </ButtonRole>
                    }
                    icon_show={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                    icon_hide={
                        <>
                            <i className="fa fa-search mr-1"></i> Cari
                        </>
                    }
                >
                    <PegawaiSearch
                        handleChange={this.handleChange}
                        onSubmit={this.onSubmit}
                        q={this.state.q}
                        list_ruas={this.state.list_ruas}
                        list_unit={this.state.list_unit}
                    />
                </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <PegawaiTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default Pegawai;
