import React from "react";
import ReactPlayer from 'react-player'
// komponen
import {
    Input,
    InputRadioInline,
    InputSelectReact,
    InputTextarea,
    InputSelectAsync
} from "../../../components/AdminLTE";

import Loading from "../../../components/AdminLTE/loading";


const FieldEvent = props => {
    return (
        <fieldset className="mt-2">
        
        <div className="row">

        <div className="col-xs-6">
                    <div className="row">
        <div className="col-md-12">
        <div className="form-group">
                <label
                    htmlFor={"id_cctv"}
                    className="control-label"
                >
                    KM
                </label>
                    <InputSelectReact
                        id={"id_cctv"}
                        has_error={props.error_field.id}
                        defaultValue={{
                            label: props.nama,
                            value: props.id
                        }}
                        onChange={item => {
                            props.handleChange("id_cctv", item.value);
                            props.loadCCTV();
                        }}
                        error_field={props.error_field.id}
                        options={props.list_cctv}
                    />
                </div>
            </div>
             
            </div>

            <div className="row">
         <div className="col-md-12">
             <div className="form-group">
                <label
                    htmlFor={"nama_event"}
                    className="control-label"
                >
                    Nama Event
                </label>
                    <InputSelectReact
                        id={"nama_event"}
                        has_error={props.error_field.id}
                        defaultValue={{
                            label: props.label,
                            value: props.label
                        }}
                        onChange={item => {
                            props.handleChange("nama_event", item.value);
                        }}
                        error_field={props.error_field.id}
                        options={props.list_event}
                    />
                </div>
            </div>
            </div>

            <div className="row">
            <div className="col-md-12">
            <div className="form-group">
                <label
                    htmlFor={"deskripsi_event"}
                    className="control-label"
                >
                    Deskripsi
                </label>
                    <InputTextarea
                        id={"deskripsi_event"}
                        placeholder="Deskripsi Event"
                        has_error={props.error_field.deskripsi_event}
                        defaultValue={props.deskripsi_event}
                        onChange={ev =>
                            props.handleChange("deskripsi_event", ev.target.value)
                        }
                        error_field={props.error_field.deskripsi_event}
                        rows="10" 
                        cols="60" 
                    />
                </div>
            </div>
            </div>
            
            <div className="row">
                <div className="col-md-12">
            <div className="form-group">
                <label
                    htmlFor={"sumber_event"}
                    className="control-label"
                >
                    Sumber Event
                </label>
                    <InputSelectReact
                        id={"sumber_event"}
                        has_error={props.error_field.value}
                        defaultValue={{
                            label: props.label,
                            value: props.label
                        }}
                        onChange={item => {
                            props.handleChange("sumber_event", item.label);
                        }}
                        error_field={props.error_field.value}
                        options={props.list_sumber}
                    />
                </div>
            </div>
            </div>

            <div className="row">
                <div className="col-md-12">
            <div className="form-group">
                <label
                    htmlFor={"id_unit"}
                    className="control-label"
                >
                    Send To Unit
                </label>
                    <InputSelectReact
                        id={"id_unit"}
                        has_error={props.error_field.id}
                        defaultValue={{
                            label: props.nama,
                            value: props.id
                        }}
                        onChange={item => {
                            props.handleChange("id_unit", item.value);
                        }}
                        error_field={props.error_field.id}
                        options={props.list_unit}
                    />
                </div>
            </div>
            </div>
            </div>

            <div className="col-sm-4">
             {/* <label
                    htmlFor={"id_cctv"}
                    className="control-label"
                >
                    Kamera
                </label> */}
            <div className="form-group">
                {props.loading_cctv ? (
                <>
                    <Loading />
                </>
            ) : (
                props.children
            )}                 
                <ReactPlayer url={props.camera_url} playing={props.camera_playing} />       
                 </div>
                    </div>
            </div>
        
    
    
        </fieldset>
    );
};

export default FieldEvent;
