import React, { Component } from "react";

// komponen
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";
import { Content, Button, ButtonBack } from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import RuasSectionTable from "./table_section";
import RuasLaneModal from "./modal_lane";

class RuasSection extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // ruas id
        this.ruas_id = props.match.params.id;
        // state
        this.state = {
            ruas_name: "",
            list: [],
            // modal
            index: 0,
            item: null,
            modal: false,
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
        this.addRowLane = this.addRowLane.bind(this);
        this.removeRowLane = this.removeRowLane.bind(this);
        this.handleChangeLane = this.handleChangeLane.bind(this);
    }
    // component did mount
    componentDidMount() {
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/ruas/section/${this.ruas_id}`)
                .then(result => {
                    this.setState({
                        loading: false,
                        ruas_name: result.data.ruas_name,
                        list: result.data.rs_section.map(item => {
                            let list = [];
                            try {
                                list = item.section_lane.split(",");
                            } catch (error) {}
                            item["section_lane"] = list;
                            return item;
                        })
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    //addRow
    addRow() {
        this.setState({
            list: [
                ...this.state.list,
                {
                    section_id: "",
                    section_name: "",
                    section_km_start: "",
                    section_km_finish: "",
                    section_lane: []
                }
            ]
        });
    }
    //removeRow
    removeRow(index) {
        let { list } = this.state;
        list.splice(index, 1);
        this.setState({ list: list });
    }
    // konfirmasi hapus
    deleteConfirm(item, index) {
        if (item.section_id) {
            SwalConfirm(
                `Hapus Seksi Ruas [${item.section_name}]`,
                "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
                () => this.deleteProcess(item, index)
            );
        } else {
            this.removeRow(index);
        }
    }
    // deleteprocess
    deleteProcess(item, index) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/ruas/delete_section/${item.section_id}`)
                .then(result => {
                    // remove row
                    this.removeRow(index);
                })
                .catch(error => {})
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                ruas_id: this.ruas_id,
                section: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `referensi/ruas/update_section`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // reload
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    //toogleShow
    toogleShow(index = -1, item = []) {
        this.setState(
            { index: index, item: item, list: this.state.list },
            function() {
                this.setState({ modal: !this.state.modal });
            }
        );
    }
    //addRowLane
    addRowLane() {
        let { list, index } = this.state;
        list[index]["section_lane"].push("");
        this.setState({
            list: list
        });
    }
    //removeRowLane
    removeRowLane(i) {
        let { list, index } = this.state;
        list[index]["section_lane"].splice(index, 1);
        this.setState({ list: list, item: list[index]["section_lane"] });
    }
    // handle change
    handleChangeLane(i, value) {
        let { list, index } = this.state;
        list[index]["section_lane"][i] = value;
        this.setState({ list: list });
    }
    render() {
        // return
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Ruas {this.state.ruas_name}
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <RuasSectionTable
                                {...this.props}
                                addRow={this.addRow}
                                handleChange={this.handleChange}
                                removeRow={this.removeRow}
                                deleteConfirm={this.deleteConfirm}
                                toogleShow={this.toogleShow}
                                list={this.state.list}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
                {this.state.modal && (
                    <RuasLaneModal
                        index={this.state.index}
                        item={this.state.item}
                        show={this.state.modal}
                        toogleShow={() => this.toogleShow()}
                        addRowLane={() => this.addRowLane()}
                        removeRowLane={this.removeRowLane}
                        handleChangeLane={this.handleChangeLane}
                    />
                )}
            </Content>
        );
    }
}

export default RuasSection;
