import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import ReportTable from "./table_odometer";
import ReportSearch from "./search";

class ReportPL01Odometer extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();

        // state
        this.state = {
            url: "",
            loading: false,
            list: [],
            date: Dates.getTodayDB()
        };
        // bind
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRuas = this.handleChangeRuas.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
        this.loadRef();
    }
    // load data
    loadRef() {
        GetPrivate(this, `referensi/ruas`).then(result => {
            this.setState(
                {
                    list_ruas: result.data.map(item => ({
                        value: item.ruas_id,
                        label: item.ruas_name
                    })),
                    ruas_id: result.data[0].ruas_id,
                    ruas_name: result.data[0].ruas_name
                },
                function() {
                    this.loadData();
                }
            );
        }).catch(error => {});
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const ruas = `${this.state.ruas_id}`
            if (ruas && ruas !== 'undefined') {
                GetPrivate(this, `laporan/pl01odometer/all${this.search}?ruas=${this.state.ruas_id}&date=${this.state.date}`).then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            }
        });
    }
    // handle change
    handleChangeDate(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChangeRuas (item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    downloadExcel () {
        const token = Session.getSession("session__jmto_admin");
        const token_key = Config.webKey;
        axios({
            url: `${Config.endPoint}/laporan/pl01odometer/export?ruas=${this.state.ruas_id}&date=${this.state.date}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: token,
                'X-API-KEY': token_key,
                'X-Apps': 'web'
            },
            crossdomain: true
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'laporan-pl01-odometer.xls')
            document.body.appendChild(link)
            link.click()
        })
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <ReportSearch
                        handleChange={this.handleChange}
                        handleChangeDate={this.handleChangeDate}
                        handleChangeRuas={this.handleChangeRuas}
                        date={this.state.date}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <a onClick={this.downloadExcel} className="btn btn-success">
                            <i className="fa fa-download mr-1"></i>Download Excel
                        </a>
                        <ReportTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default ReportPL01Odometer;
