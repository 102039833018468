import React, { Component } from "react";
import ReactPlayer from 'react-player'

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class ApprovalModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        //console.log(props);
        
        this.state = {
            loading: false,
            tanggal: props.detail ? props.detail.tanggal : "",
            waktu: props.detail ? props.detail.waktu : "",
            id: props.detail ? props.detail.id : "",
            detail_lokasi: props.detail_lokasi ? props.detail.detail_lokasi : "",
            jml_kendaraan: props.detail ? props.detail.jml_kendaraan : "",
            luka_ringan: props.detail ? props.detail.luka_ringan : "",
            luka_berat: props.detail ? props.detail.luka_berat : "",
            meninggal: props.detail ? props.detail.meninggal : "",
            penyebab_id: props.detail ? props.detail.penyebab_id : "",
            penyebab_nama: props.detail ? props.detail.penyebab_nama : "",
            uraian: props.detail ? props.detail.uraian : "",
            patroli_id: props.detail ? props.detail.patroli_id : "",
            patroli_nama: props.detail ? props.detail.patroli_nama : "",
            ambulance_kp_id: props.detail ? props.detail.ambulance_kp_id : "",
            ambulance_kp_nama: props.detail ? props.detail.ambulance_kp_nama : "",
            ambulance_kh_id: props.detail ? props.detail.ambulance_kh_id : "",
            ambulance_kh_nama: props.detail ? props.detail.ambulance_kh_nama : "",
            rescue_id: props.detail ? props.detail.rescue_id : "",
            rescue_nama: props.detail ? props.detail.rescue_nama : "",
            derek_id: props.detail ? props.detail.derek_id : "",
            derek_nama: props.detail ? props.detail.derek_nama : "",
            satgas_id: props.detail ? props.detail.satgas_id : "",
            satgas_nama: props.detail ? props.detail.satgas_nama : "",
            pjr_id: props.detail ? props.detail.pjr_id : "",
            pjr_nama: props.detail ? props.detail.pjr_nama : "",
            damkar_id: props.detail ? props.detail.damkar_id : "",
            damkar_nama: props.detail ? props.detail.damkar_nama : "",
            catatan_lain: props.detail ? props.detail.catatan_lain : "",
            barang_bukti: props.detail ? props.detail.barang_bukti : "",
            foto1: props.detail ? props.detail.foto1 : "",
            foto2: props.detail ? props.detail.foto2 : "",
            foto3: props.detail ? props.detail.foto3 : "",
            waktu_laka: props.detail ? props.detail.waktu_laka : "",
            no_laporan: props.detail ? props.detail.no_laporan : "",
            form_id: props.detail ? props.detail.form_id : "",
            shift_id: props.detail ? props.detail.shift_id : "",
            unit_id: props.detail ? props.detail.unit_id : "",
            unit_nama: props.detail ? props.detail.unit_nama : "",
            event_id: props.detail ? props.detail.event_id : "",
            jenis_laka_id: props.detail ? props.detail.jenis_laka_id : "",
            jenis_laka_nama: props.detail ? props.detail.jenis_laka_nama : "",
            km: props.detail ? props.detail.km : "",
            km_to: props.detail ? props.detail.km_to : "",
            id_lokasi: props.detail ? props.detail.id_lokasi : "",
            nama_lokasi: props.detail ? props.detail.nama_lokasi : "",
            status: props.detail ? props.detail.status : "",
            kendaraan_terlibat:props.detail ? props.detail.kendaraan : [],
            keterangan:"",
            penumpang:props.penumpang,
            error_field: {
                gardu_nama: null
            }
        };
        // bind
        this.onSubmit   = this.onSubmit.bind(this);     
    }
    

   
    onSubmit = evt => {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        
        that.setState({ loading: true }, function() {
            const params = {
                tanggal                 : this.state.tanggal,
                waktu                   : this.state.waktu,
                id                      : this.state.id,
                detail_lokasi           : this.state.detail_lokasi,
                jml_kendaraan           : this.state.jml_kendaraan,
                luka_ringan             : this.state.luka_ringan,
                luka_berat              : this.state.luka_berat,
                meninggal               : this.state.meninggal,
                penyebab_id             : this.state.penyebab_id,
                penyebab_nama           : this.state.penyebab_nama,
                uraian                  : this.state.uraian,
                patroli_id              : this.state.patroli_id,
                patroli_nama            : this.state.patroli_nama,
                ambulance_kp_id         : this.state.ambulance_kp_id,
                ambulance_kp_nama       : this.state.ambulance_kp_nama,
                ambulance_kh_id         : this.state.ambulance_kh_id,
                ambulance_kh_nama       : this.state.ambulance_kh_nama,
                rescue_id               : this.state.rescue_id,
                rescue_nama             : this.state.rescue_nama,
                derek_id                : this.state.derek_id,
                derek_nama              : this.state.derek_nama,
                satgas_id               : this.state.satgas_id,
                satgas_nama             : this.state.satgas_nama,
                pjr_id                  : this.state.pjr_id,
                pjr_nama                : this.state.pjr_nama,
                damkar_id               : this.state.damkar_id,
                damkar_nama             : this.state.damkar_nama,
                catatan_lain            : this.state.catatan_lain,
                barang_bukti            : this.state.barang_bukti,
                waktu_laka              : this.state.waktu_laka,
                jenis_laka_id           : this.state.jenis_laka_id,
                jenis_laka_nama         : this.state.jenis_laka_nama,
                km                      : this.state.km,
                km_to                   : this.state.km_to,
                id_lokasi               : this.state.id_lokasi,
                nama_lokasi             : this.state.nama_lokasi,
                kendaraan_terlibat      : this.state.kendaraan_terlibat,
                penumpang               : this.state.penumpang

            };
            
            
            PostPrivate(that, `transaksi/kecelakaan/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow('');
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });     
        });
    };
    
    render() {
        // return
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ display: !this.state.form_mode ? 'block' : 'none' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">No. Laporan</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.no_laporan}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Tanggal</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.tanggal} />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jam</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.waktu}  />
                                                    
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Lokasi KM</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.km}  />
                                                </div>
                                                <label className="col-sm-2 col-form-label">KM Hingga</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.km_to}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Jenis Lokasi</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.nama_lokasi}/>  
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Kategori Kejadian</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.jenis_laka_nama}  />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Deskripsi Singkat</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.uraian}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-4 col-form-label">Lokasi Lainnya</label>
                                                <div className="col-md-8">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.detail_lokasi}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Gambar Lampiran</label>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <div className="col-sm-4"><img src={this.state.foto1} className="img-fluid" alt="foto1" width="300" /></div>
                                                <div className="col-sm-4"><img src={this.state.foto2} className="img-fluid" alt="foto2" width="300" /></div>
                                                <div className="col-sm-4"><img src={this.state.foto3} className="img-fluid" alt="foto3" width="300" /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Dugaan Penyebab Kecelakaan</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.penyebab_nama}  />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jumlah Korban Meninggal</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.meninggal}  />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Jumlah Kendaraan Terlibat</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.jml_kendaraan}  />
                                                </div>
                                                <label className="col-sm-2 col-form-label">Jumlah Luka Berat</label>
                                                <div className="col-md-4">
                                                    <input type="text" readOnly disabled className="form-control" value={this.state.luka_berat}  />
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-sm-offset-6 col-form-label">Jumlah Luka Ringan</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.luka_ringan}/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Patroli</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.patroli_id}/>
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Derek</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.derek_id}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Ambulance</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.ambulance_kp_id}/>
                                                    
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong PJR</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.pjr_id}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Satgas</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.satgas_id}/>
                                                </div>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Rescue</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.rescue_id}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-2 col-form-label">Unit Penolong Damkar</label>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" value={this.state.damkar_id}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <label className="col-sm-8 col-form-label">Detail Kendaraan</label>
                                            </div>
                                            <div className="row" style={{marginBottom: '10px'}}>
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                              <th scope="col">No</th>
                                                              <th scope="col">Merk</th>
                                                              <th scope="col">Seri</th>
                                                              <th scope="col">Plat Nomor</th>
                                                              <th scope="col">Jml Penumpang</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="body_kendaraan">
                                                            {this.state.kendaraan_terlibat.map((row, i) => (
                                                                <tr key={i + 1}>
                                                                    <td scope="row">{i + 1}</td>
                                                                    <td><input type="text" className="form-control" value={row.merk}/></td>
                                                                    <td><input type="text" className="form-control" value={row.seri}/></td>
                                                                    <td><input type="text" className="form-control" value={row.plat_nomor}/></td>
                                                                    <td><input type="text" className="form-control" value={row.jml_penumpang}/></td>
                                                                </tr>
                                                            ))}
                                                            
                                                            
                                                            {this.state.kendaraan_terlibat.length === 0 &&
                                                                <tr><td className="text-center" colSpan="5">- Tidak ada data- </td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                            </div>
                            <div className="form-group row" style={{marginBottom: '10px'}}>
                                <label className="col-sm-8 col-form-label">Penumpang Kendaraan</label>
                            </div>
                            <div className="form-group row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <table className="table">
                                            <thead>
                                                <tr>
                                                  <th scope="col">No</th>
                                                  <th scope="col" style={{width:'250px'}}>Kendaraan</th>
                                                  <th scope="col">NIK/Nama</th>
                                                  <th scope="col">Jenis Kelamin/Usia</th>
                                                  <th scope="col">Kondisi/Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody id="body_penumpang">
                                                {this.state.penumpang.map((row, i) => (
                                                    <tr key={i + 1}>
                                                        <td scope="row">{i + 1}</td>
                                                        <td><input type="text" className="form-control" value={row.kendaraan_label}/></td>
                                                        <td><input type="text" className="form-control" value={row.nik}/><br/>
                                                            <input type="text" className="form-control" value={row.nama}/>
                                                            </td>
                                                        <td>
                                                            <input type="text" className="form-control" value={row.jenis_kelamin}/><br/>
                                                            <input type="text" className="form-control" value={row.usia}/>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" value={row.kondisi}/><br/>
                                                            <input type="text" className="form-control" value={row.alamat}/>
                                                         </td>
                                                    </tr>
                                                ))}


                                                {this.state.penumpang.length === 0 &&
                                                    <tr><td className="text-center" colSpan="5">- Tidak ada data- </td></tr>
                                                }
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                             <div className="form-group row">
                                <div className="col-sm-12">
                                    <div style={{float:'right'}}>
                                    <Button type="button" className="btn btn-warning" onClick={() => this.props.toogleShow('')}>Tutup</Button>&nbsp;
                                    {this.state.status == 0 &&
                                    <Button type="button" className="btn btn-success" onClick={() => this.props.terima(this.state.id)}>Terima</Button>
                                }&nbsp;
                                {this.state.status == 0 &&
                                    <Button type="button" className="btn btn-danger" onClick={() => this.props.showConfirm(this.state.id)}>Tolak</Button>
                                }
                        
                                    </div>
                                </div>
                            </div>
                         </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.props.toogleShow('')}
                    onSubmit={this.onSubmit}
                />
        )
    }
    
    
}

export default ApprovalModal;
