import React from "react";

// komponen
import { Input, InputSelectReact } from "../../components/AdminLTE";

const SearchLaka = props => {
    return (
        <div>

        <div className="row">
            <div className="col-xs-12">
            <div className="form-group row mb-0">
            <div className="col-xs-2">
                </div>
            <div className="col-xs-2">
            <p className="robotos" style={{color: '#0C0C0C'}}>
                        Periode :
                    </p>
                </div>
            <div>
            
            <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.start}
                        onChange={ev =>
                            props.handleChangeStart("start", ev.target.value)
                        }
                        parentStyle="col-md-2 p-0"
                    />
                     <Input
                        id="date"
                        type="date"
                        placeholder="Tanggal"
                        defaultValue={props.end}
                        onChange={ev =>
                            props.handleChange("end", ev.target.value)
                        }
                        parentStyle="col-md-2 p-0"
                    />
            </div>

                        
                </div>
            </div>
        </div>
  </div>
    );
};

export default SearchLaka;
