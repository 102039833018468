import SET_SESSION from './type.js';

const sessionReducer = (state = {}, action) => {
	switch (action.type) {
		case SET_SESSION:
			return action.data;
		default:
			return state;
	}
};

export default sessionReducer;
