import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputTextarea
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class GroupModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = props.mode == "edit" ? "Ubah Grups" : "Tambah Grups";
        // state
        this.state = {
            // field input
            group_id: props.item ? props.item.group_id : "",
            group_name: props.item ? props.item.group_name : "",
            group_desc: props.item ? props.item.group_desc : "",
            // error field
            error_field: {
                group_id: null,
                group_name: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                group_id: that.state.group_id,
                group_name: that.state.group_name,
                group_desc: that.state.group_desc
            };
            // paggil post api
            PostPrivate(that, `setting/group/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"group_id"}
                                className="col-sm-3 control-label mt-1"
                            >
                                ID Grup
                            </label>
                            {this.props.mode == "edit" ? (
                                <div className="col-sm-9">
                                    <p className="form-control-static">
                                        {this.state.group_id}
                                    </p>
                                </div>
                            ) : (
                                <div className="col-sm-3">
                                    <Input
                                        id={"group_id"}
                                        type="text"
                                        placeholder="ID Grup"
                                        has_error={
                                            this.state.error_field.group_id
                                        }
                                        defaultValue={this.state.group_id}
                                        onChange={ev =>
                                            this.handleChange(
                                                "group_id",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field.group_id
                                        }
                                        autoFocus={true}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"group_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Grup
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"group_name"}
                                    type="text"
                                    placeholder="Nama Grup"
                                    has_error={
                                        this.state.error_field.group_name
                                    }
                                    defaultValue={this.state.group_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "group_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.group_name
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"group_desc"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Grup
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"group_desc"}
                                    type="text"
                                    placeholder="Deskripsi Grup"
                                    rows={5}
                                    defaultValue={this.state.group_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "group_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default GroupModal;
