import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Judul",
        selector: "judul_berita",
        sortable: true,
        width: "27.5%"
    },
    {
        name: "Kategori",
        selector: "nama_kategori",
        sortable: true,
        width: "20%"
    },
    // {
    //     name: "Isi",
    //     selector: "isi_berita",
    //     sortable: true,
    //     width: "30%"
    // },
    {
        name: "Bujt",
        selector: "nama_bujt",
        sortable: true,
        width: "15%"
    },
    {
        name: "",
        selector: "action",
        width: "30.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <>
                <ButtonRole
                    role_url={`${props.location.pathname}/edit/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/edit/${item.id_berita}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-edit mr-1"></i> Update
                    </NavLink>
                </ButtonRole>
                <ButtonRole
                    role_url={`${props.location.pathname}/delete/:id`}
                    role_type="role_delete"
                >
                    <NavLink
                        to={`${props.location.pathname}/delete/${item.id_berita}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-trash mr-1"></i>Detail & Delete
                    </NavLink>
                </ButtonRole>
            </>
        );
        return item;
    });
};

const BeritaTable = props => {
    return (
        <TableBootstrap
            keyField={"berita"}
            empty="Belum ada berita, klik tombol + untuk menambahkan berita baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default BeritaTable;
