import React, { Component } from "react";
import GetPrivate from "../../../utils/Connection";
import Config from "../../../utils/Config";
import image_placeholder from "../../../resources/img/placeholder.png";
import { Button } from "../../../components/AdminLTE";


class Detail extends Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: false,
            _foto1: image_placeholder,
            _foto2: image_placeholder,
            _foto3: image_placeholder,
            id: null,
            no_kritik_saran: null,
            date: null,
            time: null,
            member: null,
            no_telp: null,
            ruas_tol: null,
            keterangan: null,
            email: null,
            jenis: null,
            lainnya: null,
            foto: null,
            foto_2: null,
            foto_3: null,
        }

        this.loadDetail = this.loadDetail.bind(this)
    }
    componentDidMount () {
        this.loadDetail()
    }
    loadDetail () {
        this.setState({ loading: true })
        GetPrivate(this, 'laporan/member/detail/' + this.props.id).then(result => {

            this.setState({
                id: result.data.id,
                no_kritik_saran: result.data.no_kritik_saran,
                tanggal: result.data.tanggal,
                date: result.data.date,
                time: result.data.time,
                member: result.data.member,
                no_telp: result.data.no_telp,
                ruas_tol: result.data.ruas_tol,
                keterangan: result.data.keterangan,
                email: result.data.email,
                jenis: result.data.jenis,
                lainnya: result.data.lainnya,
                foto: result.data.foto,
                foto_2: result.data.foto_2,
                foto_3: result.data.foto_3,
            })
            if (result.data.foto) {
                GetPrivate(this, 'laporan/member/image/' + result.data.foto).then(image => { this.setState({ _foto1: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto1: image_placeholder })
            }
            if (result.data.foto_2) {
                GetPrivate(this, 'laporan/member/image/' + result.data.foto_2).then(image => { this.setState({ _foto2: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto2: image_placeholder })
            }
            if (result.data.foto_3) {
                GetPrivate(this, 'laporan/member/image/' + result.data.foto_3).then(image => { this.setState({ _foto3: 'data:image/png;base64,' + image }) }).catch(error => { console.log(error) })
            } else {
                this.setState({ _foto3: image_placeholder })
            }

            if (result.data.jenis == '1') {
                this.setState({ jenis: 'Kritik' })
            }
            
            if (result.data.jenis == '2') {
                this.setState({ jenis: 'Saran' })
            }

            this.setState({ loading: false })
        }).catch(error => { this.setState({ loading: false }) })
    }
    
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">No. Kritik dan Saran</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.no_kritik_saran || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Tanggal</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.date || '-'}/>
                        </div>
                        <label className="col-sm-2 col-form-label">Waktu</label>
                        <div className="col-md-4">
                            <input type="text" readOnly disabled className="form-control" value={this.state.time || '-'}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                    <label className="col-sm-2 col-form-label">Nama</label>
                    <div className="col-md-4">
                        <input type="text" readOnly disabled className="form-control" value={this.state.member || '-'}/>
                    </div>
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-md-4">
                    <input type="text" readOnly disabled className="form-control" value={this.state.email || '-'}/>
                    </div>
                    </div>

                    <div className="row" style={{marginBottom: '10px'}}>
                    <label className="col-sm-2 col-form-label">No. Telepon</label>
                    <div className="col-md-4">
                        <input type="text" readOnly disabled className="form-control" value={this.state.no_telp || '-'}/>
                    </div>
                    <label className="col-sm-2 col-form-label">Jenis</label>
                    <div className="col-md-4">
                    <input type="text" readOnly disabled className="form-control" value={this.state.jenis || '-'}/>
                    </div>
                    </div>

                    <div className="row" style={{marginBottom: '10px'}}>
                    <label className="col-sm-2 col-form-label">Ruas</label>
                    <div className="col-md-4">
                        <input type="text" readOnly disabled className="form-control" value={this.state.ruas_tol || '-'}/>
                    </div>
                    <label className="col-sm-2 col-form-label">Detail Lokasi</label>
                    <div className="col-md-4">                
                        <input type="text" readOnly disabled className="form-control" value={this.state.lainnya || '-'}/>
                    </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                    <label className="col-sm-2 col-form-label">Keterangan</label>
                    <div className="col-md-4">
                    <textarea readOnly disabled className="form-control" value={this.state.keterangan || '-'}/>
                    </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <label className="col-sm-2 col-form-label">Gambar Lampiran</label>
                    </div>
                    <div className="row" style={{marginBottom: '40px'}}>
                        <div className="col-sm-4"><img src={this.state._foto1} className="img-fluid" alt="foto1" style={{maxWidth: '100%'}} /></div>
                        <div className="col-sm-4"><img src={this.state._foto2} className="img-fluid" alt="foto2" style={{maxWidth: '100%'}} /></div>
                        <div className="col-sm-4"><img src={this.state._foto3} className="img-fluid" alt="foto3" style={{maxWidth: '100%'}} /></div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Detail;
