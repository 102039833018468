import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonRole,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "ID Akses",
        selector: "role_id",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Akses",
        selector: "role_nm",
        sortable: true,
        width: "40%"
    },
    {
        name: "Grup Akses",
        selector: "group_name",
        sortable: true,
        width: "15%"
    },
    {
        name: "Landing",
        selector: "default_page",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "",
        selector: "action",
        width: "25%",
        center: true
    }
];

const data = props => {
    return props.list.map(item => {
        item["action"] = (
            <ButtonGroup>
                <ButtonRole
                    role_url={`${props.location.pathname}/detail/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/detail/${item.role_id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-bars"></i> Detail
                    </NavLink>
                </ButtonRole>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const RoleTable = props => {
    return (
        <TableBootstrap
            keyField={"role"}
            empty="Belum ada hak akses, klik tombol + untuk menambahkan hak akses baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RoleTable;
