import React from "react";

// komponen
import { Table, Button, Input } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        {
            width: "10%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={props.addRow}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            )
        },
        { width: "90%", className: "text-center", label: "Kode Unit" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={index}>
            <td className="text-center">
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={() =>
                        item.code_id != ""
                            ? props.deleteConfirm(item, index)
                            : props.removeRow(index)
                    }
                >
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
            <td>
                <Input
                    type="text"
                    placeholder="Kode Unit"
                    defaultValue={item.code_name}
                    onChange={ev => props.handleChange(index, ev.target.value)}
                />
            </td>
        </tr>
    ));
};

const UnitKodeTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada kode unit, silahkan klik tombol + untuk menambahkan kode unit"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default UnitKodeTable;
