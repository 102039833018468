import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import qs from 'qs';

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import { PostPrivate } from "../../../utils/Connection";
import Dates from "../../../utils/Dates";
import Config from "../../../utils/Config";
import Session from "../../../utils/Session";

// page
import KejadianTable from "./table";
import KejadianSearch from "./search";
import KejadianModal from "./modal";

class Kejadian extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;

        const dateObj = new Date();
        let start = qs.parse(this.search, { ignoreQueryPrefix: true }).start;
        start = start ? start : Dates.getTodayDB();

        let end = qs.parse(this.search, { ignoreQueryPrefix: true }).end;
        end = end ? end : Dates.getTodayDB();

        let bujt = qs.parse(this.search, { ignoreQueryPrefix: true }).bujt;
        bujt = bujt ? bujt : null;

        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            list: [],
            start: start,
            end: end,
            title:'Laporan Kejadian',
            detail:null,
            kategori:[],
            jenis:[],
            gerbang:[],
            unit:[]
        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.loadRef();
    }
    loadRef() {
        // LOAD ALL REFERENCE HERE
        this.setState({ loading: true }, function() {
            //params
            const params = {
                id: this.state.ruas_id
            };
            // paggil post api
            PostPrivate(this, 'transaksi/kejadian/kategori_kejadian', params)
                .then(result => {
                    this.setState(
                        {kategori: result.data},
                        function() {
                            PostPrivate(this, 'transaksi/kejadian/jenis_gangguan', params)
                                .then(result => {
                                    this.setState(
                                        {jenis: result.data},
                                        function() {
                                            PostPrivate(this, 'transaksi/kejadian/gerbang', params)
                                                .then(result => {
                                                    this.setState(
                                                        {gerbang: result.data},
                                                        function() {
                                                            PostPrivate(this, 'transaksi/kejadian/unit', params)
                                                                .then(result => {
                                                                    this.setState(
                                                                        {unit: result.data}
                                                                    );
                                                                })
                                                                .catch(error => {
                                                                    if (typeof error == "object") {
                                                                        this.setState({
                                                                            error_field: error
                                                                        });
                                                                    }
                                                                    // hapus loading
                                                                    this.setState({
                                                                        loading: false
                                                                    });
                                                                });
                                                        }
                                                    );
                                                })
                                                .catch(error => {
                                                    if (typeof error == "object") {
                                                        this.setState({
                                                            error_field: error
                                                        });
                                                    }
                                                    // hapus loading
                                                    this.setState({
                                                        loading: false
                                                    });
                                                });
                                        }
                                    );
                                })
                                .catch(error => {
                                    if (typeof error == "object") {
                                        this.setState({
                                            error_field: error
                                        });
                                    }
                                    // hapus loading
                                    this.setState({
                                        loading: false
                                    });
                                });
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        this.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    this.setState({
                        loading: false
                    });
                });
        });
        
        
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const params = {
                start   : this.state.start,
                end     : this.state.end
            };

            PostPrivate(this, 'transaksi/kejadian',params).then(result => {
                this.setState({
                    loading : false,
                    list    : result.data
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
            });
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(id) {
      
        if (id == null || id=='') {
            this.setState({ modal: !this.state.modal});
        } else {
            // get detail post
            // panggil get api
            
            this.setState({ loading: true }, function() {
                const params = {
                    id : id
                };

                PostPrivate(this, 'transaksi/kejadian/detail',params).then(result => {
                    
                    this.setState({
                        loading   : false,
                        detail    : result.data,
                        modal     : !this.state.modal
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                });
            });
        }
        
        
    }
    
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <KejadianSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        start={this.state.start}
                        end={this.state.end}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <KejadianTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <KejadianModal
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        id={this.state.id}
                        detail={this.state.detail}
                        handleChange={this.handleChange}
                        kategori={this.state.kategori}
                        jenis={this.state.jenis}
                        gerbang={this.state.gerbang}
                        unit={this.state.unit}  
                    />
                )}
            </Content>
        );
    }
}

export default Kejadian;
