import React, { Component } from "react";
import img_cctv from "../../resources/img/logo_cctv.png";

class MarkerCctv extends Component {
    constructor (props) {
        super(props)

        this.state = {
            clicked: false,
            hovered: false
        }
        
        this.onClick = this.onClick.bind(this)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
    }
    
    onClick () {
        this.setState({ clicked: !this.state.clicked })
    }
    onMouseEnter () {
        this.setState({ hovered: true })
    }
    onMouseLeave () {
        this.setState({ hovered: false })
    }
    
    render() {
        return (
            <>
            <div onClick={ this.props.showDetail } onMouseEnter={ this.onMouseEnter } onMouseLeave={ this.onMouseLeave }>
            <div style={{display: this.state.clicked || this.state.hovered ? 'block' : 'none'}}>
                    <table  style={{backgroundColor:"#FFFFFF",padding:"4px",borderRadius:"16px"}}>
                        <tr>
                            <td style={{padding:"4px", fontSize:'22px'}} nowrap="nowrap">{this.props.nama}</td>
                        </tr>
                        <tr>
                            <td style={{padding:"4px", fontSize:'22px'}} nowrap="nowrap">{this.props.nama_bujt}</td>
                        </tr>

                        <tr>
                        </tr>
                    </table>
                </div>
                <div> <img src={img_cctv} style={{width:'20px', display: !this.state.hovered ? 'block' : 'none'}} /></div>
            </div>
            </>
        )
    }
    
}

export default MarkerCctv;