import React, { Component } from "react";

// komponen
import { Content, Button, ButtonBack } from "../../../components/AdminLTE";
import { SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// page
import TableDetail from "./table_detail";
import RolePortalOptions from "./options_portal";

class RoleDetail extends Component {
    constructor(props) {
        super(props);
        // role id
        this.role_id = props.match.params.id;
        // state
        this.state = {
            // field
            portal_id: "",
            role_nm: "",
            // array
            list_portal: [],
            list_menu: [],
            // column
            column_create: false,
            column_read: false,
            column_update: false,
            column_delete: false,
            // all check
            allcheck: false,
            // loading
            loading: false
        };
        // bind
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeRoles = this.onChangeRoles.bind(this);
        this.onAllChecked = this.onAllChecked.bind(this);
        this.onColumnChecked = this.onColumnChecked.bind(this);
    }
    componentDidMount() {
        // get list portal
        this.loadData();
    }
    // loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `setting/portal`)
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list_portal: result.data,
                            portal_id: this.state.portal_id
                                ? this.state.portal_id
                                : result.data[0].portal_id || ""
                        },
                        function() {
                            this.loadMenu();
                        }
                    );
                })
                .catch(error => {});
        });
    }
    // loadMenu
    loadMenu() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(
                this,
                `setting/role/detail/${this.role_id}/${this.state.portal_id}`
            )
                .then(result => {
                    this.list_temp = [];
                    this.formatMenu(result.data.list_menu);
                    // set state
                    this.setState({
                        loading: false,
                        role_nm: result.data.role_nm,
                        list_menu: this.list_temp
                    });
                })
                .catch(error => {});
        });
    }
    // formatMenu
    formatMenu(list) {
        list.map(item => {
            item["rows"] = false;
            this.list_temp.push(item);
            console.log(this.list_temp);
            // jika punya child
            if (item.list_child.length > 0) {
                this.formatMenu(item.list_child);
            }
        });
    }
    // onChange
    onChange(event) {
        const index = event.target.selectedIndex;
        // set state
        this.setState(
            {
                portal_id: this.state.list_portal[index].portal_id || ""
            },
            function() {
                this.loadMenu();
            }
        );
    }
    //onChangeRoles
    onChangeRoles(index, name) {
        // state sekarang
        let { list_menu } = this.state;
        if (name == "rows") {
            const rows = Number(!list_menu[index]["rows"]);
            list_menu[index]["rows"] = rows;
            list_menu[index]["role_create"] = rows;
            list_menu[index]["role_read"] = rows;
            list_menu[index]["role_update"] = rows;
            list_menu[index]["role_delete"] = rows;
        } else {
            list_menu[index][name] = Number(!list_menu[index][name]);
        }
        // set state
        this.setState({ list_menu: list_menu });
    }
    //onColumnChecked
    onColumnChecked(name) {
        // state sekarang
        let { list_menu } = this.state;
        const columnCheck = Number(!this.state[`column_${name}`]);
        list_menu.map(item => {
            item[`role_${name}`] = columnCheck;
            return item;
        });
        // set state
        this.setState({
            list_menu: list_menu,
            [`column_${name}`]: columnCheck
        });
    }
    //onAllChecked
    onAllChecked() {
        // state sekarang
        let { list_menu } = this.state;
        const allcheck = Number(!this.state.allcheck);
        list_menu.map(item => {
            item["rows"] = allcheck;
            item["role_create"] = allcheck;
            item["role_read"] = allcheck;
            item["role_update"] = allcheck;
            item["role_delete"] = allcheck;
            return item;
        });
        // set state
        this.setState({ list_menu: list_menu, allcheck: allcheck });
    }
    //onSubmit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                role_id: that.role_id,
                portal_id: that.state.portal_id,
                menu: JSON.stringify(that.state.list_menu)
            };
            // paggil post api
            PostPrivate(that, `setting/role/update-role`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // load data
                            that.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Hak Akses {this.state.role_nm}
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="box-body">
                            <div className="form-group row">
                                <label
                                    className="control-label col-md-offset-2 col-md-2 mt-1"
                                    htmlFor={"portal_name"}
                                >
                                    Daftar Aplikasi
                                </label>
                                <div className="form-group col-md-5">
                                    <select
                                        id={"portal_name"}
                                        className="form-control"
                                        defaultValue={this.state.portal_id}
                                        onChange={this.onChange}
                                    >
                                        <RolePortalOptions
                                            list={this.state.list_portal}
                                        />
                                    </select>
                                </div>
                            </div>
                            <TableDetail
                                allcheck={this.state.allcheck}
                                column_create={this.state.column_create}
                                column_read={this.state.column_read}
                                column_update={this.state.column_update}
                                column_delete={this.state.column_delete}
                                onColumnChecked={this.onColumnChecked}
                                onAllChecked={this.onAllChecked}
                                onChangeRoles={this.onChangeRoles}
                                list={this.state.list_menu}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className="box-footer text-right">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                loading={this.state.loading}
                            >
                                Simpan
                            </Button>
                        </div>
                    </form>
                </div>
            </Content>
        );
    }
}
export default RoleDetail;
