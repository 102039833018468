import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputTextarea,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

class JabatanModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode === "edit"
                ? "Ubah Referensi Jabatan"
                : "Tambah Referensi Jabatan";
        // state
        this.state = {
            // field input
            jabatan_id: props.item ? props.item.jabatan_id : "",
            jabatan_name: props.item ? props.item.jabatan_name : "",
            jabatan_desc: props.item ? props.item.jabatan_desc : "",
            jabatan_level: props.item ? props.item.jabatan_level : "",
            role_id: props.item ? props.item.role_id : "",
            role_nm: props.item ? props.item.role_nm : "",
            list_role: [],
            // error field
            error_field: {
                jabatan_name: null,
                jabatan_level: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // component did mount
    componentDidMount() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/jabatan/role")
                .then(result => {
                    this.setState({
                        loading: false,
                        list_role: result.data.map(item => ({
                            label: item.role_nm,
                            value: item.role_id
                        }))
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                jabatan_id: that.state.jabatan_id,
                jabatan_name: that.state.jabatan_name,
                jabatan_desc: that.state.jabatan_desc,
                jabatan_level: that.state.jabatan_level,
                role_id: that.state.role_id
            };
            // paggil post api
            PostPrivate(that, `referensi/jabatan/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error === "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"jabatan_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Jabatan
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"jabatan_name"}
                                    type="text"
                                    placeholder="Nama Jabatan"
                                    has_error={
                                        this.state.error_field.jabatan_name
                                    }
                                    defaultValue={this.state.jabatan_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "jabatan_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.jabatan_name
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"jabatan_desc"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Deskripsi Jabatan
                            </label>
                            <div className="col-sm-9">
                                <InputTextarea
                                    id={"jabatan_desc"}
                                    type="text"
                                    placeholder="Deskripsi Jabatan"
                                    rows={5}
                                    defaultValue={this.state.jabatan_desc}
                                    onChange={ev =>
                                        this.handleChange(
                                            "jabatan_desc",
                                            ev.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"jabatan_level"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Level Jabatan
                            </label>
                            <div className="col-sm-3">
                                <Input
                                    id={"jabatan_level"}
                                    type="number"
                                    placeholder="Level"
                                    has_error={
                                        this.state.error_field.jabatan_level
                                    }
                                    defaultValue={this.state.jabatan_level}
                                    onChange={ev =>
                                        this.handleChange(
                                            "jabatan_level",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.jabatan_level
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"group_id"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Hak Akses Jabatan
                            </label>
                            <div className="col-sm-7">
                                <InputSelectReact
                                    id={"role_id"}
                                    has_error={this.state.error_field.role_id}
                                    defaultValue={{
                                        label: this.state.role_nm,
                                        value: this.state.role_id
                                    }}
                                    onChange={item =>
                                        this.handleChange("role_id", item.value)
                                    }
                                    error_field={this.state.error_field.role_id}
                                    options={this.state.list_role}
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default JabatanModal;
