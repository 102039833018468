import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Nama Pegawai",
        selector: "pegawai_name",
        sortable: true,
        width: "27.5%"
    },
    {
        name: "Nik",
        selector: "pegawai_nik",
        sortable: true,
        width: "29%"
    },
    {
        name: "Ruas Tol",
        selector: "pegawai_ruas",
        sortable: true,
        width: "15%"
    },
    {
        name: "Jabatan",
        selector: "pegawai_jabatan",
        sortable: true,
        width: "15%"
    },
    {
        name: "Unit",
        selector: "pegawai_unit",
        sortable: true,
        width: "15%"
    },
    {
        name: "Status",
        selector: "pegawai_st_custom",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "",
        selector: "action",
        width: "12.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["pegawai_st_custom"] =
            item.pegawai_st == "1" ? (
                <span className="label label-success">Aktif</span>
            ) : (
                <span className="label label-warning">Suspend</span>
            );
        item["action"] = (
            <>
                <ButtonRole
                    role_url={`${props.location.pathname}/edit/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/edit/${item.pegawai_id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-edit mr-1"></i> Update
                    </NavLink>
                </ButtonRole>
            </>
        );
        return item;
    });
};

const PegawaiTable = props => {
    return (
        <TableBootstrap
            keyField={"pegawai"}
            empty="Belum ada pegawai, klik tombol + untuk menambahkan pegawai baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default PegawaiTable;
