import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Kendaraan / Unit",
        selector: "pegawai_unit",
        sortable: true,
        width: "15%"
    },
    {
        name: "Nama Petugas",
        selector: "pegawai_name",
        sortable: true,
        width: "50%"
    },
    {
        name: "Check In",
        selector: "check_in",
        sortable: true,
        width: "15%",
        center: true
    },
    {
        name: "Check Out",
        selector: "check_out",
        sortable: true,
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        return item;
    });
};

const RealisasiTable = props => {
    return (
        <TableBootstrap
            keyField={"user"}
            empty="Belum ada riwayat"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RealisasiTable;
