import React, { Component } from "react";

// komponen
import { Content, ButtonBack, Button } from "../../../components/AdminLTE";
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// default image
import img_default from "../../../resources/img/img-default.jpg";

class FasilitasDelete extends Component {
    constructor(props) {
        super(props);
        // user id
        this.id_fasilitas = props.match.params.id;
        // state
        this.state = {
            // field
            id_fasilitas: "",
            nama: "",
            nama_deskripsi: "",
            lattitude: "",
            longitude: "",
            deskripsi: "",
            gambar_lokasi: null,
            // loading
            loading: false,
            gambar64:"",
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `fasilitas/fasilitas/detail/${this.id_fasilitas}`)
                .then((result) => {
                    this.setState({
                        loading: false,
                        id_fasilitas: result.data.id_fasilitas,
                        nama_deskripsi: result.data.nama_deskripsi,
                        nama: result.data.nama,
                        lattitude: result.data.lattitude,
                        longitude: result.data.longitude,
                        deskripsi: result.data.deskripsi,
                        gambar_lokasi: result.data.gambar_lokasi,
                        gambar64: result.data.file64,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm() {
        SwalConfirm(
            `Hapus Fasilitas [${this.state.nama_deskripsi}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess()
        );
    }
    // deleteprocess
    deleteProcess() {
        // panggil get api
        this.setState({ loading: true }, function () {
            GetPrivate(this, `fasilitas/fasilitas/delete/${this.id_fasilitas}`)
                .then((result) => {
                    // reload page
                    this.props.history.goBack();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Hapus Fasilitas</h3>
                        <div className="box-tools pull-right">
                            <ButtonBack />
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3 text-center">
                            <img
                                    src={
                                        this.state.gambar64
                                            ? this.state.gambar64
                                            : img_default
                                    }
                                    alt="Profil"
                                    style={{width:"100%"}}
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label
                                        htmlFor={"id_fasilitas"}
                                        className="control-label"
                                    >
                                        ID Fasilitas
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.id_fasilitas}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"nama_deskripsi"}
                                        className="control-label"
                                    >
                                        Nama Deskripsi
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.nama_deskripsi}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"nama"}
                                        className="control-label"
                                    >
                                        Kategori
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.nama}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"lattitude"}
                                        className="control-label"
                                    >
                                        Lattitude
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.lattitude}
                                    </p>
                                </div>
                               <div className="form-group">
                                    <label
                                        htmlFor={"longitude"}
                                        className="control-label"
                                    >
                                        Longitude
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.longitude}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={"deskripsi"}
                                        className="control-label"
                                    >
                                        Deskripsi
                                    </label>
                                    <p className="form-control-static">
                                        {this.state.deskripsi}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer text-right">
                        <Button
                            type="button"
                            className="btn btn-danger"
                            loading={this.state.loading}
                            onClick={this.deleteConfirm}
                        >
                            <i className="fa fa-trash mr-1"></i> Hapus Fasilitas
                        </Button>
                    </div>
                </div>
            </Content>
        );
    }
}

export default FasilitasDelete;
