import React, { useState } from "react";
import classNames from "classnames";

const Callout = props => {
    // hooks
    const [state, setState] = useState(true);
    // classname
    let icon = props.icon;
    let type = "callout-" + (props.type ? props.type : "danger");
    let callouts = classNames("callout", type);
    let icons = classNames("icon", icon);
    // return
    return state ? (
        <div className={callouts}>
            <h4>
                <i className={icons}></i> {props.title}
            </h4>
            {props.content}
        </div>
    ) : (
        <></>
    );
};

export default Callout;
