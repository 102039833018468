import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    Button,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "No Voice of Customer",
        selector: "no_kritik_saran",
        sortable: true,
        width: "15.5%"
    },
    {
        name: "Waktu",
        selector: "waktu",
        sortable: true,
        width: "17.5%"
    },
    {
        name: "Lokasi",
        selector: "ruas_tol",
        sortable: true,
        width: "15%",
        center: true
    },
    {
        name: "Keterangan",
        selector: "keterangan",
        sortable: true,
        width: "20.5%"
    },
    {
        name: "Action",
        selector: "action",
        width: "17.5%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        if (item.status != '0') {
            item["action"] = (
                <Button className="btn btn-default"
                    onClick={() => props.toogleShow("edit", item)} >
                        <i className="fa fa-play mr-1"></i> 
                        Kirim
                </Button>
            );
        }
        
        if (item.status == '0') {
            item["action"] = (
                <Button className="btn btn-primary"
                    onClick={() => props.toogleShow("edit", item)} >
                        Sudah Diproses
                </Button>
            );
        }
        return item;
    });
};

const RefTable = props => {
    return (
        <TableBootstrap
            keyField={props.ref_title}
            empty={`Belum ada ${props.ref_title.toLowerCase()}`}
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default RefTable;
