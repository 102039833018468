import React from "react";
import moment from "moment";

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";
import Dates from "../../../utils/Dates";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "No Kritik Saran",
        selector: "no_kritik_saran",
        sortable: true,
        width: "20%",
    },
    {
        name: "Keterangan",
        selector: "keterangan",
        sortable: true,
        width: "30%",
    },
    {
        name: "Tanggal",
        selector: "waktu",
        sortable: true,
        width: "15%",
    },
    {
        name: "Nama",
        selector: "member",
        width: "20%",
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item.no = index + 1;


        item.detail = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.id)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const LakalantasTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default LakalantasTable;
