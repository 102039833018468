import React from "react";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "ID Aplikasi",
        selector: "portal_id",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Aplikasi",
        selector: "portal_nm",
        sortable: true,
        width: "20%"
    },
    {
        name: "Judul Situs",
        selector: "site_title",
        sortable: true,
        width: "20%"
    },
    {
        name: "Deskripsi Situs",
        selector: "site_desc",
        width: "35%"
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map(item => {
        item["action"] = (
            <ButtonGroup>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const PortalTable = props => {
    return (
        <TableBootstrap
            keyField={"portal"}
            empty="Belum ada aplikasi, klik tombol + untuk menambahkan aplikasi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default PortalTable;
