import React from "react";

import "../../resources/theme/css/loading.css";

// resources
import logo_jmto from "../../resources/img/logo-jmto.png";

const LoadingBig = () => {
    return (
        <div className="text-center text-muted logo-loading">
            <img src={logo_jmto} alt="loading" />
        </div>
    );
};

export default LoadingBig;
