import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    ButtonEdit,
    ButtonDelete,
    ButtonRole,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "Nama Unit",
        selector: "report_unit",
        sortable: true,
        width: "70%"
    },
    {
        name: "Status",
        selector: "report_status",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] =
            item.report_status == "pending" ? (
                <ButtonGroup>
                    <ButtonRole
                        role_url={`${props.location.pathname}/detail/:id`}
                        role_type="role_update"
                    >
                        <NavLink
                            to={`${props.location.pathname}/detail/${item.report_id}`}
                            className="btn btn-sm btn-default"
                        >
                            <i className="fa fa-edit"></i> Approve / Reject
                        </NavLink>
                    </ButtonRole>
                </ButtonGroup>
            ) : null;
        return item;
    });
};

const ApprovalTable = props => {
    return (
        <TableBootstrap
            keyField={"role"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default ApprovalTable;
