import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputTextarea
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class ShiftModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Shift"
                : "Tambah Referensi Shift";
        // state
        this.state = {
            // field input
            shift_id: props.item ? props.item.shift_id : "",
            shift_name: props.item ? props.item.shift_name : "",
            shift_start: props.item ? props.item.shift_start : "",
            shift_finish: props.item ? props.item.shift_finish : "",
            shift_color: props.item ? props.item.shift_color : "",
            // error field
            error_field: {
                shift_name: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                shift_id: that.state.shift_id,
                shift_name: that.state.shift_name,
                shift_start: that.state.shift_start,
                shift_finish: that.state.shift_finish,
                shift_color: that.state.shift_color
            };
            // paggil post api
            PostPrivate(that, `referensi/shift/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"shift_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Shift
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"shift_name"}
                                    type="text"
                                    placeholder="Nama Shift"
                                    has_error={
                                        this.state.error_field.shift_name
                                    }
                                    defaultValue={this.state.shift_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "shift_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.shift_name
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"shift_start"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Jam Mulai
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"shift_start"}
                                    type="text"
                                    placeholder="Jam Mulai"
                                    has_error={
                                        this.state.error_field.shift_start
                                    }
                                    defaultValue={this.state.shift_start}
                                    onChange={ev =>
                                        this.handleChange(
                                            "shift_start",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.shift_start
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"shift_finish"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Jam Selesai
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"shift_finish"}
                                    type="text"
                                    placeholder="Jam Selesai"
                                    has_error={
                                        this.state.error_field.shift_finish
                                    }
                                    defaultValue={this.state.shift_finish}
                                    onChange={ev =>
                                        this.handleChange(
                                            "shift_finish",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.shift_finish
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor={"shift_color"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Warna
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"shift_finish"}
                                    type="text"
                                    placeholder="Kode Warna"
                                    has_error={
                                        this.state.error_field.shift_color
                                    }
                                    defaultValue={this.state.shift_color}
                                    onChange={ev =>
                                        this.handleChange(
                                            "shift_color",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.shift_color
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default ShiftModal;
