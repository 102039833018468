import React from "react";
import { Route, Switch } from "react-router-dom";

//pages
import Error404 from "./pages/error404";
import DashboardDetail from "./pages/dashboard/detail";
import DashboardProfile from "./pages/dashboard/profile";
import Pegawai from "./pages/pegawai/pegawai/index";
import PegawaiAdd from "./pages/pegawai/pegawai/add";
import PegawaiEdit from "./pages/pegawai/pegawai/edit";
import PegawaiDelete from "./pages/pegawai/pegawai/delete";
import PegawaiShift from "./pages/pegawai/shift/index";
import PegawaiRealisasi from "./pages/pegawai/realisasi/index";
import ReferensiForm from "./pages/referensi/form/index";
import ReferensiFormUnit from "./pages/referensi/form/unit";
import ReferensiFormDetail from "./pages/referensi/form/detail";
import ReferensiUnit from "./pages/referensi/unit/index";
import ReferensiJabatan from "./pages/referensi/jabatan/index";
import ReferensiKategoriSarpras from "./pages/referensi/kategorisarpras/index";
import ReferensiStatus from "./pages/referensi/status/index";
import ReferensiSarpras from "./pages/referensi/sarpras/index";
import ReferensiKondisiBarang from "./pages/referensi/kondisibarang/index";
import ReferensiJenisKelamin from "./pages/referensi/jeniskelamin/index";
import ReferensiKewarganegaraan from "./pages/referensi/kewarganegaraan/index";
import ReferensiKondisiBadan from "./pages/referensi/kondisibadan/index";
import ReferensiMember from "./pages/referensi/member/index";
import ReferensiKondisiPenumpang from "./pages/referensi/kondisipenumpang/index";
import ReferensiSabukPengaman from "./pages/referensi/sabukpengaman/index";
import ReferensiTipePengendara from "./pages/referensi/tipepengendara/index";
import ReferensiTindakLanjut from "./pages/referensi/tindaklanjut/index";
import ReferensiSarprasSatuan from "./pages/referensi/satuan/index";
import ReferensiKendaraan from "./pages/referensi/kendaraan/index";
import ReferensiBody from "./pages/referensi/body/index";
import ReferensiRambu from "./pages/referensi/rambu/index";
import ReferensiKegiatan from "./pages/referensi/kegiatan/index";
import ReferensiHoliday from "./pages/referensi/holiday/index";
import ReferensiRuas from "./pages/referensi/ruas/index";
import ReferensiRuasSection from "./pages/referensi/ruas/index_section";
import ReferensiWeather from "./pages/referensi/weather/index";
import ReferensiTraffic from "./pages/referensi/traffic/index";
import ReferensiReport from "./pages/referensi/report/index";
import ReferensiKategoriKegiatan from "./pages/referensi/kategorikegiatan/index";
import ReferensiKategoriPengaduan from "./pages/referensi/kategoripengaduan/index";
import ReferensiGolonganKendaraan from "./pages/referensi/golongankendaraan/index";
// referensi lakalantas
import ReferensiNomorKasus from "./pages/referensi/lakalantas/nomorkasus/index";
import ReferensiAlinemenJalan from "./pages/referensi/lakalantas/alinemenjalan/index";
import ReferensiAngin from "./pages/referensi/lakalantas/angin/index";
import ReferensiDiagnosa from "./pages/referensi/lakalantas/diagnosa/index";
import ReferensiHasilPertolongan from "./pages/referensi/lakalantas/hasilpertolongan/index";
import ReferensiJarakPenglihatan from "./pages/referensi/lakalantas/jarakpenglihatan/index";
import ReferensiJenisKecelakaan from "./pages/referensi/lakalantas/jeniskecelakaan/index";
import ReferensiJenisLajurArah from "./pages/referensi/lakalantas/jenislajurarah/index";
import ReferensiJenisMedian from "./pages/referensi/lakalantas/jenismedian/index";
import ReferensiJenisPertolongan from "./pages/referensi/lakalantas/jenispertolongan/index";
import ReferensiKategoriKecelakaan from "./pages/referensi/lakalantas/kategorikecelakaan/index";
import ReferensiKategoriKorbanPedestrian from "./pages/referensi/lakalantas/kategorikorbanpedestrian/index";
import ReferensiKeadaanKorban from "./pages/referensi/lakalantas/keadaankorban/index";
import ReferensiKondisiPermukaan from "./pages/referensi/lakalantas/kondisipermukaan/index";
import ReferensiLebarJalur from "./pages/referensi/lakalantas/lebarjalur/index";
import ReferensiPekerjaan from "./pages/referensi/pekerjaan/index";
import ReferensiPekerjaanJalan from "./pages/referensi/lakalantas/pekerjaanjalan/index";
import ReferensiPendidikan from "./pages/referensi/lakalantas/pendidikan/index";
import ReferensiPenyebabKecelakaan from "./pages/referensi/lakalantas/penyebabkecelakaan/index";
import ReferensiPosisiPenumpang from "./pages/referensi/posisipenumpang/index";
import ReferensiPosisiTabrakan from "./pages/referensi/lakalantas/posisitabrakan/index";
import ReferensiRambuBatasKecepatan from "./pages/referensi/lakalantas/rambubataskecepatan/index";
import ReferensiTabrakLari from "./pages/referensi/lakalantas/tabraklari/index";
import ReferensiTempatKorbanPedestrian from "./pages/referensi/lakalantas/tempatkorbanpedestrian/index";
import ReferensiTempatLuka from "./pages/referensi/tempatluka/index";
import ReferensiTipeKorban from "./pages/referensi/tipekorban/index";
import ReferensiDasarGantiRugi from "./pages/referensi/dasargantirugi/index";
import ReferensiSumberInformasi from "./pages/referensi/lakalantas/sumberinformasi/index";
// base
import SettingGroups from "./pages/setting/groups/index";
import SettingPortal from "./pages/setting/portal/index";
import SettingMenu from "./pages/setting/menu/index";
import SettingMenuDetail from "./pages/setting/menu/detail";
import SettingRole from "./pages/setting/role/index";
import SettingRoleDetail from "./pages/setting/role/detail";
import SettingUser from "./pages/setting/user/index";
import SettingUserAdd from "./pages/setting/user/add";
import SettingUserEdit from "./pages/setting/user/edit";
import SettingUserDelete from "./pages/setting/user/delete";
import SettingLang from "./pages/setting/lang/index";
// pik
import PikShift from "./pages/pik/shift/index";
import PikComplaint from "./pages/pik/complaint/index";
import PikForm from "./pages/pik/form/index";
import PikPresensi from "./pages/pik/presensi/index";
import PikPjr from "./pages/pik/pjr/index";
import PikDailyReport from "./pages/pik/dailyreport/index";
import PikSendReport from "./pages/pik/send/index";
import PikReceiveReport from "./pages/pik/receive/index";
// spv
import SpvApproval from "./pages/spv/approval/index";
import SpvApprovalDetail from "./pages/spv/approval/detail";
// report
import ReportLakalantas from "./pages/report/lakalantas/index";
import ReportLakalantasMd from "./pages/report/lakalantas/meninggal";
import ReportLakalantasLb from "./pages/report/lakalantas/berat";
import ReportLakalantasLr from "./pages/report/lakalantas/ringan";
import ReportPL01Odometer from "./pages/report/pl01/odometer";
import ReportPL01Sarpras from "./pages/report/pl01/sarpras";
import ReportPL02 from "./pages/report/pl02/index";
import ReportGantiRugi from "./pages/report/ganti_rugi/index";
import ReportCustomer from "./pages/report/customer/index";
import ReportKpi from "./pages/report/kpi/index";
import ReportGantiRugiRekap from "./pages/report/rekap_ganti_rugi/index";
import ReportMovingUnit from "./pages/report/moving_unit/index";
import ReportHandlingResponse from "./pages/report/handling_response/index";
import ReportHandlingResponseRespon from "./pages/report/handling_response/respon";
import ReportHandlingResponsePenanganan from "./pages/report/handling_response/penanganan";
import ReportHandlingResponseSelesai from "./pages/report/handling_response/selesai";

import TransactionGantiRugi from "./pages/transaction/ganti_rugi";

import EventCalendar from "./pages/event/event/index";
import EventCalendarAdd from "./pages/event/event/add";
import EventCalendarEdit from "./pages/event/event/edit";
import EventCalendarDelete from "./pages/event/event/delete";
import EventCamera from "./pages/event/camera/index";
import EventAdd from "./pages/event/camera/add";
import EventCameraNotification from "./pages/event/camera/notification";

import Berita from "./pages/berita/berita/index";
import BeritaAdd from "./pages/berita/berita/add";
import BeritaEdit from "./pages/berita/berita/edit";
import BeritaDelete from "./pages/berita/berita/delete";
import BeritaKategori from "./pages/berita/kategoriberita/index";

import Fasilitas from "./pages/fasilitas/fasilitas/index";
import FasilitasAdd from "./pages/fasilitas/fasilitas/add";
import FasilitasEdit from "./pages/fasilitas/fasilitas/edit";
import FasilitasDelete from "./pages/fasilitas/fasilitas/delete";

import FasilitasKategori from "./pages/fasilitas/kategorifasilitas/index";
import CallCenter from "./pages/call/callcenter/index";

import Gerbang from "./pages/referensi/gerbang/index";
import GerbangAdd from "./pages/referensi/gerbang/add";
import GerbangEdit from "./pages/referensi/gerbang/edit";
import GerbangDelete from "./pages/referensi/gerbang/delete";

import Gardu from "./pages/referensi/gardu/index";
import GarduAdd from "./pages/referensi/gardu/add";
import GarduEdit from "./pages/referensi/gardu/edit";
import GarduDelete from "./pages/referensi/gardu/delete";

import KategoriKejadian from "./pages/referensi/kategorikejadian/index";
import JenisGangguan from "./pages/referensi/jenisgangguan/index";
import Kamera from "./pages/referensi/kamera/index";

import Tarif from "./pages/referensi/tarif/index";
import Ganti from "./pages/referensi/ganti/index";
import Putar from "./pages/referensi/putar/index";

import Cctv from "./pages/cctv/index";
import Management from "./pages/management/index";
import Shift from "./pages/referensi/shift/index";

import PenyerahanShift from "./pages/penyerahanshift/index";
import Kecelakaan from "./pages/transaction/kecelakaan/index";
import Approval from "./pages/transaction/approval/index";

import Rekening from "./pages/referensi/rekening/index";
import Kejadian from "./pages/transaction/kejadian/index";
import Medis from "./pages/transaction/medis/index";

const mapping = {
    DashboardDetail: DashboardDetail,
    DashboardProfile: DashboardProfile,
    Pegawai: Pegawai,
    PegawaiAdd: PegawaiAdd,
    PegawaiEdit: PegawaiEdit,
    PegawaiDelete: PegawaiDelete,
    PegawaiShift: PegawaiShift,
    PegawaiRealisasi: PegawaiRealisasi,
    //ref
    ReferensiForm: ReferensiForm,
    ReferensiFormUnit: ReferensiFormUnit,
    ReferensiFormDetail: ReferensiFormDetail,
    ReferensiUnit: ReferensiUnit,
    ReferensiJabatan: ReferensiJabatan,
    ReferensiKategoriSarpras: ReferensiKategoriSarpras,
    ReferensiStatus: ReferensiStatus,
    ReferensiSarpras: ReferensiSarpras,
    ReferensiSarprasSatuan: ReferensiSarprasSatuan,
    ReferensiKendaraan: ReferensiKendaraan,
    ReferensiBody: ReferensiBody,
    ReferensiRambu: ReferensiRambu,
    ReferensiKegiatan: ReferensiKegiatan,
    ReferensiKondisiBarang: ReferensiKondisiBarang,
    ReferensiJenisKelamin: ReferensiJenisKelamin,
    ReferensiKondisiBadan: ReferensiKondisiBadan,
    ReferensiMember: ReferensiMember,
    ReferensiKondisiPenumpang: ReferensiKondisiPenumpang,
    ReferensiSabukPengaman: ReferensiSabukPengaman,
    ReferensiTipePengendara: ReferensiTipePengendara,
    ReferensiKewarganegaraan: ReferensiKewarganegaraan,
    ReferensiTindakLanjut: ReferensiTindakLanjut,
    ReferensiHoliday: ReferensiHoliday,
    ReferensiRuas: ReferensiRuas,
    ReferensiRuasSection: ReferensiRuasSection,
    ReferensiWeather: ReferensiWeather,
    ReferensiTraffic: ReferensiTraffic,
    ReferensiReport: ReferensiReport,
    ReferensiKategoriKegiatan: ReferensiKategoriKegiatan,
    ReferensiKategoriPengaduan: ReferensiKategoriPengaduan,
    ReferensiGolonganKendaraan: ReferensiGolonganKendaraan,
    // referensi lakalantas
    ReferensiNomorKasus: ReferensiNomorKasus,
    ReferensiAlinemenJalan: ReferensiAlinemenJalan,
    ReferensiAngin: ReferensiAngin,
    ReferensiDiagnosa: ReferensiDiagnosa,
    ReferensiHasilPertolongan: ReferensiHasilPertolongan,
    ReferensiJarakPenglihatan: ReferensiJarakPenglihatan,
    ReferensiJenisKecelakaan: ReferensiJenisKecelakaan,
    ReferensiJenisLajurArah: ReferensiJenisLajurArah,
    ReferensiJenisMedian: ReferensiJenisMedian,
    ReferensiJenisPertolongan: ReferensiJenisPertolongan,
    ReferensiKategoriKecelakaan: ReferensiKategoriKecelakaan,
    ReferensiKategoriKorbanPedestrian: ReferensiKategoriKorbanPedestrian,
    ReferensiKeadaanKorban: ReferensiKeadaanKorban,
    ReferensiKondisiPermukaan: ReferensiKondisiPermukaan,
    ReferensiLebarJalur: ReferensiLebarJalur,
    ReferensiPekerjaan: ReferensiPekerjaan,
    ReferensiPekerjaanJalan: ReferensiPekerjaanJalan,
    ReferensiPendidikan: ReferensiPendidikan,
    ReferensiPenyebabKecelakaan: ReferensiPenyebabKecelakaan,
    ReferensiPosisiPenumpang: ReferensiPosisiPenumpang,
    ReferensiPosisiTabrakan: ReferensiPosisiTabrakan,
    ReferensiRambuBatasKecepatan: ReferensiRambuBatasKecepatan,
    ReferensiTabrakLari: ReferensiTabrakLari,
    ReferensiTempatKorbanPedestrian: ReferensiTempatKorbanPedestrian,
    ReferensiTempatLuka: ReferensiTempatLuka,
    ReferensiTipeKorban: ReferensiTipeKorban,
    ReferensiDasarGantiRugi: ReferensiDasarGantiRugi,
    ReferensiSumberInformasi: ReferensiSumberInformasi,
    // core
    SettingGroups: SettingGroups,
    SettingPortal: SettingPortal,
    SettingMenu: SettingMenu,
    SettingMenuDetail: SettingMenuDetail,
    SettingRole: SettingRole,
    SettingRoleDetail: SettingRoleDetail,
    SettingUser: SettingUser,
    SettingUserAdd: SettingUserAdd,
    SettingUserEdit: SettingUserEdit,
    SettingUserDelete: SettingUserDelete,
    SettingLang: SettingLang,
    // pik
    PikShift: PikShift,
    PikComplaint: PikComplaint,
    PikForm: PikForm,
    PikPresensi: PikPresensi,
    PikPjr: PikPjr,
    PikDailyReport: PikDailyReport,
    PikSendReport: PikSendReport,
    PikReceiveReport: PikReceiveReport,
    // spv
    SpvApproval: SpvApproval,
    SpvApprovalDetail: SpvApprovalDetail,
    // report
    ReportLakalantas: ReportLakalantas,
    ReportLakalantasMd: ReportLakalantasMd,
    ReportLakalantasLb: ReportLakalantasLb,
    ReportLakalantasLr: ReportLakalantasLr,
    ReportPL01Odometer: ReportPL01Odometer,
    ReportPL01Sarpras: ReportPL01Sarpras,
    ReportPL02: ReportPL02,
    ReportGantiRugi: ReportGantiRugi,
    ReportCustomer: ReportCustomer,
    ReportKpi: ReportKpi,
    ReportGantiRugiRekap: ReportGantiRugiRekap,
    ReportMovingUnit: ReportMovingUnit,
    ReportHandlingResponse: ReportHandlingResponse,
    ReportHandlingResponseRespon: ReportHandlingResponseRespon,
    ReportHandlingResponsePenanganan: ReportHandlingResponsePenanganan,
    ReportHandlingResponseSelesai: ReportHandlingResponseSelesai,

    TransactionGantiRugi: TransactionGantiRugi,

    EventCalendar : EventCalendar,
    EventCalendarAdd : EventCalendarAdd,
    EventCalendarEdit : EventCalendarEdit,
    EventCalendarDelete : EventCalendarDelete,
    EventCamera : EventCamera,
    EventAdd : EventAdd,
    EventCameraNotification : EventCameraNotification,
   
    Berita : Berita,
    BeritaAdd : BeritaAdd,
    BeritaEdit : BeritaEdit,
    BeritaDelete : BeritaDelete,
    BeritaKategori : BeritaKategori,

    Fasilitas : Fasilitas,
    FasilitasAdd : FasilitasAdd,
    FasilitasEdit : FasilitasEdit,
    FasilitasDelete : FasilitasDelete,
    FasilitasKategori : FasilitasKategori,
    CallCenter : CallCenter,
    Gerbang : Gerbang,
    GerbangAdd : GerbangAdd,
    GerbangEdit : GerbangEdit,
    GerbangDelete : GerbangDelete,
    Gardu : Gardu,
    GarduAdd : GarduAdd,
    GarduEdit : GarduEdit,
    GarduDelete : GarduDelete,
    KategoriKejadian:KategoriKejadian,
    JenisGangguan:JenisGangguan,
    Kamera:Kamera,
    Tarif:Tarif,
    Ganti:Ganti,
    Putar:Putar,
    Cctv:Cctv,
    Management:Management,
    Shift:Shift,
    PenyerahanShift:PenyerahanShift,
    Kecelakaan:Kecelakaan,
    Approval:Approval,
    Rekening:Rekening,
    Kejadian:Kejadian,
    Medis:Medis
};

const Router = (json) => (
    <Switch>
        {json.map((item, index) => {
           
            const Elem = mapping[item.react_component] || Error404;
            //return
            return (
                <Route
                    key={`router__${index}`}
                    exact={Boolean(item.react_exact)}
                    path={item.nav_url}
                    component={(props) => {
                        // console.log(item.nav_url.split("/"));
                        // judul page
                        document.title = item.react_page_title;
                        // breadcrumb
                        let breadcrumb = [];
                        try {
                            breadcrumb = JSON.parse(item.react_breadcrumb);
                        } catch (error) {}
                        //return
                        return (
                            <Elem
                                {...props}
                                breadcrumb={breadcrumb}
                                pagetitle={item.nav_title}
                                pagesubtitle={item.nav_desc}
                            />
                        );
                    }}
                />
            );
        })}
        <Route
            component={(props) => {
                return <Error404 {...props} />;
            }}
        />
    </Switch>
);

export default Router;
