import React from "react";
import classNames from "classnames";

const InputTextarea = props => {
    return (
        <div
            className={classNames("form-group", {
                "has-error": props.has_error
            })}
        >
            <textarea
                id={props.id}
                type={props.type}
                className="form-control"
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={props.onChange}
                autoFocus={props.autoFocus}
                rows={props.rows}
            ></textarea>
            <span className="help-block">{props.error_field}</span>
        </div>
    );
};

export default InputTextarea;
