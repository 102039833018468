import React, { Component } from "react";
import ReactPlayer from 'react-player'
import ImageUploader from "react-images-upload";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";
import toBase64 from "../../../utils/File";

class KecelakaanModal extends Component {
        // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title = "Edit Data Laporan";
        
        //console.log(props);
        
        this.state = {
            loading: false,
            id: props.detail ? props.detail.id : "",
            id_bujt: props.detail ? props.detail.id_bujt : "",
            tanggal: props.detail ? props.detail.tanggal : "",
            waktu: props.detail ? props.detail.waktu : "",
            ruas_tol: props.detail ? props.detail.ruas_tol : "",
            kategori_id: props.detail ? props.detail.kategori_id : "",
            kategori_nama: props.detail ? props.detail.kategori_nama : "",
            gangguan_id: props.detail ? props.detail.gangguan_id : "",
            gangguan_nama: props.detail ? props.detail.gangguan_nama : "",
            lokasi_km: props.detail ? props.detail.lokasi_km : "",
            tindak_lanjut: props.detail ? props.detail.tindak_lanjut : "",
            tindak_lanjut_nama: props.detail ? props.detail.tindak_lanjut_nama : "",
            deskripsi_singkat: props.detail ? props.detail.deskripsi_singkat : "",
            gerbang_nama: props.detail ? props.detail.gerbang_nama : "",
            gerbang_id: props.detail ? props.detail.gerbang_id : "",
            gardu: props.detail ? props.detail.gardu : "",
            lokasi_lain: props.detail ? props.detail.lokasi_lain : "",
            gambar1: props.detail ? props.detail.gambar1 : "",
            gambar2: props.detail ? props.detail.gambar2 : "",
            gambar3: props.detail ? props.detail.gambar3 : "",
            nomor_kecelakaan: props.detail ? props.detail.nomor_kecelakaan : "",
            kategori:props.kategori,
            jenis:props.jenis,
            gerbang:props.gerbang,
            unit:props.unit,
            gambar1_new:"",
            gambar2_new:"",
            gambar3_new:"",
            tindak:[{id:"1",label:"Ya"},{id:"0",label:"Tidak"}],
            unit_terlibat:props.detail ? props.detail.unit_terlibat : [],
            error_field: {
                gardu_nama: null
            }
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);     
        this.handleDropGambar1 = this.handleDropGambar1.bind(this);
        this.handleDropGambar2 = this.handleDropGambar2.bind(this);
        this.handleDropGambar3 = this.handleDropGambar3.bind(this);
        this.bersihkanGambar1 = this.bersihkanGambar1.bind(this);
        this.bersihkanGambar2 = this.bersihkanGambar2.bind(this);
        this.bersihkanGambar3 = this.bersihkanGambar3.bind(this);
        this.hapusUnit          = this.hapusUnit.bind(this);
        this.addUnit          = this.addUnit.bind(this);
        this.handleChangeUnit  = this.handleChangeUnit.bind(this);
    }
    
    handleChangeUnit(field, value, index) {
        // set state disini akan kompleks
        let unit = this.state.unit_terlibat;
        unit[index][field] = value;
        this.setState({ unit_terlibat: unit });
    }
    
    addUnit() {
        // nati dulu
        let unit_terlibat = this.state.unit_terlibat;
        unit_terlibat.push({id_unit:"",nama_unit:""});
        this.setState({ unit_terlibat: unit_terlibat });
    }
    
    hapusUnit(index) {
        let unit_terlibat = this.state.unit_terlibat;
        unit_terlibat.splice(index, 1);
        this.setState({ unit_terlibat: unit_terlibat });
    }
    
    bersihkanGambar1(picture) {
        this.setState({
            gambar1: ""
        });
    }
    
     bersihkanGambar2(picture) {
        this.setState({
            gambar2: ""
        });
    }
    
     bersihkanGambar3(picture) {
        this.setState({
            gambar3: ""
        });
    }
    
    handleDropGambar1(picture) {
        this.setState({
            gambar1_new: picture[0]
        });
    }
    
    handleDropGambar2(picture) {
        this.setState({
            gambar2_new: picture[0]
        });
    }
    
    handleDropGambar3(picture) {
        this.setState({
            gambar3_new: picture[0]
        });
    }

   
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
        
    }
    

    async onSubmit(evt) {
        
        evt.preventDefault();
        // this
        const that = this;
        // api request
        
        let image1 = null;
        if (that.state.gambar1_new) {
            image1 = await toBase64(that.state.gambar1_new)
                .then(result => result)
                .catch(error => null);
        } else {
            image1 = that.state.gambar1;
        }
        
        let image2 = null;
        if (that.state.gambar2_new) {
            image2 = await toBase64(that.state.gambar2_new)
                .then(result => result)
                .catch(error => null);
        } else {
            image2 = that.state.gambar2;
        }
        
        let image3 = null;
        if (that.state.gambar3_new) {
            image3 = await toBase64(that.state.gambar3_new)
                .then(result => result)
                .catch(error => null);
        } else {
            image3 = that.state.gambar3;
        }
        
        that.setState({ loading: true }, function() {
            const params = {
                tanggal_laporan         : this.state.tanggal,
                detail_time             : this.state.waktu,
                id_event                : this.state.id,
                id_bujt                 : this.state.id_bujt,
                ruas_tol                : this.state.ruas_tol,
                id_kategori_kejadian    : this.state.kategori_id,
                kategori_nama           : this.state.kategori_nama,
                id_jenis_gangguan       : this.state.gangguan_id,
                gangguan_nama           : this.state.gangguan_nama,
                lokasi_km               : this.state.lokasi_km,
                tindak_lanjut           : this.state.tindak_lanjut,
                tindak_lanjut_nama      : this.state.tindak_lanjut_nama,
                deskripsi_singkat       : this.state.deskripsi_singkat,
                lokasi_gerbang          : this.state.gerbang_nama,
                gerbang_id              : this.state.gerbang_id,
                gardu                   : this.state.gardu,
                lokasi_lain             : this.state.lokasi_lain,
                gambar1                 : image1,
                gambar2                 : image2,
                gambar3                 : image3,
                nomor_kecelakaan        : this.state.nomor_kecelakaan,
                unit_terlibat           : this.state.unit_terlibat
            };
            
          
            
            PostPrivate(that, `transaksi/kejadian/update`, params)
                .then(result => {
                    if (result.status == 200) {
                        that.setState(
                            {
                                loading: false
                            },
                            function() {
                                // close modal
                                that.props.toogleShow('');
                                SwalSuccess("Berhasil", result.message);
                            }
                        );
                    } else {
                        alert('Update data gagal');
                        
                        // hapus loading
                        that.setState({
                            loading: false
                        });
                    }
                    
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });     
        });
    };
    
    render() {
        // return
        return (
                <Modal
                    form={true}
                    title={this.title}
                    content={
                        <>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">ID Event</label>
                                    <div className="col-md-4">
                                        <input type="text" readOnly disabled className="form-control" value={this.state.id}/>
                                    </div>
                                    <label className="col-sm-2 col-form-label">BUJT</label>
                                    <div className="col-md-4">
                                        <input type="text" readOnly disabled className="form-control" value={this.state.ruas_tol}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Tanggal</label>
                                    <div className="col-md-4">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            value={this.state.tanggal}
                                            onChange={ev =>
                                                this.handleChange(
                                                    "tanggal",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.tanggal
                                            }
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label">Waktu</label>
                                    <div className="col-md-4">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.waktu}
                                            onChange={ev =>
                                                this.handleChange(
                                                    "waktu",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.waktu
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Kategori Kejadian</label>
                                    <div className="col-md-4">
                                        <InputSelectReact
                                            id={"kategori"}
                                            has_error={this.state.error_field.kategori}
                                            defaultValue={{
                                                label: this.state.kategori_nama,
                                                value: this.state.kategori_id
                                            }}
                                            onChange={item => {
                                                this.handleChange("kategori_id", item.id);
                                                this.handleChange("kategori_nama", item.label);
                                            }}
                                            error_field={this.state.error_field.kategori}
                                            options={this.state.kategori}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label">Jenis Gangguan</label>
                                    <div className="col-md-4">
                                        <InputSelectReact
                                            id={"jenis"}
                                            has_error={this.state.error_field.jenis}
                                            defaultValue={{
                                                label: this.state.gangguan_nama,
                                                value: this.state.gangguan_id
                                            }}
                                            onChange={item => {
                                                this.handleChange("gangguan_id", item.id);
                                                this.handleChange("gangguan_nama", item.label);
                                            }}
                                            error_field={this.state.error_field.jenis}
                                            options={this.state.jenis}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Deskripsi Singkat</label>
                                    <div className="col-md-4">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.deskripsi_singkat}
                                            onChange={ev =>
                                                this.handleChange(
                                                    "deskripsi_singkat",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.deskripsi_singkat
                                            }
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label">Lokasi KM</label>
                                    <div className="col-md-4">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.lokasi_km}
                                            onChange={ev =>
                                                this.handleChange(
                                                    "lokasi_km",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.lokasi_km
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Lokasi Gerbang</label>
                                    <div className="col-md-4">
                                        <InputSelectReact
                                            id={"gerbang"}
                                            has_error={this.state.error_field.gerbang}
                                            defaultValue={{
                                                label: this.state.gerbang_nama,
                                                value: this.state.gerbang_id
                                            }}
                                            onChange={item => {
                                                this.handleChange("gerbang_id", item.id);
                                                this.handleChange("gerbang_nama", item.label);
                                            }}
                                            error_field={this.state.error_field.gerbang}
                                            options={this.state.gerbang}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label">Lokasi Lain</label>
                                    <div className="col-md-4">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.lokasi_lain}
                                            onChange={ev =>
                                                this.handleChange(
                                                    "lokasi_lain",
                                                    ev.target.value
                                                )
                                            }
                                            error_field={
                                                this.state.error_field.lokasi_lain
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Tindak Lanjut</label>
                                    <div className="col-md-4">
                                        <InputSelectReact
                                            id={"tindak_lanjut"}
                                            has_error={this.state.error_field.tindak}
                                            defaultValue={{
                                                label: this.state.tindak_lanjut_nama,
                                                value: this.state.tindak_lanjut
                                            }}
                                            onChange={item => {
                                                this.handleChange("tindak_lanjut", item.id);
                                                this.handleChange("tindak_lanjut_nama", item.label);
                                            }}
                                            error_field={this.state.error_field.tindak}
                                            options={this.state.tindak}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Unit Terlibat</label>
                                    <div className="col-md-10">
                                        <div className="col-sm-12"><Button type="button" className="btn btn-success" style={{float:'right'}} onClick={() => this.addUnit()}>Tambah</Button></div>
                                            
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>Nama Unit</th>
                                                    <th style={{width:"100px"}}>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody id="body_unit">
                                            {this.state.unit_terlibat.map((row, i) => (
                                               <>
                                                    <tr>
                                                        <td>
                                                        <InputSelectReact
                                                                has_error={this.state.error_field.unit}
                                                                defaultValue={{
                                                                    label: this.state.unit_terlibat[i].nama_unit,
                                                                    value: this.state.unit_terlibat[i].id_unit
                                                                }}
                                                                onChange={item => {
                                                                    this.handleChangeUnit("id_unit", item.id, i);
                                                                    this.handleChangeUnit("nama_unit", item.label,i);
                                                                }}
                                                                error_field={this.state.error_field.unit}
                                                                options={this.state.unit}
                                                        />
                                                        </td>
                                                        <td><Button type="button"
                                                                className="btn btn-danger" 
                                                                onClick={ev =>
                                                                    this.hapusUnit(
                                                                        i
                                                                    )
                                                                }
                                                               >
                                                               Hapus
                                                               </Button></td>
                                                    </tr>
                                               </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                    
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                    <label className="col-sm-2 col-form-label">Gambar</label>
                                    <div className="col-md-10">
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <td>Gambar</td>
                                                    <td>Upload Baru</td>
                                                    <td style={{width:"70px"}}>Aksi</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    {this.state.gambar1 !='' && (
                                                        <img src={this.state.gambar1} style={{width:"300px"}} />
                                                    )} 
                                                    </td>
                                                    <td>
                                                        <ImageUploader
                                                            withIcon={true}
                                                            buttonText="Pilih Gambar"
                                                            onChange={this.handleDropGambar1}
                                                            imgExtension={[
                                                                ".jpg",
                                                                ".gif",
                                                                ".png",
                                                                ".gif"
                                                            ]}
                                                            maxFileSize={5242880}
                                                            singleImage={true}
                                                            withPreview={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button className="btn btn-success" type="button" onClick={() => this.bersihkanGambar1()}>Bersihkan</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    {this.state.gambar2 !='' && (
                                                        <img src={this.state.gambar2} style={{width:"300px"}} />
                                                    )} 
                                                    </td>
                                                    <td>
                                                        <ImageUploader
                                                            withIcon={true}
                                                            buttonText="Pilih Gambar"
                                                            onChange={this.handleDropGambar2}
                                                            imgExtension={[
                                                                ".jpg",
                                                                ".gif",
                                                                ".png",
                                                                ".gif"
                                                            ]}
                                                            maxFileSize={5242880}
                                                            singleImage={true}
                                                            withPreview={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button className="btn btn-success" type="button" onClick={() => this.bersihkanGambar2()}>Bersihkan</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    {this.state.gambar3 !='' && (
                                                        <img src={this.state.gambar3} style={{width:"300px"}} />
                                                    )} 
                                                    </td>
                                                    <td>
                                                        <ImageUploader
                                                            withIcon={true}
                                                            buttonText="Pilih Gambar"
                                                            onChange={this.handleDropGambar3}
                                                            imgExtension={[
                                                                ".jpg",
                                                                ".gif",
                                                                ".png",
                                                                ".gif"
                                                            ]}
                                                            maxFileSize={5242880}
                                                            singleImage={true}
                                                            withPreview={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button className="btn btn-success" type="button" onClick={() => this.bersihkanGambar3()}>Bersihkan</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '10px'}}>
                                <div className="col-md-12">
                                   
                                    <Button style={{float:"right"}} type="button" className="btn btn-primary" onClick={() => this.props.toogleShow('')}>Tutup</Button>
                                    <Button style={{float:"right"}} type="submit" className="btn btn-success">Simpan</Button>&nbsp;
                                </div>
                            </div>
                         </>
                    }
                    show={this.props.show}
                    onDismiss={() => this.props.toogleShow('')}
                    onSubmit={this.onSubmit}
                />
        )
    }
    
    
}

export default KecelakaanModal;
