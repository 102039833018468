import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Formulir",
        selector: "title_desc",
        sortable: true,
        width: "75%"
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <>
                <ButtonRole
                    role_url={`/dashboard/ref/form/detail/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`/dashboard/ref/form/detail/${item.title_id}`}
                        className="btn btn-sm btn-default"
                    >
                        Update Form
                    </NavLink>
                </ButtonRole>
            </>
        );
        return item;
    });
};

const FormTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_form_unit"}
            empty="Belum ada referensi unit"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(FormTable));
