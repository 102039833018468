import React from "react";

// komponen
import { Table } from "../../../components/AdminLTE";

// table
const thead = props => {
    return [
        { width: "5%", className: "text-center", label: "Nama Kendaraan" },
        { width: "5%", className: "text-center", label: "Nomor Polisi" },
        { width: "5%", className: "text-center", label: "Masa Berlaku STNK" },
        { width: "5%", className: "text-center", label: "Pajak STNK" },
        { width: "5%", className: "text-center", label: "Masa Berlaku KIR" },
        { width: "5%", className: "text-center", label: "Masa Berlaku Oli" },
        { width: "5%", className: "text-center", label: "ID Unit Kendaraan" },
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={item.key}>
            <td className="text-center">{item.kendaraan_nama}</td>
            <td className="text-center">{item.no_polisi}</td>
            <td className="text-center">{item.masa_berlaku_stnk}</td>
            <td className="text-center">{item.pajak_stnk}</td>
            <td className="text-center">{item.masa_berlaku_kir}</td>
            <td className="text-center">{item.masa_berlaku_olie}</td>
            <td className="text-center">{item.kendaraan_unit_id}</td>
        </tr>
    ));
};

const BujtTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Silahkan upload file excel"
            className="table table-bordered table-hover"
        />
    );
};

export default BujtTable;
