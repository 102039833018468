import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../components/Alert";
import { Modal, Button} from "../../components/AdminLTE";

// utils
import { PostPrivate } from "../../utils/Connection";

class PenyerahanshiftModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Penyerahan Shift"
                : "Tambah Referensi Penyerahan Shift";
        // state
        
        this.state = {
            // field input
            item: props.item,
            detail:props.detail,
            gallery:props.gallery,
            error_field: {
                unit_name: null
            },
            // loading
            loading: false
        };
        
    }
    
    
    render() {
        // return
        let is_diterima = '';
        let button_terima = '';
        
        if (this.state.detail.is_accepted == 0) {
            is_diterima = <div class="btn btn-primary">Belum Diproses</div>
            button_terima = (
                    <>
                    <Button className="btn btn-success" onClick={() => this.props.terimaLaporan(this.state.item.form_id)}>Terima</Button>&nbsp;
                    <Button className="btn btn-danger" onClick={() => this.props.showConfirm(this.state.item)}>Tolak</Button>&nbsp;
                    </>
            )
    
        } else if (this.state.detail.is_accepted == 1) {
            is_diterima = <div class="btn btn-success">Sudah Diterima</div>
            button_terima = '';
        } else {
            is_diterima = <div class="btn btn-danger">Ditolak</div>
            button_terima = '';
        }
        
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Nama Unit
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.unit_nama}
                            </div>
                            <div className="col-sm-3">
                                Shift Mulai
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.shift_start}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Diterima
                            </div>
                            <div className="col-sm-3">
                            {is_diterima}
                            </div>
                            <div className="col-sm-3">
                                Shift Selesai
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.shift_end}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Cuaca
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.weather_name}
                            </div>
                            <div className="col-sm-3">
                                Lalu Lintas
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.traffic_name}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Kegiatan
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.section_name}
                            </div>
                            <div className="col-sm-3">
                                KM
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.km}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Waktu
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.waktu}
                            </div>
                            <div className="col-sm-3">
                                KM Hinga
                            </div>
                            <div className="col-sm-3">
                            {this.state.detail.km_to}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Deskripsi
                            </div>
                            <div className="col-sm-9">
                            {this.state.detail.deskripsi}
                            </div>
                            
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                File
                            </div>
                            <div className="col-sm-9">
                                <ul>
                                    {this.state.gallery.map(function(gallery, index){
                                        return <li><a href={gallery.file_nama_full} target="_blank">{gallery.file_nama}</a></li>;
                                    })}
                                </ul>
                            </div>
                           
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3">
                                Alasan ditolak
                            </div>
                            <div className="col-sm-9">
                            {this.state.detail.alasan}
                            </div>
                            
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <div style={{float:'right'}}>
                                {button_terima}
                                <Button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.props.showDetail()}
                                        loading={this.state.loading}
                                    >
                                        Tutup
                                    </Button>    
                                </div>
                            </div>
                        </div>
                    </>
                }
                show={this.props.show}
                onDismiss={this.props.showDetail}
            />
        );
    }
}

export default PenyerahanshiftModal;
