import React from "react";

// komponen
import {
    Input,
    InputRadioInline,
    InputSelectReact,
    InputSelectAsync
} from "../../../components/AdminLTE";

const FieldBiodata = props => {
    return (
        <fieldset className="mt-2">
            <legend>Biodata Pegawai : </legend>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_nik"}
                    className="control-label col-md-3 mt-1"
                >
                    Nomor NIK Pegawai
                </label>
                <div className="col-md-4">
                    <Input
                        id={"pegawai_nik"}
                        type="text"
                        placeholder="Nomor NIK Pegawai"
                        has_error={props.error_field.pegawai_nik}
                        defaultValue={props.pegawai_nik}
                        onChange={ev =>
                            props.handleChange("pegawai_nik", ev.target.value)
                        }
                        error_field={props.error_field.pegawai_nik}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_name"}
                    className="control-label col-md-3 mt-1"
                >
                    Nama Pegawai
                </label>
                <div className="col-md-7">
                    <Input
                        id={"pegawai_name"}
                        type="text"
                        placeholder="Nama Pegawai"
                        has_error={props.error_field.pegawai_name}
                        defaultValue={props.pegawai_name}
                        onChange={ev =>
                            props.handleChange("pegawai_name", ev.target.value)
                        }
                        error_field={props.error_field.pegawai_name}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_birth_place"}
                    className="control-label col-md-3 mt-1"
                >
                    Tempat, Tanggal Lahir
                </label>
                <div className="col-md-5">
                    <InputSelectAsync
                        has_error={props.error_field.pegawai_birth_place}
                        defaultValue={props.pegawai_birth_place}
                        defaultInputValue={props.pegawai_birth_place_name}
                        hasDefaultOptions={props.hasDefaultOptions}
                        defaultOptions={[
                            {
                                value: props.pegawai_birth_place,
                                label: props.pegawai_birth_place_name
                            }
                        ]}
                        onChange={item =>
                            props.handleChange(
                                "pegawai_birth_place",
                                item.value
                            )
                        }
                        error_field={props.error_field.pegawai_birth_place}
                        loadOptions={q =>
                            props.loadData(
                                q,
                                "referensi/kabupaten",
                                "kabupaten_id",
                                "kabupaten_name"
                            )
                        }
                    />
                </div>
                <div className="col-md-3">
                    <Input
                        id={"pegawai_birth"}
                        type="date"
                        placeholder="Tanggal Lahir"
                        has_error={props.error_field.pegawai_birth}
                        defaultValue={props.pegawai_birth}
                        onChange={ev =>
                            props.handleChange("pegawai_birth", ev.target.value)
                        }
                        error_field={props.error_field.pegawai_birth}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"pegawai_gender__L"}
                    className="control-label col-md-3 mt-2"
                >
                    Jenis Kelamin
                </label>
                <div className="col-md-9">
                    <InputRadioInline
                        id={"pegawai_gender"}
                        has_error={props.error_field.pegawai_gender}
                        defaultChecked={props.pegawai_gender}
                        onChange={ev =>
                            props.handleChange(
                                "pegawai_gender",
                                ev.target.value
                            )
                        }
                        error_field={props.error_field.pegawai_gender}
                        list={[
                            {
                                label: "Laki - Laki",
                                value: "L"
                            },
                            {
                                label: "Perempuan",
                                value: "P"
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"jabatan_id"}
                    className="control-label col-md-3 mt-1"
                >
                    Jabatan Pegawai
                </label>
                <div className="col-md-4">
                    <InputSelectReact
                        id={"jabatan_id"}
                        has_error={props.error_field.jabatan_id}
                        defaultValue={{
                            label: props.pegawai_jabatan,
                            value: props.jabatan_id
                        }}
                        onChange={item => {
                            props.handleChange("jabatan_id", item.value);
                            props.handleChange("role_id", item.role_id);
                        }}
                        error_field={props.error_field.jabatan_id}
                        options={props.list_jabatan}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"unit_id"}
                    className="control-label col-md-3 mt-1"
                >
                    Unit
                </label>
                <div className="col-md-5">
                    <InputSelectReact
                        id={"unit_id"}
                        has_error={props.error_field.unit_id}
                        defaultValue={{
                            label: props.pegawai_unit,
                            value: props.unit_id
                        }}
                        onChange={item => {
                            if (item) props.handleChange("unit_id", item.value);
                            else props.handleChange("unit_id", "");
                        }}
                        error_field={props.error_field.unit_id}
                        options={props.list_unit}
                        isClearable={true}
                    />
                    <i className="help-block text-muted">Opsional</i>
                </div>
            </div>
            <div className="form-group row">
                <label
                    htmlFor={"ruas_id"}
                    className="control-label col-md-3 mt-1"
                >
                    Ruas Tol
                </label>
                <div className="col-md-3">
                    <InputSelectReact
                        id={"ruas_id"}
                        has_error={props.error_field.ruas_id}
                        defaultValue={{
                            label: props.pegawai_ruas,
                            value: props.ruas_id
                        }}
                        onChange={item =>
                            props.handleChange("ruas_id", item.value)
                        }
                        error_field={props.error_field.ruas_id}
                        options={props.list_ruas}
                    />
                </div>
            </div>
        </fieldset>
    );
};

export default FieldBiodata;
