import React, { Component } from "react";

// komponen
import { Content, ButtonAdd } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// page
import ShiftModal from "./modal";
import ShiftTable from "./table";

class Shift extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: false,
            modal: false,
            mode: "",
            item: null,
            list: []
        };
        // bind
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, "referensi/shift")
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // konfirmasi hapus
    deleteConfirm(item) {
        SwalConfirm(
            `Hapus Referensi Shift [${item.shift_name}]`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () => this.deleteProcess(item)
        );
    }
    // deleteprocess
    deleteProcess(item) {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `referensi/shift/delete/${item.shift_id}`)
                .then(result => {
                    // load data
                    this.loadData();
                    // pesan
                    SwalSuccess("Berhasil", result.message);
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    //toogleShow
    toogleShow(mode = "", item = null) {
        this.setState({ mode: mode, item: item }, function() {
            this.setState({ modal: !this.state.modal });
        });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">
                            Manajemen Referensi Shift
                        </h3>
                        <div className="box-tools pull-right">
                            <ButtonAdd
                                onClick={() => this.toogleShow("add")}
                                loading={this.props.loading}
                            />
                        </div>
                    </div>
                    <div className="box-body">
                        <ShiftTable
                            list={this.state.list}
                            toogleShow={this.toogleShow}
                            deleteConfirm={this.deleteConfirm}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <ShiftModal
                        mode={this.state.mode}
                        item={this.state.item}
                        show={this.state.modal}
                        toogleShow={() => this.toogleShow()}
                        loadData={() => this.loadData()}
                    />
                )}
            </Content>
        );
    }
}

export default Shift;
