import React, { useState } from "react";
import classNames from "classnames";

const BoxCollapse = props => {
    const [state, setState] = useState(props.open ? props.open : false);
    return (
        <div
            className={classNames("box box-default", {
                "collapsed-box": !state
            })} 
            style={{borderTopColor: props.topColor, zoom: props.zoom}}
        >
            <div className="box-header with-border">
                <h3 className="box-title" style={{color: props.color, fontFamily: 'Poppins', fontSize: '24px', marginLeft:props.margin2}}>{props.title}</h3>
                <div className="box-tools pull-right">
                    {props.tools}
                    <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                        onClick={() => setState(!state)}
                    >
                        {state ? (
                            props.icon_hide ? (
                                props.icon_hide
                            ) : (
                                <i className="fa fa-chevron-up"></i>
                            )
                        ) : props.icon_show ? (
                            props.icon_show
                        ) : (
                            <i className="fa fa-chevron-down"></i>
                        )}
                    </button>
                </div>
            </div>
            <div className={classNames("box-body", { hide: !state })} style={{marginLeft:props.margin, textAlign:props.align}}>
                {props.children}
            </div>
        </div>
    );
};

export default BoxCollapse;
