import React, { Component } from "react";

// komponen
import { Content, ButtonAdd, BoxCollapse } from "../../../components/AdminLTE";
import { SwalConfirm, SwalSuccess } from "../../../components/Alert";

// utils
import GetPrivate from "../../../utils/Connection";

// page
import HolidayModal from "./modal";
import HolidayTable from "./table";
import HolidaySearch from "./search";

class Holiday extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      modal: false,
      mode: "",
      item: null,
      list: [],
      year_now: '',
      label:''
    };
    // bind
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.toogleShow = this.toogleShow.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }
  componentDidMount() {
    // loadData
    this.loadData();
    this.loadYear();
  }
  //load year
  loadYear() {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => year - index);
    console.log(years);

    this.setState(
      {
        list_year: years.map((item) => ({
          value: item,
          label: item,
        })),
        year_now: years[0],
      },
      function () {
        // loadData
        this.loadData();
      }
    );
  }
  // load data
  loadData() {
    // panggil get api
    this.setState({ loading: true }, function () {
      GetPrivate(this, `referensi/holiday?year=${this.state.year_now}`)
        .then((result) => {
          this.setState({
            loading: false,
            list: result.data,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    });
  }
  handleChangeSelect(item) {
      console.log('-----');
      console.log(item);
    this.setState({ year_now: item.value, year_now: item.label,label:item.label }, function () {
      this.loadData();
    });
  }
  // konfirmasi hapus
  deleteConfirm(item) {
    SwalConfirm(
      `Hapus Hari Libur [${item.holiday_name}]`,
      "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
      () => this.deleteProcess(item)
    );
  }
  // deleteprocess
  deleteProcess(item) {
    // panggil get api
    this.setState({ loading: true }, function () {
      GetPrivate(this, `referensi/holiday/delete/${item.holiday_id}`)
        .then((result) => {
          // load data
          this.loadData();
          // pesan
          SwalSuccess("Berhasil", result.message);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    });
  }
  //toogleShow
  toogleShow(mode = "", item = null) {
    this.setState({ mode: mode, item: item }, function () {
      this.setState({ modal: !this.state.modal });
    });
  }
  // render
  render() {
    return (
      <Content title={this.props.pagetitle} breadcrumb={this.props.breadcrumb}>
        <BoxCollapse open={true} title="">
          <HolidaySearch
            handleChangeSelect={this.handleChangeSelect}
            ruas_id={this.state.ruas_id}
            ruas_name={this.state.ruas_name}
            year_name={this.state.year_now}
            list_year={this.state.list_year}
          />
        </BoxCollapse>
        <div className="box">
          <div className="box-header with-border">
            <h3 className="box-title">Manajemen Hari Libur</h3>
            <div className="box-tools pull-right">
              <ButtonAdd
                onClick={() => this.toogleShow("add")}
                loading={this.props.loading}
              />
            </div>
          </div>
          <div className="box-body">
            <HolidayTable
              list={this.state.list}
              toogleShow={this.toogleShow}
              deleteConfirm={this.deleteConfirm}
              loading={this.state.loading}
            />
          </div>
        </div>
        {this.state.modal && (
          <HolidayModal
            mode={this.state.mode}
            item={this.state.item}
            show={this.state.modal}
            toogleShow={() => this.toogleShow()}
            loadData={() => this.loadData()}
          />
        )}
      </Content>
    );
  }
}

export default Holiday;
