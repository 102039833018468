import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole, Button } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "ID Event",
        selector: "id",
        sortable: true,
        width: "15%"
    },
    {
        name: "Kategori Event",
        selector: "status_category",
        sortable: true,
        width: "12%",
        center: true
    },
    {
        name: "Nama Event",
        selector: "name",
        sortable: true,
        width: "20%"
    },
    {
        name: "Tanggal",
        selector: "date",
        sortable: true,
        width: "12%"
    },
    {
        name: "Waktu",
        selector: "time",
        sortable: true,
        width: "10%"
    },
    {
        name: "ID CCTV",
        selector: "location",
        sortable: true,
        width: "10%"
    },
    {
        name: "Unit Patroli",
        selector: "nama_unit",
        sortable: true,
        width: "10%"
    },
    {
        name: "Status",
        selector: "status_action",
        sortable: true,
        width: "20%"
    }/*,
    {
        name: "Action",
        selector: "action",
        width: "25%",
        center: true
    }*/
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["status_custom"] =
            item.status == "1" ? (
                <span className="label label-success">Aktif</span>
            ) : (
                <span className="label label-warning">Tidak Aktif</span>
            );

        if (item.id.length == '18') {
                item["status_category"] = (
                    <span className="label label-warning">Manual</span>
                );
            }
            if (item.id.length == '17') {
                item["status_category"] = (
                    <span className="label label-success">Sistem</span>
                );
            }
    
        if (item.status == '0') {
            item["status_action"] = (
                <Button type="button" className="btn btn-danger" onClick={() => props.showDetail2(item)}>{item.status_label_tugas}</Button>
            );
        } else if (item.status == '1') {
            item["status_action"] = (
                <Button type="button" className="btn btn-primary" onClick={() => props.showDetail2(item)}>{item.status_label_tugas}</Button>
            );
        } else if (item.status == '2') {
            item["status_action"] = (
                <Button type="button" className="btn btn-warning">{item.status_label_tugas}</Button>
            );
        } else if (item.status == '3') {
            item["status_action"] = (
                <Button type="button" className="btn btn-warning">{item.status_label_tugas}</Button>
            );
        } else if (item.status == '4') {
            item["status_action"] = (
                <Button type="button" className="btn btn-primary">{item.status_label_tugas}</Button>
            );
        } else if (item.status == '5') {
            item["status_action"] = (
                <Button type="button" className="btn btn-secondary">{item.status_label_tugas}</Button>
            );
        } else if (item.status == '6') {
            item["status_action"] = (
                <Button type="button" className="btn btn-success" onClick={ev => props.showDetail(item)}>{item.status_label_tugas}</Button>
            );
        }else if (item.status == '7') {
            item["status_action"] = (
                <Button type="button" className="btn btn-danger" onClick={() => props.showDetail3(item)}>{item.status_label_tugas}</Button>
            );
        }
    /*
        item["action"] = (
            <>
                
                <ButtonRole
                    role_url={`${props.location.pathname}/edit/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/edit/${item.id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-edit mr-1"></i> Update
                    </NavLink>
                </ButtonRole>
                <ButtonRole
                    role_url={`${props.location.pathname}/delete/:id`}
                    role_type="role_delete"
                >
                    <NavLink
                        to={`${props.location.pathname}/delete/${item.id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-trash mr-1"></i> Delete
                    </NavLink>
                </ButtonRole>
            </>
        );*/
        return item;
    });
};

const EventTable = props => {
    return (
        <TableBootstrap
            keyField={"event"}
            empty="Belum ada event, klik tombol + untuk menambahkan event baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default EventTable;
