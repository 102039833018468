import Axios from "axios";
import Pace from "pace-js-amd-fix";

// utils
import Config from "../Config";
import Session from "../Session";

// komponen
import { SwalError, SwalWarning } from "../../components/Alert";

// get private perlu token
const GetPrivate = (that, url) => {
    return new Promise((resolve, reject) => {
        // Pace.restart();
        // token
        const token = Session.getSession("session__jmto_admin");
        const token_key = Config.webKey;
        // cek token
        if (token) {
            // request api get
            Axios({
                method: "get",
                url: `${Config.endPoint}${url}`,
                headers: {
                    Authorization: token,
                    "X-API-KEY": token_key,
                    "X-Apps": "web"
                },
                crossdomain: true
            })
                .then(function(response) {
                    const datas = response.data;
                    // cek kode response
                    if (typeof datas === 'object') {
                        switch (datas.status) {
                            case Config.error_code.success:
                                // resolve
                                resolve(datas);
                                return;
                            case Config.error_code.forbidden:
                                throw Config.error_code.forbidden;
                            case Config.error_code.notfound:
                                // redirect notfound
                                if (that.props.history) {
                                    that.props.history.push("/404");
                                }
                                return;
                            default:
                                throw datas.message;
                        }
                    } else if (response.status === 200) {
                        resolve(datas);
                        return;
                    }
                })
                .catch(function(error) {
                    switch (error) {
                        case Config.error_code.forbidden:
                            // redirect login
                            if (that.props.history) {
                                that.props.history.push("/");
                            }
                            break;
                        default:
                            SwalError("Error", error);
                            break;
                    }
                    // reject
                    reject("");
                });
        } else {
            // redirect login
            if (that.props.history) {
                that.props.history.push("/");
            }
            // reject
            reject("");
        }
    });
};

// post private untuk request yg menggunkan token
const PostPrivate = (that, url, params) => {
    return new Promise((resolve, reject) => {
        // Pace.restart();
        // token
        const token = Session.getSession("session__jmto_admin");
        const token_key = Config.webKey;
        // cek token
        if (token) {
            // request api get
            Axios({
                method: "post",
                url: `${Config.endPoint}${url}`,
                headers: {
                    Authorization: token,
                    "X-API-KEY": token_key,
                    "X-Apps": "web"
                },
                data: params,
                crossdomain: true
            })
                .then(function(response) {
                    const datas = response.data;
                    // cek kode response
                    switch (datas.status) {
                        case Config.error_code.success:
                            // resolve
                            resolve(datas);
                            return;
                        case Config.error_code.forbidden:
                            throw Config.error_code.forbidden;
                        case Config.error_code.notfound:
                            // redirect notfound
                            that.props.history.push("/404");
                            return;
                        default:
                            if (typeof datas.message == "object") {
                                SwalWarning(
                                    "Warning",
                                    "Periksa kembali inputan anda"
                                );
                                // reject
                                reject(datas.message);
                            } else {
                                throw datas.message;
                            }
                    }
                })
                .catch(function(error) {
                    switch (error) {
                        case Config.error_code.forbidden:
                            // redirect login
                            that.props.history.push("/");
                            break;
                        default:
                            SwalError("Error", error);
                            break;
                    }
                    // reject
                    reject("");
                });
        } else {
            // redirect login
            that.props.history.push("/");
            // reject
            reject("");
        }
    });
};

// post public untuk request yg tidak menggunkan token
const PostPublic = (that, url, params) => {
    return new Promise((resolve, reject) => {
        // Pace.restart();
        // request api get
        Axios({
            method: "post",
            url: `${Config.endPoint}${url}`,
            headers: {
                "X-Apps": "web"
            },
            data: params,
            crossdomain: true
        })
            .then(function(response) {
                const datas = response.data;
                // cek kode response
                switch (datas.status) {
                    case Config.error_code.success:
                        // resolve
                        resolve(datas);
                        return;
                    case Config.error_code.forbidden:
                        throw Config.error_code.forbidden;
                    case Config.error_code.notfound:
                        // redirect notfound
                        that.props.history.push("/404");
                        return;
                    default:
                        if (typeof datas.message == "object") {
                            // reject
                            reject(datas.message);
                        } else {
                            throw datas.message;
                        }
                }
            })
            .catch(function(error) {
                switch (error) {
                    case Config.error_code.forbidden:
                        // redirect login
                        that.props.history.push("/");
                        break;
                    default:
                        SwalError("Error", error);
                        break;
                }
                // reject
                reject("");
            });
    });
};

export default GetPrivate;
export { PostPublic, PostPrivate };
