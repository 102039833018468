import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";


const Notification = props => {
    // hooks
    const [state, setState] = useState(false);
    //open dropdown
    let dropdown = classNames("dropdown", "notifications-menu", {
        open: state
    });
    //return
    return (
        <li className={dropdown}>
            <NavLink
                to="#"
                className="dropdown-toggle"
                onClick={event => {
                    event.preventDefault();
                    setState(!state);
                }}
            >
                <i className="fa fa-bell-o"></i>
                <span className="label label-danger">{props.total_rugi}</span>
            </NavLink>
            <ul className="dropdown-menu">
                <li className="header">{props.total_rugi} Notifikasi</li>
                <li>
                    <ul className="menu">
                    {props.list_cs.map((item) => 
                                        (
                        <li>
                             <NavLink
                                to="/dashboard/event/customer"
                                    onClick={() => props.toogleShow("edit", item)} >
                                <b>{item.keterangan.substring(0, 25)}...</b> <br></br> {item.waktu}&nbsp;{item.time}
                                </NavLink>
                        </li>
                        ))}

                        {props.list_gr.map((item) => 
                                        (
                        <li>
                             <NavLink
                                to="/dashboard/report/ganti_rugi/rekap"
                                onClick={() => props.notifProcess(item)} 
                                   >
                                        Ganti Rugi dengan No Laporan<br></br>
                                {item.no_laporan} - <br></br> <b>Sudah dibayar</b> &nbsp; 
                                  {item.tanggal}&nbsp;{item.time}
                                </NavLink>
                        </li>
                        ))}
                    </ul>
                </li>
                <li className="footer">
                    {/* <NavLink
                            to="/dashboard/event/customer"
                        >
                            View all
                        </NavLink> */}
                </li>
            </ul>
        </li>
    );
};

export default Notification;
